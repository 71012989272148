import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { NavLink } from "react-router-dom";
const ExtensionModule = () => {

    return (
        <>
            <Header />
            <section>
            <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/header-bnr.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
                    <div className="container">
                        <div className="row text-center d-flex  justify-content-center align-items-center">
                            <div className="col-lg-6 text-lg-start">
                                <div className="pb-lg-5 pt-lg-5">
                                    <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>Extension Modules</p>

                                </div>
                            </div>
                            <div className="col-lg-6 text-lg-center">
                                <div className=" pb-lg-5  pt-lg-5">
                                    <p className="text-white ">
                                        <span className=""><NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}> <NavLink exact to="/Grandstream" className="text-white">/ Grandstream</NavLink> / IP Voice Telephony / Extension Modules </span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 d-none d-sm-block">
                                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>BRANDS</span>
                                <div className="mb-1"></div>
                                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                                <div className="border border-white border-opacity-10 mb-2 "></div>
                                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                                    <NavLink exact to="/grandstream" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; Grandstream</p></NavLink>
                                    <NavLink exact to="/Dinstar" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Dinstar</p></NavLink>
                                    <NavLink exact to="/NEC" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; NEC</p></NavLink>
                                    <NavLink exact to="/Alcatel" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Alcatel</p></NavLink>
                                    <NavLink exact to="/YeaLink" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;YeaLink</p></NavLink>
                                    <NavLink exact to="/Poly" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Poly</p></NavLink>
                                    <NavLink exact to="/Cisco" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Cisco</p></NavLink>

                                </p>

                                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>SOLUTIONS</span>
                                <div className="mb-1"></div>
                                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                                <div className="border border-white border-opacity-10 mb-2 "></div>
                                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                                    <NavLink exact to="/Enterprise-Manufacturing" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Enterprise-Manufacturing</p></NavLink>
                                    <NavLink exact to="/Security&Surveillance" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Security & Surveillance</p></NavLink>
                                    <NavLink exact to="/EducationHospitality" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}> Education & Hospitality</p></NavLink>
                                    <NavLink exact to="/BusinessConference" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Business Conference</p></NavLink>

                                </p>

                                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">

                                    <div className="carousel-inner">
                                        <div className="carousel-item active" data-bs-interval="2500">
                                            <div>
                                                <NavLink exact to="/GWN7605">
                                                    <img src={require("../images/GWN7605.png")} style={{ width: "263px" }} alt="GWN7605" />
                                                    <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>WiFi Access Point (GWN7605)</p>
                                                    <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream's powerful WiFi Access Points offer high performance networking, tremendous WiFi coverage range read more.. </p>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="carousel-item" data-bs-interval="2500">
                                            <div>
                                                <NavLink exact to="/BusinessConference">
                                                    <img src={require("../images/Business-Conferencing.png")} style={{ width: "263px" }} alt="Business-Conferencing" />
                                                    <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Business Conferencing</p>
                                                    <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream’s video conferencing solutions offer small and medium sized businesses flexibility read more..</p>
                                                </NavLink>
                                            </div>            </div>
                                        <div className="carousel-item" data-bs-interval="2500">
                                            <div>
                                                <NavLink exact to="/wp810">
                                                    <img src={require("../images/wifi-cordless.png")} style={{ width: "263px" }} alt="wifi-cordless" />
                                                    <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Wi-Fi IP Phone (WP 810)</p>
                                                    <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>WP810 is a portable Wi-Fi IP phone designed to suit a variety of enterprises  Read More..</p>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div>
                                    <img src={require("../images/Extension-Modules-bnr.jpg")} className="w-100" alt="Extension-Modules-bnr" />
                                </div>

                                <div className="mt-3" style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                                    <p>
                                        Expand your communications solution by linking your desk phone to your mobile device to keep in touch with your business and residential accounts while working remotely or traveling. Grandstream Wave is a free softphone that revolutionizes a user’s workflow. It integrates with up to 6 SIP accounts and supports essential call control features such as 6-way voice conferencing, 24 virtual BLF keys, 2-way video calls, and so much more. The Grandstream Wave is also capable of integrating with your IP PBXs (such as Grandstream’s UCM6200 series or UCM6510 series of IP PBX) for even more powerful network utilization.
                                    </p>
                                </div>

                                <div className="row gy-4 mb-4">
                                    <div className="col-lg-5 p-3  text-center " >
                                        <div id="carouselExampleFade" class="carousel border slide " data-bs-ride="carousel">
                                            <div class="carousel-inner text-center">

                                                <div class="carousel-item active animated">

                                                    <img className=" w-100" src={require("../images/extension-modules1.png")} alt="extension-modules1" />
                                                </div>
                                                <div class="carousel-item" >

                                                    <img className=" w-100" src={require("../images/extension-modules2.png")} alt="extension-modules2" />
                                                </div>
                                                <div class="carousel-item ">

                                                    <img className=" w-100" src={require("../images/extension-modules3.png")} alt="extension-modules3" />
                                                </div>

                                            </div>
                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                                                <span class="carousel-control-next-icon p-4" aria-hidden="true" style={{backgroundColor:"#2B2B2B"}}></span>
                                                <span class="visually-hidden">Previous</span>
                                            </button>
                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                                                <span class="carousel-control-prev-icon p-4" aria-hidden="true" style={{backgroundColor:"#2B2B2B"}}></span>
                                                <span class="visually-hidden">Next</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-7 pt-5 ">
                                        <p style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#666666" }}>
                                            Enable users to move freely and continue to receive calls from any business or residential SIP account. The Grandstream Wave is a free softphone application that allows users to connect to their SIP accounts from anywhere in the world. Utilize on any AndroidTM or iOS device to increase mobility and stay connected to essential communications.
                                        </p>
                                        <NavLink exact to="/Contact-us" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Request a Quote</button></NavLink>

                                    </div>

                                </div>

                                <div className="row" style={{ backgroundColor: "#F7F7F7" }}>
                                    <div className="col-lg-7">
                                        <p className="pt-3 ps-3" style={{ fontSize: "24px", color: "#186191", fontFamily: "serif", fontWeight: "600" }}>Features
                                        </p>
                                        <ul style={{ listStyleType: "none", lineHeight: "28px" }}>
                                            <li>
                                                <i className="fa-solid fa-check"></i> 128x384 backlit LCD display
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-check"></i>  20 programmable dual-color buttons per module, 2 pages per module (40 contacts total)
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-check"></i> BLF/speed dial

                                            </li>
                                            <li>
                                                <i className="fa-solid fa-check"></i> Daisy-chain up to 4 modules for up to 160 contacts/extensions

                                            </li>
                                            <li>
                                                <i className="fa-solid fa-check"></i> BLF (busy lamp field, standard or eventlist) Call Park/Pick-up, Speed Dial, Presence, Intercom, and conference/ transfer/forward and more
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-5 text-end p-3">
                                        <div>
                                            <img src={require("../images/gxp2200ext-right.jpg")} className="w-100" alt="" />
                                        </div>
                                    </div>

                                </div>

                                <div className="row mt-3">
                                    <div className="col-lg-2 text-center">
                                        <img src={require("../images/icon-busy-lamp-field.png")} className="w-75" alt="" />
                                        <p style={{fontSize:"14px",fontFamily:"sans-serif",color:"#666666"}}>
                                            Busy Lamp Field
                                        </p>
  
                                    </div>
                                    <div className="col-lg-2 text-center">
                                        <img src={require("../images/icon-call-transfert.png")} className="w-75" alt="icon-call-transfert" />
                                        <p style={{fontSize:"14px",fontFamily:"sans-serif",color:"#666666"}}>
                                        Call transfer
                                        </p>
  
                                    </div>
                                    <div className="col-lg-2 text-center">
                                        <img src={require("../images/icon-call-forwarding.png")} className="w-75" alt="icon-call-forwarding" />
                                        <p style={{fontSize:"14px",fontFamily:"sans-serif",color:"#666666"}}>
                                        Call forwarding
                                        </p>
  
                                    </div>
                                    <div className="col-lg-2 text-center">
                                        <img src={require("../images/icon-call-queue.png")} className="w-75" alt="icon-call-queue" />
                                        <p style={{fontSize:"14px",fontFamily:"sans-serif",color:"#666666"}}>
                                        Call queue
                                        </p>
  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <Footer />
        </>
    )
}
export default ExtensionModule;