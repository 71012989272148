import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import "./Gateway-ATAs.css"
import Header from "./Header";

const Gateway = () => {

    return (
        <>
            <Header />

            <section style={{ backgroundColor: "#090909" }}>
                <div className="container">
                    <div className="row text-center d-flex  justify-content-center align-items-center">
                        <div className="col-lg-6  ">
                            <div className="text-lg-start p-lg-2 ps-lg-5">
                                <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>Gateways & Atas
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6   text-lg-center">
                            <div className="p-lg-2">
                                <p className="text-white ">
                                <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}> / Gateways & Atas
                                    </span></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        

            <section>
                <div className="container-fluid pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 d-none d-sm-block">
                                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>BRANDS</span>
                                <div className="mb-1"></div>
                                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                                <div className="border border-white border-opacity-10 mb-2 "></div>
                                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                                    <NavLink exact to="/grandstream" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; Grandstream</p></NavLink>
                                    <NavLink exact to="/Dinstar" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Dinstar</p></NavLink>
                                    <NavLink exact to="/NEC" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; NEC</p></NavLink>
                                    <NavLink exact to="/Alcatel" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Alcatel</p></NavLink>
                                    <NavLink exact to="/YeaLink" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;YeaLink</p></NavLink>
                                    <NavLink exact to="/Poly" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Poly</p></NavLink>
                                    <NavLink exact to="/Cisco" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Cisco</p></NavLink>

                                </p>

                                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>SOLUTIONS</span>
                                <div className="mb-1"></div>
                                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                                <div className="border border-white border-opacity-10 mb-2 "></div>
                                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                                    <NavLink exact to="/Enterprise-Manufacturing" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Enterprise-Manufacturing</p></NavLink>
                                    <NavLink exact to="/Security&Surveillance" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Security & Surveillance</p></NavLink>
                                    <NavLink exact to="/EducationHospitality" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}> Education & Hospitality</p></NavLink>
                                    <NavLink exact to="/BusinessConference" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Business Conference</p></NavLink>

                                </p>

                                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">

                                    <div className="carousel-inner">
                                        <div className="carousel-item active" data-bs-interval="2500">
                                            <div>
                                                <NavLink exact to="/GWN7605">
                                                    <img src={require("../images/GWN7605.png")} style={{ width: "263px" }} alt="GWN7605" />
                                                    <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>WiFi Access Point (GWN7605)</p>
                                                    <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream's powerful WiFi Access Points offer high performance networking, tremendous WiFi coverage range read more.. </p>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="carousel-item" data-bs-interval="2500">
                                            <div>
                                                <NavLink exact to="/BusinessConference">
                                                    <img src={require("../images/Business-Conferencing.png")} style={{ width: "263px" }} alt="Business-Conferencing" />
                                                    <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Business Conferencing</p>
                                                    <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream’s video conferencing solutions offer small and medium sized businesses flexibility read more..</p>
                                                </NavLink>
                                            </div>            </div>
                                        <div className="carousel-item" data-bs-interval="2500">
                                            <div>
                                                <NavLink exact to="/wp810">
                                                    <img src={require("../images/wifi-cordless.png")} style={{ width: "263px" }} alt="wifi-cordless" />
                                                    <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Wi-Fi IP Phone (WP 810)</p>
                                                    <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>WP810 is a portable Wi-Fi IP phone designed to suit a variety of enterprises  Read More..</p>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div>
                                    <img src={require("../images/img/gateways-ata-bnr.jpg")} className="w-100" alt="ip-voice-telephony-bnr" />
                                </div>

                                <div className="mt-5" style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                                    <p>
                                        Making integrated IP solutions easy and manageable, our HT series of Analog Telephone Adapters are trusted and deployed by some of the largest service providers in the world. Integrate individual analog phones to a home or office environment with these simple to deploy solutions.
                                    </p>
                                </div>

                                <div className="row mb-5">
                                    <div className="col-md-3 h-100 mt-2">
                                        <div className="d-flex btnHover justify-content-center align-items-center imgHover border  p-3 border-dark ">
                                            <img src={require("../images/Voip-gateway.png")} className="w-100 " alt="Voip-gateway" />
                                            <NavLink className="imgbtn text-center" exact to="/Voip-gateway"> <button className="btn border-none">See Product</button></NavLink>
                                        </div>
                                    </div>
                                    <div className="col-lg-9">
                                        <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>VoIP Gateways</p>
                                        <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                                            The GXW IP Analog Gateway Series enables businesses to create seamless office environments, integrate traditional phone systems into a VoIP network and efficiently manage communication costs. The GXW Series is designed for full interoperability with leading IP-PBXs, Softswitches and most SIP-based environments and offers 4 or 8 port models and a video surveillance port on the GXW410x models.<NavLink exact to="/Voip-gateway">more →</NavLink>
                                        </p>
                                        <div className="border border-dark border-opacity-10 "></div>
                                    </div>
                                </div>

                                <div className="row mt-5">
                                    <div className="col-md-3 h-100 mt-2">
                                        <div className="d-flex btnHover justify-content-center align-items-center imgHover border  p-3 border-dark ">
                                            <img src={require("../images/Analog-Telephone-Adapter.jpg")} className="w-100 " alt="Analog-Telephone-Adapter" />
                                            <NavLink className="imgbtn text-center" exact to="/Analog-Telephone-Adapter"> <button className="btn border-none">See Product</button></NavLink>
                                        </div>
                                    </div>
                                    <div className="col-lg-9">
                                        <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>Analog Telephone Adaptors</p>
                                        <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                                            The GRP2615 is a high-end carrier-grade IP phone featuring a sleek design and a suite of next-generation features including integrated Wi-Fi, Bluetooth support, 40 multipurpose keys (MPKs), an available extension module, dual Gigabit ports and more. This device features a large 4.3 inch color LCD with swappable face plates to allow for easy logo customization. For cloud provisioning and centralized management, the GRP2615 is supported by Grandstream’s Device Management System (GDMS), which provides a centralized interface to configure, provision, manage and monitor deployments of Grandstream endpoints. <NavLink exact to="/Analog-Telephone-Adapter">more →</NavLink>
                                        </p>
                                        <div className="border border-dark border-opacity-10 "></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <Footer />
        </>
    )
}
export default Gateway;