import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const EnterpriseServer = () => {

  return (
    <>
      <Header />
      <section>
        <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/header-bnr.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
          <div className="container">
            <div className="row text-center d-flex  justify-content-center align-items-center">

              <p className="text-white text-center p-lg-5 fw-bold fs-3">Enterprise Server</p>

            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-3">
          <div className="row">
            <div className="col-lg-6">
              <img className="w-100 " src={require("../images/EPS.png")} alt="" />
            </div>
            <div className="col-lg-6">
              <p style={{ fontSize: "20px", fontFamily: 'serif', fontWeight: "600" }}>What is Enterprise Server?</p>
              <p style={{ textAlign: "justify", fontSize: "14px", lineHeight: "1.7", fontFamily: "sans-serif", color: "#666666" }}>
                Powerful Linux scale-up server that delivers sustainability, security, hybrid cloud and cyber resilience. A scale-up server that runs IBM® AIX®, IBM i or Linux, designed to tackle challenges with new levels of performance, core-to-cloud data protection and streamlined automation and insights. An Enterprise Server is a computer that stores programs serving the collective needs of an enterprise rather than a single user or department. Historically, mainframe-sized computers have functioned as enterprise servers (although they were not referred to as servers until recently).
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid mt-5">
          <div className="container">
            <div className="row g-3">
              <div className="col-lg-4 h-100">
                <div className="border text-center">
                  <img src={require("../images/IBM-z16.png")} className="w-75" alt="" />
                  <p style={{ fontSize: "20px", fontFamily: "sans-serif", fontWeight: "600" }}>IBM® z16™</p>
                  <p className="p-2" style={{ fontSize: "15px", fontFamily: "sans-serif" }}>The new IBM z16 platform brings AI and cyber resiliency to your hybrid cloud using innovative on-chip AI inferencing and industry-first, quantum-safe technologies.</p>
                </div>
              </div>

              <div className="col-lg-4 h-100">
                <div className="border text-center">
                  <img src={require("../images/IBM-LinuxONE-Emperor-4.jpg")} className="w-75 pt-2" alt="" />
                  <p style={{ fontSize: "20px", fontFamily: "sans-serif", fontWeight: "600" }}>IBM® LinuxONE Emperor 4</p>
                  <p className="p-1 " style={{ fontSize: "15px", fontFamily: "sans-serif" }}>IBM® LinuxONE Emperor 4 helps organizations that care about achieving sustainability goals reduce energy costs and carbon footprint with a secure.</p>
                </div>
              </div>

              <div className="col-lg-4 h-100">
                <div className="border text-center">
                  <img src={require("../images/IBM-Power-E1080.png")} className="w-75 mt-2" alt="" />
                  <p style={{ fontSize: "20px", fontFamily: "sans-serif", fontWeight: "600" }}>IBM® Power® E1080</p>
                  <p className="p-2" style={{ fontSize: "15px", fontFamily: "sans-serif" }}>IBM Power E1080 is now improved with transparent memory encryption, efficient scaling and faster insights using production-ready AI at the point of data.</p>
                </div>
              </div>



              <div className="col-lg-4">
                <div className="border text-center">
                  <img src={require("../images/IBM-Power-E1050.png")} className="w-100" alt="" />
                  <p style={{ fontSize: "20px", fontFamily: "sans-serif", fontWeight: "600" }}>IBM® Power® E1050</p>
                  <p className="p-2" style={{ fontSize: "15px", fontFamily: "sans-serif" }}>
                    A high performance 4-socket rack scale-up server that <br /> runs IBM® AIX®, or Linux, optimized for data-intensive applications and hybrid cloud deployments.
                  </p>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="border text-center">
                  <img src={require("../images/IBM-Power-S1024.png")} className="w-100" alt="" />
                  <p style={{ fontSize: "20px", fontFamily: "sans-serif", fontWeight: "600" }}>IBM®-Power®-S1024</p>
                  <p className="p-2" style={{ fontSize: "15px", fontFamily: "sans-serif" }}>A 2-socket, 4U Power10-based scale-out server that runs on IBM AIX, IBM i or Linux, with pay-as-needed capabilities, end-to-end data security, superior reliability and shared resources across systems.</p>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="border text-center">
                  <img src={require("../images/IBM-Power-S1022.png")} className="w-100" alt="" />
                  <p style={{ fontSize: "20px", fontFamily: "sans-serif", fontWeight: "600" }}>IBM®-Power®-S1022</p>
                  <p className="p-2" style={{ fontSize: "15px", fontFamily: "sans-serif" }}>A 2-socket, 2U scale-out server that runs IBM® AIX®, IBM i or Linux. Workloads can be consolidated on fewer servers, thereby reducing software licensing, electrical and cooling costs.</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

    
      <Footer />
    </>
  )
}
export default EnterpriseServer;