import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const AnalogVoip = () => {
  return (
    <>
      <Header />


      <section>
      <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/header-bnr.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center',borderBottom:"2px solid white" }}>
          <div className="row text-center d-flex  justify-content-center align-items-center">
            <div className="col-lg-6">
              <div className="  pb-lg-5 pt-lg-5">
                <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>Analog Voip Gateway</p>

              </div>
            </div>
            <div className="col-lg-6   text-lg-center">
              <div className=" pt-lg-5 pb-lg-5">
                <p className="text-white">
                  <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}> / Analog Voip Gateway
                  </span></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid pt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-3 d-none d-sm-block">
                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>BRANDS</span>
                <div className="mb-1"></div>
                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                <div className="border border-white border-opacity-10 mb-2 "></div>
                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                  <NavLink exact to="/grandstream" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; Grandstream</p></NavLink>
                  <NavLink exact to="/Dinstar" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Dinstar</p></NavLink>
                  <NavLink exact to="/NEC" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; NEC</p></NavLink>
                  <NavLink exact to="/Alcatel" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Alcatel</p></NavLink>
                  <NavLink exact to="/YeaLink" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;YeaLink</p></NavLink>
                  <NavLink exact to="/Poly" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Poly</p></NavLink>
                  <NavLink exact to="/Cisco" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Cisco</p></NavLink>

                </p>

                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>SOLUTIONS</span>
                <div className="mb-1"></div>
                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                <div className="border border-white border-opacity-10 mb-2 "></div>
                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                  <NavLink exact to="/Enterprise-Manufacturing" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Enterprise-Manufacturing</p></NavLink>
                  <NavLink exact to="/Security&Surveillance" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Security & Surveillance</p></NavLink>
                  <NavLink exact to="/EducationHospitality" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}> Education & Hospitality</p></NavLink>
                  <NavLink exact to="/BusinessConference" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Business Conference</p></NavLink>

                </p>

                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">

                  <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/GWN7605">
                          <img src={require("../images/GWN7605.png")} style={{ width: "263px" }} alt="GWN7605" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>WiFi Access Point (GWN7605)</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream's powerful WiFi Access Points offer high performance networking, tremendous WiFi coverage range read more.. </p>
                        </NavLink>
                      </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/BusinessConference">
                          <img src={require("../images/Business-Conferencing.png")} style={{ width: "263px" }} alt="Business-Conferencing" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Business Conferencing</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream’s video conferencing solutions offer small and medium sized businesses flexibility read more..</p>
                        </NavLink>
                      </div>            </div>
                    <div className="carousel-item" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/wp810">
                          <img src={require("../images/wifi-cordless.png")} style={{ width: "263px" }} alt="wifi-cordless" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Wi-Fi IP Phone (WP 810)</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>WP810 is a portable Wi-Fi IP phone designed to suit a variety of enterprises  Read More..</p>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div>
                  <img src={require("../images/img/analog-voip-gateway-bnr.jpg")} className="w-100" alt="ip-voice-telephony-bnr" />
                </div>
                <div className="mt-3" style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                  <p>
                    Overview The Dinstar DAG series Analog VoIP gateway are cost-effective, reliable VoIP gateways that seamlessly connect your analog phones, fax machines and legacy PBX with IP PBX systems and IP Phone networks, leveraging investment of your existing telephony infrastructure and simplifying your VoIP migration. The DAG series includes a full range of robust gateways, from 1 to 112 FXS ports, 4 to 16 FXO ports, and hybrid 4FXS4FXO, 8FXS8FXO, have been widely deployed by telecom operators, service providers and enterprises.
                  </p>
                </div>

                <div className="row ">
                  <div className="col-md-3">

                    <div className="d-flex btnHover justify-content-center align-items-center imgHover border p-1 border-dark ">
                      <img src={require("../images/FXS-Gateway.png")} className="w-100 " alt="carrier-grade-ip-phone" />
                      <NavLink className="imgbtn text-center" exact to="/FXS-Gateway"> <button className="btn border-none">See Product</button></NavLink>
                    </div>
                  </div>

                  <div className="col-lg-9">
                    <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>FXS Gateway</p>
                    <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                      The Dinstar DAG series VoIP FXS analog gateways are cost-effective, reliable VoIP gateways that seamlessly connect your analog phones, fax machines and legacy PBX with IP PBX systems and IP Phone networks, without altering your existing telephony infrastructure.                    </p>
                    <div className="border border-dark border-opacity-10 "></div>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-md-3">
                    <div className="d-flex  btnHover justify-content-center align-items-center imgHover border  p-2 border-dark ">
                      <img src={require("../images/img/fxo-gateway-thumb.png")} className="w-100 " alt="carrier-grade-ip-phone" />
                      <NavLink className="imgbtn text-center" exact to="/FXO-Gateway"> <button className="btn border-none">See Product</button></NavLink>

                    </div>
                  </div>
                  <div className="col-lg-9">
                    <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>FXO Gateway</p>
                    <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                      The Dinstar DAG Series FXO VoIP Analog Gateways support 4, 8 and 16 FXO ports, connect traditional telecom operator phone lines or analog PBXs with IP telephony networks. Moreover, automatic fallback to PSTN teco lines when VoIP network go down is available to ensure your business continuity.                    </p>
                    <div className="border border-dark border-opacity-10 "></div>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-md-3">
                    <div className="d-flex  btnHover justify-content-center align-items-center imgHover border  border-dark ">
                      <img src={require("../images/img/fxo-fxs-gateway-thumb.png")} className="w-100" alt="carrier-grade-ip-phone" />
                      <NavLink className="imgbtn text-center" exact to="/FXO-FXS-Gateway"> <button className="btn border-none">See Product</button></NavLink>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>FXO & FXS Gateway</p>
                    <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                      The Dinstar DAG Series Hybrid Analog Voice Gateway are mixed 4FXS and 4FXO, 8 FXS and 8FXO gateways, with power failover and IP/SIP failover features to ensure business continuity, which are designed to be your perfect choices if PSTN connectivity and local survivability are needed.                    </p>
                    <div className="border border-dark border-opacity-10 "></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >


      <Footer />
    </>
  )
}
export default AnalogVoip;