import React, { useState } from "react";
import "./About.css";
import Footer from "./Footer";
import Header from "./Header";
import Patners from "./Patners";
import { Modal } from "react-bootstrap";



const About = () => {


  const [item, setItem] = useState("");
  console.log(item);
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const data = [
    { img: require("../images/img/img15.png") },
    { img: require("../images/img/img23.png") },
    { img: require("../images/img/img5.png") },
    { img: require("../images/img/img25.png") },
    { img: require("../images/img/img6.png") },
    { img: require("../images/img/img7.png") },
    { img: require("../images/img/img8.png") },
    { img: require("../images/img/img10.png") },
    { img: require("../images/img/img2.png") },
    { img: require("../images/img/img17.png") },
    { img: require("../images/img/img1.png") },
    { img: require("../images/img/img4.png") },
    { img: require("../images/img/img3.png") },
    { img: require("../images/img/img14.png") },
    { img: require("../images/img/img2.png") },
    { img: require("../images/img/img16.png") },
    { img: require("../images/img/img17.png") },
    { img: require("../images/img/img24.png") },
    { img: require("../images/img/img18.png") },
    { img: require("../images/img/img20.png") },
  ];

  return (
    <>
      <Header />


      <section>
        <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/bg1.jpg")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center', height: "50vh" }}>
        </div>
      </section>


      <section>
        <div className="container-fluid" style={{ backgroundColor: "#f7f7f7" }}>
          <div className="container">
            <div className="row ">
              <div className="col-md-6 ">
                <div className="about-Box">
                  <img src={require("../images/About.png")} className="img-fluid pt-5" alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="pt-3">
                  <p className="pb-3" style={{ fontSize: "24px", fontFamily: "Trirong, serif" }}>Our Mission
                    <div className="" style={{ width: "55px", border: "1px solid #186191" }}></div>
                    <div className="border border-dark border-opacity-10 mb-2  "></div>
                  </p>
                  <p className="pb-3" style={{ textAlign: "justify", fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>Skyward works with a mission to strive endlessly to be the high-performing telephony
                    solution provider. To achieve this goal, we believe in these 4 missions:</p>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="">
                      <p ><img src={require("../images/quality.png")} alt="quality" style={{ backgroundColor: "#186191" }} className="img-fluid" /><span className="" style={{ fontSize: "18px", color: "#333333", fontWeight: "500", fontFamily: "Trirong,serif" }}>&nbsp; Quality Is Imperative</span></p >
                      <p style={{ textAlign: "justify", color: '#808080', fontFamily: "sans-serif", fontSize: "14px" }} className="">Quality can never be compromised upon at Skyward. We leave no stone upturned to give 100 percent quality to our customers.</p>
                    </div>

                  </div>
                  <div className="col-lg-6">
                    <div className="about-p">
                      <p ><img src={require("../images/High-Performance.png")} alt="High-Performance" style={{ backgroundColor: "#186191" }} className="img-fluid" /> <span className="" style={{ fontSize: "18px", color: "#333333", fontWeight: "500", fontFamily: "Trirong,serif" }}>&nbsp; High-Performance Mandatory</span></p >
                      <p style={{ textAlign: "justify", color: '#808080', fontFamily: "sans-serif", fontSize: "14px" }}>We work with a mission to provide only the best telecom and VoIP solutions to our customers.</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="about-p">
                      <p ><img src={require("../images/Telecom-Solutions-icon.png")} alt="Telecom-Solution-icon" style={{ backgroundColor: "#186191", height: '65px' }} className="img-fluid" /> <span style={{ fontSize: "18px", color: "#333333", fontWeight: "500", fontFamily: "Trirong,serif" }}>&nbsp; World-Class Telecom Solutions</span></p >
                      <p style={{ textAlign: "justify", color: '#808080', fontFamily: "sans-serif", fontSize: "14px" }}>Skyward holds the reputation of providing world-class IP & VoIP systems to our wide range of clients.</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="about-p">
                      <p ><img src={require("../images/Customer.png")} alt="Customer" style={{ backgroundColor: "#186191" }} className="img-fluid" /> <span style={{ fontSize: "18px", color: "#333333", fontWeight: "500", fontFamily: "Trirong,serif" }}>&nbsp; Customer Is The King</span></p >
                      <p style={{ textAlign: "justify", color: '#808080', fontFamily: "sans-serif", fontSize: "14px" }}>Last but not the least! Customer holds much importance for our growth and success.</p>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </section>






      <div className="container-fluid bg-white">
        <div className="container ">
          <div className="row mt-5">
            <div className="col-lg-6">
              <img src={require("../images/vision.png")} className="img-fluid" alt="" />

            </div>
            <div className="col-lg-6">
              <p className=" mb-3" style={{ fontSize: "24px", fontFamily: "trirong,serif" }}>OUR VISION
                <div className="border border-primary" style={{ width: "55px" }}></div>
                <div className="border border-dark border-opacity-10 mb-2 "></div>
              </p>
              <div className="">
                <p><p style={{ textAlign: "justify", fontSize: "18px", fontFamily: "Open Sans" }}>The company is aimed to work on the VOIP technology in a highly affordable and innovative manner. Our main vision is to allow the success & satisfaction of our employees, partners and customers who are connected with us by using our Products & Solutions.
                  We pull our deep expertise and profound appreciative of client’s business province areas and needs to create a personalized solution for their individual business requirements.
                  We Work & Play with transparency, trust & team work, We feel proud on everything we do and make sure that everyone who is connected with us feel the same.
                  Recognized for the disciplined approach towards offering the best value of pleasure to every customer, we shake hand with.</p></p>


              </div>            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mb-3">
        <div className="container">
          <div className="row mt-5 " style={{ textAlign: "justify" }}  >
            <p className="fs-3" style={{ fontFamily: "Trirong,serif", color: "#333" }}>Who We Are ?
              <div className="border border-primary" style={{ width: "55px" }}></div>
            </p>

            <p className="fs-6" style={{ fontFamily: "sans-serif", color: "#666666" }}>Skyward Telecom is a renowned Global Telecom service provider working on the platform of IP and VoIP. We are working with our technology partners like Akuvox, Cyberdata, Epygi, Grandstream, Guardian Telecom, Milesight, Planet, Tonmind, Wi-Tek and Yeastar who support us in offering innovative products and solutions for the premise or Cloud-based requirements.
              In the ever changing and ever growing telecommunications industry. The firm offers multi-vendor solutions for Security and Surveillance.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid p-4" style={{ backgroundColor: "#25272E" }}>
        <div className="container ps-5 pe-5">
          <div className="row g-4 ps-5 pe-5 p-4">
            <div className="col-sm-3 col-md-3 ">
              <div className="h-100  example1 text-center">
                <i class="fa fa-file-pdf-o fs-2 example2 text-white" aria-hidden="true"></i>
                <p className="p-2 text-white" style={{ fontFamily: "Trirong,serif", fontSize: "14px", fontWeight: "500" }}>Company <br /> Profile</p>
              </div>
            </div>
            <div className="col-sm-3 col-md-3">
              <div className="h-100 example1 text-center">
                <i class="fa fa-file-pdf-o fs-2 example2 text-white" aria-hidden="true"></i>
                <p className=" p-2 text-white" style={{ fontFamily: "Trirong,serif", fontSize: "14px", fontWeight: "500" }}>Company <br /> Product Profile</p>
              </div>
            </div>
            <div className="col-sm-3 col-md-3">
              <div className="h-100 example1 text-center">
                <i class="fa fa-files-o fs-2 text-white example2" aria-hidden="true"></i>
                <p className="p-2 text-white" style={{ fontFamily: "Trirong,serif", fontSize: "14px", fontWeight: "500" }}>
                  Business Conferencing <br />
                  Presentation</p>
              </div>
            </div>
            <div className="col-sm-3 col-md-3">
              <div className="h-100 example1 text-center">
                <i class="fa fa-file-pdf-o fs-2 text-white example2" aria-hidden="true"></i>
                <p className=" p-2 text-white" style={{ fontFamily: "Trirong,serif", fontSize: "14px", fontWeight: "500" }}>Success <br /> Stories</p>
              </div>
            </div>
          </div>
        </div>

      </div>

      <section>
        <div className="container-fluid " style={{ marginTop: "70px" }}>
          <div className="container ">
            <div className="row mb-3">
              <div className="col-lg-6">
                <p className="">
                  <i class="fa fa-picture-o" style={{ fontSize: "70px", opacity: "0.1", position: "absolute", top: "" }} ></i>
                  <span style={{ fontFamily: "serif", fontSize: "16px", color: "#333333" }}>Priceless Memories</span> <br />
                  <span style={{ fontFamily: "tririong ,serif", fontSize: "32px" }}> OUR <span style={{ color: "#186191" }}>GALLERY</span> .</span>
                </p>
              </div>
              <div className="col-lg-6">
                <i><p style={{ fontSize: "18px", fontFamily: "open Sans" - 'sans serif' }}>The Cloud Team going through Hussles & Bussles of Channel Partner Interactions in Events at Different parts of the Country.
                </p></i>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-md-10">
                <div className=''>

                  <div className="row">
                    {
                      data.length === 0 || data === "undefined" || data === null ?
                        <> </> :
                        data.map((ele) => {
                          return (
                            <>
                              <div className="col-lg-3 border p-0 border-2 "  >
                                <div className="thumb">
                                  <img className='w-100' src={ele.img} alt="" srcset="" />
                                  <div className="shadow-img d-flex justify-content-center align-items-center ">
                                    <button className='text-start HoverEffect ' style={{ border: "none", padding: "5px", borderRadius: "25px", backgroundColor: "#186191", outline: "none" }} onClick={() => {
                                      handleShow();
                                      setItem(ele);
                                    }}>
                                      <i className="fa fa-picture-o fs-5 " style={{ color: "white" }} aria-hidden="true"></i>
                                    </button>
                                    <div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        })
                    }
                  </div>
                </div>
              </div>
              {
                item.length === 0 || item === "undefined" || item === null ?
                  < >
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header >
                        <div className='w-100 h-100 p-5 text-center'> "somethings went wrong" </div>
                      </Modal.Header>
                    </Modal>
                  </> :

                  <Modal className='w-100 bg-dark bg-opacity-10' show={show} onHide={handleClose} >
                    <Modal.Header closeButton >
                      <img className='w-100' src={item.img} alt="" srcset="" />
                    </Modal.Header>
                  </Modal>
              }
              <div className="col-lg-1"></div>
            </div>
          </div>
        </div>
      </section >

      <section>
        <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/bg31.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-9">
                <div className="ps-2 pt-4">
                  <p className="fs-2" style={{ color: "white", fontWeight: "700", fontFamily: "sans serif" }}>
                    Bright Solutions. Profitable Results.

                    <p style={{ fontSize: '18px', color: "white", fontWeight: "700", fontFamily: "sans serif" }}>
                      Experience you can trust, service you can count on.
                    </p></p>
                </div>
              </div>
              <div className="col-lg-3 text-start pt-4 pb-2">
                <a href="/Contact-us"><button style={{ border: "none", outline: "none" }} className="ps-3 pe-3 pt-3 pb-3 rounded-pill btn-lg text-black bg-white">Contact Us</button></a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="container-fluid bg-dark bg-opacity-10 ">
        <p className="text-center fs-3  pt-3 fw-bold pb-3 ">Our Clients</p>
      </div> */}
      <div className="container-fluid pt-5">
        <Patners />
      </div>

      <Footer />
    </>
  )
}
export default About;