import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";


const WP822 = () => {

    return (
        <>
            <Header />

            <section style={{ backgroundColor: "#090909" }}>
                <div className="container">
                    <div className="row text-center d-flex  justify-content-center align-items-center">
                        <div className="col-lg-6  ">
                            <div className="text-lg-start p-lg-2 ps-lg-5">
                                <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>WiFi Cordless
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6   text-lg-center">
                            <div className="p-lg-2">
                                <p className="text-white ">
                                    <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home /</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}>Grandstream / IP Voice Telephony  / WiFi Cordless /  WP822
                                    </span></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


          
            <section>
                <div className="container">
                    <div className="container mt-5 ">
                        <div className="row">

                            <div className="col-lg-4 " >
                                <div id="carouselExampleFade" class="carousel slide " data-bs-ride="carousel">
                                    <div class="carousel-inner text-center border" style={{ height: "380px" }}>
                                        <div class="carousel-item active ">
                                            <img className=" w-100" src={require("../images/WP825-slide1.png")} alt="WP825-slide1" />
                                        </div>

                                        <div class="carousel-item">
                                            <img className=" w-100" src={require("../images/WP825-slide2.png")} alt="WP825-slide2" />
                                        </div>

                                        <div className="carousel-item">
                                            <img className=" w-100" src={require("../images/WP825-slide1.png")} alt="WP825-slide1" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className=" w-100" src={require("../images/WP825-slide2.png")} alt="WP825-slide2" />
                                        </div>

                                    </div>
                                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                                        <span class="carousel-control-prev-icon p-4 bg-dark" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                                        <span class="carousel-control-next-icon p-4 bg-dark" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>


                            <div className="col-lg-8">
                                <div>
                                    <p className="" style={{ fontSize: "40px", fontFamily: "serif", fontWeight: "600" }} >
                                        Grandstream WP822
                                    </p>
                                    <p style={{ fontSize: "38px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>Cordless Wi-Fi IP Phone</p>

                                    <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                                        The WP822 is a portable Wi-Fi IP phone designed to suit a variety of enterprises and vertical market applications, including retail, logistics, medical and security. This cordless Wi-Fi phone comes equipped with integrated dual-band 802.11a/b/g/n/ac Wi-Fi, an advanced antenna design and roaming support. By adding 8-hour talk time and HD voice with dual-MICs, the WP822 offers an affordable option and comes equipped with a combination of features, mobility and durability to suit all portable telephony needs.
                                    </p>
                                    <NavLink exact to="/Contact-us" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Request a Quote</button></NavLink>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container mt-5">
                    <div className="row" style={{ backgroundColor: "#F7F7F7" }}>
                        <div className="col-lg-7  ps-lg-5 ">
                            <p className="ps-3" style={{ fontSize: "30px", fontFamily: "serif", color: "#186191" }}>
                                Features
                            </p>

                            <ul className="text-decoration-none" style={{ listStyleType: "none", fontSize: "16px", fontFamily: "sans-serif", lineHeight: "30px", textAlign: "justify" }}>
                                <li>
                                    <i className=" fa fa-check" aria-hidden="true"></i>  2 SIP accounts, 2 lines
                                </li>
                                <li>
                                    <i className=" fa fa-check" aria-hidden="true"></i> Dual-band Wi-Fi with efficient antenna design and advanced roaming support.
                                </li>
                                <li>
                                    <i className=" fa fa-check" aria-hidden="true"></i> HD voice & dual MIC design with AEC and Noise Shield Technology
                                </li>
                                <li>
                                    <i className=" fa fa-check" aria-hidden="true"></i> Integrated Bluetooth to connect headsets
                                </li>
                                <li>
                                    <i className=" fa fa-check" aria-hidden="true"></i>  Rechargeable 2000mAh battery, 8 hour talk time, 200-hour standby
                                </li>
                                <li>
                                    <i className=" fa fa-check" aria-hidden="true"></i>  Configurable button for push-to-talk
                                </li>
                                <li>
                                    <i className=" fa fa-check" aria-hidden="true"></i>  Micro USB port and 3.5mm headset jack
                                </li>

                            </ul>

                        </div>
                        <div className="col-lg-5 mt-lg-3 mb-lg-3 text-center">
                            <div>
                                <img src={require("../images/wp820-1.png")} className="w-75" alt="WP820-1" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container  mt-lg-5  mb-lg-3 ">
                    <div className="row text-center">
                        <div className="col-md-2">
                            <div>
                                <img className="w-25 m-2" src={require("../images/wifi icon web-01.png")} alt="wifi icon web-01" />
                                <p>Rechargeable Battery</p>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <img className="w-25 m-2" src={require("../images/icon-bluetooth.png")} alt="icon-bluetooth" />
                                <p>USB</p>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <img className="w-25 m-2" src={require("../images/icon-HD-audio.png")} alt="icon-HD-audio" />
                                <p>WIFI</p>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <img className="w-25 m-2" src={require("../images/icon-USB.png")} alt="icon-USB" />
                                <p>Hd-video</p>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <img className="w-25 m-2" src={require("../images/rechargeable battery-01.png")} alt="rechargeable battery-01" />
                                <p>bluetooth</p>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <img src={require("../images/accelerometer.png")} className="w-25 m-2" alt="accelerometer" />
                            <p>accelerometer</p>
                        </div>
                    </div>

                </div>
            </section>


            <Footer />
        </>
    )
}
export default WP822;