import React from "react";
import Header from "./Header";
import Footer from "./Footer";

import './Blog.css';
import { NavLink } from "react-router-dom";

const Blog = () => {

  return (
    <>
      <Header />
      <section>
        <div className='container-fluid' style={{ backgroundImage: `url(${require("../images/img/header-bnr.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center', height: "20vh" }}>
          <div className="container">
            <div className="row text-center d-flex justify-content-center align-items-center">
              <p className="pt-5 text-white fs-5 " style={{ fontFamily: "serif", fontWeight: "500" }}>OUR BLOGS</p>
            </div>
          </div>
        </div>
      </section >


      <section>
        <div className="container-fluid mt-5">
          <div className="container">
            <div className="row g-3 ">
              <div className="col-lg-3">

                <img src={require("../images/security_ser.png")} className="w-100" alt="" />
                <NavLink style={{ textDecoration: "none" }} exact to="/Surveillance-Security-Solution"><p className="" style={{ fontFamily: 'serif', fontSize: "20px" }}>Surveillance Security Solution</p> </NavLink>
                <p style={{ fontSize: "16px", fontFamily: "serif", color: "#666666", textAlign: "justify" }}>Surveillance is an essential aspect of human living. With increasing troubles and deteriorating law.</p>
                <NavLink exact to="/Surveillance-Security-Solution"><button type="button" className="btn bmw rounded-pill" style={{ fontSize: "11px", fontFamily: "Open Sans', sans-serif" }} >Read more</button></NavLink>
              </div>
              <div className="col-lg-3">
                <img src={require("../images/video-conference-1.jpg")} className="w-100" alt="" />
                <NavLink style={{ textDecoration: "none" }} exact to="/Video-Conferencing-benefits"><p className="" style={{ fontFamily: 'serif', fontSize: "20px" }}>Video Conferencing Benefits</p> </NavLink>
                <p style={{ fontSize: "16px", fontFamily: "serif", color: "#666666", textAlign: "justify" }}>One of the greatest blessings of modern technology has been video conferencing. Similar in concept to an actual conference.</p>
                <NavLink exact to="/Video-Conferencing-benefits"><button type="button" className="btn bmw rounded-pill" style={{ fontSize: "11px", fontFamily: "Open Sans', sans-serif" }} >Read more</button></NavLink>
              </div>

              <div className="col-lg-3">
                <img src={require("../images/Grandstream-releases.png")} className="w-100" alt="" />
                <NavLink style={{ textDecoration: "none" }} exact to="/New-Cordless-Wifi-Ip-Phone-Wp810"><p className="" style={{ fontFamily: 'serif', fontSize: "20px" }}>New Cordless Wifi IP Phone WP810</p> </NavLink>
                <p style={{ fontSize: "16px", fontFamily: "serif", color: "#666666", textAlign: "justify" }}>Grandstream, connecting the world since 2002 with award-winning unified communication solutions.</p>
                <NavLink exact to="/New-Cordless-Wifi-Ip-Phone-Wp810"><button type="button" className="btn bmw rounded-pill" style={{ fontSize: "11px", fontFamily: "Open Sans', sans-serif" }} >Read more</button></NavLink>
              </div>

              <div className="col-lg-3">
                <img src={require("../images/Top-office-phone.png")} className="w-100" alt="" />
                <NavLink style={{ textDecoration: "none" }} exact to="/Top-office-phone"><p className="" style={{ fontFamily: 'serif', fontSize: "20px" }}>TOP OFFICE PHONE SYSTEM</p> </NavLink>
                <p style={{ fontSize: "16px", fontFamily: "serif", color: "#666666", textAlign: "justify" }}>Are you opening up your own new business? Let’s update your existing phone system and give it a new range of technology.</p>
                <NavLink exact to="/Top-office-phone"><button type="button" className="btn bmw rounded-pill" style={{ fontSize: "11px", fontFamily: "Open Sans', sans-serif" }} >Read more</button></NavLink>
              </div>


            </div>
          </div>
        </div>
      </section>








      <Footer />
    </>
  )
}
export default Blog;