import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";


const Configuration = () => {

   return (
      <>
         <Header />

         <section>
         <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/bnr.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
               <div className="container">
                  <div className="row text-center d-flex  justify-content-center align-items-center">
                     <div className="col-lg-6 text-lg-start">
                        <div className="pb-lg-5 pt-lg-5">
                        <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>CONFIGURATION</p>

                        </div>
                     </div>
                     <div className="col-lg-6   text-lg-end">
                        <div className=" pt-lg-5 pb-lg-5">
                           <p className="text-white">
                           <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}> / <NavLink exact to="/Configuration" className="text-white">Services</NavLink> / Configuration</span></span>
                           </p>


                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section>
            <div className="container-fluid pt-5" style={{ backgroundColor: "#f7f7f7" }}>
               <div className="container">
                  <div className="row">
                     <p className="fs-2" style={{ fontSize: "32px", color: "#333333", fontWeight: "600", fontFamily: "serif" }}>Power Up All Your Business Communication Requirements with Our Configuration of Any Services</p>
                     <div style={{ fontFamily: "sans-serif", fontSize: "14px" }}>
                        <p>Our services have made a niche for themselves in the communication world. This is the reason why many enterprises have started shifting to the any gateway services for all their communication needs. In fact, this is the reason why many firms have started venturing into the field of offering these any services. However, Skyward stands apart from the crowd when it comes to offering all kinds of internet telephony services, including configuration.</p>
                        <p>Any products services are basically the need of the hour as they allow you to make and receive call over the Internet. These services are basically set of protocols which work together to offer the Internet functions just like the phone lines. Moreover, the basic difference between the two is that telephone services offers the mobility as well as flexibility which you may seldom get with traditional phone services. Moreover, the best aspect of these internet telephone services is that you can use it from anywhere and everywhere. You can use your laptops, desktops, or even your smart phones to make the calls.</p>
                        <p>Also, you may have to pay hundreds of dollars just for configuring an on-site hardware for your normal phone services. However, with gateway services, you do not need any such hardware as these services are easily inbuilt. In addition, the normal telephone services offers you business features with an extra cost while with  services, you get the standard business features such as voicemail, call forwarding, free of cost. For the traditional phone services, you need extra physical cable lines for their expansion. However, for internet telephone services, you can get an expansion by just tapping on the admin control panel.</p>
                     </div>
                  </div>
               
               </div>
            </div>

         </section>

         <section>
            <div className="container-fluid pt-3 pb-4" style={{backgroundColor:"#f7f7f7"}}>
               <div className="container">
               <div className="row">
                     <div className="col-lg-6 pt-3">
                        <img src={require("../images/img/configuration-service.jpg")} className="img-fluid" alt="Configuration-1" />
                     </div>
                     <div className="col-lg-6">

                        <p className="fs-2" style={{ fontFamily: "Trirong,serif", fontWeight: "600", fontSize: "32px" }}>Why Skyward Telecom?</p>
                        <div style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "30px" }}>
                           <p>However, the configuration of these any services may sound really easier to be done but the real picture is not so. It requires a reasonable amount of good technical skills as well as approaches. This is the reason why Skyward has built a team of professionals who hold the right expertise in configuring the telephony services. We offer elaborate sessions where our professionals will sit with you and tell you in detail about how to configure these services.</p>
                           <p>Moreover, our range of services do not just end at configuring the gateway services. We offer you the best support system for these services as well. Thus, we can even troubleshoot the configuration problems if any. Our quality of services are supreme and we hold the reputation of handling even bigger clients with ease. So, boost up your revenues and business sales with our tailor made configuration services Skyward stands apart from the crowd to offer you the best in the world configuration services. So, do not wait anymore and contact our office to get the best deal on the configuration of these gateway services.</p>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </section>
      
         <section>
         <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/bg31.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
               <div className="container">
                  <div className="row">
                     <div className="col-lg-9">
                        <div className="ps-2 pt-4">
                           <p className="fs-2" style={{ color: "white", fontWeight: "700", fontFamily: "sans serif" }}>
                              Bright Solutions. Profitable Results.

                              <p style={{ fontSize: '18px', color: "white", fontWeight: "700", fontFamily: "sans serif" }}>
                                 Experience you can trust, service you can count on.
                              </p></p>
                        </div>
                     </div>
                     <div className="col-lg-3 text-start pt-5 pb-2">
                        <a href="/Contact-us"><button style={{ fontSize: "14px", border: "none", outline: "none", padding: "10px 26px" }} className=" rounded-pill btn-lg text-black bg-white">Get a Best Configuration Service</button></a>
                     </div>
                  </div>
               </div>
            </div>
         </section>


         <Footer />
      </>
   )
}
export default Configuration;