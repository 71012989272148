import React, { useState } from "react";
import Header from "./Header";
import { NavLink } from "react-router-dom";
import "./Cisco.css";
import Footer from "./Footer";


const Cisco = () => {
  const [display, setDisplay] = useState("key_Featured");

  const [btn1, setBtn1] = useState("newStyle");

  const [btn2, setBtn2] = useState("btnStyle");

  const [btn3, setBtn3] = useState("newwStyle");



  const changleDisplay = () => {

    setDisplay("key_Featured")
    setBtn1("newStyle")
    setBtn2("btnStyle")
    setBtn3("newwStyle")


  }

  const changleDisplay1 = () => {

    setDisplay("Benefits")
    setBtn2("newStyle");
    setBtn3("newwStyle")

  }

  const changleDisplay2 = () => {

    setDisplay("features")
    setBtn2("newStyle");
    setBtn3("newwStyle")

  }



  return (
    <>
      <Header />


      <section style={{ backgroundColor: "#090909" }}>
        <div className="container">
          <div className="row text-center d-flex  justify-content-center align-items-center">
            <div className="col-lg-6  ">
              <div className="text-lg-start p-lg-2 ps-lg-5">
              <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>Network Switches</p>
                
              </div>
            </div>
            <div className="col-lg-6   text-lg-center">
              <div className="p-lg-2">
                <p className="text-white ">
                <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home /</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}>Brand / Cisco Server
                  </span></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section>
        <div className="container-fluid pt-5 mb-3">
          <div className="container">
            <div className="row">
              <div className="col-md-3 d-none d-sm-block">
                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>BRANDS</span>
                <div className="mb-1"></div>
                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                <div className="border border-white border-opacity-10 mb-2 "></div>
                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                  <NavLink exact to="/grandstream" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; Grandstream</p></NavLink>
                  <NavLink exact to="/Dinstar" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Dinstar</p></NavLink>
                  <NavLink exact to="/NEC" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; NEC</p></NavLink>
                  <NavLink exact to="/Alcatel" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Alcatel</p></NavLink>
                  <NavLink exact to="/YeaLink" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;YeaLink</p></NavLink>
                  <NavLink exact to="/Poly" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Poly</p></NavLink>
                  <NavLink exact to="/Cisco" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Cisco</p></NavLink>
                </p>

                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>SOLUTIONS</span>
                <div className="mb-1"></div>
                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                <div className="border border-white border-opacity-10 mb-2 "></div>
                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                  <NavLink exact to="/Enterprise-Manufacturing" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Enterprise-Manufacturing</p></NavLink>
                  <NavLink exact to="/Security&Surveillance" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Security & Surveillance</p></NavLink>
                  <NavLink exact to="/EducationHospitality" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}> Education & Hospitality</p></NavLink>
                  <NavLink exact to="/BusinessConference" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Business Conference</p></NavLink>

                </p>

                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">

                  <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/GWN7605">
                          <img src={require("../images/GWN7605.png")} style={{ width: "263px" }} alt="GWN7605" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>WiFi Access Point (GWN7605)</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream's powerful WiFi Access Points offer high performance networking, tremendous WiFi coverage range read more.. </p>
                        </NavLink>
                      </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/BusinessConference">
                          <img src={require("../images/Business-Conferencing.png")} style={{ width: "263px" }} alt="Business-Conferencing" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Business Conferencing</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream’s video conferencing solutions offer small and medium sized businesses flexibility read more..</p>
                        </NavLink>
                      </div>            </div>
                    <div className="carousel-item" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/wp810">
                          <img src={require("../images/wifi-cordless.png")} style={{ width: "263px" }} alt="wifi-cordless" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Wi-Fi IP Phone (WP 810)</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>WP810 is a portable Wi-Fi IP phone designed to suit a variety of enterprises  Read More..</p>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">

                <div className="mt-3">
                  <p className="" style={{ color: "#333333", fontSize: '27px', fontFamily: "sans-serif", fontWeight: "600" }}>
                    Meet Cisco
                  </p>
                </div>

                <div className="" style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                  <p>
                    Cisco is the worldwide technology leader that has been making the Internet work since 1984. Our people, products, and partners help society securely connect and seize tomorrow’s digital opportunity today. Cisco India: Cisco commenced India operations in 1995. The Advanced Global Briefing Center located in Pune and Bangalore showcases Cisco’s latest technology solutions and proof-of-concepts. Cisco’s go-to-Market strategy is through partners like Syntel.
                  </p>


                </div>



                <div className="ps-3">
                  <div className="row">
                    <div className="col-lg-6 p-0 d-flex " >
                      <button style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "600" }} className={btn1} onClick={changleDisplay}>VIDEO</button>
                      <button style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "600" }} className={btn2} onClick={changleDisplay1}>PHONE</button>
                      <button style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "600" }} className={btn3} onClick={changleDisplay2}>COLLABORATION</button>

                    </div>
                  </div>
                  <div className="row border ">
                    <div>
                      {


                        display === "key_Featured" ?
                          <div className="m-2 ">
                            <div className="container">
                              <div className="row gy-4 mb-4">
                                <div className="col-md-4 h-100 ">
                                  <div className="border p-2 border-dark">
                                    <div className="d-flex  justify-content-center align-items-center  ">
                                      <img className="w-100" src={require("../images/cisco-webex-room-kit.jpg")} alt="HP-Integrity-RX3600-Server" />
                                    </div>
                                    <div className="text-center">
                                      <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }}>CISCO WEBEX ROOM <br /> KIT</p>
                                      <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000", textAlign: "justify" }}>
                                        In addition, new capabilities enable even smarter meetings, smarter presentation capabilities, and smarter room and device integrations – further removing the barriers to usage and deployment of video in small to medium-sized rooms.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4 h-100 ">
                                  <div className="border p-2 border-dark">
                                    <div className="d-flex  justify-content-center align-items-center  ">
                                      <img className="w-100" src={require("../images/cisco-webex-room-kit-mini.jpg")} alt="HP-Integrity-RX4640-1" />
                                    </div>
                                    <div className="text-center">
                                      <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }}>CISCO WEBEX ROOM KIT MINI</p>
                                      <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000", textAlign: "justify" }}>
                                        Room Kit Mini delivers the industry-leading collaboration experience customers have come to expect from Cisco, which is consistent whether you’re in a huddle space or a corporate boardroom. Easy deployment.                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 h-100 ">
                                  <div className="border  p-2 border-dark">
                                    <div className="d-flex  justify-content-center align-items-center  ">
                                      <img className="w-100" src={require("../images/cisco-webex-rom-kit-plus.jpg")} alt="HT813" />
                                    </div>
                                    <div className="text-center">
                                      <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }}>CISCO WEBEX ROOM KIT PLUS</p>
                                      <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000", textAlign: "justify" }}>
                                        In addition, new capabilities enable even smarter meetings, smarter presentation capabilities, and smarter room and device integrations – further removing the barriers to usage and deployment of video in medium to large-sized rooms.                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 h-100 ">
                                  <div className="border p-2 border-dark">
                                    <div className="d-flex  justify-content-center align-items-center  ">
                                      <img className="w-100" src={require("../images/cisco-webex-room-kit-pro.jpg")} alt="HPE-ProLiant-DL20-Gen10-E-2224" />
                                    </div>
                                    <div className="text-center">
                                      <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }}>CISCO WEBEX ROOM KIT PLUS PRO</p>
                                      <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000", textAlign: "justify" }}>
                                        The Room Kit Pro acts as the audio and video engine for Ultra-High-Definition (UHD) video collaboration applications and AV integrations in which multiple screens, cameras, and content sources may be required.                                      </p>
                                    </div>
                                  </div>
                                </div>




                                <div className="col-md-4 h-100 ">
                                  <div className="border p-2 border-dark">
                                    <div className="d-flex  justify-content-center align-items-center  ">
                                      <img className="w-100" src={require("../images/cisco-webex-room-kit-plus-P60.jpg")} alt="HP-Integrity-RX6600" />
                                    </div>
                                    <div className="text-center">
                                      <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }}>CISCO WEBEX ROOM KIT PLUS P60</p>
                                      <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000", textAlign: "justify" }}>
                                        The Room Kit Plus P60 integrator package delivers an unmatched audio and video experience that customers have come to expect from Cisco. In addition, the package provides flexibility for rooms that can benefit from a pan-tilt-zoom camera.                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 h-100 ">
                                  <div className="border  p-2 border-dark">
                                    <div className="d-flex  justify-content-center align-items-center  ">
                                      <img className="w-100" src={require("../images/cisco-spark-board55.jpg")} alt="HPE-ProLiant-DL380-Gen10-4208" />
                                    </div>
                                    <div className="text-center">
                                      <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }}>CISCO SPARK BOARD 55</p>
                                      <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000", textAlign: "justify" }}>
                                        The Cisco Webex Board 55 is a fully self-contained system on a high-resolution 4K 55-inch LED screen. With an integrated 4K camera, embedded microphones, and a capacitive touch interface, the board brings intelligence, style, and usability to meeting rooms, from small to large.                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4 h-100 ">
                                  <div className="border  p-2 border-dark">
                                    <div className="d-flex  justify-content-center align-items-center  ">
                                      <img className="w-100" src={require("../images/cisco-webex-room-70.jpg")} alt="HPE-ProLiant-DL180-Gen10" />
                                    </div>
                                    <div className="text-center">
                                      <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }}>CISCO WEBEX ROOM 70</p>
                                      <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000", textAlign: "justify" }}>
                                        The Room 70 is available in two configurations: Room 70 Single (70S) with one 70″ LED screen for a people-focused experience, and Room 70 Dual (70D) with two 70” LED screens for a people-focused and people/content focused experience.                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 h-100 ">
                                  <div className="border  p-2 border-dark">
                                    <div className="d-flex  justify-content-center align-items-center  ">
                                      <img className="w-100" src={require("../images/Cisco-DX80.jpg")} alt="HPE-ProLiant-DL380-Gen10-4214R" />
                                    </div>
                                    <div className="text-center">
                                      <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }}>CISCO WEBEX DX80</p>
                                      <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000", textAlign: "justify" }}>
                                        Your Cisco DX80 may operate in one of three modes: Public Mode, Simple Mode, or Enhanced Mode. Public Mode is similar to a kiosk mode. This guide does not cover Public Mode. In Enhanced Mode, the Cisco DX80 acts as an Android tablet, and the phone part of the unit is then an app referred to as the Call app.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4 h-100 ">
                                  <div className="border  p-2 border-dark">
                                    <div className="d-flex  justify-content-center align-items-center  ">
                                      <img className="w-100" src={require("../images/cisco-webex-room-55.jpg")} alt="HPE-ProLiant-DL380-Gen10-4214R" />
                                    </div>
                                    <div className="text-center">
                                      <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }}>CISCO WEBEX ROOM 55</p>
                                      <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000", textAlign: "justify" }}>
                                        The Room 55, which includes camera, codec, display, speaker system, and microphones integrated in a single device, is optimized for rooms that seat up to seven people. It is an all-in-one, integrated system that’s easy to install, use, deploy, and manage. It’s crafted with high-quality components.                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 h-100 ">
                                  <div className="border  p-2 border-dark">
                                    <div className="d-flex  justify-content-center align-items-center  ">
                                      <img className="w-100" src={require("../images/cisco-IX5000.jpg")} alt="HPE-ProLiant-DL380-Gen10-4214R" />
                                    </div>
                                    <div className="text-center">
                                      <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }}>CISCO IX5000</p>
                                      <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000", textAlign: "justify" }}>
                                        The IX5000 Series delivers an exceptional immersive experience and a faster return on investment, both in lower deployment costs and innovative features that are designed to encourage higher utilization.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 h-100 ">
                                  <div className="border  p-2 border-dark">
                                    <div className="d-flex  justify-content-center align-items-center  ">
                                      <img className="w-100" src={require("../images/cisco-cmr-cloud.jpg")} alt="HPE-ProLiant-DL380-Gen10-4214R" />
                                    </div>
                                    <div className="text-center">
                                      <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }}>CISCO CMR CLOUD</p>
                                      <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000", textAlign: "justify" }}>
                                        With cloud-based conferencing services, you can shift from a Capital Expenditures (CapEx) financial model to an Operating Expenses (OpEx) model while using existing network and device investments. And because it is a cloud service, it simplifies the complexity of managing disparate technologies.                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 h-100 ">
                                  <div className="border  p-2 border-dark">
                                    <div className="d-flex  justify-content-center align-items-center  ">
                                      <img className="w-100" src={require("../images/cisco-spark-board70.jpg")} alt="HPE-ProLiant-DL380-Gen10-4214R" />
                                    </div>
                                    <div className="text-center">
                                      <p style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }}>CISCO SPARK BOARD 70</p>
                                      <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000", textAlign: "justify" }}>
                                        The Cisco Webex Board 70 is a fully self-contained system on a high-resolution 4K 70-inch LED screen. With an integrated 4K camera, embedded microphones, and a capacitive touch interface, the board brings intelligence, style, and usability to meeting rooms, from small to large.                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>


                          : display === "Benefits" ?
                            <div>
                              <div className="row m-3">
                                <div className="col-lg-4 h-100 text-center ">
                                  <div className=" border">
                                    <img src={require("../images/Cisco-Jabber.jpg")} className="w-100 mt-2" alt="" />
                                    <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }} >CISCO JABBER</p>
                                    <p className="p-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>Give your teams the freedom to be productive from anywhere, on any device, with Cisco Jabber. Cisco Jabber lets you access presence, instant messaging (IM), voice, video, voice messaging, desktop sharing, and conferencing.
                                    </p>
                                  </div>

                                </div>

                                <div className="col-lg-4 text-center ">
                                  <div className=" border">
                                    <img src={require("../images/Cisco-SPA500.jpg")} className="w-100 mt-2" alt="" />
                                    <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }} >CISCO SPA500</p>
                                    <p className="p-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>
                                      An Affordable Way to Improve Communications. Simplify and improve communications with Cisco Small Business SPA500 Series IP Phones. These phones offer features to serve everyone from manufacturing floor staff and cubicle workers to executives and remote employees.                                      </p>
                                  </div>

                                </div>

                                <div className="col-lg-4 text-center ">
                                  <div className=" border">
                                    <img src={require("../images/Cisco-SPA300.jpg")} className="w-100 mt-2" alt="" />
                                    <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }} >CISCO SPA300</p>
                                    <p className="p-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>
                                      Provide high-quality HD video and advanced VoIP communications for your workplace with the Cisco IP Phone 8800 Series. Select models also integrate telephony with your personal mobile devices. The 8800 series offers flexible deployment options, including Cisco on-premises, hosted, Spark Cloud, and third-party call control platforms.
                                    </p>
                                  </div>

                                </div>

                                <div className="col-lg-4 mt-3 text-center">
                                  <div className=" border">
                                    <img src={require("../images/Cisco-8800.jpg")} className="w-100 mt-2" alt="" />
                                    <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }} >CISCO 8800</p>
                                    <p className="p-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>The cost-effective Cisco IP Phone 7800 Series is ideal for small to large enterprises seeking high-quality, full-featured VoIP communications. It’s a great fit for customers who use traditional analog or digital phones today and want to advance to a Cisco IP Phone system.</p>
                                  </div>

                                </div>

                                <div className="col-lg-4 mt-3 text-center">
                                  <div className=" border">
                                    <img src={require("../images/Cisco-7800.jpg")} className="w-100 mt-2" alt="" />
                                    <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }} >CISCO 7800</p>
                                    <p className="p-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>
                                      The cost-effective Cisco IP Phone 7800 Series is ideal for small to large enterprises seeking high-quality, full-featured VoIP communications. It’s a great fit for customers who use traditional analog or digital phones today and want to advance to a Cisco IP Phone system.</p>
                                  </div>

                                </div>

                                <div className="col-lg-4 mt-3 text-center">
                                  <div className=" border">
                                    <img src={require("../images/Cisco-6900.jpg")} className="w-100 mt-2" alt="" />
                                    <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }} >CISCO 6900</p>
                                    <p className="p-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>Get affordable, reliable voice and video communications with a user- and eco-friendly endpoint. The Cisco Unified IP Phone 6900 Series is designed to improve productivity, foster collaboration, and reduce operating expenses with simplified, fully featured, and cost-effective communications.
                                    </p>
                                  </div>

                                </div>
                              </div>
                            </div>
                            : display === 'features' ?

                              <div>
                                <div className="row">
                                  <div className="col-lg-4  text-center">
                                    <div className="m-3 border">
                                      <img src={require("../images/Cisco-BE6K.jpg")} className="img-fluid" alt="" />
                                      <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }}>CISCO BE6K</p>
                                      <p className="ps-2 pe-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>Designed for mid-sized businesses and built with industry-leading, proven, and trusted technology, Cisco Business Edition 6000 delivers end-to-end collaboration capabilities for up to 1000 users, 2500 devices, 50 sites, and 100 contact centre agents.The packaged collaboration solution offers premium voice, video, mobility, messaging.</p>
                                    </div>
                                  </div>

                                  <div className="col-lg-4 text-center">
                                    <div className="m-3 border">
                                      <img src={require("../images/Cisco-BE7K.jpg")} className="img-fluid" alt="" />
                                      <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }}>CISCO BE7K</p>
                                      <p className="ps-2 pe-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>Cisco BE7K is a stackable, packaged collaboration solution optimized for organizations with 1000 or more users and thousands of devices. The solution offers premium voice, video, mobility, messaging, conferencing, instant messaging and presence, and contact center features on a single, integrated platform.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              : <div>
                                somethings went wrong
                              </div>
                      }
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    
      <Footer />
    </>
  )
}
export default Cisco;