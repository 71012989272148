import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const IPPBX = () => {
    return (
        <>
            <Header />

            <section style={{ backgroundColor: "#090909" }}>
                <div className="container">
                    <div className="row text-center d-flex  justify-content-center align-items-center">
                        <div className="col-lg-6  ">
                            <div className="text-lg-start p-lg-2 ps-lg-5">
                                <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>IP Pbxs</p>
                            </div>
                        </div>
                        <div className="col-lg-6   text-lg-center">
                            <div className="p-lg-2">
                                <p className="text-white ">
                                    <span className="" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}><NavLink className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span> <NavLink exact to="/Grandstream" className="text-white">/ Grandstream </NavLink>/ IP Pbxs
                                    </span></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section>
                <div className="container-fluid pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 d-none d-sm-block">
                                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>BRANDS</span>
                                <div className="mb-1"></div>
                                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                                <div className="border border-white border-opacity-10 mb-2 "></div>
                                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                                    <NavLink exact to="/grandstream" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; Grandstream</p></NavLink>
                                    <NavLink exact to="/Dinstar" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Dinstar</p></NavLink>
                                    <NavLink exact to="/NEC" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; NEC</p></NavLink>
                                    <NavLink exact to="/Alcatel" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Alcatel</p></NavLink>
                                    <NavLink exact to="/YeaLink" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;YeaLink</p></NavLink>
                                    <NavLink exact to="/Poly" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Poly</p></NavLink>
                                    <NavLink exact to="/Cisco" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Cisco</p></NavLink>

                                </p>

                                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>SOLUTIONS</span>
                                <div className="mb-1"></div>
                                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                                <div className="border border-white border-opacity-10 mb-2 "></div>
                                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                                    <NavLink exact to="/Enterprise-Manufacturing" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Enterprise-Manufacturing</p></NavLink>
                                    <NavLink exact to="/Security&Surveillance" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Security & Surveillance</p></NavLink>
                                    <NavLink exact to="/EducationHospitality" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}> Education & Hospitality</p></NavLink>
                                    <NavLink exact to="/BusinessConference" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Business Conference</p></NavLink>

                                </p>

                                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">

                                    <div className="carousel-inner">
                                        <div className="carousel-item active" data-bs-interval="2500">
                                            <div>
                                                <NavLink exact to="/GWN7605">
                                                    <img src={require("../images/GWN7605.png")} style={{ width: "263px" }} alt="GWN7605" />
                                                    <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>WiFi Access Point (GWN7605)</p>
                                                    <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream's powerful WiFi Access Points offer high performance networking, tremendous WiFi coverage range read more.. </p>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="carousel-item" data-bs-interval="2500">
                                            <div>
                                                <NavLink exact to="/BusinessConference">
                                                    <img src={require("../images/Business-Conferencing.png")} style={{ width: "263px" }} alt="Business-Conferencing" />
                                                    <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Business Conferencing</p>
                                                    <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream’s video conferencing solutions offer small and medium sized businesses flexibility read more..</p>
                                                </NavLink>
                                            </div>            </div>
                                        <div className="carousel-item" data-bs-interval="2500">
                                            <div>
                                                <NavLink exact to="/wp810">
                                                    <img src={require("../images/wifi-cordless.png")} style={{ width: "263px" }} alt="wifi-cordless" />
                                                    <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Wi-Fi IP Phone (WP 810)</p>
                                                    <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>WP810 is a portable Wi-Fi IP phone designed to suit a variety of enterprises  Read More..</p>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div>
                                    <img src={require("../images/img/pbx-bnr.jpg")} className="w-100" alt="ip-voice-telephony-bnr" />
                                </div>
                                <div className="mt-3">
                                    <p className="" style={{ color: "#333333", fontSize: '27px', fontFamily: "sans-serif", fontWeight: "600" }}>
                                        Best IP PBX Systems
                                    </p>
                                </div>
                                <div className="" style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                                    <p>
                                        A powerful yet easy to manage voice, video, data, and mobility communications platform has never been more achievable than when anchored with Grandstream’s series of UCM IP PBXs. The UCM series of IP PBXs offer an extensive set of unified communication features in an easy-to-manage on premise solution with no licensing fees, upgrade fees or costs per features. Our UCMs range from small and medium business deployments to small and medium enterprise solutions.
                                    </p>

                                </div>

                                <div className="row mb-5">
                                    <div className="col-md-3">
                                        <div className="d-flex h-100 btnHover justify-content-center align-items-center imgHover border  p-2 border-dark ">
                                            <img src={require("../images/img/UCM-Series-IP-PBXs-thumb.png")} className="w-100" alt="carrier-grade-ip-phone" />
                                            <NavLink className="imgbtn text-center" exact to="/UCB-Series-IP"> <button className="btn border-none">See Product</button></NavLink>
                                        </div>
                                    </div>
                                    <div className="col-lg-9">
                                        <p className="" style={{ fontSize: "20px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>UCB Series IP</p>
                                        <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                                            These award-winning IP PBXs offer an advanced set of features with no licensing fees, upgrade fees or costs per features. Enjoy smooth and easy network integration with the UCM series of IP PBXs. Support from 500 users, all the way up to 2000 users on our UCM6510 series, these IP PBXs provide the anchor to any communications network solution.                                        </p>
                                        <div className="border border-dark border-opacity-10 "></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default IPPBX;