import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const PowerVirtualServer = () => {

    return (
        <>
            <Header />

            <section>
                <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/header-bnr.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
                    <div className="container">
                        <div className="row text-center d-flex  justify-content-center align-items-center">

                            <p className="text-white text-center p-lg-5 fw-bold fs-3">Power Virtual Server</p>

                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mt-3">
                    <div className="row">
                        <div className="col-lg-6">
                            <img className="w-100 " src={require("../images/Power-Virtual-Server.png")} alt="" />
                        </div>
                        <div className="col-lg-6">
                            <p style={{ fontSize: "20px", fontFamily: 'serif', fontWeight: "600" }}>What is a Power Virtual  server?</p>
                            <p style={{ textAlign: "justify", fontSize: "14px", lineHeight: "1.7", fontFamily: "sans-serif", color: "#666666" }}>
                                IBM® Power Systems™ Virtual Server is a Power Systems offering. The Power Systems Virtual Servers are located in the IBM data centers, distinct from the IBM Cloud servers with separate networks and direct-attached storage. The environment is in its own pod and the internal networks are fenced but offer connectivity options to meet customer requirements. This infrastructure design enables Power Systems Virtual Server to maintain key enterprise software certification and support as the Power Systems Virtual Server architecture is identical to certified on-premises infrastructure. The virtual servers, also known as logical partitions (LPAR), run on IBM Power Systems hardware with the PowerVM hypervisor.                            </p>

                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-lg-6 ps-lg-5 ">
                            <p className="ps-3" style={{ fontSize: "30px", fontFamily: "serif", color: "#333333" }}>
                                Features
                            </p>
                            <ul style={{ fontSize: "16px", fontFamily: "sans-serif", lineHeight: "30px", textAlign: "justify" }}>
                                <li>
                                    Bring your own image
                                </li>
                                <li>
                                    Straightforward billing
                                </li>
                                <li>
                                    Infrastructure customization
                                </li>
                                <li>
                                    Support for deploying a Red Hat OpenShift Cluster
                                </li>

                                <li>
                                    Support for SAP NetWeaver or SAP HANA applications
                                </li>

                            </ul>
                        </div>
                        <div className="col-lg-6 text-center">
                            <div>
                                <img src={require("../images/power-virtual-server.jpg")} className=" w-75 " alt="Cloud Virtual Server-img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}
export default PowerVirtualServer;