import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { NavLink } from "react-router-dom";
const SecuritySurveillance = () => {

  return (
    <>
      <Header />

      <section>
        <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/surveillance-bnr.jpg")})`, backgroundAttachment: "local", height: "25vh", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center', borderBottom: "2px solid white" }}>
          <div className="container">
            <div className="row text-center d-flex  justify-content-center align-items-center">
              <div className="col-lg-6 ">
                <div className=" pt-lg-5 pb-lg-5">
                  <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>Enterprise & Manufacturing</p>
                </div>
              </div>
              <div className="col-lg-6   text-lg-end">
                <div className=" pt-lg-5 pb-lg-5">
                  <p className="text-white">
                    <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}>  <NavLink exact to="/Solution" className="text-white">/ Solutions </NavLink>/ Enterprise & Manufacturing </span></span>
                  </p>



                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid bg-light">
          <div className="row">
            <div className="col-lg-6 ps-lg-5">
              <div className="pt-lg-5">
                <p className="text-start ps-lg-5 " style={{ fontSize: "30px", fontFamily: "serif", color: "#333", fontWeight: "600" }}>
                  Security & Surveillance Solutions
                </p>
                <div className="ps-lg-5" style={{ textAlign: "justify", fontSize: "14px", fontFamily: "sans-serif", color: "#666", lineHeight: '1.7' }}>
                  <p>
                    Under the current market conditions, IP Surveillance has become a must for Enterprises and Business of any level to prevent losses, to cover risk management & other operations and improve overall security systems across all sectors like IT, Retail, Hospitality, Health Sector, Government Sector, Educational Institutions, Real Estate, Small Businesses and many more. Realizing the importance of Surveillance, the biggest impact of Security needs has been the switching over of large number of organizations into surveillance operations. As such, advanced level of IP Security and Surveillance devices are also now available in the market. As the need varies from different businesses, the surveillance products too range into different dimensions. It is all the more important and necessary to choose the right kind of security surveillance solution. These have to be installed aptly to make maximum use of the product at an economical cost.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pb-2 text-center">
              <img className="w-75  p-lg-5" src={require("../images/Security-Surveillance.jpg")} alt="Security-Surveillance" />
            </div>
          </div>
        </div>
      </section>

      <section style={{ backgroundColor: "#E8E6E6" }}>
        <div className="container ">
          <div className="row pb-lg-5">
            <div className="col-lg-12">
              <p className="pt-lg-5" style={{ fontSize: "30px", fontWeight: "600", fontFamily: "serif", color: "#186191" }}>Products:</p>

              <b style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#555555" }}>1. IP Cameras.
              </b><br />
              <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7" }}>
                IP cameras including PTZ dome and fixed IP cameras provide fully compressed digital video for unmatched image quality in a variety of lighting conditions. They enable businesses to archive higher quality video for longer periods of time while reducing bandwidth and storage costs significantly.
              </p>

              <b style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#555555" }}>2. Video Surveillance Solutions.
              </b><br />
              <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7" }}>
                It has come a long way -- from the days of Analog to Digital CCTV system to DVR and finally IP Video Surveillance. IP-Surveillance is an exciting alternative to the old DVR system as it provides a bridge to enter the Digital World with the promise of high performance, low-cost Digital Video Surveillance and monitoring. The greatest impact of video surveillance is the large number of organizations involved in surveillance operations.
              </p>

              <b style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#555555" }}>3. Network Video Recorders and Digital Video Recorders.
              </b><br />
              <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7" }}>
                NVRs and DVRs are scalable recording platforms that are able to support both IP and Analog cameras. They deliver outstanding video compression and storage and are formatted to address different site requirements.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light">
        <div className="container">
          <div className="row pt-lg-5 pb-lg-5">

            <div className="text-start">
              <p className="" style={{ fontSize: "15px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                <b>Skyward Telecom </b> offers a complete range of IP security and surveillance solutions to a variety of business sectors. Having an IP security system in place ensures that your business is protected – as best as possible. From Corporate to Housing Complex Security, from Hospitality to Hospitals, from Educational Sector to Industrial sector, Skyward Telecom  is happy to provide solutions in all fields that are customizable and fit into the requirements of clients.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/bg31.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
          <div className="container">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-lg-9">
                <div className="ps-2 p-lg-4" style={{lineHeight:"2"}}>
                  <p className="fs-2" style={{ color: "white", fontWeight: "700", fontFamily: "sans serif" }}>
                    Bright Solutions. Profitable Results.

                    <p style={{ fontSize: '18px', color: "white", fontWeight: "700", fontFamily: "sans serif" }}>
                      Experience you can trust, service you can count on.
                    </p></p>
                </div>
              </div>
              <div className="col-lg-3 mb-2">
                <div className="text-lg-center p-lg-5">
                  <a href="/Contact-us"><button style={{ fontSize: "14px", border: "none", outline: "none", padding: "10px 26px" }} className=" rounded-pill btn-lg text-black bg-white">Schedule a Demo</button></a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="pt-5 text-center">
            <img src={require("../images/office-sloutions1.png")} className="w-50" alt="office-sloutions1" />
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}
export default SecuritySurveillance;