import React from "react";
import "./Dell.css";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const Dell =()=>{

    return(
        <>
        <Header/>
        <div className="Dell">
        <div class="p-3">
                <img src="https://skywardtelecom.co.in//static/media/Server-1.png" className="img-fluid" alt="Server-1" />
                <p className="fs-1 fw-bold">Server</p>
               </div>
               <div className="bg-success text-dark bg-opacity-10">
                <p>
                A server is a computer program or device that provides a service to another computer program and its user, also known as the client. In a data center, the physical computer that a server program runs on is also frequently referred to as a server.
               <br/><b>What is Server?</b><br/>
               server, network computer, computer program, or device that processes requests from a client (see client-server architecture). On the World Wide Web, for example, a Web server is a computer that uses the HTTP protocol to send Web pages to a client's computer when the client requests them.
                <br/><b>How does a Server work?</b><br/>
                 A server doesn't have the screen or keyboard. And although your computer stores files and data you've put on it, a server stores all the data associated with the websites that are hosted by it and shares that info with all computers and mobile devices (like yours) that need to access them.
                <br/><b>What are the uses of a server?</b><br/>
                  Servers manage network resources. For example, a user may set up a server to control access to a network, send/receive e-mail, manage print jobs, or host a website. They are also proficient at performing intense calculations
                </p>
                </div>
                <div className="server-box">
                <NavLink exact to="/Servers" style={{textDecoration:"none"}}><h6>IBM</h6></NavLink>
                <NavLink exact to="/HP" style={{textDecoration:"none"}}><h6>HP</h6></NavLink>
                <NavLink exact to="/Dell" style={{textDecoration:"none"}}><h5>DELL</h5></NavLink>
                <NavLink exact to="/Cisco-servers" style={{textDecoration:"none"}}><h6>CISCO</h6></NavLink>
                </div>
                <div className="dell-box">
                 <div className="dell-block">
                    <img src="https://skywardtelecom.co.in//static/media/rack-server.png" alt="" />
                    <h5>Rack Servers</h5>
                     <h6>A versatile server with extra storage capacity and I/O performance. Designed for data-intensive applications. Maximize performance for cloud applications, XaaS, and virtualization workloads. Designed to balance accelerator cards and storage. Provide scalable, secure, high-density computing.</h6>
                   </div>
                   <div className="dell-block">
                    <img src="https://skywardtelecom.co.in//static/media/tower-server.png" alt="" />
                    <h5>Tower Servers</h5>
                    <h6>Designed for business-critical workloads, cloud infrastructure, database management, AI/ML, and inference. An entry-level tower server with adaptable performance and high capacity. Designed for small business data centers to handle office workloads.</h6>
                   </div>
                   <div className="dell-block">
                    <img src="https://skywardtelecom.co.in//static/media/Modular-server.png" alt="" />
                    <h5>Modular Servers</h5>
                    <h6>A flexible two-socket, single-width sled server ideal for dense compute. Designed for virtualization, software-defined storage, HCI, and big data. A flexible, storage-rich, four-socket compute sled. Designed for data-driven, mission-critical applications and performance workloads.</h6>
                   </div>
                   <div className="dell-block">
                    <img src="https://skywardtelecom.co.in//static/media/Rugged-server.png" alt="" />
                    <h5>Rugged Servers</h5>
                    <h6>Rugged PowerEdge XR servers are built to withstand the extreme heat, dust, shock and vibration of factory floors, construction sites, mobile command centers and other extreme environments. Offering you high-performance, reliable and expertly engineered technology backed by engineering expertise.</h6>
                   </div>
                </div>
        </div>
        <Footer/>
        </>
    )
}
export default Dell;