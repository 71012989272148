import React from 'react';
import "./Slider.css";

const Slider = () => {

  return (
    <>

      <section  className='mt-1'>
        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators">

            <input style={{ width: "20px", height: "15px" }} type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active " aria-current="true" aria-label="Slide 1"></input>

            <input style={{ width: "20px", height: "15px" }} className=' ' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></input>
            <input style={{ width: "20px", height: "15px" }} className=' ' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></input>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="4000">
              <img src={require("../images/img/banner-1.jpg")} class="d-block w-100" alt="banner-1" />
            </div>
            <div className="carousel-item" data-bs-interval="2000">
              <img src={require("../images/img/banner-2.jpg")} class="d-block w-100" alt="banner-2" />
            </div>
            <div className="carousel-item" data-bs-interval="2000">
              <img src={require("../images/img/banner-3.jpg")} class="d-block w-100" alt="banner-3" />
            </div>
          </div>
        </div>
      </section>


    </>
  )
}
export default Slider;


