import React from "react";
import Footer from "./Footer";
import Header from "./Header";


const TopOfficePhone = () => {

    return (
        <>
            <Header />

            <section>
                <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/header-bnr.png")})`, backgroundAttachment: "local", height: "25vh", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
                    <div className="container">
                        <div className="row d-flex  justify-content-center align-items-center">

                            <div className="text-center">
                                <p className="text-white" style={{ paddingTop: "7%", fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>Top Office Phone System For Small Business</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mt-5">
                                <img src={require("../images/phone-system-bnr.jpg")} className="w-100" alt="" />
                            </div>
                            <div className="col-lg-6 mt-5">
                                <p style={{ fontSize: "24px", fontFamily: 'serif', fontWeight: "600" }}>Top Office Phone System For Small Business</p>

                                <p style={{ textAlign: "justify", fontSize: "14px", lineHeight: "1.7", fontFamily: "sans-serif", color: "#666666" }}>
                                    Are you opening up your own new business? Let’s update your existing phone system and give it a new range of technology to establish a better communication system. We have gone through many research and testing to check the effectiveness of the system. With these, we have prepared a list of office phone system that will help small businesses in their growth and development.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mt-4">
                    <div className="row">
                        <p style={{ fontSize: "24px", fontFamily: "serif", color: "#555555", fontWeight: "600" }}>Some of the effective features associated with the small business phone system</p>
                        <p style={{ fontSize: "16px", fontFamily: "sans-serif",color:"#555555", fontWeight:"600",lineHeight: "1.7", textAlign: "justify" }}>Before illustrating the facts proven through the researches and studies, we want to discuss some properties. On the basis of these properties only, we have declared the list. Hence, these features are:-</p>
                        <div style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7", textAlign: "justify" }}>
                            <ul>
                                <li>
                                  <b>  Reliability check:</b> We have checked the reliability of these phone systems. There we found the necessity of a manufacturer’s knowledge. It is on the basis of knowledge that can prove one’s history and how he/she will pursue the reliability measures for the benefit of businesses.
                                </li>
                                <li>
                                  <b>  Affordability:</b> Money is the most important matter that everyone considers before adopting anything. Hence this list of phone systems is made on its affordable nature so that it can be easy to purchase and maintain by the small businesses.
                                </li>
                                <li>
                                   <b> Ease of maintenance:</b> Ease of maintenance is another important property associated with these phone systems. It doesn’t require any cloud subscription on a monthly per-phone basis. Instead, it allows the user to choose the VoIP option of their want. Moreover, it also supports up to 4 traditional telephone lines in a single connection
                                </li>
                                <li>
                                    Here, by small businesses we mean the business having at least 10 employees. These phone systems are even more beneficial for offering applications for smartphones. It enables the transfer of calls to another cell phone easily and makes employees’ work-from-home convenient.
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </section>

          
            <Footer />
        </>
    )
}
export default TopOfficePhone;