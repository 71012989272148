import React from "react";
import Footer from "./Footer";
import Header from "./Header";
// import { NavLink } from "react-router-dom";
const CloudVirtualServer = () => {

    return (
        <>
            <Header />
            <section>
                <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/header-bnr.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
                    <div className="container">
                        <div className="row text-center d-flex  justify-content-center align-items-center">

                            <p className="text-white text-center p-lg-5 fw-bold fs-3">Cloud Virtual Server</p>

                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mt-3">
                    <div className="row">
                        <div className="col-lg-6">
                            <img className="w-100 " src={require("../images/Cloud-Virtual-Server.png")} alt="" />
                        </div>
                        <div className="col-lg-6">
                            <p style={{ fontSize: "20px", fontFamily: 'serif', fontWeight: "600" }}>What is a cloud server?</p>
                            <p style={{ textAlign: "justify", fontSize: "14px", lineHeight: "1.7", fontFamily: "sans-serif", color: "#666666" }}>
                                A cloud server is powerful physical or virtual infrastructure that delivers applications, processes information or provides data storage. Some cloud servers are created using virtualization software that divides a single physical (bare metal) server into multiple virtual servers. Cloud service providers use an infrastructure-as-a-service (IaaS) model to make virtual or bare metal servers avalable to customers.
                            </p>

                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-lg-6 ps-lg-5 ">
                            <p className="ps-3" style={{ fontSize: "30px", fontFamily: "serif", color: "#333333" }}>
                                Features
                            </p>
                            <ul style={{ fontSize: "16px", fontFamily: "sans-serif", lineHeight: "30px", textAlign: "justify" }}>
                                <li>
                                    Has all the capabilities of an on-premises server
                                </li>
                                <li>
                                    Gives users the choice of monthly or as-you-go payment
                                </li>
                                <li>
                                    Automated services are accessed on demand through an API
                                </li>
                                <li>
                                    Users can opt for a shared hosting plan that scales depending on needs
                                </li>

                                <li>
                                    Enables users to process intensive workloads and store large volumes of information
                                </li>
                                <li>
                                    Computing infrastructure that can be physical (bare metal), virtual, or a mix of the two depending on use case
                                </li>

                            </ul>
                        </div>
                        <div className="col-lg-6 text-center">
                            <div>
                                <img src={require("../images/Cloud Virtual Server-img.jpeg")} className=" w-75 " alt="Cloud Virtual Server-img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}
export default CloudVirtualServer;