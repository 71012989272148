import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const UMC6300Series = () => {

     return (
          <>
               <Header />

               <section>
                    <div className="container-fluid header-bnr">
                         <div className="row text-center d-flex  justify-content-center align-items-center">
                              <div className="col-lg-6 ">
                                   <div className="ps-lg-5 pt-lg-5">
                                        <p className="text-white pt-5" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>Network POE Switches</p>

                                   </div>
                              </div>
                              <div className="col-lg-6   text-lg-center">
                                   <div className=" pt-lg-5">
                                        <p className="text-white pt-lg-5">
                                             <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}> / Grandstream / UCM 6300 Series
                                             </span></span>
                                        </p>


                                   </div>
                              </div>
                         </div>
                    </div>
               </section>

               <section>
                    <div className="container-fluid pt-5">
                         <div className="container">
                              <div className="row">
                                   <div className="col-md-3">
                                        <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>BRANDS</span>
                                        <div className="mb-1"></div>
                                        <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                                        <div className="border border-white border-opacity-10 mb-2 "></div>
                                        <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                                             <NavLink exact to="/grandstream" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; Grandstream</p></NavLink>
                                             <NavLink exact to="/Dinstar" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Dinstar</p></NavLink>
                                             <NavLink exact to="/NEC" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; NEC</p></NavLink>
                                             <NavLink exact to="/Alcatel" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Alcatel</p></NavLink>
                                             <NavLink exact to="/YeaLink" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;YeaLink</p></NavLink>
                                             <NavLink exact to="/Poly" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Poly</p></NavLink>
                                             <NavLink exact to="/Cisco" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Cisco</p></NavLink>

                                        </p>

                                        <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>SOLUTIONS</span>
                                        <div className="mb-1"></div>
                                        <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                                        <div className="border border-white border-opacity-10 mb-2 "></div>
                                        <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                                             <NavLink exact to="/Enterprise-Manufacturing" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Enterprise-Manufacturing</p></NavLink>
                                             <NavLink exact to="/Security&Surveillance" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Security & Surveillance</p></NavLink>
                                             <NavLink exact to="/Education & Hospitality" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}> Education & Hospitality</p></NavLink>
                                             <NavLink exact to="/BusinessConference" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Business Conference</p></NavLink>

                                        </p>

                                        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">

                                             <div className="carousel-inner">
                                                  <div className="carousel-item active" data-bs-interval="2500">
                                                       <div>
                                                            <NavLink exact to="/GWN7605">
                                                                 <img src={require("../images/GWN7605.png")} style={{ width: "263px" }} alt="GWN7605" />
                                                                 <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>WiFi Access Point (GWN7605)</p>
                                                                 <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream's powerful WiFi Access Points offer high performance networking, tremendous WiFi coverage range read more.. </p>
                                                            </NavLink>
                                                       </div>
                                                  </div>
                                                  <div className="carousel-item" data-bs-interval="2500">
                                                       <div>
                                                            <NavLink exact to="/BusinessConference">
                                                                 <img src={require("../images/Business-Conferencing.png")} style={{ width: "263px" }} alt="Business-Conferencing" />
                                                                 <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Business Conferencing</p>
                                                                 <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream’s video conferencing solutions offer small and medium sized businesses flexibility read more..</p>
                                                            </NavLink>
                                                       </div>            </div>
                                                  <div className="carousel-item" data-bs-interval="2500">
                                                       <div>
                                                            <NavLink exact to="/wp810">
                                                                 <img src={require("../images/wifi-cordless.png")} style={{ width: "263px" }} alt="wifi-cordless" />
                                                                 <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Wi-Fi IP Phone (WP 810)</p>
                                                                 <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>WP810 is a portable Wi-Fi IP phone designed to suit a variety of enterprises  Read More..</p>
                                                            </NavLink>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="col-md-8">
                                        <div>
                                             <img src={require("../images/img/grandstream-ucm6300series-bnr.jpg")} className="w-100" alt="ip-voice-telephony-bnr" />
                                        </div>
                                        <div className="mt-3">
                                             <p className="" style={{ color: "#333333", fontSize: '27px', fontFamily: "sans-serif", fontWeight: "600" }}>
                                                  Grandstream UCM6300 Series IP PBX
                                             </p>
                                        </div>
                                        <div className="" style={{ fontSize: "15px", color: "#666666", fontFamily: "sans-serif" }}>
                                             <p>
                                                  The UCM6300 series allows businesses to build powerful and scalable unified communication and collaboration solutions. This series of IP PBXs provide a platform that unifies all business communication on one centralized network, including voice, video calling, video conferencing, video surveillance, web meetings, data, analytics, mobility, facility access, intercoms and more. The UCM6300 series supports up to 3000 users and includes a built-in web meetings and video conferencing solution that allows employees to connect from the desktop, mobile, GVC series devices and IP phones. It can be paired with the UCM6300 ecosystem to offer a hybrid platform that combines the control of an on-premise IP PBX with the remote access of a cloud solution. The UCM6300 ecosystem consists of the Wave app for web and mobile, which provides a hub for collaborting remotely, and UCM RemoteConnect, a cloud NAT traversal service for ensuring secure remote connections. The UCM6300 series also offers cloud setup and management through GDMS and an API for integration with third-party platforms. By offering a high-end unified communications and collaboration solution packed with a suite of mobility, security, meeting and collaboration tools, the UCM6300 series provides a powerful platform for any organization.                                             </p>
                                             {/* <p style={{ color: "#333333", fontSize: '14px', fontFamily: "sans-serif", fontWeight: "600" }}>
                                                  Network POE switches provide a scalable and sustainable business solution to build your communication and collaboration solutions. Offering smart solutions for unified communications for any organization.
                                             </p> */}
                                        </div>
                                        <div className="row mt-5">
                                             <p style={{ fontSize: "24px", fontFamily: "serif", fontWeight: "600", color: "#333333" }}>Features</p>
                                             <ul className="ms-5" style={{ fontSize: "16px", fontWeight: "600", fontFamily: "sans-serif", color: "#666666" }}>
                                                  <li>Supports up to 3000 users and up to 450 concurrent calls</li>
                                                  <li>Zero configuration provisioning of Grandstream SIP endpoints</li>
                                                  <li>Compatible with GDMS for cloud setup, management and monitoring</li>
                                                  <li>Based on Asterisk* version 16 open source telephony operating system</li>
                                                  <li>Automated NAT firewall traversal service facilitates secure remote connections</li>
                                                  <li>API available for third-party integrations, including CRM and PMS platforms</li>
                                                  <li>Automated NAT firewall traversal service facilitates secure remote connections</li>
                                                  <li>Three Gigabit auto-sensing RJ45 network ports with integrated PoE+ and support NAT router</li>
                                             </ul>
                                        </div>

                                        <div className="row mt-2 mb-5">
                                             <div className="col-md-3 pt-3">

                                                  <div className="d-flex btnHover justify-content-center align-items-center imgHover border p-1 border-dark ">
                                                       <img className="w-100 " src={require("../images/UCM6301.png")} alt="UCM6301" />
                                                       <NavLink className="imgbtn text-center" exact to=""> <button className="btn border-none">See Product</button></NavLink>
                                                  </div>
                                             </div>

                                             <div className="col-lg-9">
                                                  <p className="" style={{ fontSize: "20px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>Grandstream UCM6301 IP PBX</p>
                                                  <p className="" style={{ fontSize: "15px", fontFamily: "sans-serif" }}>
                                                       The Grandstream UCM6301 IP PBX is a 1FXO, 1FXS port IP PBX that brings robust functionality and enhanced functionalities to any small business. The UCM6301 offers business the ability to have a centralized device for voice, video calling, video conferencing, surveillance, data tools, analytics, mobility options, facility access, intercom and more.
                                                  </p>
                                                  <div className="border border-dark border-opacity-10 "></div>
                                             </div>
                                        </div>

                                        <div className="row mt-2 mb-5">
                                             <div className="col-md-3 pt-3">

                                                  <div className="d-flex btnHover justify-content-center align-items-center imgHover border p-1 border-dark ">
                                                       <img className="w-100 " src={require("../images/UCM6302.png")} alt="UCM6302" />
                                                       <NavLink className="imgbtn text-center" exact to=""> <button className="btn border-none">See Product</button></NavLink>
                                                  </div>
                                             </div>

                                             <div className="col-lg-9">
                                                  <p className="" style={{ fontSize: "20px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>Grandstream UCM6302 IP PBX</p>
                                                  <p className="" style={{ fontSize: "15px", fontFamily: "sans-serif" }}>
                                                       The Grandstream UCM6302 is an easy to manage device that does not require licensing fees and can support up to 1000 users and up to 200 concurrent calls. It features advanced technology including three Gigabit auto-sensing RJ45 network ports with integrated PoE+ and support for a NAT router. This enterprise-grade IP PBX can be integrated with third-party CRMs and PMS allowing for custom integrations.
                                                  </p>
                                                  <div className="border border-dark border-opacity-10 "></div>
                                             </div>
                                        </div>

                                        <div className="row mt-2 mb-5">
                                             <div className="col-md-3 pt-3">

                                                  <div className="d-flex btnHover justify-content-center align-items-center imgHover border p-1 border-dark ">
                                                       <img className="w-100 " src={require("../images/UCM6304.png")} alt="UCM6304" />
                                                       <NavLink className="imgbtn text-center" exact to=""> <button className="btn border-none">See Product</button></NavLink>
                                                  </div>
                                             </div>

                                             <div className="col-lg-9">
                                                  <p className="" style={{ fontSize: "20px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>Grandstream UCM6304 IP PBX</p>
                                                  <p className="" style={{ fontSize: "15px", fontFamily: "sans-serif" }}>
                                                       The Grandstream UCM6304 is an easy to manage device that does not require licensing fees and can support up to 2000 users and up to 300 concurrent calls. It features advanced technology including three Gigabit auto-sensing RJ45 network ports with integrated PoE+ and support for a NAT router. This enterprise-grade IP PBX can be integrated with third-party CRMs and PMS allowing for custom integrations.                                                  </p>
                                                  <div className="border border-dark border-opacity-10 "></div>
                                             </div>
                                        </div>

                                        <div className="row mt-2 mb-5">
                                             <div className="col-md-3 pt-3">

                                                  <div className="d-flex btnHover justify-content-center align-items-center imgHover border p-1 border-dark ">
                                                       <img className="w-100 " src={require("../images/UCM6308.png")} alt="UCM6308" />
                                                       <NavLink className="imgbtn text-center" exact to=""> <button className="btn border-none">See Product</button></NavLink>
                                                  </div>
                                             </div>

                                             <div className="col-lg-9">
                                                  <p className="" style={{ fontSize: "20px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>Grandstream UCM6308 IP PBX</p>
                                                  <p className="" style={{ fontSize: "15px", fontFamily: "sans-serif" }}>
                                                       The UCM6308 is an easy to manage device that does not require licensing fees and can support up to 6000 users and up to 400 concurrent calls. It features advanced technology including three Gigabit auto-sensing RJ45 network ports with integrated PoE+ and support for a NAT router. This enterprise-grade IP PBX can be integrated with third-party CRMs and PMS allowing for custom integrations.
                                                       </p>
                                                       <div className="border border-dark border-opacity-10 "></div>
                                                 
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section >


               <Footer />
          </>
     )
}
export default UMC6300Series;