import React, { useState } from 'react';
import CountUp from 'react-countup';
import { NavLink } from "react-router-dom";
import "./Home.css";
import Slider from "./Slider";
import Footer from "./Footer"
import { Modal } from "react-bootstrap";
import Header from "./Header";
import ScrollTrigger from 'react-scroll-trigger';

const Home = () => {
  const [counterOn, setCounterOn] = useState(false);
  const [item, setItem] = useState("");
  console.log(item);
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const data = [
    { img: require("../images/img/img15.png") },
    { img: require("../images/img/img23.png") },
    { img: require("../images/img/img5.png") },
    { img: require("../images/img/img25.png") },
    { img: require("../images/img/img6.png") },
    { img: require("../images/img/img7.png") },
    { img: require("../images/img/img8.png") },
    { img: require("../images/img/img10.png") },
    { img: require("../images/img/img2.png") },
    { img: require("../images/img/img17.png") },
    { img: require("../images/img/img1.png") },
    { img: require("../images/img/img4.png") },
    { img: require("../images/img/img3.png") },
    { img: require("../images/img/img14.png") },
    { img: require("../images/img/img2.png") },
    { img: require("../images/img/img16.png") },
    { img: require("../images/img/img17.png") },
    { img: require("../images/img/img24.png") },
    { img: require("../images/img/img18.png") },
    { img: require("../images/img/img20.png") },
  ];


  const testimonialsData = [
    // Testimonial data (replace with your own)
    { id: 1, name: '- Ganesh Jagrut ', content: 'Overall, I am very satisfied with the services offered by skyward, I would highly recommend them to anyone looking for a reliable and quality telecom solution.', img: require("../images/img/image-1.jpg"), company: "Rayat shikshan sanstha" },
    { id: 2, name: '- Akshay Salunkhe ', content: 'I am really impressed with skyward telecom solutions. Their services are top-notch, and I have never had any problems with their products or services. They always deliver on time, and their products are of the highest quality.', img: require("../images/img/image-1.jpg"), company: "JSPM" },
    { id: 3, name: '- Anil kadam ', content: 'Skyward Telecom Solutions have truly made a name for themselves in the telecom industry and I would highly recommend them to anyone looking for a reliable and efficient cloud solution.', img: require("../images/img/image-1.jpg"), company: "CEO of iCloudSoft Technology" },
    { id: 4, name: '- Sunny Varpe ', content: 'Skyward Telecom Solutions have also provided me with excellent customer service and technical support. Their customer service team is always available to answer any questions I have and help me with any issues I may have.', img: require("../images/img/image-1.jpg"), company: "OYO" },
    { id: 5, name: '- Dadasaheb Bhusnar ', content: 'Skyward Telecom have consistently provided me with reliable and efficient cloud solutions that have allowed me to stay connected with my customers and business partners.', img: require("../images/img/image-1.jpg"), company: "Yashada" },
    { id: 6, name: '- Nikhil Thorat ', content: 'Skyward  have truly made a name for themselves in the Systems integrators industry and I would highly recommend them to anyone looking for a reliable and efficient IT  solution.', img: require("../images/img/image-1.jpg"), company: "Hyundai" }
    // { id: 7, name: '- Sudip Samaddar ', content: 'Thanks a lot support team for resolving our problems so quickly. Really appreciate your prompt support. And as usual you did wonders. A lot of appreciation to your team management skills.', img: require("../images/img/image-1.jpg"), company: "Imagine Sales Technologies" },
  ];

  const [startIndex, setStartIndex] = useState(0);

  const handleSlideUp = () => {
    setStartIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleSlideDown = () => {
    setStartIndex((prevIndex) => Math.min(prevIndex + 1, testimonialsData.length - 3));
  };
  return (
    <>
    
      <Header />
     
      <Slider />

      <section>
        <div className="container-fluid mt-1">
          <div className="container">
            <div className="row mt-3 " >
              <div className=" col-lg-3  block-1 text-center "

                style={{ backgroundColor: '#186191' }}>
                <img src={require("../images/idea.png")} className="img-fluid text-center mt-5" alt="" />
                <p className="fs-5 text-white text-center mt-2 mb-5"><b>ONE STOP SOLUTIONS</b></p>
                <br />
              </div>


              <div className="col-lg-3 block-1  text-center "
                style={{ backgroundColor: '#30AFD6' }}>
                <img src={require("../images/technical-support.png")} className="img-fluid mt-5" alt="" />
                <p className="fs-5 text-white text-center mt-2 mb-5"><b>SERVICE & SUPPORT</b></p>
                <br />
              </div>

              <div className="col-lg-3  block-1 text-center "
                style={{ backgroundColor: '#29ABD3' }}>
                <img src={require("../images/time-left.png")} className="img-fluid mt-5" alt="" />
                <p className="fs-5 text-white text-center mt-2 mb-5"><b>24/7</b></p>
                <br />
              </div>


              <div className="col-lg-3  block-1 text-center "
                style={{ backgroundColor: ' #28A4CB' }}>
                <img src={require("../images/community.png")} className="img-fluid mt-5 " alt="" />
                <p className="fs-5 text-white text-center mt-2 mb-5"><b>GLOBAL PRESENCE</b></p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </section>


      <section>
        <div className="container-fluid mb-5 mt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 ">
                <p className="fs-4 " style={{ fontFamily: "tririong ,serif" }}>About<span className="text-primary fs-4 ms-2">Skyward</span></p>
                <div className="border border-primary" style={{ width: "55px" }}></div>
                <div className="border border-dark border-opacity-10 mb-2 "></div>
                <div className="mt-4">
                  <img className="w-100" src={require("../images/Aboutcompany.jpg")} alt="Aboutcompany" />
                </div>
                <div className="mt-3" style={{ fontFamily: "'Open Sans', sans-serif", fontSize: "14px", color: "#666", textAlign: "justify" }}>
                  <p className="">Skyward, a well-known name in the field of telecom solutions, has definitely made a niche for itself in the field. A well-placed proof of this fact would be impressive growth of the company’s sales chart! In the ever changing and ever growing telecommunications industry, Skyward is one solid name. The firm offers multi-vendor solutions for Real Estate, Security and Surveillance, Video conferencing solutions. In fact, you name it and We have it!
                  </p>
                  <p className="" style={{ textAlign: "justify" }}>Skyward works with a mission to simplify technology for the end users. This is the reason why our list of customers is increasing day by day. Our products help your business grow in the IP & VoIP sector. After all, they are a superb merger of latest technology</p>
                  <NavLink exact to="/About-us"><button type="button" class="btn btn-outline-primary rounded-pill me-md-3 ">Read More..</button></NavLink>
                </div>
              </div>
              <div className="col-lg-4">
                <p className="fs-4" style={{ fontFamily: "tririong ,serif" }}>Hot<span className="text-primary fs-4 ms-2">Deal</span></p>
                <div className="border border-primary" style={{ width: "55px" }}></div>
                <div className="border border-dark border-opacity-10 mb-2 "></div>
                <div className="mt-4">
                  <NavLink exact to="/Contact-us">
                    <img src={require("../images/img/Skyward Telecom Pvt. Ltd..png")} className="w-75 d-block" alt="poster" />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section>
        <div className="container-fluid  d-none d-sm-block" style={{ marginTop: "70px" }}>
          <div className="container ">
            <div className="row mb-3">
              <div className="col-lg-6">
                <p className="" >
                  <i class="fa fa-hospital-o" style={{ fontSize: "70px", opacity: "0.1", position: "absolute", top: "" }} ></i>
                  <span style={{ fontFamily: "tririong ,serif", fontSize: "24px" }}> Enterprises</span> <br />
                  <span style={{ fontFamily: "tririong ,serif", fontSize: "32px" }}> BUSINESS SOLUTIONS.</span>
                </p>
              </div>
              <div className="col-lg-6">
                <i><p style={{ fontSize: "18px", fontFamily: "open Sans" - 'sans serif' }}>Versatile solutions that meet the unique needs of customers in diverse market <br /> segments. <br />
                  We provide all type of solutions …
                </p></i>
              </div>

            </div>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid d-none d-sm-block">
          <div className="container">
            <div className="row mb-3">
              <div className="col-lg-3 ">
                <div className="block">
                  <div className=" text-center" >
                    <img src={require("../images/Business-Conferencing.jpg")} style={{ height: "204px" }} className="img-fluid" alt="" />
                  </div>
                </div>
                <div className=" p-2">
                  <p className=" " style={{ fontSize: "20px", fontFamily: "trirong,serif", color: "black" }}>
                    Business Conferencing
                  </p>
                  <p className="" style={{ fontSize: "16px", fontFamily: "Open Sans', sans-serif", color: "black" }}>Our range of business conferencing options are designed to take your business to the next level and beyond.</p>
                  <NavLink exact to="/BusinessConference"><p className=" me-md-3 " style={{ fontSize: "13px", color: "#186191" }}>Read More →</p></NavLink>
                </div>
              </div>

              <div className="col-lg-3">
                <div className=" text-center  block">
                  <img src={require("../images/Security.jpg")} style={{ height: "204px" }} className="img-fluid" alt="" />
                </div>
                <div className=" p-2">
                  <p className="" style={{ fontSize: "20px", fontFamily: "trirong,serif", color: "black" }}>
                    Security & Surveillance
                  </p>
                  <p className="" style={{ fontSize: "16px", fontFamily: "Open Sans', sans-serif", color: "black" }}>Our cutting-edge security and surveillance measures provide a unique.</p>

                  <NavLink exact to="/Security&Surveillance"><p className=" me-md-3 " style={{ fontSize: "13px", color: "#186191" }}>Read More →</p></NavLink>
                </div>

              </div>
              <div className="col-lg-3">
                <div className="text-center   block">
                  <img src={require("../images/Enterprise -Manufacturing.jpg")} style={{ height: "204px" }} className="img-fluid" alt="" />
                </div>
                <div className="p-2">
                  <p className="" style={{ fontSize: "20px", fontFamily: "trirong,serif", color: "black" }}>
                    Enterprise & Manufacturing
                  </p>
                  <p className="" style={{ fontSize: "16px", fontFamily: "Open Sans', sans-serif", color: "black" }}>We take pride in offering award-winning solutions that cater to the unique needs of each.</p>
                  <NavLink exact to="/Enterprise-Manufacturing"><p className=" me-md-3 " style={{ fontSize: "13px", color: "#186191" }}>Read More →</p></NavLink>
                </div>
              </div>


              <div className="col-lg-3 ">
                <div className="text-center  block ">
                  <img src={require("../images/Education-Hospitality.jpg")} style={{ height: "204px" }} className="img-fluid" alt="" />
                </div>
                <div className="p-2">
                  <p className="" style={{ fontSize: "20px", fontFamily: "trirong,serif", color: "black" }}>
                    Education & Hospitality
                  </p>
                  <p style={{ fontSize: "16px", fontFamily: "Open Sans', sans-serif", color: "black" }}>Our security and surveillance solutions make a significant difference in business performance.</p>
                  <NavLink exact to="/EducationHospitality"><p className=" me-md-3 " style={{ fontSize: "13px", color: "#186191" }}>Read More →</p></NavLink>
                </div>
              </div>

              
            </div>

          </div>
        </div>
      </section>


      <section>
        <div className="container-fluid mb-5" style={{ backgroundColor: "#E6E6E6" }}>
          <div className="container">
            <div className="row text-center text-dark " >
              <p className=" mt-5" style={{
                fontSize: "32px", fontWeight: "bold", fontFamily: 'Open-Sans' - "sans-serif"
              }}><span>Quality is at the heart of what we do and technology is our secret weapon</span></p>
              <p className="" style={{ fontSize: "18px", fontFamily: 'Open-Sans' - "sans-serif", color: "#666666" }}>May our partnership soar above the  touch the skies of success.</p>
              <p className=" mb-5" style={{ fontSize: '32px', fontWeight: '600', fontFamily: "Trirong, serif" }}>Just Ring at  :  <span className="" style={{ color: "#186191", fontSize: '2rem', fontWeight: '600', fontFamily: "Trirong, serif" }}>+91 9579-713-010</span></p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <section>
          <div className="container-fluid  ">
            <div className="container">
              <div className="row g-4">
                <div className="col-lg-7 pe-3">
                  <div>
                    <p className=" mb-3" style={{ fontSize: "24px", fontFamily: "trirong,serif" }}>OUR MISSION
                      <div className="border border-primary" style={{ width: "55px" }}></div>
                      <div className="border border-dark border-opacity-10 mb-2 "></div>

                      <div className="bor1"><span></span></div>
                    </p>
                    <p style={{ textAlign: "justify", fontSize: "14px", fontFamily: "Open Sans', sans-serif" }} className="">Skyward works with a mission to strive endlessly to be the high-performing telephony
                      solution provider. To achieve this goal, we believe in these 4 missions:</p>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="about-p">
                        <p ><img src={require("../images/quality.png")} alt='quality' style={{ backgroundColor: "#186191", fontSize: "18px", fontFamily: "trirong,serif" }} className="img-fluid" /><span style={{ fontWeight: "600", fontSize: "18px", fontFamily: "Trirong,serif" }}>&nbsp; Quality Is Imperative</span></p >
                        <p style={{ textAlign: "justify", fontSize: "14px", fontFamily: "Open Sans', sans-serif" }} className="">Quality can never be compromised upon at Skyward. We leave no stone upturned to give 100 percent quality to our customers.</p>
                      </div>

                    </div>
                    <div className="col-lg-6">
                      <div className="about-p">
                        <p ><img src={require("../images/High-Performance.png")} alt='Hight-Performance' style={{ backgroundColor: "#186191" }} className="img-fluid" /> <span className="fs-5" style={{ fontWeight: "600", fontSize: "18px", fontFamily: "Trirong,serif" }}>&nbsp; High-Performance Mandatory</span></p >
                        <p style={{ textAlign: "justify", fontSize: "14px", fontFamily: "Open Sans', sans-serif" }} className="">We work with a mission to provide only the best telecom and VoIP solutions to our customers.</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="about-p">
                        <p ><img src={require("../images/Telecom-Solutions-icon.png")} alt='Telecom-Solutions-icon' style={{ backgroundColor: "#186191", height: '65px' }} className="img-fluid" /> <span className="fs-5" style={{ fontWeight: "600", fontSize: "18px", fontFamily: "Trirong,serif" }}>&nbsp; World-Class Telecom Solutions</span></p >
                        <p style={{ textAlign: "justify", fontSize: "14px", fontFamily: "Open Sans', sans-serif" }} className="">Skyward holds the reputation of providing world-class IP & VoIP systems to our wide range of clients.</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="about-p">
                        <p ><img src={require("../images/Customer.png")} alt='Customer' style={{ backgroundColor: "#186191" }} className="img-fluid" /> <span className="fs-5" style={{ fontWeight: "600", fontSize: "18px", fontFamily: "Trirong,serif" }}>&nbsp; Customer Is The King</span></p >
                        <p style={{ textAlign: "justify", fontSize: "14px", fontFamily: "Open Sans', sans-serif" }} className="">Last but not the least! Customer holds much importance for our growth and success.</p>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="col-lg-5 ps-3 ">
                  <p className=" mb-3" style={{ fontSize: "24px", fontFamily: "trirong,serif" }}>OUR VISION
                    <div className="border border-primary" style={{ width: "55px" }}></div>
                    <div className="border border-dark border-opacity-10 mb-2 "></div>

                    <div className="bor1"><span></span></div>
                  </p>
                  <div className="about-Box">
                    <p className='ps-lg-2'><p style={{ textAlign: "justify", fontSize: "18px", fontFamily: "Open Sans" }}>The company is aimed to work on the VOIP technology in a highly affordable and innovative manner. Our main vision is to allow the success & satisfaction of our employees, partners and customers who are connected with us by using our Products & Solutions.
                      We pull our deep expertise and profound appreciative of client’s business province areas and needs to create a personalized solution for their individual business requirements.
                      We Work & Play with transparency, trust & team work, We feel proud on everything we do and make sure that everyone who is connected with us feel the same.
                      Recognized for the disciplined approach towards offering the best value of pleasure to every customer, we shake hand with.</p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section>
        <div className="container-fluid bg-light pt-5 pb-5" style={{ backgroundColor: "#ffffff" }}>
          <div className="container">
            <div className="row text-center ">
              <p className="" style={{ fontSize: "32px", fontFamily: "trirong,serif" }}> <b>OUR SERVICES</b></p>
              <p className='pb-3'><i style={{ fontSize: "16px", fontFamily: "open sans" - 'sans-serif' }}>Skyward delivers exceptional technological services that cater to all your basic needs and beyond.</i></p>
            </div>


            <div className="row g-3 ">

              <div className="col-lg-3" >
                <div className="text-center h-100 p-3" style={{ backgroundColor: "#ffffff" }}>
                  <NavLink exact to="/Consultation" style={{ textDecoration: "none", color: "black" }}>
                    <img src={require("../images/img/Consultation.png")} style={{ backgroundColor: '#186191', height: '100px', width: '100px' }} className="img-fluid backl" alt="" />
                    <p className="fs-5 mt-3" style={{ fontSize: "18px", fontFamily: "trirong,serif" }}><b>Consultation</b></p>
                    <p style={{ fontSize: "16px" }}>Skyward offers comprehensive telecom and security solutions. Let us take care of your issues, and walk away with peace of mind.</p>
                  </NavLink>
                </div>
              </div>


              <div className=" col-lg-3 " >
                <div className="text-center h-100  p-3" style={{ backgroundColor: "#ffffff" }}>
                  <NavLink exact to="/Configuration" style={{ textDecoration: "none", color: "black" }}>
                    <img src={require("../images/img/Configuration.png")} style={{ backgroundColor: '#186191', height: '100px', width: '100px' }} className="img-fluid backl" alt="" />
                    <p className="fs-5 mt-3 fw-bold" style={{ fontSize: "18px", fontFamily: "trirong,serif" }}>Configuration</p>
                    <p style={{ fontSize: "16px" }}>Skyward has the knowledge and experience to configure your VoIP and IP solutions wherever you need them.</p>
                    <br />
                  </NavLink>
                </div>
              </div>

              <div className=" col-lg-3 " >
                <div className="text-center h-100 p-3" style={{ backgroundColor: "#ffffff" }}>
                  <NavLink exact to="/Installations" style={{ textDecoration: "none", color: "black" }}>
                    <img src={require("../images/img/Installations.png")} style={{ backgroundColor: '#186191', height: '100px', width: '100px' }} className="img-fluid backl" alt="" />
                    <p className="fs-5 mt-3 fw-bold" style={{ fontSize: "18px", fontFamily: "trirong,serif" }}> Installations</p>
                    <p style={{ fontSize: "16px" }}>Skyward takes care of all your telecom solution installations without any complications.</p>
                    <br />

                  </NavLink>
                </div>
              </div>

              <div className="col-lg-3 " >
                <div className="text-center h-100 p-3" style={{ backgroundColor: "#ffffff" }}>
                  <NavLink exact to="/Support" style={{ textDecoration: "none", color: "black" }}>
                    <img src={require("../images/img/support.png")} style={{ backgroundColor: '#186191', height: '100px', width: '100px' }} className="img-fluid backl" alt="" />
                    <p className="fs-5 mt-3 fw-bold" style={{ fontSize: "18px", fontFamily: "trirong,serif" }}>Support</p>
                    <p style={{ fontSize: "16px" }}>If you experience any issues with our telecom products, our technical support team is available around the clock to assist you.</p>
                  </NavLink>
                </div>

              </div>


            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='container-fluid p-5 ' style={{ backgroundImage: `url(${require("../images/img/backimg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center', height: "" }}>
          <div className="container p-5" style={{ fontFamily: "serif" }} >
            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
              <div className="row d-flex justify-content-center align-items-center text-white text-center">

                <div className="col-lg-3  d-flex justify-content-center align-items-center">
                  <div>
                    <div style={{ fontSize: "48px", fontFamily: "serif" }}> {counterOn && <CountUp start={0} end={350} duration={2} delay={0}> </CountUp>}</div>
                    <p className='' style={{ fontSize: "14px" }}>HAPPY CUSTOMER</p>
                  </div>
                </div>
                <div className="col-lg-3  d-flex justify-content-center align-items-center">
                  <div>
                    <div style={{ fontSize: "48px", fontFamily: "serif" }}> {counterOn && <CountUp start={0} end={200} duration={2} delay={0}> </CountUp>}</div>
                    <p className='' style={{ fontSize: "14px" }}> CORPORATE CLIENT</p>
                  </div>
                </div>
                <div className="col-lg-3  d-flex justify-content-center align-items-center">
                  <div >
                    <div style={{ fontSize: "48px", fontFamily: "serif" }}> {counterOn && <CountUp start={0} end={150} duration={2} delay={0}> </CountUp>}</div>
                    <p className='' style={{ fontSize: "14px" }}>SMB CUSTOMER</p>
                  </div>
                </div>
                <div className="col-lg-3  d-flex justify-content-center align-items-center">
                  <div>
                    <div style={{ fontSize: "48px", fontFamily: "serif" }}> {counterOn && <CountUp start={0} end={24} duration={2} delay={0}> </CountUp>}</div>
                    <p className='' style={{ fontSize: "14px" }}>OTHER STATE</p>
                  </div>
                </div>

              </div>
            </ScrollTrigger>
          </div>
        </div>

      </section>

      <section>
        <div className="container-fluid pt-5 pb-5" style={{ backgroundColor: "#f7f8fa" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className=''>
                  <i class="fa fa-picture-o text-dark me-2" style={{ fontSize: "23px" }} aria-hidden="true"></i><span className='' style={{ fontSize: "24px", fontFamily: 'serif' }}>PHOTO<span style={{ color: "#186191" }} > GALLERY</span></span>
                  <div className="border border-primary" style={{ width: "55px" }}></div>
                  <div className=" mb-2 " style={{ borderBottom: "1px solid lightgray" }}></div>
                  <p className='' style={{ fontSize: '14px', fontFamily: "sans-serif", color: "#666666" }}>
                    The Skyward Team going through Hussles & Bussles of Channel Partner Interactions in Events at Different parts of the Country.
                  </p>
                  <div className="row">
                    {
                      data.length === 0 || data === "undefined" || data === null ?
                        <> </> :
                        data.map((ele) => {
                          return (
                            <>
                              <div className="col-lg-3 border p-0 border-2 "  >
                                <div className="thumb">
                                  <img className='w-100' src={ele.img} alt="" srcset="" />
                                  <div className="shadow-img d-flex justify-content-center align-items-center ">
                                    <button className='text-start HoverEffect ' style={{ border: "none", padding: "5px", borderRadius: "25px", backgroundColor: "#186191", outline: "none" }} onClick={() => {
                                      handleShow();
                                      setItem(ele);
                                    }}>
                                      <i className="fa fa-picture-o fs-5 " style={{ color: "white" }} aria-hidden="true"></i>
                                    </button>
                                    <div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        })
                    }
                  </div>
                </div>
              </div>
              {
                item.length === 0 || item === "undefined" || item === null ?
                  < >
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header >
                        <div className='w-100 h-100 p-5 text-center'> "somethings went wrong" </div>
                      </Modal.Header>
                    </Modal>
                  </> :

                  <Modal className='w-100 bg-dark bg-opacity-10' show={show} onHide={handleClose} >
                    <Modal.Header closeButton >
                      <img className='w-100' src={item.img} alt="" srcset="" />
                    </Modal.Header>
                  </Modal>
              }
              <div className="col-lg-5 ps-lg-4 ">
                <div className='row   d-flex justify-content-between align-items-center' style={{ borderBottom: "1px solid lightgray" }}>
                  <div className='col-8 ps-lg-2 d-flex justify-content-start align-items-center' style={{ fontSize: "23px", fontFamily: 'serif' }}>
                    <div> <i class="fa fa-users text-dark " aria-hidden="true"></i > </div>
                    <div className='ps-2'> CLIENT <span style={{ color: "#186191" }} > TESTIMONIALS</span></div>
                    {/* <span  ></span> */}
                  </div>

                  <div className='controls col-4 d-flex justify-content-end align-items-center'>

                    <button onClick={handleSlideUp} disabled={startIndex === 0}>
                      <i class="fa fa-angle-left " aria-hidden="true"></i> </button>
                    &nbsp; &nbsp;
                    <button onClick={handleSlideDown} disabled={startIndex === testimonialsData.length - 3}>
                      <i class="fa fa-angle-right " aria-hidden="true"></i>
                    </button>
                  </div>
                  <div className="border border-primary" style={{ width: "55px" }}></div>
                </div>
                <div className="testimonials-container ps-lg-2 mt-5">
                  <div className="testimonials">
                    <div className="testimonial-column">
                      {testimonialsData.slice(startIndex, startIndex + 3).map((testimonial) => (
                        <div className="container    testimonial w-100" key={testimonial.id} >
                          <div className="row p-3 d-flex bg-body justify-content-center align-items-center  inner-testimonial ">
                            <div className="col-lg-3">
                              <img className='w-100' src={testimonial.img} style={{ borderRadius: '50%' }} alt="" />
                            </div>
                            <div className="col-lg-9" >
                              <p style={{ fontFamily: "'Open Sans', sans-serif" }}>
                                {testimonial.content}

                              </p>
                              <p style={{ fontFamily: "'Open Sans', sans-serif" }}>
                                <span style={{ color: "#186191", fontSize: "16px" }}> {testimonial.name} </span>
                                <small style={{ fontSize: "14px" }}><em>{testimonial.company}</em></small>
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                </div>


              </div>
            </div>
          </div>
        </div>
      </section >

      <section>
        <div className="container-fluid mb-3" style={{ backgroundColor: ' #999999' }}>
          <div className="container ">
            <div className="row " >
              <div className="col-lg-6 mt-5 ps-3 p-3">
                <p className="ps-3" style={{ fontSize: "24px", fontFamily: "trirong,serif" }}><b>VIRTUAL MEETING PLATFORM TO THE NEXT LEVEL SOLUTIONS</b></p>

                <p className="ps-3" style={{ fontSize: "20px", fontFamily: "trirong,serif" }}>with Business Conferencing Systems</p>
                <p className='ps-3' style={{ textAlign: "justify", fontSize: "16px", fontFamily: "open sans" - 'sans-serif' }}>Skyward offers a unique & comprehensive set of support services that enable clients to accelerate the value gained from their investment in our products & solutions</p>
                <NavLink exact to="/BusinessConference" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px" }} className="btn bgmi  rounded-pill .me-20 mb-5" >View More..</button></NavLink>

              </div>
              <div className="col-lg-6 ps-3 pt-5">
                <img src={require("../images/hd-con.png")} className="w-75 ps-3" alt="hd-con" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="whatsApp">
        <a href="http://wa.me/+919579713010" area-aria-label="whatsapp" rel="noopener noreferrer" target="_blank">
          <i className="fs-1  text-success bi bi-whatsapp"></i>
        </a>

      </section>

      <Footer />
    </>
  )
}
export default Home;
