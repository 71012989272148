import React from "react";
// import { NavLink } from "react-router-dom";
import Header from "./Header";
import { KeyboardDoubleArrowRight } from "@mui/icons-material";
import Footer from "./Footer";

const CiscoSwitch = () => {

  return (
    <>
      <Header />


     
      <section>
        <div className="container">
          <div className="row">
            <div className="">
              <div >
                <img src={require("../images/Cisco-switch.png")} style={{ height: "200px" }} className=" w-100" alt="" />
                <p className="fs-1  text-center"><b>Cisco Switches</b></p>

              </div>
            </div>
          </div>

          <div className="row bg-success bg-opacity-10">
            <p style={{ textAlign: "justify" }}>
              Most business networks today use switches to connect computers, printers, phones, cameras, lights, and servers in a building or campus. A switch serves as a controller, enabling networked devices to talk to each other efficiently.
              Cisco offers two types of network switches: fixed configuration and modular switches.
            </p>

          </div>

          <div className="row">
            <div className="col-lg-6">
              <img src={require("../images/Cisco-switch.png")} className="img-fluid" alt="" />


            </div>
            <div className="col-lg-6">
              <p className="fs-4"><b>Features</b></p>
              <p><KeyboardDoubleArrowRight className="ar" />Choose what works for you
                <p>Get work done faster, smarter, and more securely with the switch that's right for you.</p>
              </p>
              <p><KeyboardDoubleArrowRight className="ar" />Evolve your network with intent
                <p>Bridge the gap between what your business needs and what your network delivers.</p>
              </p>
              <p><KeyboardDoubleArrowRight className="ar" />Protect with insight
                <p>Stay ahead of threats even as they evolve, with built-in security features and proactive operations.</p>
              </p>
              <p><KeyboardDoubleArrowRight className="ar" />Be ready for what's next
                <p>Flex to future needs, from ASIC to OS, and model-driven programmability across the full stack.  </p>
              </p>

            </div>
          </div>
        </div>
      </section>








      <Footer />
    </>
  )
}

export default CiscoSwitch;