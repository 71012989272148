import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const Ciscoservers = () => {

  const [display, setDisplay] = useState("key_Featured");

  const [btn1, setBtn1] = useState("newStyle");

  const [btn2, setBtn2] = useState("btnStyle");

  const [btn3, setBtn3] = useState("newwStyle");

  const [btn4, setBtn4] = useState("newwwStyle");


  const changleDisplay = () => {

    setDisplay("key_Featured")
    setBtn1("newStyle")
    setBtn2("btnStyle")
    setBtn3("newwStyle")


  }

  const changleDisplay1 = () => {

    setDisplay("Benefits")
    setBtn2("newStyle");
    setBtn3("newwStyle")

  }

  const changleDisplay2 = () => {

    setDisplay("features")
    setBtn2("newStyle");
    setBtn3("newwStyle")

  }

  const changleDisplay3 = () => {

    setDisplay("effects")
    setBtn2("newStyle");
    setBtn3("newwStyle")
    setBtn4("newwwStyle")

  }



  return (
    <>
      <Header />

      <section style={{ backgroundColor: "#090909" }}>
        <div className="container">
          <div className="row text-center d-flex  justify-content-center align-items-center">
            <div className="col-lg-6  ">
              <div className="text-lg-start p-lg-2 ps-lg-5">
                <p className="text-white " style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>Network Switches</p>

              </div>
            </div>
            <div className="col-lg-6   text-lg-center">
              <div className="p-lg-2">
                <p className="text-white ">
                  <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home /</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}>Grandstream / Network Solutions / Server
                  </span></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid pt-5 mb-3">
          <div className="container">
            <div className="row">
              <div className="col-md-3 d-none d-sm-block">
                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>BRANDS</span>
                <div className="mb-1"></div>
                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                <div className="border border-white border-opacity-10 mb-2 "></div>
                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                  <NavLink exact to="/grandstream" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; Grandstream</p></NavLink>
                  <NavLink exact to="/Dinstar" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Dinstar</p></NavLink>
                  <NavLink exact to="/NEC" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; NEC</p></NavLink>
                  <NavLink exact to="/Alcatel" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Alcatel</p></NavLink>
                  <NavLink exact to="/YeaLink" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;YeaLink</p></NavLink>
                  <NavLink exact to="/Poly" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Poly</p></NavLink>
                  <NavLink exact to="/Cisco" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Cisco</p></NavLink>
                </p>

                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>SOLUTIONS</span>
                <div className="mb-1"></div>
                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                <div className="border border-white border-opacity-10 mb-2 "></div>
                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                  <NavLink exact to="/Enterprise-Manufacturing" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Enterprise-Manufacturing</p></NavLink>
                  <NavLink exact to="/Security&Surveillance" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Security & Surveillance</p></NavLink>
                  <NavLink exact to="/EducationHospitality" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}> Education & Hospitality</p></NavLink>
                  <NavLink exact to="/BusinessConference" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Business Conference</p></NavLink>

                </p>

                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">

                  <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/GWN7605">
                          <img src={require("../images/GWN7605.png")} style={{ width: "263px" }} alt="GWN7605" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>WiFi Access Point (GWN7605)</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream's powerful WiFi Access Points offer high performance networking, tremendous WiFi coverage range read more.. </p>
                        </NavLink>
                      </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/BusinessConference">
                          <img src={require("../images/Business-Conferencing.png")} style={{ width: "263px" }} alt="Business-Conferencing" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Business Conferencing</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream’s video conferencing solutions offer small and medium sized businesses flexibility read more..</p>
                        </NavLink>
                      </div>            </div>
                    <div className="carousel-item" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/wp810">
                          <img src={require("../images/wifi-cordless.png")} style={{ width: "263px" }} alt="wifi-cordless" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Wi-Fi IP Phone (WP 810)</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>WP810 is a portable Wi-Fi IP phone designed to suit a variety of enterprises  Read More..</p>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">

                <div className="mt-3">
                  <p className="" style={{ color: "#333333", fontSize: '27px', fontFamily: "sans-serif", fontWeight: "600" }}>
                    SERVER
                  </p>
                </div>

                <div className="" style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                  <p>
                    A server is a computer program or device that provides a service to another computer program and its user, also known as the client. In a data center, the physical computer that a server program runs on is also frequently referred to as a server.
                  </p>

                  <p style={{ fontSize: "16px", color: "#666666", fontWeight: "600", fontFamily: "sans-serif" }}>What is Server?</p>

                  <p>
                    server, network computer, computer program, or device that processes requests from a client (see client-server architecture). On the World Wide Web, for example, a Web server is a computer that uses the HTTP protocol to send Web pages to a client's computer when the client requests them.                                    </p>

                  <p style={{ fontSize: "16px", color: "#666666", fontWeight: "600", fontFamily: "sans-serif" }}>   How does a Server work?</p>

                  <p>
                    A server doesn't have the screen or keyboard. And although your computer stores files and data you've put on it, a server stores all the data associated with the websites that are hosted by it and shares that info with all computers and mobile devices (like yours) that need to access them.
                  </p>

                  <p style={{ fontSize: "16px", color: "#666666", fontWeight: "600", fontFamily: "sans-serif" }}>   How does a Server work?</p>
                  <p>
                    Servers manage network resources. For example, a user may set up a server to control access to a network, send/receive e-mail, manage print jobs, or host a website. They are also proficient at performing intense calculations
                  </p>
                </div>



                <div className="row">
                  <div className="col-lg-5 p-0 d-flex " >
                    <button style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "600" }} className={btn4} onClick={changleDisplay3}>SERVER</button>
                    <button style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "600" }} className={btn1} onClick={changleDisplay}>HP</button>
                    <button style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "600" }} className={btn2} onClick={changleDisplay1}>DELL</button>
                    <button style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "600" }} className={btn3} onClick={changleDisplay2}>CISCO</button>

                  </div>
                </div>
                <div className="row border ">
                  <div>
                    {


                      display === 'effects' ?
                        <div>
                          <div className="row m-4">

                            <div className="col-md-4 h-100">
                              <div className=" border border-dark">
                                <div className="  d-flex  justify-content-center align-items-center  ">
                                  <img className="w-100 p-3" src={require("../images/Cloud-Virtual-Server.png")} alt="Cloud Virtual Server" />

                                </div>
                                <div className="text-center">
                                  <p style={{ fontSize: "18px", fontFamily: "serif", fontWeight: "600" }}>Cloud Virtual Server</p>
                                  <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000" }}>A cloud server is a virtual or physical server, hosted remotely by a cloud service provider, that customers create or access via an internet connection.</p>
                                  <NavLink exact to="/Cloud-Virtual-Server">Read More →</NavLink>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 h-100">
                              <div className=" border border-dark">
                                <div className="  d-flex  justify-content-center align-items-center  ">
                                  <img className="w-100  p-3" src={require("../images/Power-Virtual-Server.png")} alt="Power-Virtual-Server" />
                                </div>
                                <div className="text-center">
                                  <p style={{ fontSize: "18px", fontFamily: "serif", fontWeight: "600" }}>Power-Virtual-Server</p>
                                  <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000" }}>A cloud server is a virtual or physical server, hosted remotely by a cloud service provider, that customers create or access via an internet connection.</p>
                                  <NavLink exact to="/Power-Virtual-Server">Read More →</NavLink>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4 h-100">
                              <div className=" border border-dark">
                                <div className="  d-flex  justify-content-center align-items-center  ">
                                  <img className="w-100 p-3" src={require("../images/EPS.png")} alt="Enterprise-Server" />

                                </div>
                                <div className="text-center">
                                  <p style={{ fontSize: "18px", fontFamily: "serif", fontWeight: "600" }}>Enterprise-Server</p>
                                  <p className="" style={{ marginBottom: "34px", fontSize: "12px", fontFamily: "sans-serif", color: "#000000" }}>Built to handle mission-critical workloads while maintaining security, reliability and control of your entire IT infrastructure </p>
                                  <NavLink exact to="/Enterprise-Server">Read More →</NavLink>
                                </div>

                              </div>
                            </div>
                          </div>

                        </div>


                        : display === "key_Featured" ?
                          <div className="m-2 ">
                            <div className="container">
                              <div className="row gy-4 mb-4">
                                <div className="col-md-4 h-100 ">
                                  <div className="border p-2 border-dark">
                                    <div className="d-flex  justify-content-center align-items-center  ">
                                      <img className="w-100" src={require("../images/hp-2.png")} alt="HP-Integrity-RX3600-Server" />
                                    </div>
                                    <div className="text-center">
                                      <p style={{ fontSize: "18px", fontFamily: "serif" }}>HP Integrity RX3600 Server</p>
                                      <NavLink exact to="/HP-Integrity-RX3600-Server" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</NavLink>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4 h-100 ">
                                  <div className="border p-2 border-dark">
                                    <div className="d-flex  justify-content-center align-items-center  ">
                                      <img className="w-100" src={require("../images/HP-Integrity-RX4640-1.png")} alt="HP-Integrity-RX4640-1" />
                                    </div>
                                    <div className="text-center">
                                      <p style={{ fontSize: "18px", fontFamily: "serif" }}>HP-Integrity-RX4640 Server</p>
                                      <NavLink exact to="/HP-Integrity-RX4640" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</NavLink>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 h-100 ">
                                  <div className="border  p-2 border-dark">
                                    <div className="d-flex  justify-content-center align-items-center  ">
                                      <img className="w-100" src={require("../images/hp-3.png")} alt="HT813" />
                                    </div>
                                    <div className="text-center">
                                      <p style={{ fontSize: "18px", fontFamily: "serif" }}>HPE-ProLiant-DL380-Gen10</p>
                                      <NavLink exact to="/HPE-ProLiant-DL380-Gen10" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</NavLink>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 h-100 ">
                                  <div className="border p-2 border-dark">
                                    <div className="d-flex  justify-content-center align-items-center  ">
                                      <img className="w-100" src={require("../images/HPE-ProLiant-DL20-Gen10-E-2224.png")} alt="HPE-ProLiant-DL20-Gen10-E-2224" />
                                    </div>
                                    <div className="text-center">
                                      <p C>HPE-ProLiant-DL20-Gen10-E-2224</p>
                                      <NavLink exact to="/HPE-ProLiant-DL20-Gen10-E-2224" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</NavLink>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4 h-100 ">
                                  <div className="border p-2 border-dark">
                                    <div className="d-flex  justify-content-center align-items-center  ">
                                      <img className="w-100" src={require("../images/hp-1.png")} alt="HP-Integrity-RX6600" />
                                    </div>
                                    <div className="text-center">
                                      <p style={{ fontSize: "18px", fontFamily: "serif" }}>HP-Integrity- <br />RX6600</p>
                                      <NavLink exact to="/HP-Integrity-RX6600" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</NavLink>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 h-100 ">
                                  <div className="border  p-2 border-dark">
                                    <div className="d-flex  justify-content-center align-items-center  ">
                                      <img className="w-100" src={require("../images/hp-3.png")} alt="HPE-ProLiant-DL380-Gen10-4208" />
                                    </div>
                                    <div className="text-center">
                                      <p style={{ fontSize: "18px", fontFamily: "serif" }}>HPE-ProLiant-DL380-Gen10-4208</p>
                                      <NavLink exact to="/HPE-ProLiant-DL380-Gen10-4208-Silver" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</NavLink>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4 h-100 ">
                                  <div className="border  p-2 border-dark">
                                    <div className="d-flex  justify-content-center align-items-center  ">
                                      <img className="w-100" src={require("../images/HPE-ProLiant-DL180-Gen10.jpg")} alt="HPE-ProLiant-DL180-Gen10" />
                                    </div>
                                    <div className="text-center">
                                      <p style={{ fontSize: "18px", fontFamily: "serif" }}>HPE-ProLiant-DL180-Gen10</p>
                                      <NavLink exact to="/HPE-ProLiant-DL180-Gen10" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</NavLink>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 h-100 ">
                                  <div className="border  p-2 border-dark">
                                    <div className="d-flex  justify-content-center align-items-center  ">
                                      <img className="w-100" src={require("../images/HPE-ProLiant-DL380-Gen10-4214R.png")} alt="HPE-ProLiant-DL380-Gen10-4214R" />
                                    </div>
                                    <div className="text-center">
                                      <p style={{ fontSize: "18px", fontFamily: "serif" }}>HPE-ProLiant-DL380-Gen10-4214R</p>
                                      <NavLink exact to="/HPE-ProLiant-DL380-Gen10-4214R" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</NavLink>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                          : display === "Benefits" ?
                            <div>
                              <div className="row m-3">
                                <div className="col-lg-4 h-100 text-center ">
                                  <div className=" border">
                                    <img src={require("../images/rack-server.png")} className="w-100 mt-2" alt="" />
                                    <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }} >Rack Servers</p>
                                    <p className="p-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>A versatile server with extra storage capacity and I/O performance. Designed for data-intensive applications. Maximize performance for cloud applications, XaaS, and virtualization workloads. Designed to balance accelerator cards and storage. Provide scalable, secure, high-density computing.</p>
                                  </div>

                                </div>

                                <div className="col-lg-4 text-center ">
                                  <div className=" border">
                                    <img src={require("../images/rack-server.png")} className="w-100 mt-2" alt="" />
                                    <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }} >Tower Servers</p>
                                    <p className="p-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>Designed for business-critical workloads, cloud infrastructure, database management, AI/ML, and inference. An entry-level tower server with adaptable performance and high capacity. Designed for small business data centers to handle office workloads.</p>
                                  </div>

                                </div>

                                <div className="col-lg-4 text-center ">
                                  <div className=" border">
                                    <img src={require("../images/rack-server.png")} className="w-100 mt-2" alt="" />
                                    <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }} >Modular Servers</p>
                                    <p className="p-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>A flexible two-socket, single-width sled server ideal for dense compute. Designed for virtualization, software-defined storage, HCI, and big data. A flexible, storage-rich, four-socket compute sled. Designed for data-driven, mission-critical applications and performance workloads.</p>
                                  </div>

                                </div>

                                <div className="col-lg-4 mt-3 text-center">
                                  <div className=" border">
                                    <img src={require("../images/rack-server.png")} className="w-100 mt-2" alt="" />
                                    <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }} >Rugged Servers</p>
                                    <p className="p-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>Rugged PowerEdge XR servers are built to withstand the extreme heat, dust, shock and vibration of factory floors, construction sites, mobile command centers and other extreme environments. Offering you high-performance, reliable and expertly engineered technology backed by engineering expertise.</p>
                                  </div>

                                </div>
                              </div>
                            </div>
                            : display === 'features' ?

                              <div>
                                <div className="row">
                                  <div className="col-lg-4 text-center">
                                    <div className="m-3 border">
                                      <img src={require("../images/UCS X-Series.png")} className="img-fluid" alt="" />
                                      <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }}>UCS X-Series Modular System</p>
                                      <p className="ps-2 pe-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>Unlock operational efficiency, agility, and scale for your hybrid cloud infrastructure.</p>

                                    </div>
                                  </div>

                                  <div className="col-lg-4 text-center">
                                    <div className="m-3 border">
                                      <img src={require("../images/UCS-B-Series.png")} className="img-fluid" alt="" />
                                      <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }}>UCS B-Series Blade Servers</p>
                                      <p className="ps-2 pe-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>Tap into better performance and operational efficiency for a wide range of physical and virtual workloads.</p>

                                    </div>
                                  </div>

                                  <div className="col-lg-4 text-center">
                                    <div className="m-3 border">
                                      <img src={require("../images/UCS-C-Series.png")} className="img-fluid" alt="" />
                                      <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }}>UCS C-Series Rack Servers</p>
                                      <p className="ps-2 pe-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>Tackle any workload challenge with a flexible combination of processor, memory, I/O, and internal storage resources.</p>

                                    </div>
                                  </div>

                                  <div className="col-lg-4 text-center">
                                    <div className="m-3 border">
                                      <img src={require("../images/UCS-S-Series.png")} className="img-fluid" alt="" />
                                      <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }}>UCS S-Series Storage Server</p>
                                      <p className="ps-2 pe-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>Activate your data and insights in real time with this modular, high-availability, storage-optimized server.</p>

                                    </div>
                                  </div>

                                </div>
                              </div>



                              : <div>
                                somethings went wrong
                              </div>
                    }
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>





      <Footer />
    </>
  )
}
export default Ciscoservers;