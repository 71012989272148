import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "./RealEstate.css";
import { NavLink } from "react-router-dom";

const RealEstate = () => {

  return (
    <>
      <Header />

      <section>
        <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/realestate-bnr.png")})`, backgroundAttachment: "local", height: "25vh", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
          <div className="container">
            <div className="row text-center d-flex  justify-content-center align-items-center">
              <div className="col-lg-6 ">
                <div className=" pt-lg-5 pb-lg-5">
                  <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>Enterprise & Manufacturing</p>
                </div>
              </div>
              <div className="col-lg-6   text-lg-end">
                <div className=" pt-lg-5 pb-lg-5">
                  <p className="text-white">
                    <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}>  <NavLink exact to="/Solution" className="text-white">/ Solutions </NavLink>/ Enterprise & Manufacturing </span></span>
                  </p>



                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid bg-light">
          <div className="row">
            <div className="col-lg-6 ps-lg-5">
              <div className="pt-lg-5">
                <p className="text-start ps-lg-5 " style={{ fontSize: "30px", fontFamily: "serif", color: "#333", fontWeight: "600" }}>
                  Smart Solutions for Real Estate
                </p>
                <div className="ps-lg-5" style={{ textAlign: "justify", fontSize: "14px", fontFamily: "sans-serif", color: "#666", lineHeight: '1.7' }}>
                  <p>
                    The real estate industry faces fierce competition. Realtors try to sell their best property. People don’t buy property just like that. It’s a matter of lifetime savings, plans, and core investment for them. Since buying a property is a significant decision for everyone, they naturally ask many questions and clear their doubts before even considering a piece of property. It is necessary then that, people are able to communicate hassle free with real estate agents and brokers to clear their doubts Traditional phone systems keep posing many barriers against uninterrupted communication and are not adapted to the volume of business of the real estate sectors.
                  </p>
                  <p>
                    Unified Communication as a solution offers lots of features and benefits that enable real estate agents to market and advertise their property efficiently. It provides an option to leave behind the cold calling as part of promotion and communication.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pb-2 text-center">
              <img className="w-75  p-lg-5" src={require("../images/smart-home1.png")} alt="smart-home1" />
            </div>
          </div>
        </div>
      </section>



      <section style={{ backgroundColor: "#E8E6E6" }}>
        <div className="container ">
          <div className="row pb-lg-5">
            <div className="col-lg-12">
              <p className="pt-lg-5" style={{ fontSize: "30px", fontWeight: "600", fontFamily: "serif", color: "#186191" }}>How VoIP helps Real Estate Agents and Marketing?</p>
              <div style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7" }}>
                <p >
                  Unified Communication requires easy setup to start working. It allows real estate agents to move around freely without missing a call and has features like IVR, ACD, and other feature to handle customer calls better. It also allows agents to target a specific audience and engage them, which, in turn, improves the marketing campaigns.
                </p>
                <p>
                  It is important to understand how Unified Communication is the perfect choice for realtors and why this facility should be implemented at the earliest
                </p>
              </div>
              <p className="pt-lg-5" style={{ fontSize: "30px", fontWeight: "600", fontFamily: "serif", color: "#186191" }}>Six Reasons</p>


              <b style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#555555" }}>1. Easy Use, Management
              </b><br />
              <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7" }}>
                Unified Communication is very easy to use and manage. It can be customized whichever way it is required and also be changed in due course.
              </p>

              <b style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#555555" }}>2. IVR and ACD
              </b><br />
              <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7" }}>
                An IVR greets callers and provides option to connect with the different departments. The caller listens to some music while waiting during the call transfer, making it easier for them to wait and comprehend the present situation.
                An IVR paired with automatic call distribution helps automatically transferring the callers to the right extension thus saving time and comfort to the customer.
              </p>

              <b style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#555555" }}>3. Find me, Follow me
              </b><br />
              <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7" }}>
                It really works for the people who are always on the move. The need is to remain contactable wherever he is; be it on mobile, in office or at home. A FIND ME, FOLLOW ME technology helps provide the flexibility through ring groups enabling the call ringing at all the three points at the same time.
                It can also set up the follow me in which the call follows the person wherever he is. Follow me feature ensures handling of customer calls as per requirement so that no call or conversation is missed.
              </p>
              <b style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#555555" }}>4. Scalability
              </b><br />
              <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7" }}>
                On Hold, Listen To Some Music IP Telephony helps calls reach the concerned person wherever he is. However, there are times when the person is busy and unable to respond immediately. UC offers music on hold feature which ensures that customer is comfortable in waiting before the call is answered.
              </p>
              <b style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#555555" }}>5. Quality, the Best
              </b><br />
              <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7" }}>
                Good quality uninterrupted Communication is vital for the real estate sector. Disturbances in the calling system may irk the customer that can create bad impression about the company. Unified Communication offers undisturbed voice calling which ensures that all customers get the proper response at right time, unhindered.
              </p>
              <b style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#555555" }}>6. Mobility, Flexibility

              </b><br />
              <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7" }}>
                Real Estate is always on the go, making them different from other businesses. Each call is extremely valuable and having an effective communication system in place that helps control the customer calls, further eases the business process. UC ensures that agents are able to access and control their incoming calls, wherever they are. It also lets agents control their status of availability making each conversation important and rewarding.
              </p>
              <b style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#555555" }}>7. Cost Saving, Scalable
              </b><br />
              <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7" }}>
                Effective management of funds and Cost saving is essential in every business. UC is cheaper and easier to buy than traditional phone systems. It is also scalable as compared to other systems. Addition or removal of phone number devices, logins etc. can be easily done when required. Since the internet is easily accessible, it helps in looking at IP Telephony as future investment. It is easier to manage and control and needs minimal support than a conventional system. Hence, it is possible to reduce overhead expenses and bring down the overall operation cost, allowing the saved money to be diverted towards other important factors.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light">
        <div className="container">
          <div className="row ps-lg-5 pt-lg-5 pb-lg-5">

            <div className="text-start">
              <p className="" style={{ fontSize: "15px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                Skyward Telecom offers Smart Unified Communication Solution enabling the Real Estate sector to make the most of their conversations and see their business blossom!


              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
      <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/bg31.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}> 
          <div className="container">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-lg-9">
                <div className="ps-2 p-lg-4" style={{lineHeight:"2"}}>
                  <p className="fs-2" style={{ color: "white", fontWeight: "700", fontFamily: "sans serif" }}>
                    Bright Solutions. Profitable Results.

                    <p style={{ fontSize: '18px', color: "white", fontWeight: "700", fontFamily: "sans serif" }}>
                      Experience you can trust, service you can count on.
                    </p></p>
                </div>
              </div>
              <div className="col-lg-3 mb-2">
              <div className="text-lg-center p-lg-5">
              <a href="/Contact-us"><button style={{ fontSize: "14px", border: "none", outline: "none", padding: "10px 26px" }} className=" rounded-pill btn-lg text-black bg-white">Schedule a Demo</button></a>

              </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className=" pt-5 text-center">
            <img src={require("../images/office-sloutions1.png")} className="w-50" alt="office-sloutions1" />
          </div>
        </div>
      </section>




      {/* <div className="RealEstate">
        <div class="p-3">
          <img src={require("../images/Real-Estate.jpg")} className="w-100" alt="Real-Estate" />
          <h1>Real Estate</h1>
        </div>
        <div class="row g-0 bg-body-secondary position-relative">
          <div class="col-md-6 mb-md-0 p-md-4">
            <img src={require("../images/Real-estate-1.jpg")} class="w-100" alt="..." />
          </div>
          <div class="col-md-6">
            <p class="mt-25">The real estate industry faces fierce competition. Realtors try to sell their best property. People don’t buy property just like that. It’s a matter of lifetime savings, plans, and core investment for them. Since buying a property is a significant decision for everyone, they naturally ask many questions and clear their doubts before even considering a piece of property. It is necessary then that, people are able to communicate hassle free with real estate agents and brokers to clear their doubts Traditional phone systems keep posing many barriers against uninterrupted communication and are not adapted to the volume of business of the real estate sectors.</p>
            <p>Unified Communication as a solution offers lots of features and benefits that enable real estate agents to market and advertise their property efficiently. It provides an option to leave behind the cold calling as part of promotion and communication.</p>
          </div>
        </div>
        <div className="realbox bg-body-tertiary ">
          <h2>How VoIP helps Real Estate Agents and Marketing?</h2>
          <p>Unified Communication requires easy setup to start working. It allows real estate agents to move around freely without missing a call and has features like IVR, ACD, and other feature to handle customer calls better. It also allows agents to target a specific audience and engage them, which, in turn, improves the marketing campaigns.</p>
          <p>It is important to understand how Unified Communication is the perfect choice for realtors and why this facility should be implemented at the earliest</p>
          <h4>Six Reasons</h4>
          <p><b>1. Easy Use, Management</b><br />
            Unified Communication is very easy to use and manage. It can be customized whichever way it is required and also be changed in due course.
          </p>
          <p><b>2. Find me, Follow me</b><br />
            It really works for the people who are always on the move. The need is to remain contactable wherever he is; be it on mobile, in office or at home. A FIND ME, FOLLOW ME technology helps provide the flexibility through ring groups enabling the call ringing at all the three points at the same time.
            <br />
            It can also set up the follow me in which the call follows the person wherever he is. Follow me feature ensures handling of customer calls as per requirement so that no call or conversation is missed.
          </p>
          <p><b>3. IVR and ACD</b><br />
            An IVR greets callers and provides option to connect with the different departments. The caller listens to some music while waiting during the call transfer, making it easier for them to wait and comprehend the present situation.
            <br />
            An IVR paired with automatic call distribution helps automatically transferring the callers to the right extension thus saving time and comfort to the customer.
          </p>
          <p><b>4. Quality, the Best</b><br />
            Good quality uninterrupted Communication is vital for the real estate sector. Disturbances in the calling system may irk the customer that can create bad impression about the company. Unified Communication offers undisturbed voice calling which ensures that all customers get the proper response at right time, unhindered.
          </p>
          <p><b>5. Scalability</b><br />
            On Hold, Listen To Some Music IP Telephony helps calls reach the concerned person wherever he is. However, there are times when the person is busy and unable to respond immediately. UC offers music on hold feature which ensures that customer is comfortable in waiting before the call is answered.
          </p>
          <p><b>6. Cost Saving, Scalable</b><br />
            Effective management of funds and Cost saving is essential in every business. UC is cheaper and easier to buy than traditional phone systems. It is also scalable as compared to other systems. Addition or removal of phone number devices, logins etc. can be easily done when required. Since the internet is easily accessible, it helps in looking at IP Telephony as future investment. It is easier to manage and control and needs minimal support than a conventional system. Hence, it is possible to reduce overhead expenses and bring down the overall operation cost, allowing the saved money to be diverted towards other important factors.
          </p>
        </div>

      </div> */}
      <Footer />
    </>
  )
}
export default RealEstate;