import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";


const YeaLink = () => {
  return (
    <>
      <Header />

      <section>
        <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/header-bnr.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
          <div className="container">
            <div className="row text-center d-flex  justify-content-center align-items-center">
              <div className="col-lg-6  text-lg-start">
                <div className="pb-lg-5 pt-lg-5">
                  <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>Yealink</p>

                </div>
              </div>
              <div className="col-lg-6   text-lg-center">
                <div className=" pt-lg-5 pb-lg-5">
                  <p className="text-white">
                    <span className=""><NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}> /Yealink </span></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid  text-dark" style={{ backgroundColor: "#EEECEC" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 ">
                <p className=" mt-3 fs-2 " style={{ color: "#186191", fontSize: '36px', fontWeight: "600", fontFamily: "serif" }}>Meet Yealink</p>
                <p className="mt-3" style={{ fontSize: "16px", fontFamily: "serif" }}>
                  Yealink is a global leading unified communication (UC) solution provider that primarily offers video conferencing systems and voice communication solutions. Yealink is the world’s second-largest SIP phone provider.                                </p>
                <NavLink exact to="/Contact-us"><button type="button" className=" mb-2 btn  me-md-3 " style={{ backgroundColor: "#186191", color: "white" }}>Get a Quote </button></NavLink>

              </div>
              <div className="col-lg-4">
                <img className="w-75 mt-5" src={require("../images/img/Yealink-logo.png")} alt="Grandstream-logo" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 p-5">
                <p className="" style={{ fontSize: "24px", fontFamily: "serif", color: "#333333" }}>
                  We Preferred yealink'S
                  <div className="border border-primary mt-lg-2" style={{ width: "55px" }}></div>
                  <div className="border border-dark border-opacity-10 mb-3 "></div>
                </p>
                <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666" }}>
                  Yealink (Stock Code: 300628) is a global brand that specializes in video conferencing, voice communications and collaboration solutions with best-in-class quality, innovative technology and user-friendly experience. As one of the best providers in more than 140 countries and regions, Yealink ranks No.1 in the global market share of SIP phone shipments (Global IP Desktop Phone Growth Excellence Leadership Award Report, Frost & Sullivan, 2018).
                </p>
              </div>
              <div className="col-lg-6">
                <div>
                  <img src={require("../images/img/yealink-1.png")} className="w-100" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid mt-5" style={{ backgroundColor: "#186191" }}>
          <div className="container">
            <div className="row g-2 p-3">
              <div className="col-lg-7 pt-3">
                <div className="text-white">
                  <p className=" mt-3 mb-3 fs-3 fw-bold" style={{ fontSize: "34px", fontFamily: "sans-serif" }}>yealink is connecting the World</p>
                  <p style={{ fontSize: "18px", fontFamily: "sans-serif" }}>To know more about Dinstar Products & Solutions connect with our sales person!!</p>
                </div>
              </div>
              <div className="col-lg-5 text-center pt-5">
                <a href="/Contact-us"><button style={{ fontSize: "14px", border: "none", outline: "none", padding: "10px 26px" }} className=" rounded-pill btn-lg text-black bg-white">Contact Us</button></a>

              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid pt-5 pb-3">
          <div className="container">
            <div className="row">
              <div className="">
                <p className=" fw-bold" style={{ fontSize: "32px", fontFamily: "serif" }}>Products and Solutions</p>
                <p style={{ fontSize: "14px", fontFamily: "sans-serif" }}>Grandstream designs and manufactures a wide-range of award winning products and solutions</p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section>
        <div className="container-fluid pt-5">
          <div className="container">
            <div className="row g-4">
              <div className="col-lg-3 h-100">
                <div className=" business-border ">
                  <div className="text-center">
                    <img className="w-100" src={require("../images/img/IP-DECT-PHONE-home.png")} alt="IP-phone" />

                  </div>
                  <p className="text-center" style={{ fontSize: '20px', color: "#555555", fontWeight: "600", fontFamily: "serif" }}>IP Dect Phone</p>
                  <p className="text-start ps-3" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>SIP Cordless Phone System designed for small business </p>
                  <NavLink exact to="/Ip-dect-phone"><p className="text-center" style={{ fontFamily: "sans-serif", color: "#186191", fontSize: "14px" }}>See products →</p></NavLink>
                </div>
              </div>
              <div className="col-lg-3 h-100">
                <div className=" business-border ">
                  <div className="text-center">
                    <img className="w-100" src={require("../images/img/ip-phone-home.png")} alt="IP-phone" />

                  </div>
                  <p className="text-center" style={{ fontSize: '20px', color: "#555555", fontWeight: "600", fontFamily: "serif" }}>IP Phone</p>
                  <p className="text-start ps-3" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>Yealink HD technology enables rich, clear, life-like voice communications</p>
                  <NavLink exact to="/Ip-phone"><p className="text-center" style={{ fontFamily: "sans-serif", color: "#186191", fontSize: "14px" }}>See products →</p></NavLink>
                </div>
              </div>

              <div className="col-lg-3 h-100">
                <div className=" business-border ">
                  <div className="text-center">
                    <img className="w-100" src={require("../images/img/video-phone-home.png")} alt="IP-phone" />

                  </div>
                  <p className="text-center" style={{ fontSize: '20px', color: "#555555", fontWeight: "600", fontFamily: "serif" }}>IP Video Phone</p>
                  <p className="text-start ps-3" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>Executive-level IP Video Phone. With integrated audio, video and applications,</p>
                  <NavLink exact to="/Ip-video-phone"><p className="text-center" style={{ fontFamily: "sans-serif", color: "#186191", fontSize: "14px" }}>See products →</p></NavLink>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}
export default YeaLink;