import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";


const Webcams = () => {

  return (
    <>
      <Header />


      <section>
      <div className="">
          <div >
          <img src={require("../images/brand.jpg")} className=" w-100" alt="" />

          </div>
      
           
           
 
        </div>
        <div className="container">
          <div className="row">
            {/* <img src={require("../images/brand.jpg")} className="img-fluid" alt="" /> */}
            <p className="fs-1 text-center"><b> Webcams</b></p>
          </div>
       

        <div className="row bg-success bg-opacity-10">
          <p className="fs-3 text-center" >
            Grandstream GUV Full HD USB camera
          </p>
          <p>Webcams in the GUV series include a Full HD USB camera that enables high-quality audio and video collaboration through laptops, computers and more. This webcam supports 1080p Full HD real-time video through a 2 megapixel CMOS image sensor and includes 2 built-in omni-directional microphones with 1+ meter voice pickup for clear communications. The series is compatible with all major third-party communication platforms, apps and softphones as well as Grandstream’s IPVideoTalk Meetings and Wave app.</p>
        </div>


        <div className="row justify-content-center border border-dark mt-5">
          <img src={require("../images/webcams.png")} className="img-fluid " style={{ height: "250px", width: "250px" }} alt="" />
          <NavLink exact to="/Webcams"><p className="fs-4 text-center">GUV3100</p></NavLink>
          <p>Ideal for remote workers, the GUV3100 provides an easy-to-setup, easy-to-use USB webcam with 1080P Full HD that enables high-quality audio and video collaboration through laptops, computers and more.
            Ideal for remote workers, the GUV3100 provides an easy-to-setup, easy-to-use USB webcam with Full HD video and audio quality for web meetings, video conferences and more.
          </p>
        </div>
        </div>
      </section>



      
      <Footer />
    </>
  )
}
export default Webcams;