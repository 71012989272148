import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { NavLink } from "react-router-dom";
const VP530 = () => {

  return (
    <>
      <Header />
      <section style={{ backgroundColor: "#090909" }}>
        <div className="container">
          <div className="row text-center d-flex  justify-content-center align-items-center">
            <div className="col-lg-6  ">
              <div className="text-lg-start p-lg-2 ps-lg-5">
                <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>VP530
                </p>

              </div>
            </div>
            <div className="col-lg-6   text-lg-center">
              <div className="p-lg-2">
                <p className="text-white ">
                  <span className="" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}   >  <NavLink className=" text-white pt-5  text-decoration-none" to="/" > Home /</NavLink> <span className="text-white">Yealink  / IP DECT PHONE / <b style={{ fontSize: "16px" }}>VP530</b>
                  </span></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section>
        <div className="container">
          <div className="container mt-5 ">
            <div className="row">

              <div className="col-lg-4 " >
                <div id="carouselExampleFade" class="carousel slide " data-bs-ride="carousel">
                  <div class="carousel-inner text-center border" style={{ height: "310px" }}>
                    <div class="carousel-item active ">
                      <img className=" w-100" src={require("../images/VP530-slide2.png")} alt="VP530-slide2" />
                    </div>

                    <div class="carousel-item">
                      <img className=" w-100" src={require("../images/VP530.png")} alt="VP530" />
                    </div>

                  </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon p-4 bg-dark" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                    <span class="carousel-control-next-icon p-4 bg-dark" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>


              <div className="col-lg-6">
                <div>
                  <p className="" style={{ fontSize: "40px", fontFamily: "serif", fontWeight: "600" }} >
                    VP530
                  </p>
                  <p style={{ fontSize: "38px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>Yealink Video IP Phone</p>

                  <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                    The Yealink VP530 is a further innovation of our advanced, executive-level IP Video Phone. With integrated audio, video and applications, the VP530 is a very powerful business video phone.
                  </p>
                  <NavLink exact to="/Contact-us" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Request a Quote</button></NavLink>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-3">
          <div className="row ps-lg-3">
            <div className="col-lg-12">
              <p style={{ fontSize: "32px", fontFamily: "serif", color: "#333333", fontWeight: "500" }}>
                Description

                <div className="border border-primary" style={{ width: "55px" }}></div>
                <div className=" mb-2 " style={{ borderBottom: "1px solid lightgray" }}></div>
              </p>
              <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                The Yealink VP530 is a further innovation of our advanced, executive-level IP Video Phone. With integrated audio, video and applications, the VP530 is a very powerful business video phone. Its large display and easy use make it an ideal all-in-one tool for today’s busy business leaders, regardless of where they happen to be. With excellent performance and rich business features, the VP530 offers an all-round, face-to-face network experience that allows users to interact and communicate like never before.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-6 ps-lg-5 ">
              <p className="ps-3" style={{ fontSize: "30px", fontFamily: "serif", color: "#333333" }}>
                Features
              </p>
              <ul style={{ fontSize: "16px", fontFamily: "sans-serif", lineHeight: "30px", textAlign: "justify" }}>
                <li>
                  Integrated intelligent features that make it exceptionally easy to operate
                </li>
                <li>
                  Maximum productivity for managers and executives
                </li>
                <li>
                  Efficient person-to-person contact by video call or video conferencing, leading to lower travel costs and faster decision-making
                </li>
              </ul>
            </div>
            <div className="col-lg-6  text-center">
              <div>
                <img src={require("../images/VP530.png")} className=" w-75 " alt="VP530" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-4">
              <div className="text-center" style={{ backgroundColor: "#F5ECEC", padding: "30px" }} >
                <p style={{ fontSize: "22px", fontFamily: "serif", fontWeight: "600" }}>
                  Quick Enquiry
                </p>
                <p style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                  For the product quotation please fill the given form our team will get back to you shortly after analysing your needs
                </p>

                <div className="pt-3">
                  <NavLink className=" text-decoration-none fs-6" to="/Contact-us" >
                    <span className="btn-round send-btn">SEND ENQUIRY</span>
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="col-lg-8" >
              <div id="carouselExampleIndicators" className="carousel slide " data-bs-ride="carousel" style={{ height: "300px" }}>
                <div className="carousel-indicators sliderbtn">
                  <input style={{ width: "20px", height: "15px" }} type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></input>
                  <input style={{ width: "20px", height: "15px" }} className='' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></input>
                  <input style={{ width: "20px", height: "15px" }} className=' ' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></input>
                  <input style={{ width: "20px", height: "15px" }} className=' ' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></input>
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active animated" data-bs-interval="1500">
                    <div className="row">
                      <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                        <img src={require("../images/img/IP-DECT-PHONE-home.png")} className="w-100" alt="IP-DECT-PHONE-home" />
                      </div>
                      <div className="col-lg-6">
                        <div>
                          <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>IP DECT PHONE  </p>
                          <div className="border border-primary" style={{ width: "55px" }}></div>

                          <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}> The GVC3210 is an innovative video conferencing endpoint ideal for small to medium businesses who seek an easy-to-use yet potent video conferencing solution.   </p>

                          <NavLink exact to="/Ip-dect-phone" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item" data-bs-interval="1500">

                    <div className="row">
                      <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                        <img src={require("../images/ip-phone-home.png")} className="w-100" alt="ip-phone-home" />
                      </div>
                      <div className="col-lg-6">
                        <div>
                          <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>IP PHONE </p>
                          <div className="border border-primary" style={{ width: "55px" }}></div>

                          <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}> The GVC3210 is an innovative video conferencing endpoint ideal for small to medium businesses who seek an easy-to-use yet potent video conferencing solution. </p>
                          <NavLink exact to="/Ip-phone" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item" data-bs-interval="1500">

                    <div className="row">
                      <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                        <img src={require("../images/img/video-phone-home.png")} className="w-100" alt="" />
                      </div>
                      <div className="col-lg-6">
                        <div>
                          <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>IP VIDEO PHONE</p>
                          <div className="border border-primary" style={{ width: "55px" }}></div>

                          <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>The GVC3210 is an innovative video conferencing endpoint ideal for small to medium businesses who seek an easy-to-use yet potent video conferencing solution.    </p>
                          <NavLink exact to="/Ip-video-phone" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}
export default VP530;