import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import "./GsmVoip.css";
import Header from "./Header";

const GsmVoip = () => {

  return (
    <>
      <Header />



      <section>
        <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/header-bnr.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
          <div className="container">
            <div className="row text-center d-flex  justify-content-center align-items-center">
              <div className="col-lg-6 ">
                <div className="pb-lg-5 pt-lg-5">
                  <p className="text-white " style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>GSM/3G/4G VoIP Gateway</p>

                </div>
              </div>
              <div className="col-lg-6   text-lg-center">
                <div className=" pt-lg-5 pb-lg-5">
                  <p className="text-white">
                    <span className=""><NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}> <NavLink exact to="/Dinstar" className="text-white">/Dinstar</NavLink> / GSM/3G/4G VoIP Gateway </span></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid pt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-3 d-none d-sm-block">
                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>BRANDS</span>
                <div className="mb-1"></div>
                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                <div className="border border-white border-opacity-10 mb-2 "></div>
                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                  <NavLink exact to="/grandstream" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; Grandstream</p></NavLink>
                  <NavLink exact to="/Dinstar" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Dinstar</p></NavLink>
                  <NavLink exact to="/NEC" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; NEC</p></NavLink>
                  <NavLink exact to="/Alcatel" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Alcatel</p></NavLink>
                  <NavLink exact to="/YeaLink" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;YeaLink</p></NavLink>
                  <NavLink exact to="/Poly" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Poly</p></NavLink>
                  <NavLink exact to="/Cisco" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Cisco</p></NavLink>

                </p>

                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>SOLUTIONS</span>
                <div className="mb-1"></div>
                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                <div className="border border-white border-opacity-10 mb-2 "></div>
                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                  <NavLink exact to="/Enterprise-Manufacturing" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Enterprise-Manufacturing</p></NavLink>
                  <NavLink exact to="/Security&Surveillance" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Security & Surveillance</p></NavLink>
                  <NavLink exact to="/EducationHospitality" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}> Education & Hospitality</p></NavLink>
                  <NavLink exact to="/BusinessConference" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Business Conference</p></NavLink>

                </p>

                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">

                  <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/GWN7605">
                          <img src={require("../images/GWN7605.png")} style={{ width: "263px" }} alt="GWN7605" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>WiFi Access Point (GWN7605)</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream's powerful WiFi Access Points offer high performance networking, tremendous WiFi coverage range read more.. </p>
                        </NavLink>
                      </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/BusinessConference">
                          <img src={require("../images/Business-Conferencing.png")} style={{ width: "263px" }} alt="Business-Conferencing" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Business Conferencing</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream’s video conferencing solutions offer small and medium sized businesses flexibility read more..</p>
                        </NavLink>
                      </div>            </div>
                    <div className="carousel-item" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/wp810">
                          <img src={require("../images/wifi-cordless.png")} style={{ width: "263px" }} alt="wifi-cordless" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Wi-Fi IP Phone (WP 810)</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>WP810 is a portable Wi-Fi IP phone designed to suit a variety of enterprises  Read More..</p>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div>
                  <img src={require("../images/img/dinstar-GSM-VoIP-Gateway-bnr.jpg")} className="w-100" alt="ip-voice-telephony-bnr" />
                </div>
                <div className="mt-3">
                  <p className="" style={{ color: "#333333", fontSize: '27px', fontFamily: "sans-serif", fontWeight: "600" }}>
                    Dinstar GSM/3G/4G VoIP Gateway
                  </p>
                </div>
                <div className="" style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                  <p>
                    The Dinstar UC2000 series VoIP GSM/3G/4G Gateways offer complete choices scalable from 1 to 128 channels, implement the state-of-the-art functionality in market as always, and enable the smooth transit between mobile and VoIP networks. Integrated GSM/WCDMA/LTE connectivity and SIP protocol compatible with mainstream VoIP platforms, it is suitable for enterprises, multi-site organizations, call terminators and areas with limited landline like rural area to cut down telephony costs and enable easy & efficient communications.                  </p>
                </div>

                <div className="row gy-4 mb-4">
                  <div className="col-md-4 h-100 ">
                    <div className=" border border-dark">
                      <div className=" d-flex  justify-content-center align-items-center  ">
                        <img className="w-100" src={require("../images/UC2000-VA.png")} alt="SIP-T26P" />
                      </div>
                      <div className=" text-center">
                        <p style={{ fontSize: "18px", fontFamily: "serif" }}>UC2000-VA</p>
                        <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000" }}>Hot Swappable SIM Cards</p>
                        <NavLink exact to="/UC2000-VA" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</NavLink>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4  h-100">
                    <div className="border border-dark">
                      <div className="d-flex  justify-content-center align-items-center  ">
                        <img className="w-100" src={require("../images/UC2000-VE.png")} alt="UC2000-VE" />
                      </div>
                      <div className="text-center">
                        <p style={{ fontSize: "18px", fontFamily: "serif" }}>UC2000-VE</p>
                        <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000" }}>4 or 8 channels GSM/3G/4G VoIP Gateway</p>
                        <NavLink exact to="/UC2000-VE" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</NavLink>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 h-100">
                    <div className=" border border-dark">
                      <div className="d-flex  justify-content-center align-items-center  ">
                        <img className="w-100" src={require("../images/UC2000-VF.png")} alt="SIP-T21P-E2" />
                      </div>
                      <div className="text-center">
                        <p style={{ fontSize: "18px", fontFamily: "serif" }}>UC2000 VF</p>
                        <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000" }}>16-channel GSM/3G/4G VoIP Gateway</p>
                        <NavLink exact to="/UC2000-VF" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</NavLink>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="row gy-4 mb-4">
                  <div className="col-md-4 h-100 ">
                    <div className="border border-dark">
                      <div className="d-flex  justify-content-center align-items-center  ">
                        <img className="w-100" src={require("../images/UC2000-VG.png")} alt="SIP-T26P" />
                      </div>
                      <div className="text-center">
                        <p style={{ fontSize: "18px", fontFamily: "serif" }}>UC2000 VG</p>
                        <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000" }}>32-channel GSM/WCDMA/LTE VoIP Gateway</p>
                        <NavLink exact to="/UC2000-VG" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</NavLink>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4  h-100">
                    <div className="border border-dark">
                      <div className="d-flex  justify-content-center align-items-center  ">
                        <img className="w-100" src={require("../images/UC2000-VH.png")} alt="SIP-T26P" />
                      </div>
                      <div className="text-center">
                        <p style={{ fontSize: "18px", fontFamily: "serif" }}>UC2000 VH</p>
                        <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000" }}>64 Channel VoIP GSM Gateway</p>
                        <NavLink exact to="/UC2000-VH" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</NavLink>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4  h-100">
                    <div className="border border-dark">
                      <div className="d-flex  justify-content-center align-items-center  ">
                        <img className="w-100" src={require("../images/Multi-SIM-Gateway.png")} alt="SIP-T26P" />
                      </div>
                      <div className="text-center">
                        <p style={{ fontSize: "18px", fontFamily: "serif" }}>Multi SIM Gateway</p>
                        <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000" }}>Multi-SIM VoIP GSM/3G/4G Gateway</p>
                        <NavLink exact to="/Multi-SIM-Gateway" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</NavLink>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row gy-4 mb-5">
                  <div className="col-md-4 h-100 ">
                    <div className="border border-dark">
                      <div className="d-flex  justify-content-center align-items-center  ">
                        <img className="w-100" src={require("../images/SIMBank-SIMCloud.png")} alt="SIP-T26P" />
                      </div>
                      <div className="text-center">
                        <p style={{ fontSize: "18px", fontFamily: "serif" }}>SIMBank SIMCloud</p>
                        <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000" }}>Hot swappable SIM cards</p>
                        <NavLink exact to="/SIMBank-SIMCloud" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</NavLink>
                      </div>
                    </div>
                  </div>

                </div>


              </div>
            </div>
          </div>
        </div>
      </section>


      <Footer />
    </>
  )
}
export default GsmVoip;