import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const HA100 = () => {

  return (
    <>
      <Header />


      <section style={{ backgroundColor: "#090909" }}>
        <div className="container">
          <div className="row text-center d-flex  justify-content-center align-items-center">
            <div className="col-lg-6  ">
              <div className="text-lg-start p-lg-2 ps-lg-5">
                <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>UCM6200 series</p>



              </div>
            </div>
            <div className="col-lg-6   text-lg-center">
              <div className="p-lg-2">
                <p className="text-white ">
                  <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home /</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}>Grandstream / IP Pbxs / UCM Series IP PBXs  / <b style={{ fontSize: "16px" }}>UCM6200 series</b>
                  </span></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section>
        <div className="container">
          <div className="container mt-5 ">
            <div className="row">

              <div className="col-lg-4 " >
                <div id="carouselExampleFade" class="carousel slide " data-bs-ride="carousel">
                  <div class="carousel-inner border" style={{ height: "280px" }}>
                    <div class="carousel-item active animated">

                      <img className="w-100" src={require("../images/HA100-slide2.png")} alt="" />
                    </div>
                    <div class="carousel-item">

                      <img className="w-100" src={require("../images/HA100.png")} alt="" />
                    </div>
                    <div class="carousel-item">

                      <img className="w-100" src={require("../images/HA100-slide2.png")} alt="" />
                    </div>
                    <div class="carousel-item">

                      <img className="w-100" src={require("../images/HA100.png")} alt="" />
                    </div>
                  </div>
                  <button class="carousel-control-prev pe-2" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                    <span class="carousel-control-next-icon p-4 bg-dark" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next ps-2" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                    <span class="carousel-control-prev-icon p-4 bg-dark" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>


              <div className="col-lg-6">
                <div>
                  <p className="" style={{ fontSize: "40px", fontFamily: "serif", fontWeight: "600" }} >
                    HA100
                  </p>
                  <p style={{ fontSize: "30px", fontFamily: "serif", fontWeight: "600" }}>
                    UCM Series IP PBXs
                  </p>
                  <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                    The HA100 is ideal for any business that requires an always-on, redundant voice system by providing an automatic failover solution when paired with two UCM6510 IP PBXs. The device will constantly monitor the operational status of both UCM6510s and will automatically switch all system control to the hot-standby secondary UCM6510 when the primary device fails for any reason.                  </p>
                  <NavLink exact to="/Contact-us" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Request a Quote</button></NavLink>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-3">
          <div className="row ps-3">
            <div>
              <p style={{ fontSize: "32px", fontFamily: "serif", color: "#333333", fontWeight: "500" }}>
                Description

                <div className="border border-primary" style={{ width: "55px" }}></div>
                <div className=" mb-2 " style={{ borderBottom: "1px solid lightgray" }}></div>
              </p>
              <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                The GSC3570 is a powerful intercom and facility control station designed to provide businesses with a dedicated device to manage facility communications, door access, physical security and more. This device can be wall-mounted or placed on a desktop as a solution for easy door control, intercom and paging communication, security camera management and facility-wide UC integration. The GSC3570 features a 7-inch touch screen LCD and full duplex 2-way HD audio. It offers a flexible network connectivity through 100Mbps network port with PoE or integrated dual-band Wi-Fi support. This SIP intercom and facility control station is designed to seamlessly integrate with Grandstream’s entire range of products, including the GDS series of Door Access devices, GSC series intercom and paging devices, security cameras and more. The GSC3570 is ideal for any deployment scenario where facility access, communications and security need to be centrally monitored and controlled.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 ">
              <p className="ps-3" style={{ fontSize: "30px", fontFamily: "serif", color: "#333333" }}>
                Features
              </p>
              <ul style={{ fontSize: "16px", fontFamily: "sans-serif", lineHeight: "30px", textAlign: "justify" }}>
                <li>
                  Connects and constantly monitors two UCM6510 together for high availability
                </li>
                <li>
                  Fast 10 to 50 second system switching time depending on the number of registered endpoints
                </li>
                <li>
                  Gratuitous ARP forces SIP endpoints to refresh the MAC address of the new UCM6510 without interruptions
                </li>
                <li>
                  Smart failover solution that automatically switches to a hot-standby secondary UCM6510 if the primary one fails
                </li>
                <li>
                  Up to 14 LED indicators showing real-time status of all of the telecom lines, network links, auxiliary devices, etc
                </li>

              </ul>
            </div>
            <div className="col-lg-6 text-center">
              <div>
                <img src={require("../images/social+image+ucm+logo.png")} className="w-75 p-lg-5  " alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-4">
              <div className="text-center" style={{ backgroundColor: "#F5ECEC", padding: "40px" }} >
                <p style={{ fontSize: "22px", fontFamily: "serif", fontWeight: "600" }}>
                  Quick Enquiry
                </p>
                <p style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                  For the product quotation please fill the given form our team will get back to you shortly after analysing your needs
                </p>

                <div className="pt-3">
                  <NavLink className=" text-decoration-none fs-6" to="/Contact-us" >
                    <span className="btn-round send-btn">SEND ENQUIRY</span>
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="col-lg-8 d-none d-sm-block">
              <div id="carouselExampleIndicators" className="carousel slide " data-bs-ride="carousel" style={{ height: "300px" }}>
                <div className="carousel-indicators sliderbtn">
                  <input style={{ width: "20px", height: "15px" }} type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></input>
                  <input style={{ width: "20px", height: "15px" }} className=' ' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></input>
                  <input style={{ width: "20px", height: "15px" }} className=' ' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></input>
                  <input style={{ width: "20px", height: "15px" }} className=' ' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></input>
                </div>

                <div className="carousel-inner">
                  <div className="carousel-item active animated" data-bs-interval="1500">
                    <div className="row">
                      <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                        <img src={require("../images/img/GVC3210-slide.jpg")} className="w-100" alt="" />
                      </div>
                      <div className="col-lg-6">
                        <div>
                          <p className="m-0 p-0">Full HD Conferencing</p>
                          <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>GVC3210 Video Conferencing </p>
                          <div className="border border-primary" style={{ width: "55px" }}></div>

                          <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}> The GVC3210 is an innovative video conferencing endpoint ideal for small to medium businesses who seek an easy-to-use yet potent video conferencing solution.  </p>

                          <NavLink exact to="/GVC3210" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item" data-bs-interval="1500">

                    <div className="row">
                      <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                        <img src={require("../images/GWN7605.png")} className="w-100" alt="" />
                      </div>
                      <div className="col-lg-6">
                        <div>
                          <p className="m-0 p-0">Networking Solutions</p>
                          <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>Wifi Access Point</p>
                          <div className="border border-primary" style={{ width: "55px" }}></div>

                          <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}> GWN7630 Wi-Fi access point for small to medium sized businesses, multiple floor offices, commercial locations and branch offices. </p>
                          <NavLink exact to="/wifi-access-point" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item" data-bs-interval="1500">

                    <div className="row">
                      <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                        <img src={require("../images/img/GXV3380-slide4.png")} className="w-100" alt="" />
                      </div>
                      <div className="col-lg-6">
                        <div>
                          <p className="m-0 p-0">Video Ip Phone</p>
                          <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>GXV 3380</p>
                          <div className="border border-primary" style={{ width: "55px" }}></div>

                          <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}> The GXV3380 is a powerful High-End Smart Video Phone for Android that combines a 16 line IP phone with a multi-platform video collaboration solution.   </p>
                          <NavLink exact to="/GXV3500" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item" data-bs-interval="1500">

                    <div className="row">
                      <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                        <img src={require("../images/img/UCM6200-slide3.png")} className="w-100" alt="" />
                      </div>
                      <div className="col-lg-6">
                        <div>
                          <p className="m-0 p-0"> UCM series IP PBXs</p>
                          <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>UCM6200 series IP PBX </p>
                          <div className="border border-primary" style={{ width: "55px" }}></div>

                          <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>  UCM6200 series IP PBX appliance combines enterprise-grade voice, video, data, and mobility features in an easy-to-manage solution. </p>
                          <NavLink exact to="/UCM6200-Series" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>




      <Footer />
    </>
  )
}
export default HA100;