import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { NavLink } from "react-router-dom";
const HPEGen104214R = () => {

    return (
        <>
            <Header />

            <section style={{ backgroundColor: "#090909" }}>
                <div className="container">
                    <div className="row text-center d-flex  justify-content-center align-items-center">
                        <div className="col-lg-6  ">
                            <div className="text-lg-start p-lg-2 ps-lg-5">
                            <p className="text-white " style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>HPE ProLiant DL380 Gen10 4214R</p>
                            </div>
                        </div>
                        <div className="col-lg-6   text-lg-center">
                            <div className="p-lg-2">
                                <p className="text-white ">
                                <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home /</NavLink>  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/Servers" > Server /</NavLink><span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}><b style={{ fontSize: "16px" }}>HPE ProLiant DL380 Gen10 4214R</b>
                                    </span></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

         

            <section>
                <div className="container">
                    <div className="container mt-5 ">
                        <div className="row">

                            <div className="col-lg-4 " >
                                <div id="carouselExampleFade" class="carousel slide " data-bs-ride="carousel">
                                    <div class="carousel-inner text-center pt-5 border" style={{ height: "280px" }}>
                                        <div class="carousel-item active animated">

                                            <img className="w-75" src={require("../images/HPE-ProLiant-DL380-Gen10-4208.jpg")} alt="" />
                                        </div>
                                        <div class="carousel-item">

                                            <img className="w-100" src={require("../images/HPE-ProLiant-DL180-Gen10-slide2.jpeg")} alt="" />
                                        </div>

                                    </div>
                                    <button class="carousel-control-prev pe-2" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                                        <span class="carousel-control-next-icon p-4 bg-dark" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next ps-2" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                                        <span class="carousel-control-prev-icon p-4 bg-dark" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>


                            <div className="col-lg-6">
                                <div>
                                    <p className="" style={{ fontSize: "40px", fontFamily: "serif", fontWeight: "600" }} >
                                    HPE ProLiant DL380 Gen10 4214R
                                    </p>

                                    <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                                        The HPE ProLiant DL380 Gen10 4214R is a rack server manufactured by Hewlett Packard Enterprise (HPE). It is part of the popular ProLiant DL380 server series, known for its performance, scalability, and reliability.
                                    </p>
                                    <NavLink exact to="/Contact-us" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Request a Quote</button></NavLink>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-lg-6 ">
                            <p className="ps-3" style={{ fontSize: "30px", fontFamily: "serif", color: "#333333" }}>
                                Features
                            </p>
                            <ul style={{ fontSize: "16px", fontFamily: "sans-serif", lineHeight: "30px", textAlign: "justify" }}>
                                <li>
                                    Item Height: 3.4 Inches
                                </li>
                                <li>
                                    Item Width: 17.5 Inches
                                </li>
                                <li>
                                    Item model number: P20174-B21
                                </li>
                                <li>
                                    Processor Brand: Intel
                                </li>
                                <li>
                                    Processor Type: Xeon
                                </li>
                                <li>
                                    Processor Speed: 2.2 GHz
                                </li>
                                <li>
                                    Product Dimensions: 67.82 x 44.45 x 8.64 cm; 19.5 Kilograms
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 text-center">
                            <div>
                                <img src={require("../images/HPE-ProLiant-DL20-Gen10-E-2224.jpg")} className="w-100 " alt="HPE-ProLiant-DL20-Gen10-E-2224" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}
export default HPEGen104214R;