import React from "react";
import "./Header.css";
import { NavLink } from "react-router-dom";

const Header = () => {

    document.addEventListener('DOMContentLoaded', function () {
        var dropdownBtn = document.querySelector('.dropdown-btn');
        var dropdownContent = document.querySelector('.dropdown-content');

        dropdownBtn.addEventListener('click', function () {
            if (dropdownContent.style.display === 'block') {
                dropdownContent.style.display = 'none';
            } else {
                dropdownContent.style.display = 'block';
            }
        });
    });



    return (
        <>
            <section>
                <div className="container-fluid  d-none d-sm-block" style={{ backgroundColor: "rgb(24,97,145)" }}>
                    <div className="container">
                        <div className="row text-center d-flex justify-content-center align-items-center">
                            <div className="col-lg-4 text-lg-start">

                                <div className="pt-2 ">
                                    <div className="pt-1" id="google_translate_element"></div>
                                </div>

                            </div>
                            <div className="col-lg-4 ">

                                <div className="" style={{ fontSize: "13px", fontFamily: 'sans-serif' }}>
                                    <a className=" email1 text-decoration-none text-white " href="http://wa.me/9579713010"><i className="text-white fs-5 fa-brands fa-whatsapp"></i>
                                        &nbsp; +91 9579-713-010</a>&nbsp; &nbsp;

                                    <a className=" email2 text-decoration-none text-white  " href="mailto:info@skywardtelecom.co.in"><span className="text-decoration-none text-white">&nbsp;  <i className="text-white fs-6 fa fa-envelope-o fa-lg" aria-hidden="true"></i>&nbsp;  info@skywardtelecom.co.in</span></a>
                                </div>
                            </div>
                            <div className="col-lg-4 text-lg-end" style={{ fontSize: "13px", fontFamily: 'sans-serif' }}>
                                <div className="">
                                    <NavLink> <i className="fa-brands fa-instagram text-white fs-5"></i></NavLink>
                                    &nbsp;&nbsp;&nbsp; &nbsp;

                                    <NavLink to="https://www.facebook.com/profile.php?id=100085546361027&mibextid=ZbWKwL" target="_blank" ><i className="fa-brands text-white fa-facebook fs-5"></i></NavLink>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container">
                    <div className="row ps-lg-5 pe-lg-5">
                        <nav className='navbar navbar-expand-lg p-0 d-flex justify-content-lg-end justify-content-around align-items-center ' style={{ fontWeight: "500" }}>
                            <div className="text-end d-flex ps-lg-5 justify-content-end">
                                <NavLink class="navbar-brand ms-lg-5 ps-lg-5 text-lg-center " to="/">
                                    <img style={{ height: "4rem" }} src={require("../images/Skyward-Logo.png")} alt="icloudsoft_logo" />
                                </NavLink>
                            </div>
                            <button className="navbar-toggler " type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars"></i>
                            </button>
                            <div class="collapse navbar-collapse  h-100   ps-lg-3 ms-lg-5 " id="navbarNavDropdown">
                                <ul class="navbar-nav w-100 mt-3  bg-body d-flex justify-content-lg-around align-items-lg-center text-lg-center">
                                    <li class="nav-items ">
                                        <NavLink className="Header text-decoration-none text-white" to="/" >
                                            Home
                                        </NavLink>
                                    </li>
                                    <li className=" nav-items  #submenu" data-bs-toggle="collapse" aria-current="true" >

                                        <span className="dropdown-toggle nav-i  " id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true">Products</span>

                                        <ul className="dropdown-menu  collapse w-100" aria-labelledby="mega-one" style={{ zIndex: "6", }} id="submenu">
                                            <li className='nav-items  text-white'>
                                                <div className='container' style={{ fontFamily: "sans-serif" }}>
                                                    <div className="row d-flex justify-content-around ">
                                                        <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">

                                                            <NavLink className='text-decoration-none' to="/Networksolution">
                                                                <div className='dropdown-item text-navy  fw-bold' style={{ fontSize: "15px" }}>NETWORK SOLUTION</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>

                                                            <NavLink className='text-decoration-none' to="/Servers">
                                                                <div className='dropdown-item' style={{ fontSize: "14px" }}>Server</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>


                                                            <NavLink className='text-decoration-none' to="/Switches" >
                                                                <div className='dropdown-item ' style={{ fontSize: "14px" }}>Switches</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>

                                                            <NavLink className='text-decoration-none' to="/Firewall" >
                                                                <div className='dropdown-item ' style={{ fontSize: "14px" }}>Firewall</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>

                                                            <NavLink className='text-decoration-none' to="/wifi-access-point" >
                                                                <div className='dropdown-item ' style={{ fontSize: "14px" }}>Wifi Access Point</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>


                                                            <NavLink className='text-decoration-none' to="/cloud-management" >
                                                                <div className='dropdown-item ' style={{ fontSize: "14px" }}>Cloud Management</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                        </div>

                                                        <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3" style={{ fontSize: "14px" }}>

                                                            <NavLink className='text-decoration-none' to="/BusinessConference">
                                                                <div className='dropdown-item text-navy' style={{ fontSize: "15px" }}><b>BUSINESS CONFERENCING</b></div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/FullHD-Conferencing">
                                                                <div className='dropdown-item' >Video Conferencing</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/Audio-Conferencing" >
                                                                <div className='dropdown-item'>Audio Conferencing</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>


                                                        </div>

                                                        <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3 " style={{ fontSize: "14px" }}>

                                                            <NavLink className='text-decoration-none'  >
                                                                <div className='dropdown-item ' style={{ fontSize: "15px" }}><b>CCTV(IP SERVEILLANCE)</b></div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/PTZ-Camera" >
                                                                <div className='dropdown-item '>PTZ Camera</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/SIP-Camera" >
                                                                <div className='dropdown-item '>SIP Camera</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/IP Video Encoders/Decoders" >
                                                                <div className='dropdown-item'>IP Video Encoder</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                        </div>

                                                        <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3" style={{ fontSize: "14px" }}>
                                                            <NavLink className='text-decoration-none' to="/Gateway-ATAs" >
                                                                <div className='dropdown-item text-navy fs-6' style={{ fontSize: "15px" }}><b>GATEWAY & ATAS</b></div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/GSM-VoIP-Gateway" >
                                                                <div className='dropdown-item'>GSM GAteway</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/product-fxo-gateway" >
                                                                <div className='dropdown-item'>FXO Gateway</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/product-fxs-gateway" >
                                                                <div className='dropdown-item'>FXS Gateway</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/Analog-Telephone-Adapter" >
                                                                <div className='dropdown-item'>ATA's</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>

                                                        </div>



                                                        <div className="row d-flex justify-content-start ">
                                                            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3" style={{ fontSize: "14px" }}>

                                                                <NavLink className='text-decoration-none' to="">
                                                                    <div className='dropdown-item text-navy' style={{ fontSize: "15px" }}><b>HEADSETS</b></div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                                <NavLink className='text-decoration-none' to="/Wired-Headset">
                                                                    <div className='dropdown-item'>Wired Headsets</div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                                <NavLink className='text-decoration-none' to="/Wireless-Headset" >
                                                                    <div className='dropdown-item'>Wireless Headsets</div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                                <NavLink className='text-decoration-none' to="/Speaker-Phone" >
                                                                    <div className='dropdown-item'>Speaker Phone</div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                                <NavLink className='text-decoration-none' to="/Grandstream-Headsets" >
                                                                    <div className='dropdown-item'>Granstream Headsets</div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>



                                                            </div>

                                                            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3" style={{ fontSize: "14px" }}>

                                                                <NavLink className='text-decoration-none' to="">
                                                                    <div className='dropdown-item text-navy ' style={{ fontSize: "15px" }}><b>INTERCOMS</b></div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                                <NavLink className='text-decoration-none' to="/control-station">
                                                                    <div className='dropdown-item'>Control Station</div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                                <NavLink className='text-decoration-none' to="/IP-Door-Intercom" >
                                                                    <div className='dropdown-item'>IP Door Intercom</div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                                <NavLink className='text-decoration-none' to="/Intercoms-Paging" >
                                                                    <div className='dropdown-item'>SIP Speaker Intercom</div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                            </div>

                                                            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3" style={{ fontSize: "14px" }}>
                                                                <NavLink className='text-decoration-none' to="">
                                                                    <div className='dropdown-item text-navy ' style={{ fontSize: "15px" }}><b>IP PBX</b></div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                                <NavLink className='text-decoration-none' to="/IP-PBX" >
                                                                    <div className='dropdown-item'>High Density</div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                                <NavLink className='text-decoration-none' to="/SME-IP-PBX" >
                                                                    <div className='dropdown-item'>SME IP PBX</div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                                <NavLink className='text-decoration-none' to="/HA-BOX" >
                                                                    <div className='dropdown-item'>HA Box</div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                            </div>


                                                            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3 " style={{ fontSize: "14px" }}>

                                                                <NavLink className='text-decoration-none' to="/Ip-phone" >
                                                                    <div className='dropdown-item text-navy' style={{ fontSize: "15px" }}><b>IP PHONES</b> </div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                                <NavLink className='text-decoration-none' to="/Executive-IP-Phone" >
                                                                    <div className='dropdown-item'>Executive IP Phone</div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                                <NavLink className='text-decoration-none' to="/Hotel-Phones" >
                                                                    <div className='dropdown-item'>Hotel Phones</div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/Video-IP-Phones" >
                                                                    <div className='dropdown-item'>Video IP Phones</div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/DectCordless" >
                                                                    <div className='dropdown-item'>DECT Cordless</div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/wifi-cordless" >
                                                                    <div className='dropdown-item'>Wifi Cordless</div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className=" nav-items #submenu2  dropdown-hover " data-bs-toggle="collapse" aria-current="true" >
                                        <span className="dropdown-toggle nav-i" id="mega-one2" role="button" data-bs-toggle="dropdown" aria-expanded="true" >Brands</span>
                                        <ul className="dropdown-menu  collapse   w-100 " aria-labelledby="mega-one2" style={{ zIndex: "6", right: '0px' }} id="submenu2">
                                            <li className='nav-item  text-white'>
                                                <div className='container' style={{ fontFamily: "sans-serif" }}>
                                                    <div className="row d-flex justify-content-around">
                                                        <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3 " style={{ fontSize: "14px" }}>
                                                            <NavLink className='text-decoration-none' to="/Grandstream">
                                                                <div className='dropdown-item text-navy ' style={{ fontSize: "15px" }}><b>GRANDSTREAM</b></div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/ip-voice-telephony" >
                                                                <div className='dropdown-item'>IP Voice Telephony</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/Gateway-ATAs" >
                                                                <div className='dropdown-item'>Gateways & ATAs</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/BusinessConference" >
                                                                <div className='dropdown-item'>Business Conferencing</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/physical-security" >
                                                                <div className='dropdown-item'>Physical Security</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/Networksolution" >
                                                                <div className='dropdown-item'>Networking solutions</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/IP-PBX" >
                                                                <div className='dropdown-item'>IP PBX</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3 " style={{ fontSize: "14px" }}>
                                                            <NavLink className='text-decoration-none' to="/NEC" >
                                                                <div className='dropdown-item text-navy fs-6' style={{ fontSize: "15px" }}><b>NEC</b> </div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/NEC" >
                                                                <div className='dropdown-item'>Voice</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/NEC" >
                                                                <div className='dropdown-item'>Phones </div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/NEC" >
                                                                <div className='dropdown-item'>Collaboration</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>

                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3" style={{ fontSize: "14px" }}>
                                                            <NavLink className='text-decoration-none' to="/Alcatel" >
                                                                <div className='dropdown-item text-navy ' style={{ fontSize: "15px" }}><b>ALCATEL</b></div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/Alcatel" >
                                                                <div className='dropdown-item'>Voice</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/Alcatel" >
                                                                <div className='dropdown-item'>Data </div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/Alcatel" >
                                                                <div className='dropdown-item'>Collaboration</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/Alcatel" >
                                                                <div className='dropdown-item'>Phones </div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3 " style={{ fontSize: "14px" }}>
                                                            <NavLink className='text-decoration-none' to="/Dinstar" >
                                                                <div className='dropdown-item text-navy ' style={{ fontSize: "15px" }}><b>DINSTAR</b></div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/Analog-VoIP-Gateway" >
                                                                <div className='dropdown-item'>Analog VoIP Gateway</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/GSM-VoIP-Gateway" >
                                                                <div className='dropdown-item'>GSM VoIP Gateway</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/Digital-VoIP-Gateway" >
                                                                <div className='dropdown-item'>Digital VoIP Gateway</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/SME-IP-PBX" >
                                                                <div className='dropdown-item'>SME IP PBX</div>
                                                                <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                            </NavLink>

                                                        </div>
                                                        <div className="row d-flex justify-content-start">
                                                            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3" style={{ fontSize: "14px" }}>
                                                                <NavLink className='text-decoration-none' to="/Yealink">
                                                                    <div className='dropdown-item text-navy fs-6' style={{ fontSize: "15px" }}><b>YEALINK</b></div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                                <NavLink className='text-decoration-none' to="/Ip-video-phone">
                                                                    <div className='dropdown-item'>IP Video Phone</div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                                <NavLink className='text-decoration-none' to="/Ip-phone" >
                                                                    <div className='dropdown-item'>IP Phone </div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                                <NavLink className='text-decoration-none' to="/Ip-dect-phone" >
                                                                    <div className='dropdown-item'>IP Dect Phone </div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>

                                                            </div>

                                                            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3" style={{ fontSize: "14px" }}>
                                                                <NavLink className='text-decoration-none' to="/Poly">
                                                                    <div className='dropdown-item text-navy fs-6' style={{ fontSize: "15px" }}><b>POLY</b></div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/Speaker-Phone">
                                                                    <div className='dropdown-item'>Speaker Phones</div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                                <NavLink className='text-decoration-none' to="/Wireless-Headset" >
                                                                    <div className='dropdown-item'>Wireless Headsets </div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                                <NavLink className='text-decoration-none' to="/Wired-Headset" >
                                                                    <div className='dropdown-item'>Wired Headsets </div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                            </div>
                                                            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3" style={{ fontSize: "14px" }}>
                                                                <NavLink className='text-decoration-none' to="/Cisco">
                                                                    <div className='dropdown-item text-navy' style={{ fontSize: "15px" }}><b>CISCO</b></div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                                <NavLink className='text-decoration-none' to="/Cisco-servers">
                                                                    <div className='dropdown-item'>Server</div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                                <NavLink className='text-decoration-none' to="/Cisco-switches" >
                                                                    <div className='dropdown-item'>Switches </div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                                <NavLink className='text-decoration-none' to="/Cisco-firewall" >
                                                                    <div className='dropdown-item'>Firewall</div>
                                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                                </NavLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="nav-items ">
                                        <NavLink className=" nav-i  text-decoration-none " to="/Solution" >
                                            Solutions
                                        </NavLink>
                                    </li>

                                    <li className=" nav-items #submenu2  dropdown-hover " data-bs-toggle="collapse" aria-current="true" >
                                        <span className="dropdown-toggle nav-i" id="mega-one2" role="button" data-bs-toggle="dropdown" aria-expanded="true" >Services</span>
                                        <ul className="dropdown-menu  collapse text-lg-center " aria-labelledby="mega-one2" style={{ zIndex: "6", left: '60%', }} id="submenu2">
                                            <li className='nav-item  text-white '>

                                                <li> <NavLink className='text-decoration-none' to="/Consultation" >
                                                    <div className='dropdown-item'>Consultation</div>
                                                    <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                </NavLink></li>
                                                <li>
                                                    <NavLink className='text-decoration-none' to="/Configuration" >
                                                        <div className='dropdown-item'>Configuration</div>
                                                        <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink className='text-decoration-none' to="/Installations" >
                                                        <div className='dropdown-item'>Installations</div>
                                                        <div className="" style={{ borderBottom: '1px dashed #d3d3d3' }} ></div>
                                                    </NavLink>
                                                </li>

                                                <li>
                                                    <NavLink className='text-decoration-none' to="/Support" >
                                                        <div className='dropdown-item'>Support</div>
                                                    </NavLink>
                                                </li>

                                            </li>
                                        </ul>
                                    </li>


                                    <li class="nav-items">
                                        <NavLink className=" nav-i  text-decoration-none " to="/Blog" >
                                            Blog
                                        </NavLink>
                                    </li>
                                    <li class="nav-items">
                                        <NavLink className="nav-i   text-decoration-none " to="/About-Us" >
                                            About Us
                                        </NavLink>
                                    </li>
                                    <li class="nav-items  ">
                                        <NavLink className=" nav-i  text-decoration-none " to="/Contact-us" >
                                            Contact us
                                        </NavLink>
                                    </li>

                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </section>

        </>
    )
}
export default Header;


