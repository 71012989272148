import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const Fxofxs = () => {

  return (
    <>
      <Header />


      <section>
      <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/header-bnr.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}> 
          <div className="container">
            <div className="row  text-center d-flex  justify-content-center align-items-center">
              <div className="col-lg-6 text-lg-start">
                <div className="pt-lg-5 pb-lg-5">
                  <p className="text-white " style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>FXO & FXS Gateway</p>

                </div>
              </div>
              <div className="col-lg-6   text-lg-center">
                <div className=" pt-lg-5 pb-lg-5">
                  <p className="text-white ">
                    <span className=""><NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}> <NavLink exact to="/Dinstar" className="text-white">/Dinstar</NavLink> / FXO & FXS Gateway </span></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid pt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-3 d-none d-sm-block">
                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>BRANDS</span>
                <div className="mb-1"></div>
                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                <div className="border border-white border-opacity-10 mb-2 "></div>
                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                  <NavLink exact to="/grandstream" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; Grandstream</p></NavLink>
                  <NavLink exact to="/Dinstar" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Dinstar</p></NavLink>
                  <NavLink exact to="/NEC" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; NEC</p></NavLink>
                  <NavLink exact to="/Alcatel" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Alcatel</p></NavLink>
                  <NavLink exact to="/YeaLink" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;YeaLink</p></NavLink>
                  <NavLink exact to="/Poly" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Poly</p></NavLink>
                  <NavLink exact to="/Cisco" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Cisco</p></NavLink>

                </p>

                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>SOLUTIONS</span>
                <div className="mb-1"></div>
                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                <div className="border border-white border-opacity-10 mb-2 "></div>
                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                  <NavLink exact to="/Enterprise-Manufacturing" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Enterprise-Manufacturing</p></NavLink>
                  <NavLink exact to="/Security&Surveillance" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Security & Surveillance</p></NavLink>
                  <NavLink exact to="/EducationHospitality" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}> Education & Hospitality</p></NavLink>
                  <NavLink exact to="/BusinessConference" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Business Conference</p></NavLink>

                </p>

                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">

                  <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/GWN7605">
                          <img src={require("../images/GWN7605.png")} style={{ width: "263px" }} alt="GWN7605" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>WiFi Access Point (GWN7605)</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream's powerful WiFi Access Points offer high performance networking, tremendous WiFi coverage range read more.. </p>
                        </NavLink>
                      </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/BusinessConference">
                          <img src={require("../images/Business-Conferencing.png")} style={{ width: "263px" }} alt="Business-Conferencing" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Business Conferencing</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream’s video conferencing solutions offer small and medium sized businesses flexibility read more..</p>
                        </NavLink>
                      </div>            </div>
                    <div className="carousel-item" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/wp810">
                          <img src={require("../images/wifi-cordless.png")} style={{ width: "263px" }} alt="wifi-cordless" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Wi-Fi IP Phone (WP 810)</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>WP810 is a portable Wi-Fi IP phone designed to suit a variety of enterprises  Read More..</p>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">

                <p style={{ fontSize: "34px", color: "#333", fontFamily: "serif" }}>FXO & FXS Gateway</p>
                <p style={{ fontSize: "26px", fontFamily: 'serif', color: "#333", fontWeight: "500" }}>Explore supreme quality analog cards by Cloud Infotech tailor made to meet all your communication verticals!</p>
                <div className="mt-4" style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                  <p>
                    Analog cards are soon becoming the talk of the technological town. These cards offer first-rate audio quality in telephones. In fact, these analog cards can undoubtedly be called a revolution in the world of communication. And Cloud Infotech brings this technologically supreme product right at your doorstep! Analog cards can also be defined as hardware essentials in a telephone systems which allows the systems to connect well with your computers. Moreover, these cards can also be known as the circuit boards which can easily fit into the computer’s PCI slots.
                  </p>
                  <p><b style={{ color: "#666666" }}>What are the benefits of using these cards?</b> Well, these cards can be used for different purposes, such as call recording, analog-VoIP conversion, dial dictation, and DTMF tone detection. In addition, FXS/FXO interchangeable ports can eliminate the need for channels or access gateways.</p>
                  <p><b style={{ color: "#666666" }}>What are FXO or FXS ports?</b> Well these are the ports used by analog telephone lines. While FXS is a port which deliver the analog lines to the subscribers successfully, FXO is the port receiving the analog. Thus, whenever you want to connect the analog phone lines to your IP phone system, you need these FXS and FXO ports.</p>
                  <p>Analog phone cards have surely made communication easier. They are highly useful for any enterprise set up. They are cost effective and offer many effectual phone features. Some of these include call divert, redial, call transfer, and caller ID.
                    So, tackle your communication issues with analog cards offered by Cloud Infotech. Pick that phone and call us right now and order your analog cards right now!</p>
                </div>

                <div className="row g-3 mb-5">
                  <div className="col-md-4 h-100">
                    <div className=" border border-dark">
                      <div className="d-flex  justify-content-center align-items-center  ">
                        <img className="w-100" src={require("../images/DAG2000-fxs-fxo.png")} alt="DAG2000-fxs-fxo" />
                      </div>
                      <div className="text-center">
                        <NavLink exact to="/DAG2000-8S8O-FXS-FXO-MIXED"><p style={{ fontSize: "18px", fontFamily: "serif",fontWeight:"600" }}>DAG2000-8S8O FXS/FXO <br /> MIXED</p></NavLink>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 h-100">
                    <div className=" border border-dark">
                      <div className="d-flex  justify-content-center align-items-center  ">
                        <img className="w-100" src={require("../images/DAG1000-fxs-fxo.png")} alt="DAG1000-fxs-fxo" />
                      </div>
                      <div className="text-center">
                        <NavLink exact to="/DAG1000-4S4O-FXS-FXO-MIXED"><p style={{ fontSize: "18px", fontFamily: "serif",fontWeight:"600" }}>DAG1000-4S4O FXS/FXO <br /> MIXED</p>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <Footer />
    </>
  )

}
export default Fxofxs;