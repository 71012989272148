import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import "./NEC.css";

const NEC = () => {


  const [display, setDisplay] = useState("key_Featured");

  const [btn1, setBtn1] = useState("newStyle");

  const [btn2, setBtn2] = useState("btnStyle");

  const [btn3, setBtn3] = useState("newwStyle");



  const changleDisplay = () => {

    setDisplay("key_Featured")
    setBtn1("newStyle")
    setBtn2("btnStyle")
    setBtn3("newwStyle")


  }

  const changleDisplay1 = () => {

    setDisplay("Benefits")
    setBtn2("newStyle");
    setBtn3("newwStyle")

  }

  const changleDisplay2 = () => {

    setDisplay("features")
    setBtn2("newStyle");
    setBtn3("newwStyle")

  }


  return (
    <>
      <Header />

      <section style={{ backgroundColor: "#25272e" }}>
        <div className="container " >
          <div className="row text-center ">
            <div className="col-lg-6 p-lg-2">
              <div className="text-lg-start ps-lg-5 ">
                <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>NEC</p>

              </div>
            </div>
            <div className="col-lg-6 p-lg-2  text-lg-center">
              <div className=" ">
                <p className="text-white">
                  <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home /</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}>Brand / NEC
                  </span></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid pt-5 mb-3">
          <div className="container">
            <div className="row">
              <div className="col-md-3 d-none d-sm-block">
                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>BRANDS</span>
                <div className="mb-1"></div>
                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                <div className="border border-white border-opacity-10 mb-2 "></div>
                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                  <NavLink exact to="/grandstream" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; Grandstream</p></NavLink>
                  <NavLink exact to="/Dinstar" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Dinstar</p></NavLink>
                  <NavLink exact to="/NEC" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; NEC</p></NavLink>
                  <NavLink exact to="/Alcatel" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Alcatel</p></NavLink>
                  <NavLink exact to="/YeaLink" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;YeaLink</p></NavLink>
                  <NavLink exact to="/Poly" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Poly</p></NavLink>
                  <NavLink exact to="/Cisco" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Cisco</p></NavLink>
                </p>

                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>SOLUTIONS</span>
                <div className="mb-1"></div>
                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                <div className="border border-white border-opacity-10 mb-2 "></div>
                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                  <NavLink exact to="/Enterprise-Manufacturing" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Enterprise-Manufacturing</p></NavLink>
                  <NavLink exact to="/Security&Surveillance" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Security & Surveillance</p></NavLink>
                  <NavLink exact to="/EducationHospitality" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}> Education & Hospitality</p></NavLink>
                  <NavLink exact to="/BusinessConference" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Business Conference</p></NavLink>

                </p>

                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">

                  <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/GWN7605">
                          <img src={require("../images/GWN7605.png")} style={{ width: "263px" }} alt="GWN7605" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>WiFi Access Point (GWN7605)</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream's powerful WiFi Access Points offer high performance networking, tremendous WiFi coverage range read more.. </p>
                        </NavLink>
                      </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/BusinessConference">
                          <img src={require("../images/Business-Conferencing.png")} style={{ width: "263px" }} alt="Business-Conferencing" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Business Conferencing</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream’s video conferencing solutions offer small and medium sized businesses flexibility read more..</p>
                        </NavLink>
                      </div>            </div>
                    <div className="carousel-item" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/wp810">
                          <img src={require("../images/wifi-cordless.png")} style={{ width: "263px" }} alt="wifi-cordless" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Wi-Fi IP Phone (WP 810)</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>WP810 is a portable Wi-Fi IP phone designed to suit a variety of enterprises  Read More..</p>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">

                <div className="mt-3">
                  <p className="" style={{ color: "#333333", fontSize: '27px', fontFamily: "sans-serif", fontWeight: "600" }}>
                    Meet NEC
                  </p>
                </div>

                <div className="" style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                  <p>
                    NEC Leads the Enterprise Telephony Asia Pacific Market with a share of 18%, completely overshadowing Panasonic, Siemens & other competing brands! NEC is a market leader with Enterprise Telephony Market share of 34.1%. Skyward is a Gold Partner and the largest distributor of NEC in India & contributes for around 60 % of its overall PNS revenue.
                  </p>
                </div>


 
                <div className="ps-3">
                  <div className="row">
                    <div className="col-md-6 p-0 d-flex " >
                      <button style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "600" }} className={btn1} onClick={changleDisplay}>VOICE</button>
                      <button style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "600" }} className={btn2} onClick={changleDisplay1}>PHONE</button>
                      <button style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "600" }} className={btn3} onClick={changleDisplay2}>COLLABORATION</button>

                    </div>
                  </div>
                  <div className="row border ">
                    <div>
                      {


                        display === 'effects' ?
                          <div>
                            <div className="row m-4">

                              <div className="col-md-4 h-100">
                                <div className=" border border-dark">
                                  <div className="  d-flex  justify-content-center align-items-center  ">
                                    <img className="w-100 p-3" src={require("../images/Cloud-Virtual-Server.png")} alt="Cloud Virtual Server" />

                                  </div>
                                  <div className="text-center">
                                    <p style={{ fontSize: "18px", fontFamily: "serif", fontWeight: "600" }}>Cloud Virtual Server</p>
                                    <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000" }}>A cloud server is a virtual or physical server, hosted remotely by a cloud service provider, that customers create or access via an internet connection.</p>
                                    <NavLink exact to="/Cloud-Virtual-Server" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</NavLink>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 h-100">
                                <div className=" border border-dark">
                                  <div className="  d-flex  justify-content-center align-items-center  ">
                                    <img className="w-100  p-3" src={require("../images/Power-Virtual-Server.png")} alt="Power-Virtual-Server" />
                                  </div>
                                  <div className="text-center">
                                    <p style={{ fontSize: "18px", fontFamily: "serif", fontWeight: "600" }}>Power-Virtual-Server</p>
                                    <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000" }}>A cloud server is a virtual or physical server, hosted remotely by a cloud service provider, that customers create or access via an internet connection.</p>
                                    <NavLink exact to="/Power-Virtual-Server" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</NavLink>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4 h-100">
                                <div className=" border border-dark">
                                  <div className="  d-flex  justify-content-center align-items-center  ">
                                    <img className="w-100 p-3" src={require("../images/EPS.png")} alt="Enterprise-Server" />

                                  </div>
                                  <div className="text-center">
                                    <p style={{ fontSize: "18px", fontFamily: "serif", fontWeight: "600" }}>Enterprise-Server</p>
                                    <p className="" style={{ marginBottom: "34px", fontSize: "12px", fontFamily: "sans-serif", color: "#000000" }}>Built to handle mission-critical workloads while maintaining security, reliability and control of your entire IT infrastructure </p>
                                    <NavLink exact to="/Enterprise-Server" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</NavLink>
                                  </div>

                                </div>
                              </div>
                            </div>

                          </div>


                          : display === "key_Featured" ?
                            <div className="m-2 ">
                              <div className="container">
                                <div className="row g-3 mb-4">
                                  <div className="col-lg-4 h-100 ">
                                    <div className="border p-2 border-dark">
                                      <div className="d-flex  justify-content-center align-items-center  ">
                                        <img className="w-100" src={require("../images/NEC-SV9300.jpg")} alt="HP-Integrity-RX3600-Server" />
                                      </div>
                                      <div className="text-center">
                                        <p style={{ fontSize: "18px", fontFamily: "serif", fontWeight: '600' }}>NEC SV9300</p>
                                        <p className="ps-2 pe-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>Our UNIVERGE SV9300 platform is a powerful communications solution that is designed to fit your needs, and provides competitive businesses with the high-efficiency and easy-to-deploy technology that they require.</p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4 h-100 ">
                                    <div className="border p-2 border-dark">
                                      <div className="d-flex  justify-content-center align-items-center  ">
                                        <img className="w-100" src={require("../images/NEC-SV9100.jpg")} alt="NEC-SV9100" />
                                      </div>
                                      <div className="text-center">
                                        <p style={{ fontSize: "18px", fontFamily: "serif", fontWeight: "600" }}>NEC SV9100</p>
                                        <p className="ps-2 pe-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>SV9100 is the Unified Communications (UC) solution of choice for small and medium businesses (SMBs) who don’t want to be left behind. Designed to fit your unique needs.</p>
                                        <br />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4 h-100 ">
                                    <div className="border  p-2 border-dark">
                                      <div className="d-flex  justify-content-center align-items-center  ">
                                        <img className="w-100" src={require("../images/NEC-SL2100.jpg")} alt="NEC-SL2100" />
                                      </div>
                                      <div className="text-center">
                                        <p style={{ fontSize: "18px", fontFamily: "serif", fontWeight: "600" }}>NEC-SL2100</p>
                                        <p className="ps-2 pe-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>The SL2100 is highly cost effective because there is more built-in. With VoIP capabilities and a complete Unified Communications (UC) client available right from your web browser.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 h-100 ">
                                    <div className="border p-2 border-dark">
                                      <div className="d-flex  justify-content-center align-items-center  ">
                                        <img className="w-100" src={require("../images/NEC-SV9500.jpg")} alt="NEC-SV9500" />
                                      </div>
                                      <div className="text-center">
                                        <p style={{ fontSize: "18px", fontFamily: "serif", fontWeight: "600" }}>NEC-SV9500</p>
                                        <p className="ps-2 pe-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>The UNIVERGE SV9500 Communications Server is a robust, feature-rich system that is ideal for large enterprises and geographically distributed businesses.</p>
                                      </div>
                                    </div>
                                  </div>


                                </div>

                              </div>
                            </div>
                            : display === "Benefits" ?
                              <div>
                                <div className="row g-3 m-3">
                                  <div className="col-lg-4 h-100 text-center ">
                                    <div className=" border">
                                      <img src={require("../images/NEC-SV80-00DECT.jpg")} className="w-100 mt-2" alt="" />
                                      <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }} >NEC SV8000 DECT</p>
                                      <p className="p-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>NEC’s Digital DECT phones work seamlessly with NEC’s UNIVERGE SV8000 Series communications servers. Installing Digital DECT is easy, and both data and network remain secure.</p>
                                    </div>

                                  </div>

                                  <div className="col-lg-4 text-center ">
                                    <div className=" border">
                                      <img src={require("../images/NEC-UT880.jpg")} className="w-100 mt-2" alt="" />
                                      <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }} >NEC UT880</p>
                                      <p className="p-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>IP Touch Screen Phone with 7-inch color display revolutionizes your calling experience The UT880 provides you with the full functionality of an IP desktop telephone.</p>
                                    </div>

                                  </div>

                                  <div className="col-lg-4 text-center ">
                                    <div className=" border">
                                      <img src={require("../images/NEC-DT700.jpg")} className="w-100 mt-2" alt="" />
                                      <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }} >NEC DT700</p>
                                      <p className="p-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>NEC’s UNIVERGE IP Desktop Terminal (DT700 series) helps employees be even more efficient in those organizations that choose the benefits offered by IP telephony.</p>
                                    </div>

                                  </div>

                                  <div className="col-lg-4 mt-3 text-center">
                                    <div className=" border">
                                      <img src={require("../images/NEC-DT-830-CG.jpg")} className="w-100 mt-2" alt="" />
                                      <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }} >NEC DT 830-CG</p>
                                      <p className="p-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>IP phone with a large color display, crystal-clear high definition voice and wide range of features.</p>
                                    </div>

                                  </div>

                                  <div className="col-lg-4 mt-3 text-center">
                                    <div className=" border">
                                      <img src={require("../images/NEC-T-830-830-G.jpg")} className="w-100 mt-2" alt="" />
                                      <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }} >NEC T-830/830-G</p>
                                      <p className="p-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>Economical entry level IP telephones suitable for most any business environment.</p>
                                    </div>

                                  </div>
                                  <div className="col-lg-4 mt-3 text-center">
                                    <div className=" border">
                                      <img src={require("../images/NEC-DT820.jpg")} className="w-100 mt-2" alt="" />
                                      <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }} >NEC DT820</p>
                                      <p className="p-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>User friendly interface IP phone ideal for almost any work environment: DT820 Desi-less.</p>
                                    </div>

                                  </div>
                                  <div className="col-lg-4 mt-3 text-center">
                                    <div className=" border">
                                      <img src={require("../images/NEC-DT750.jpg")} className="w-100 mt-2" alt="" />
                                      <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }} >NEC DT750</p>
                                      <p className="p-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>An extraordinary user experience for executives and top-level managers.</p>
                                    </div>

                                  </div>
                                </div>
                              </div>
                              : display === 'features' ?

                                <div>
                                  <div className="row">
                                    <div className="col-lg-4 text-center">
                                      <div className="m-3 border">
                                        <img src={require("../images/NEC-U3C.jpg")} className="img-fluid" alt="" />
                                        <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }}>NEC U3C</p>
                                        <p className="ps-2 pe-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>NEC’s UNIVERGE 3C redefines the way your business and individual employees communicate. This comprehensive solution provides you with the necessary agility for today’s ever-changing work environment.</p>

                                      </div>
                                    </div>
                                  </div>
                                </div>



                                : <div>
                                  somethings went wrong
                                </div>
                      }
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}
export default NEC;