import React from "react";
import Footer from "./Footer";
import { NavLink } from "react-router-dom";
import Header from "./Header";


const PracticaSP12 = () => {

  return (
    <>
      <Header />


      <section style={{ backgroundColor: "#090909" }}>
        <div className="container">
          <div className="row text-center d-flex  justify-content-center align-items-center">
            <div className="col-lg-6  ">
              <div className="text-lg-start p-lg-2 ps-lg-5">
                <p className="text-white " style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>Practica SP 12
                </p>
              </div>
            </div>
            <div className="col-lg-6   text-lg-center">
              <div className="p-lg-2">
                <p className="text-white ">
                  <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home /</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}>Plantronics / Wired Headset / Practica SP 12
                  </span></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>






      <section>
        <div className="container">
          <div className="container mt-5 ">
            <div className="row">

              <div className="col-lg-4 " >
                <div id="carouselExampleFade" class="carousel slide " data-bs-ride="carousel">
                  <div class="carousel-inner text-center border" style={{ height: "280px" }}>
                    <div class="carousel-item active animated">
                      <img className=" w-100" src={require("../images/Practica-SP-12.png")} alt="" />
                    </div>

                    <div class="carousel-item">
                      <img className=" w-100" src={require("../images/Practica-SP-12.png")} alt="" />
                    </div>
                    <div class="carousel-item active animated">
                      <img className=" w-100" src={require("../images/Practica-SP-12.png")} alt="" />
                    </div>

                  </div>
                  <button class="carousel-control-prev pe-2" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                    <span class="carousel-control-next-icon p-4 bg-dark" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next ps-2" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                    <span class="carousel-control-prev-icon p-4 bg-dark" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>


              <div className="col-lg-6">
                <div>
                  <p className="" style={{ fontSize: "40px", fontFamily: "serif", fontWeight: "600" }} >
                    Practica SP 12
                  </p>
                  <p style={{ fontSize: "30px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>
                    Wired Headset
                  </p>
                  <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                    Practica SP11/12 headset delivers call clarity with noise-cancelation technology. Adjustable components ensure comfort as well as ease of setup and maintenance
                  </p>
                  <NavLink exact to="/Contact-us" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Request a Quote</button></NavLink>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-3">
          <div className="row ps-3">
            <div className="col-lg-11">
              <p style={{ fontSize: "32px", fontFamily: "serif", color: "#333333", fontWeight: "500" }}>
                Description

                <div className="border border-primary" style={{ width: "55px" }}></div>
                <div className=" mb-2 " style={{ borderBottom: "1px solid lightgray" }}></div>
              </p>
              <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                The headsets of choice for the most cost-sensitive contact centers. Featuring a functional design, the Practica SP11/12 headset delivers call clarity with noise-cancelation technology. Adjustable components ensure comfort as well as ease of setup and maintenance.
              </p>

            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-6 ">
              <p className="ps-3" style={{ fontSize: "30px", fontFamily: "serif", color: "#333333" }}>
                Features
              </p>
              <ul style={{ fontSize: "16px", fontFamily: "sans-serif", lineHeight: "30px", textAlign: "justify" }}>
                <li>
                  One year
                </li>
                <li>
                  Monaural and binaural wearing styles.
                </li>
                <li>
                  Noise-canceling microphone reduces background noise.
                </li>
                <li>
                  Adjustable over-the-head design to meet most fi t and comfort needs.
                </li>

              </ul>
            </div>
            <div className="col-lg-6 text-center">
              <div>
                <img src={require("../images/Practica-SP-12.png")} className=" w-100 pb-lg-5  " alt="Entera-HW-121N" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-2">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-3" >
              <div className="text-center" style={{ padding: "35px", backgroundColor: "#F5ECEC" }} >
                <p style={{ fontSize: "22px", fontFamily: "serif", fontWeight: "600" }}>
                  Quick Enquiry
                </p>
                <p style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                  For the product quotation please fill the given form our team will get back to you shortly after analysing your needs
                </p>

                <div className="pt-3">
                  <NavLink className=" text-decoration-none fs-6" to="/Contact-us" >
                    <span className="btn-round send-btn">SEND ENQUIRY</span>
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="col-lg-8" >
              <div id="carouselExampleIndicators" className="carousel slide " data-bs-ride="carousel" style={{ height: "300px" }}>
                <div className="carousel-indicators sliderbtn">
                  <input style={{ width: "20px", height: "15px" }} type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active " aria-current="true" aria-label="Slide 1"></input>
                  <input style={{ width: "20px", height: "15px" }} className='' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></input>
                  <input style={{ width: "20px", height: "15px" }} className=' ' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></input>
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active animated" data-bs-interval="1500">
                    <div className="row">
                      <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                        <img src={require("../images/dialpad-headset-home.png")} className="w-100" alt="" />
                      </div>
                      <div className="col-lg-6">
                        <div>

                          <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>Wired Headset </p>
                          <div className="border mb-3 border-primary" style={{ width: "55px" }}></div>

                          <p className="mt-4 mb-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}> The office is changing with many companies moving to open office plans. This brings more collaboration but also more noise.  </p>

                          <NavLink exact to="/Wired-Headset" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="carousel-item" data-bs-interval="1500">

                    <div className="row">
                      <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                        <img src={require("../images/headset-home.png")} className="w-100" alt="" />
                      </div>
                      <div className="col-lg-6">
                        <div>
                          <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>Wireless Headset </p>
                          <div className="border border-primary" style={{ width: "55px" }}></div>

                          <p className="mt-4 mb-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>The office is changing with many companies moving to open office plans. This brings more collaboration but also more noise. </p>
                          <NavLink exact to="/Wireless-Headset" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item" data-bs-interval="1500">

                    <div className="row">
                      <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                        <img src={require("../images/Calisto-5200.png")} className="w-100" alt="" />
                      </div>
                      <div className="col-lg-6">
                        <div>
                          <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>Speaker Phone</p>
                          <div className="border border-primary" style={{ width: "55px" }}></div>

                          <p className="mt-4 mb-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}> Personal speakerphone, thanks to simple controls and visual indicators for call, volume and mute. Multi-language voice alerts keep you informed of your status, including an intelligent mute alert if you try to talk while on mute.   </p>
                          <NavLink exact to="/Speaker-Phone" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>






      <Footer />
    </>
  )
}
export default PracticaSP12;