import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const Poly = () => {

  return (
    <>
      <Header />


      <section>
      <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/header-bnr.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
          <div className="container">
            <div className="row text-center d-flex  justify-content-center align-items-center">
              <div className="col-lg-6 text-lg-start">
                <div className=" pt-lg-5 pb-lg-5" >
                  <p className="text-white " style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>Plantronics</p>

                </div>
              </div>
              <div className="col-lg-6   text-lg-center">
                <div className=" pb-lg-5 pt-lg-5">
                  <p className="text-white">
                    <span className=""><NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}> / Plantronics </span></span>
                  </p>


                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid  text-dark" style={{ backgroundColor: "#EEECEC" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 ">
                <p className=" mt-3 " style={{ color: "#186191", fontSize: '40px', fontWeight: "600", fontFamily: "serif" }}>Meet Poly/Plantronics</p>
                <p className="mt-3" style={{ fontSize: "16px", fontFamily: "serif" }}>
                  Grandstream Networks has been manufacturing award-winning IP voice and video telephony,video conferencing and video surveillance products since 2002.
                </p>
                <NavLink exact to="/Contact-us"><button type="button" className=" mb-2 btn  me-md-3 " style={{ backgroundColor: "#186191", color: "white" }}>Get a Quote </button></NavLink>
              </div>
              <div className="col-lg-4">
                <img className="w-75 mt-5" src={require("../images/img/plantronics-logo.png")} alt="Grandstream-logo" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 p-5">
                <p className="" style={{ fontSize: "24px", fontFamily: "serif", color: "#333333" }}>
                  We Preferred Poly
                  <div className="border border-primary mt-lg-2" style={{ width: "55px" }}></div>
                  <div className="border border-dark border-opacity-10 mb-3 "></div>
                </p>
                <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666" }}>
                  Skyward offers superior quality and genuine Headphones from a renowned brand Poly. Easily browse through our assortment and explore more about price, specifications, availability, and deals online for Plantronics Headphones. Buy these products at the best prices and get them delivered to your doorstep across India.                </p>
              </div>
              <div className="col-lg-6">
                <div>
                  <img src={require("../images/img/yealink-1.png")} className="w-75" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid mt-5" style={{ backgroundColor: "#186191" }}>
          <div className="container">
            <div className="row g-2 p-3">
              <div className="col-lg-7 pt-3">
                <div className="text-white">
                  <p className=" mt-3 mb-3 fs-3 fw-bold" style={{ fontSize: "34px", fontFamily: "sans-serif" }}>Plantronics is connecting the World</p>
                  <p style={{ fontSize: "18px", fontFamily: "sans-serif" }}>To know more about Dinstar Products & Solutions connect with our sales person!!</p>
                </div>
              </div>
              <div className="col-lg-5 text-center pt-5">
                <a href="/Contact-us"><button style={{ fontSize: "14px", border: "none", outline: "none", padding: "10px 26px" }} className=" rounded-pill btn-lg text-black bg-white">Contact Us</button></a>

              </div>
            </div>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid pt-5 pb-3">
          <div className="container">
            <div className="row">
              <div className="">
                <p className=" fw-bold" style={{ fontSize: "32px", fontFamily: "serif" }}>Products and Solutions</p>
                <p style={{ fontSize: "14px", fontFamily: "sans-serif" }}>Our Products & Solutions help your business to grow in the telecom sector.</p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section>
        <div className="container-fluid pt-5">
          <div className="container">
            <div className="row g-4">
              <div className="col-lg-3 h-100">
                <div className=" business-border ">
                  <div className="text-center">
                    <img className="w-100" src={require("../images/img/Wired-home.png")} alt="IP-phone" />
                  </div>
                  <p className="text-start ps-3" style={{ fontSize: '20px', color: "#555555", fontWeight: "600", fontFamily: "serif" }}>Wired Headset</p>
                  <p className="text-start ps-3" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>UC headsets that are durable, comfortable, easy to deploy and come in</p>
                  <NavLink exact to="/Wired-Headset"><p className="text-start ps-3" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>See Products →</p></NavLink>
                </div>
              </div>
              <div className="col-lg-3 h-100">
                <div className=" business-border ">
                  <div className="text-center">
                    <img className="w-100" src={require("../images/img/Wireless-home.png")} alt="IP-phone" />

                  </div>
                  <p className="text-start ps-3" style={{ fontSize: '20px', color: "#555555", fontWeight: "600", fontFamily: "serif" }}>Wireless Headset</p>
                  <p className="text-start ps-3" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>Reliable call clarity especially outdoors from our exclusive WindSmart</p>
                  <NavLink exact to="/Wireless-Headset"><p className="text-start ps-3" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>See Products →</p></NavLink>
                </div>
              </div>

              <div className="col-lg-3 h-100">
                <div className=" business-border ">
                  <div className="text-center">
                    <img className="w-100" src={require("../images/img/video-phone-home.png")} alt="IP-phone" />

                  </div>
                  <p className="text-start ps-3" style={{ fontSize: '20px', color: "#555555", fontWeight: "600", fontFamily: "serif" }}>Speaker Phone</p>
                  <p className="text-start ps-3" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>Portable personal speakerphone with 360˚ audio and USB and 3.5 mm</p>
                  <NavLink exact to="/Speaker-Phone"><p className="text-start ps-3" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>See Products →</p></NavLink>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
export default Poly;