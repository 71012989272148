import React from 'react'
import { NavLink } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

const GUV3000 = () => {
  return (
    <>
      <Header />


      <section style={{ backgroundColor: "#090909" }}>
        <div className="container">
          <div className="row text-center d-flex  justify-content-center align-items-center">
            <div className="col-lg-6  ">
              <div className="text-lg-start p-lg-2 ps-lg-5">
                <p className="text-white " style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>Headsets
                </p>

              </div>
            </div>
            <div className="col-lg-6   text-lg-center">
              <div className="p-lg-2">
                <p className="text-white ">
                  <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home /</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}>Grandstream  / Headsets / <b style={{ fontSize: "16px" }}>Grandstream's GUV3000 & GUV3005</b>
                  </span></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="container mt-5 ">
            <div className="row">

              <div className="col-lg-4 " >
                <div id="carouselExampleFade" class="carousel slide " data-bs-ride="carousel">
                  <div class="carousel-inner text-center border" style={{ height: "280px" }}>
                    <div class="carousel-item active animated">
                      <img className=" w-100" src={require("../images/GUV3050-1.png")} alt="" />
                    </div>

                    <div class="carousel-item">
                      <img className=" w-100" src={require("../images/GUV3050-2.png")} alt="" />
                    </div>
                    <div class="carousel-item active animated">
                      <img className=" w-100" src={require("../images/GUV3050-3.png")} alt="" />
                    </div>

                  </div>
                  <button class="carousel-control-prev pe-2" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                    <span class="carousel-control-next-icon p-4 bg-dark" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next ps-2" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                    <span class="carousel-control-prev-icon p-4 bg-dark" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>


              <div className="col-lg-6">
                <div>
                  <p className="" style={{ fontSize: "40px", fontFamily: "serif", fontWeight: "600" }} >
                    Grandstream's GUV3000 & GUV3005
                  </p>
                  <p style={{ fontSize: "30px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>
                    HD USB Headset with Noise-Cancelling Mic
                  </p>
                  <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                    Ideal for remote workers and busy environments, the GUV3000 and GUV3005 are HD USB Headsets with noise Cancelling technology that pair with laptops, computers, IP phones and other devices to offer high-quality sound.

                  </p>
                  <NavLink exact to="/Contact-us" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Request a Quote</button></NavLink>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-3">
          <div className="row ps-lg-3">
            <div className="col-lg-12">
              <p style={{ fontSize: "32px", fontFamily: "serif", color: "#333333", fontWeight: "500" }}>
                Description

                <div className="border border-primary" style={{ width: "55px" }}></div>
                <div className=" mb-2 " style={{ borderBottom: "1px solid lightgray" }}></div>
              </p>
              <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                The GUV3000 and GUV3005 are HD USB Headsets that pair with laptops, computers, IP phones and other devices to offer high-quality sound. Ideal for remote workers and busy environments, these USB headsets feature a noise cancellation microphone that minimizes background noise to provide crisp HD audio. The GUV3000 and GUV3005 provide all day comfort thanks to adjustable headbands and soft ear cushions while audio is easily adjusted using the in-line controls. GUV series headsets are compatible with any device that offers a USB connection, including laptops and Grandstream IP phones. They support all major third-party communication platforms, apps and softphones as well as Grandstream’s IPVideoTalk Meetings and Wave app. By offering crystal-clear HD audio, comfortable use and wide compatibility, the GUV series is ideal for remote workers, call centers, receptionists, sales teams and more.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-6 ps-lg-5 ">
              <p className="ps-3" style={{ fontSize: "30px", fontFamily: "serif", color: "#333333" }}>
                Features
              </p>
              <ul style={{ fontSize: "16px", fontFamily: "sans-serif", lineHeight: "30px", textAlign: "justify" }}>
                <li>
                  HD Audio for crystal clear and easy communication
                </li>
                <li>
                  Noise canceling technology to minimize background noise
                </li>
                <li>
                  USB 2.0 connection provides flexibility to support any device with USB compatibility, including laptops, desktop computers and Grandstream IP Phones (GRP series IP phones, GXV series IP Video Phones, and soon GXP series IP phones)
                </li>
                <li>
                  A busy light (GUV3005) to indicate a call in-progress
                </li>
                <li>
                  Adjustable leather headband for all day comfort; left or right wearing style
                </li>
                <li>
                  In-line controls offer quick and easy control of volume and mute
                </li>
                <li>
                  Compatible with all major third-party communication platforms, applications and softphones as well as Grandstream’s IPVideoTalk Meetings and Wave app
                </li>

              </ul>
            </div>
            <div className="col-lg-6 text-center">
              <div>
                <img src={require("../images/GUV300x_feature_image.png")} className=" w-75  " alt="Entera-HW-121N" />
              </div>
            </div>
          </div>
        </div>
      </section>



      <section >
        <div className="mt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="text-center p-5" style={{ backgroundColor: "#F5ECEC", }} >
                  <p style={{ fontSize: "22px", fontFamily: "serif", fontWeight: "600" }}>
                    Quick Enquiry
                  </p>
                  <p style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                    For the product quotation please fill the given form our team will get back to you shortly after analysing your needs
                  </p>

                  <div className="pt-3">
                    <NavLink className=" text-decoration-none fs-6" to="/Contact-us" >
                      <span className="btn-round send-btn">SEND ENQUIRY</span>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                  <div className="carousel-indicators sliderbtn">

                    <input style={{ width: "20px", height: "15px" }} type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></input>
                    <input style={{ width: "20px", height: "15px" }} className='' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></input>
                    <input style={{ width: "20px", height: "15px" }} className=' ' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></input>
                    <input style={{ width: "20px", height: "15px" }} className=' ' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></input>
                  </div>

                  <div class="carousel-inner" style={{ height: "320px" }}>
                    <div className="carousel-item active animated" data-bs-interval="1500">
                      <div className="row">
                        <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                          <img src={require("../images/GVC3210-slide.jpg")} className="w-100 bg-gradient" alt="" />
                        </div>
                        <div className="col-lg-6">
                          <div>
                            <p className="m-0 p-0">Full HD Conferencing</p>
                            <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>GVC3210 Video Conferencing </p>
                            <div className="border border-primary" style={{ width: "55px" }}></div>

                            <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}> The GVC3210 is an innovative video conferencing endpoint ideal for small to medium businesses who seek an easy-to-use yet potent video conferencing solution.  </p>

                            <NavLink exact to="/GVC3210" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="1500">

                      <div className="row">
                        <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                          <img src={require("../images/GWN7605.png")} className="w-100" alt="" />
                        </div>
                        <div className="col-lg-6">
                          <div>
                            <p className="m-0 p-0">Networking Solutions</p>
                            <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>Wifi Access Point</p>
                            <div className="border border-primary" style={{ width: "55px" }}></div>

                            <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}> GWN7630 Wi-Fi access point for small to medium sized businesses, multiple floor offices, commercial locations and branch offices. </p>
                            <NavLink exact to="/wifi-access-point" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item" data-bs-interval="1500">

                      <div className="row">
                        <div className="col-lg-4">
                          <img src={require("../images/img/GXV3380-slide4.png")} className="w-100" alt="" />
                        </div>
                        <div className="col-lg-6">
                          <div>
                            <p className="m-0 p-0">Video Ip Phone</p>
                            <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>GXV 3380</p>
                            <div className="border border-primary" style={{ width: "55px" }}></div>

                            <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}> The GXV3380 is a powerful High-End Smart Video Phone for Android that combines a 16 line IP phone with a multi-platform video collaboration solution.   </p>
                            <NavLink exact to="/GXV3500" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="1500">

                      <div className="row">
                        <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                          <img src={require("../images/img/UCM6200-slide3.png")} className="w-100" alt="" />
                        </div>
                        <div className="col-lg-6">
                          <div>
                            <p className="m-0 p-0"> UCM series IP PBXs</p>
                            <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>UCM6200 series IP PBX </p>
                            <div className="border border-primary" style={{ width: "55px" }}></div>

                            <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>  UCM6200 series IP PBX appliance combines enterprise-grade voice, video, data, and mobility features in an easy-to-manage solution. </p>
                            <NavLink exact to="/UCM6200-Series" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>

      </section>



      <Footer />
    </>
  )
}

export default GUV3000;