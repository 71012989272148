import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const PolySync20 = () => {

  return (
    <>
      <Header />

      <section>
        <div className="container-fluid m-0 p-0">
          <img src={require("../images/poly-sync-bnr.jpg")} className="w-100" alt="poly-sync-bnr" />
        </div>
      </section>


      <section>
        <div className="container">
          <div className="container mt-5 ">
            <div className="row">

              <div className="col-lg-4 ms-lg-5" >
                <img src={require("../images/Poly Sync 20_Top_high_res_rgb.webp")} className="w-100" alt="" />
              </div>


              <div className="col-lg-6">
                <div>
                  <p className="" style={{ fontSize: "40px", fontFamily: "serif", fontWeight: "600" }} >
                    POLY SYNC 20
                  </p>
                  <p style={{ fontSize: "30px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>
                    PERSONAL, USB/BLUETOOTH SMART SPEAKERPHONE
                  </p>
                  <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                    Let’s face it: the audio on your laptop and smartphone aren’t great. Sound like the professional you are with Poly Sync 20 USB/Bluetooth® smart speakerphone—for remarkable conference calls and music anywhere. Take it wherever you go—its battery lasts for hours and can charge your smartphone. You’ll always hear and be heard. The multimedia quality sound is a music-lover’s delight. Designed specifically for today’s professional, it’s sleek, compact, and filled with smart features to keep conference calls simple.
                  </p>
                  <NavLink exact to="/Contact-us" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Request a Quote</button></NavLink>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-3">
          <div className="row ps-3">
            <div className="col-lg-11">
              <p style={{ fontSize: "32px", fontFamily: "serif", color: "#333333", fontWeight: "500" }}>
                USE IT TO WORK THEN USE IT TO PLAY
                <div className="border border-primary" style={{ width: "55px" }}></div>
                <div className=" mb-2 " style={{ borderBottom: "1px solid lightgray" }}></div>
              </p>
              <p style={{ fontSize: "20px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>GRAB THE PERFECT SPEAKERPHONE FOR MEETINGS AND MUSIC, WHEREVER YOUR DAY TAKES YOU</p>
              <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                Let’s face it: the audio on your laptop and smartphone aren’t great. Sound like the professional you are with Poly Sync 20 USB/Bluetooth® smart speakerphone—for remarkable conference calls and music anywhere. Take it wherever you go—its battery lasts for hours and can charge your smartphone. You’ll always hear and be heard. The multimedia quality sound is a music-lover’s delight. Designed specifically for today’s professional, it’s sleek, compact, and filled with smart features to keep conference calls simple.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-6 ">
              <p className="ps-3" style={{ fontSize: "30px", fontFamily: "serif", color: "#333333" }}>
                Features
              </p>
              <ul style={{ fontSize: "16px", fontFamily: "sans-serif", lineHeight: "30px", textAlign: "justify" }}>
                <li>
                  IP64 dust and water-resistant.
                </li>
                <li>
                  Keep your smartphone charged and ready.
                </li>
                <li>
                  Slim and portable, comes with a carrying case.
                </li>
                <li>
                  Slim and portable, comes with a carrying case.
                </li>
                <li>
                  Works with computers via USB, and with smartphones via Bluetooth®.
                </li>
                <li>Connect wirelessly to computers via the included BT600 adapter (Poly Sync 20+).</li>
              </ul>
            </div>
            <div className="col-lg-6 text-center">
              <div>
                <img src={require("../images/poly-sync-20.png")} className=" w-100  " alt="poly-sync-20" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row p-lg-5">
            <img src={require("../images/poly-bnr2.jpg")} className="w-100" alt="poly-bnr2" />
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-3" >
              <div className="text-center" style={{ padding: "35px", backgroundColor: "#F5ECEC" }} >
                <p style={{ fontSize: "22px", fontFamily: "serif", fontWeight: "600" }}>
                  Quick Enquiry
                </p>
                <p style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                  For the product quotation please fill the given form our team will get back to you shortly after analysing your needs
                </p>

                <div className="pt-3">
                  <NavLink className=" text-decoration-none fs-6" to="/Contact-us" >
                    <span className="btn-round send-btn">SEND ENQUIRY</span>
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="col-lg-8" >
              <div id="carouselExampleIndicators" className="carousel slide " data-bs-ride="carousel" style={{ height: "300px" }}>
                <div className="carousel-indicators sliderbtn">
                  <input style={{ width: "20px", height: "15px" }} type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active mb-2" aria-current="true" aria-label="Slide 1"></input>
                  <input style={{ width: "20px", height: "15px" }} className='mb-2 ' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></input>
                  <input style={{ width: "20px", height: "15px" }} className=' ' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></input>
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active animated" data-bs-interval="1500">
                    <div className="row">
                      <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                        <img src={require("../images/dialpad-headset-home.png")} className="w-100" alt="" />
                      </div>
                      <div className="col-lg-6">
                        <div>

                          <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>Wired Headset </p>
                          <div className="border mb-3 border-primary" style={{ width: "55px" }}></div>

                          <p className="mt-4 mb-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}> The office is changing with many companies moving to open office plans. This brings more collaboration but also more noise.  </p>

                          <NavLink exact to="/Wired-Headset" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="carousel-item" data-bs-interval="1500">

                    <div className="row">
                      <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                        <img src={require("../images/headset-home.png")} className="w-100" alt="" />
                      </div>
                      <div className="col-lg-6">
                        <div>
                          <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>Wireless Headset </p>
                          <div className="border border-primary" style={{ width: "55px" }}></div>

                          <p className="mt-4 mb-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>The office is changing with many companies moving to open office plans. This brings more collaboration but also more noise. </p>
                          <NavLink exact to="/Wireless-Headset" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item" data-bs-interval="1500">

                    <div className="row">
                      <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                        <img src={require("../images/Calisto-5200.png")} className="w-100" alt="" />
                      </div>
                      <div className="col-lg-6">
                        <div>
                          <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>Speaker Phone</p>
                          <div className="border border-primary" style={{ width: "55px" }}></div>

                          <p className="mt-4 mb-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}> Personal speakerphone, thanks to simple controls and visual indicators for call, volume and mute. Multi-language voice alerts keep you informed of your status, including an intelligent mute alert if you try to talk while on mute.   </p>
                          <NavLink exact to="/Speaker-Phone" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* <section>
        <div className="container-fluid black-bg">
          <div className="row">
            <div className="col-lg-12 p-0">
              <div className=" ">
                <h1 className="text-center pt-5 text-white" >POLY SYNC 20</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid  bg-success text-dark bg-opacity-10 mt-5">
          <div className="container ">
            <div className="row">
              <div>
                <h2 className="mt-4 mb-4 text-center fs-4">Personal, USB/Bluetooth Smart Speakerphone</h2>
                <p>
                  Let’s face it: the audio on your laptop and smartphone aren’t great. Sound like the professional you are with Poly Sync 20 USB/Bluetooth® smart speakerphone—for remarkable conference calls and music anywhere. Take it wherever you go—its battery lasts for hours and can charge your smartphone. You’ll always hear and be heard. The multimedia quality sound is a music-lover’s delight. Designed specifically for today’s professional, it’s sleek, compact, and filled with smart features to keep conference calls simple.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid mt-5 mb-5">
          <div className="container">
            <div className="row shadow rounded">
              <div className="col-lg-6 pt-3 ps-3">
                <div>
                  <p className="fs-3 fw-bold">Description</p>
                  <p>
                    When you need exceptional conferencing performance in a small group conference room, turn to Calisto 7200. Four directional microphones that provide 360˚ coverage and filter out unwanted noise. With USB and Bluetooth connectivity and touch-sensitive controls, Calisto 7200 is ready when you are.
                  </p>
                </div>
                <div>
                  <p className="fs-5 fw-bold">Features</p>
                  <div className="fs-6">
                    <p><KeyboardDoubleArrowRight className="ar" />IP64 dust and water-resistant.</p>
                    <p><KeyboardDoubleArrowRight className="ar" />Keep your smartphone charged and ready.</p>
                    <p><KeyboardDoubleArrowRight className="ar" />Slim and portable, comes with a carrying case.</p>
                    <p><KeyboardDoubleArrowRight className="ar" />Works with computers via USB, and with smartphones via Bluetooth®.</p>
                    <p><KeyboardDoubleArrowRight className="ar" />Connect wirelessly to computers via the included BT600 adapter (Poly Sync 20+).</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 text-center" >
                <img className="w-75 pt-5" src={require("../images/poly-sync-20.png")} alt="poly-sync-20" />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Footer />
    </>
  )
}
export default PolySync20;