import React from "react";
import { Route, Routes } from "react-router-dom";
import Grandstream from "./Grandstream";
import Home from "./Home";
import Solution from "./Solution";
import Dinstar from "./Dinstar";
import Ipvoice from "./IP-voice";
import Gateway from "./Gateway-ATAs";
import PhysicalSecurity from "./Physical-Security";
import BusinessConference from "./BusinessConfernce";
import NetworkSolution from "./NetworkSolution";
import IPPBX from "./IP-PBX";
import WifiCordless from "./Wifi-cordless";
import WP825 from "./WP825";
import DectCordless from "./Dect-cordless";
import WP820 from "./WP820";
import WP810 from "./WP810";
import WP822 from "./WP822";
import DP720 from "./DP720";
import DP722 from "./DP722";
import DP730 from "./DP730";
import DP750 from "./DP750";
import DP752 from "./DP752";
import DP760 from "./DP760";
import ExtensionModule from "./ExtensionModule";
import SoftphoneApp from "./SoftphoneAPP";
import MidRange from "./MidRange";
import GXP1760 from "./GXP1760";
import BasicIphone from "./Basic-Iphone";
import GXP1610 from "./GXP1610";
import GX1620 from "./GXP1620";
import GXP1628 from "./GXP1628";
import GXP1630 from "./GXP1630";
import HighEndIpPhone from "./HighEndIpPhone";
import GXP2130 from "./GXP2130";
import GXP2135 from "./GXP2135";
import GXP2140 from "./GXP2140";
import GXP2160 from "./GXP2160";
import GXP2170 from "./GXP2170";
import CarrierGrade from "./Carrier-Grade";
import GRP2612 from "./GRP2612";
import GRP2613 from "./GRP2613";
import GRP2614 from "./GRP2614";
import GRP2615 from "./GRP2615";
import GRP2616 from "./GRP2616";
import GRP2624 from "./GRP2624";
import GRP2634 from "./GRP2634";
import GRP2650 from "./GRP2650";
import GRP2670 from "./GRP2670";
import AnalogTelephone from "./AnalogTelephone";
import HT801 from "./HT801";
import HT802 from "./HT802";
import HT812 from "./HT812";
import HT813 from "./HT813";
import HT814 from "./HT814";
import HT818 from "./HT818";
import VoipGateway from "./VoipGateway";
import GXW4104 from "./GXW4104";
import GXW4200 from "./GXW4200Series";
import GXW4500 from "./GXW4500Series";
import AudioConference from "./AudioConference";
import FullHdConference from "./FullHdConferencing";
import GAC2500 from "./GAC2500";
import GMD1208 from "./GMD1208";
import GAC2570 from "./GAC2570";
import GVC3200 from "./GVC3200";
import GVC3202 from "./GVC3202";
import GVC3210 from "./GVC3210";
import GVC3212 from "./GVC3212";
import VideoManagement from "./VideoManagement";
import GSurf from "./GSurf";
import IPVideoEncoder from "./IPVideo-Encoder";
import GXV3500 from "./GXV3500";
import FacilitySystem from "./FacilitySystem";
import GDS3710 from "./GDS3710";
import GDS3705 from "./GDS3705";
import IntercomsPaging from "./IntercomsPaging";
import GSC3510 from "./GSC3510";
import GSC3505 from "./GSC3505";
import HDCamera from "./HDCamera";
import GXV3610Series from "./GXV3610-v2-series";
import GXV3674Series from "./GXV3674-v2-series";
import GXV3611IRHD from "./GXV3611IR-HD";
import ControlStation from "./ControlStation";
import GSC3570 from "./GSC3570";
import CloudManagement from "./Cloud-Management";
import GigabitRouter from "./GigabitRouter";
import NetworkPOE from "./NetworkPOE";
import WifiAccess from "./WifiAccess";
import GWNCloud from "./GWN-Cloud";
import GWN7602 from "./GWN7602";
import GWN7605 from "./GWN7605";
import GWN7605LR from "./GWN7605LR";
import GWN7615 from "./GWN7615";
import GWN7630 from "./GWN7630";
import GWN7630LR from "./GWN7630LR";
import GWN7000 from "./GWN7000";
import USBSeries from "./USBSeries";
import HA100 from "./HA100";
import UCM6200Series from "./UCM6200Series";
import UCM6510 from "./UCM6510";
import UCM6300AudioSeries from "./UCM6300-Audio-Series";
import UMC6300Series from "./UCM6300Series";
import YeaLink from "./YeaLink";
import IPVideoPhone from "./IPVideoPhone";
import IPDectPhone from "./Ip-dect-phone";
import W52P from "./W52P-IP-Dect-Phone";
import VP530 from "./VP530-IP-Video-Phone";
import IPPhone from "./IP-Phone";
import SIPT19P from "./SIP-T19P";
import SIPT21P from "./SIP-T21P";
import SIPT22P from "./SIP-T22P";
import SIPT26P from "./SIP-T26P";
import SIPT28P from "./SIP-T28P";
import AnalogVoip from "./AnalogVoip";
import GsmVoip from "./GsmVoip";
import DigitalVoip from "./DigitalVoip";
import SmeIpPbx from "./SME-IP";
import Fxofxs from "./FXO-FXS";
import FxoGateway from "./FXOGateway";
import FxsGateway from "./FXSGateway";
import Poly from "./Poly";
import SpeakerPhone from "./Speaker-Phone";
import Wireless from "./Wireless-Headset";
import Wired from "./Wired";
import Consultation from "./Consultation";
import Configuration from "./Configuration";
import Installations from "./Installations";
import Support from "./Support";
import Voyager4220 from "./Voyager4220UC";
import VoyagerFocus from "./VoyagerFocusUC";
import Voyager5200UC from "./Voyager5200UC";
import Calisto3200 from "./Calisto3200";
import Calisto7200 from "./Calisto7200";
import Calisto5200 from "./Calisto5200";
import PolySync20 from "./Poly-sync-20";
import PracticaSP12 from "./PracticaSp12";
import EnteraHW121N from "./EnteraHW121N";
import Encorepro520 from "./Encorepro520";
import Blackwire5220 from "./Blackwire5220";
import Blackwire3200Series from "./Blackwire3200Series";
import EnterpriseManufacturing from "./EnterpriseManufacturing";
import EducationHospitality from "./EducationHospitality";
import SecuritySurveillance from "./Security&Surveillance";
import SIMBankSIMCloud from "./SIMBank-SIMCloud";
import MultiSIMGateway from "./MultiSim";
import UC2000VH from "./UC2000-VH";
import UC2000VG from "./UC2000-VG";
import UC2000VF from "./UC2000VF";
import UC2000VE from "./UC2000-VE";
import UC2000VA from "./UC2000-VA";
import MTG3000T from "./MTG3000T";
import MTG3000 from "./MTG3000";
import MTG2000 from "./MTG2000";
import MTG1000B from "./MTG1000B";
import MTG200 from "./MTG200";
import UC200 from "./UC200";
import UC120 from "./UC120";
import UC100 from "./UC100";
import DAG2008S80 from "./DAG2000-8S8O";
import DAG10004S4O from "./DAG1000-4S4O";
import DAG200016O from "./DAG2000-16O";
import DAG10008O from "./DAG1000-8O";
import DAG10004O from "./DAG1000-40";
import DAG3000112S from "./DAG3000-112S";
import DAG200032S from "./DAG2000-24-32S";
import DAG200016s from "./DAG2000-16S";
import DAG10008S from "./DAG1000-8S";
import DAG10004S from "./DAG1000-4S";
import DAG10001S2s from "./DAG1000-1S-2s";
import DAG25006472s from "./DAG2500-64-72s";
import FXS from "./Fxs-Gateway";
import FXO from "./Fxo-Gateway";
import ExecutiveIPPhone from "./Executive-IP-Phone";
import HotelPhone from "./HotelPhones";
import VideoPhone from "./VideoIpPhone";
import HABox from "./HA-Box";
import IpDoorIntercoms from "./IpDoorIntercoms";
import SipCamera from "./SIP-Camera";
import GrandstreamHeadsets from "./Grandstream-Headsets";
import NEC from "./NEC";
import Alcatel from "./Alcatel";
import Cisco from "./Cisco";
// import SbcHardware from "./SBC-Hardware-model";
import Server from "./Server";
import CloudVirtualServer from "./Cloud-Virtual-Server";
import PowerVirtualServer from "./Power-Virtual-Server";
import EnterpriseServer from "./EnterpriseServer";
import HPIntegrityRX6600 from "./HP-Integrity-RX6600";
import HPIntegrityRX4640 from "./HP-Integrity-RX4640";
import HPEProLiantDL380Gen10 from "./HPE-ProLiant-DL380-Gen10";
import HPEProLiantDL180Gen10 from "./HPE-ProLiant-DL180-Gen10";
import HPIntegrityRX3600Server from "./HP-Integrity-RX3600-Server";
import HPEProLiantDL20Gen10E2224 from "./HPE-ProLiant-DL20-Gen10-E-2224";
import HPESilver from "./HPE-ProLiant-DL380-Gen10-4208-Silver";
import HPEGen104214R from "./HPEGen10-4214R";
import HPEGen104210 from "./HPE-Gen10-4210-Server";
import Dell from "./Dell";
import Ciscoservers from "./Cisco-servers";
import Firewalls from "./Firewalls";
import TzSeries from "./TZSeries";
import Sophos from "./Sophos";
import Sophosfirewall from "./Sophos-firewall";
import Ciscofire from "./Ciscofirewall";
import Ciscofirewallls from "./Ciscofirewall-1";
import PersonalCollaboration from "./PersonalCollaboration";
import Webcams from "./Webcams";
import Switches from "./Switches";
import Ciscoswitches from "./Cisco-switches";
import CiscoSwitch from "./Ciscoswitches-1";
import About from "./About";
import PTZCamera from "./PTZ-Camera";
import Contact from "./Contact";
import Blog from "./Blog";
import SurveillanceSecurity from "./Surveillance-Security-Solution";
import NewCordless from "./New-Cordless";
import VideoConferencingbenefits from "./Video-Conferencing-benefits";
import TopOfficePhone from "./Top-office-phone";
import GUV3050 from "./GUV3050";
import GUV3000 from "./GUV3000";
import GDS3702 from "./GDS3702";
import GDS3712 from "./GDS3712";
import GHP620 from './GHP620';
import GHP610 from "./GHP610";
import GXV3480 from "./GXV3480";
import GXV3370 from "./GXV3370";
import GXV3350 from "./GXV3350";
import RealEstate from './RealEstate';
const Routing =()=>{

    return(
        <>
        <Routes>
            
            <Route exact path="/"element={<Home/>}/>
            <Route exact path="/solution"element={<Solution/>}/>
            <Route exact path="/grandstream"element={<Grandstream/>}/>
            <Route exact path="/Dinstar" element={<Dinstar/>}/>
            <Route exact path="/ip-voice-telephony" element={<Ipvoice/>}/>
            <Route exact path="/Gateway-ATAs" element={<Gateway/>}/>
            <Route exact path="/physical-security" element={<PhysicalSecurity/>}/>
             <Route exact path="/BusinessConference" element={<BusinessConference/>}/>
             <Route exact path="/Networksolution" element={<NetworkSolution/>}/>
             <Route exact path="/IP-PBX" element={<IPPBX/>}/>
            <Route exact path="/wifi-cordless" element={<WifiCordless/>}/>
            <Route exact path="/wp825" element={<WP825/>}/>
             <Route exact path="/wp820" element={<WP820/>}/>
             <Route exact path="/wp810" element={<WP810/>}/>
             <Route exact path="/wp822" element={<WP822/>}/>
             <Route exact path="/dp720" element={<DP720/>}/>
             <Route exact path="/dp722" element={<DP722/>}/>
             <Route exact path="/dp730" element={<DP730/>}/>
             <Route exact path="/dp750" element={<DP750/>}/>
             <Route exact path="/dp752" element={<DP752/>}/>
             <Route exact path="/dp760" element={<DP760/>}/>
             <Route exact path="/DectCordless" element={<DectCordless/>}/>
             <Route exact path="/extension-modules" element={<ExtensionModule/>}/>
             <Route exact path="/Software-App" element={<SoftphoneApp/>}/>
             <Route exact path="/Mid-Range-IP-Phones" element={<MidRange/>}/>
             <Route exact path="/GXP1760" element={<GXP1760/>}/>
             <Route exact path="/Basic-IP-Phones" element={<BasicIphone/>}/>
             <Route exact path="/GXP1610/GXP1615" element={<GXP1610/>}/>
             <Route exact path="/GXP1620/GXP1625" element={<GX1620/>}/>
             <Route exact path="/GXP1628" element={<GXP1628/>}/>
             <Route exact path="/GXP1630" element={<GXP1630/>}/>
             <Route exact path="/High-end-ip-phones" element={<HighEndIpPhone/>}/>
             <Route exact path="/GXP2130" element={<GXP2130/>}/>
             <Route exact path="/GXP2135" element={<GXP2135/>}/>
             <Route exact path="/GXP2140" element={<GXP2140/>}/>
             <Route exact path="/GXP2160" element={<GXP2160/>}/>
             <Route exact path="/GXP2170" element={<GXP2170/>}/>
             <Route exact path="/carrier-grade-ip-phone" element={<CarrierGrade/>}/>
             <Route exact path="/GRP2612(P/W/g)" element={<GRP2612/>}/>
             <Route exact path="/GRP2613" element={<GRP2613/>}/>
             <Route exact path="/GRP2614" element={<GRP2614/>}/>
             <Route exact path="/GRP2615" element={<GRP2615/>}/>
             <Route exact path="/GRP2616" element={<GRP2616/>}/>
             <Route exact path="/GRP2624" element={<GRP2624/>}/>
             <Route exact path="/GRP2634" element={<GRP2634/>}/>
             <Route exact path="/GRP2650" element={<GRP2650/>}/>
             <Route exact path="/GRP2670" element={<GRP2670/>}/>
             <Route exact path="/Analog-Telephone-Adapter" element={<AnalogTelephone/>}/>
             <Route exact path="/HT801" element={<HT801/>}/>
             <Route exact path="/HT802" element={<HT802/>}/>
             <Route exact path="/HT812" element={<HT812/>}/>
             <Route exact path="/HT813" element={<HT813/>}/>
             <Route exact path="/HT814" element={<HT814/>}/>
             <Route exact path="/HT818" element={<HT818/>}/>
             <Route exact path="/Voip-gateway" element={<VoipGateway/>}/>
             <Route exact path="/GXW4104/4108" element={<GXW4104/>}/>
             <Route exact path="/GXW4200Series" element={<GXW4200/>}/>
             <Route exact path="/GXW4500Series" element={<GXW4500/>}/>
             <Route exact path="/Audio-Conferencing" element={<AudioConference/>}/>
             <Route exact path="/FullHD-Conferencing" element={<FullHdConference/>}/>
             <Route exact path="/GAC2500" element={<GAC2500/>}/>
             <Route exact path="/GMD1208" element={<GMD1208/>}/>
             <Route exact path="/GAC2570" element={<GAC2570/>}/>
             <Route exact path="/GVC3200" element={<GVC3200/>}/>
             <Route exact path="/GVC3202" element={<GVC3202/>}/>
             <Route exact path="/GVC3210" element={<GVC3210/>}/>
             <Route exact path="/GVC3212" element={<GVC3212/>}/>
             <Route exact path="/video-management-software" element={<VideoManagement/>}/>
             <Route exact path="/GSurf Pro V2" element={<GSurf/>}/>
             <Route exact path="/IP Video Encoders/Decoders" element={<IPVideoEncoder/>}/>
             <Route exact path="/GXV3500" element={<GXV3500/>}/>
             <Route exact path="/Facility Access Systems" element={<FacilitySystem/>}/>
             <Route exact path="/GDS3710" element={<GDS3710/>}/>
             <Route exact path="/GDS3705" element={<GDS3705/>}/>
             <Route exact path="/Intercoms-Paging" element={<IntercomsPaging/>}/>
             <Route exact path="/GSC3510" element={<GSC3510/>}/>
             <Route exact path="/GSC3505" element={<GSC3505/>}/>
             <Route exact path="/HD-Ip-camera" element={<HDCamera/>}/>
             <Route exact path="/GXV3610-v2-series" element={<GXV3610Series/>}/>
             <Route exact path="/GXV3674-v2-series" element={<GXV3674Series/>}/>
             <Route exact path="/GXV3611IR_HD" element={<GXV3611IRHD/>}/>
             <Route exact path="/control-station" element={<ControlStation/>}/>
             <Route exact path="/GSC3570" element={<GSC3570/>}/>
             <Route exact path="/cloud-management" element={<CloudManagement/>}/>
             <Route exact path="/wifi-access-point" element={<WifiAccess/>}/>
             <Route exact path="/Network-POE-Switches" element={<NetworkPOE/>}/>
             <Route exact path="/Gigabit-Routers" element={<GigabitRouter/>}/>
             <Route exact path="/GWN-Cloud" element={<GWNCloud/>}/>
             <Route exact path="/GWN7602" element={<GWN7602/>}/>
             <Route exact path="/GWN7605LR" element={<GWN7605LR/>}/>
             <Route exact path="/GWN7605" element={<GWN7605/>}/>
             <Route exact path="/GWN7615" element={<GWN7615/>}/>
             <Route exact path="/GWN7630" element={<GWN7630/>}/>
             <Route exact path="/GWN7630LR" element={<GWN7630LR/>}/>
             <Route exact path="/GWN7000" element={<GWN7000/>}/>
             <Route exact path="/UCB-Series-IP" element={<USBSeries/>}/>
             <Route exact path="/ip-pbx-HA100" element={<HA100/>}/>
             <Route exact path="/UCM6200-Series" element={<UCM6200Series/>}/>
             <Route exact path="/UCM6510" element={<UCM6510/>}/>
             <Route exact path="/UCM6300-Audio-Series" element={<UCM6300AudioSeries/>}/>
             <Route exact path="/UCM6300-Series" element={<UMC6300Series/>}/>
             <Route exact path="/YeaLink" element={<YeaLink/>}/>
             <Route exact path="/Ip-video-phone" element={<IPVideoPhone/>}/>
             <Route exact path="/yealink-VP530" element={<VP530/>}/>
             <Route exact path="/Ip-dect-phone" element={<IPDectPhone/>}/>
             <Route exact path="/yealink-W52P" element={<W52P/>}/>
             <Route exact path="/Ip-phone" element={<IPPhone/>}/>
             <Route exact path="/SIP-T19P-IP-Phone" element={<SIPT19P/>}/>
             <Route exact path="/SIP-T21P-E2-IP-Phone" element={<SIPT21P/>}/>
             <Route exact path="/SIP-T22P-IP-Phone" element={<SIPT22P/>}/>
             <Route exact path="/SIP-T26P-IP-Phone" element={<SIPT26P/>}/>
             <Route exact path="/SIP-T28P-IP-Phone" element={<SIPT28P/>}/>
             <Route exact path="/Analog-VoIP-Gateway" element={<AnalogVoip/>}/>
             <Route exact path="/GSM-VoIP-Gateway" element={<GsmVoip/>}/>
             <Route exact path="/Digital-VoIP-Gateway" element={<DigitalVoip/>}/>
             <Route exact path="/SME-IP-PBX" element={<SmeIpPbx/>}/>
             <Route exact path="/FXO-FXS-Gateway" element={<Fxofxs/>}/>
             <Route exact path="/FXO-Gateway" element={<FxoGateway/>}/>
             <Route exact path="/FXS-Gateway" element={<FxsGateway/>}/>
             <Route exact path="/Poly" element={<Poly/>}/>
             <Route exact path="/Speaker-Phone" element={<SpeakerPhone/>}/>
             <Route exact path="/Wireless-Headset" element={<Wireless/>}/>
             <Route exact path="/Wired-Headset" element={<Wired/>}/>
             <Route exact path="/Consultation" element={<Consultation/>}/>
             <Route exact path="/Configuration" element={<Configuration/>}/>
             <Route exact path="/Installations" element={<Installations/>}/>
             <Route exact path="/Support" element={<Support/>}/>
             <Route exact path="/Voyager-4220-UC" element={<Voyager4220/>}/>
             <Route exact path="/Voyager-Focus-UC" element={<VoyagerFocus/>}/>
             <Route exact path="/Voyager-5200-UC" element={<Voyager5200UC/>}/>
             <Route exact path="/Calisto-3200" element={<Calisto3200/>}/>
             <Route exact path="/Calisto-5200" element={<Calisto5200/>}/>
             <Route exact path="/Calisto-7200" element={<Calisto7200/>}/>
             <Route exact path="/poly-sync-20" element={<PolySync20/>}/>
             <Route exact path="/Practica-SP-12" element={<PracticaSP12/>}/>
             <Route exact path="/Entera-HW-121N" element={<EnteraHW121N/>}/>
             <Route exact path="/Encorepro-520" element={<Encorepro520/>}/>
             <Route exact path="/Blackwire-5220" element={<Blackwire5220/>}/>
             <Route exact path="/Blackwire-3200-Series" element={<Blackwire3200Series/>}/>
             <Route exact path="/Enterprise-Manufacturing" element={<EnterpriseManufacturing/>}/>
             <Route exact path="/EducationHospitality" element={<EducationHospitality/>}/>
             <Route exact path="/Security&Surveillance" element={<SecuritySurveillance/>}/>
             <Route exact path="/SIMBank-SIMCloud" element={<SIMBankSIMCloud/>}/>
             <Route exact path="/Multi-SIM-Gateway" element={<MultiSIMGateway/>}/>
             <Route exact path="/UC2000-VH" element={<UC2000VH/>}/>
             <Route exact path="/UC2000-VG" element={<UC2000VG/>}/>
             <Route exact path="/UC2000-VF" element={<UC2000VF/>}/>
             <Route exact path="/UC2000-VE" element={<UC2000VE/>}/>
             <Route exact path="/UC2000-VA" element={<UC2000VA/>}/>
             <Route exact path="/MTG3000T" element={<MTG3000T/>}/>
             <Route exact path="/MTG3000" element={<MTG3000/>}/>
             <Route exact path="/MTG2000" element={<MTG2000/>}/>
             <Route exact path="/MTG1000B" element={<MTG1000B/>}/>
             <Route exact path="/MTG200" element={<MTG200/>}/>
             <Route exact path="/UC200" element={<UC200/>}/>
             <Route exact path="/UC120" element={<UC120/>}/>
             <Route exact path="/UC100" element={<UC100/>}/>
             <Route exact path="/DAG2000-8S8O-FXS-FXO-MIXED" element={<DAG2008S80/>}/>
             <Route exact path="/DAG1000-4S4O-FXS-FXO-MIXED" element={<DAG10004S4O/>}/>
             <Route exact path="/DAG2000-16O" element={<DAG200016O/>}/>
             <Route exact path="/DAG1000-8O" element={<DAG10008O/>}/>
             <Route exact path="/DAG1000-4O" element={<DAG10004O/>}/>
             <Route exact path="/DAG3000-112S" element={<DAG3000112S/>}/>
             <Route exact path="/DAG2000-24/32S" element={<DAG200032S/>}/>
             <Route exact path="/DAG2000-16S" element={<DAG200016s/>}/>
             <Route exact path="/DAG1000-8S" element={<DAG10008S/>}/>
             <Route exact path="/DAG1000-4S" element={<DAG10004S/>}/>
             <Route exact path="/DAG1000-1S/2s" element={<DAG10001S2s/>}/>
             <Route exact path="/DAG2500-64/72s" element={<DAG25006472s/>}/>
             <Route exact path="/product-fxs-gateway" element={<FXS/>}/>
             <Route exact path="/product-fxo-gateway" element={<FXO/>}/>
             <Route exact path="/Executive-IP-Phone" element={<ExecutiveIPPhone/>}/>
             <Route exact path="/Hotel-Phones" element={<HotelPhone/>}/>
             <Route exact path="/Video-IP-Phones" element={<VideoPhone/>}/>
             <Route exact path="/HA-BOX" element={<HABox/>}/>
             <Route exact path="/IP-Door-Intercom" element={<IpDoorIntercoms/>}/>
             <Route exact path="/SIP-Camera" element={<SipCamera/>}/>
             <Route exact path="/Grandstream-Headsets" element={<GrandstreamHeadsets/>}/>            
            <Route exact path="/NEC" element={<NEC/>}/>
            <Route exact path="/Alcatel" element={<Alcatel/>}/>
            <Route exact path="/Cisco" element={<Cisco/>}/>
            <Route exact path="/Servers" element={<Server/>}/>
            <Route exact path="/Cloud-Virtual-Server" element={<CloudVirtualServer/>}/>
            <Route exact path="/Power-Virtual-Server" element={<PowerVirtualServer/>}/>
            <Route exact path="/Enterprise-Server" element={<EnterpriseServer/>}/>
            <Route exact path="/HP-Integrity-RX6600" element={<HPIntegrityRX6600/>}/>
            <Route exact path="/HP-Integrity-RX4640" element={<HPIntegrityRX4640/>}/>
            <Route exact path="/HPE-ProLiant-DL380-Gen10" element={<HPEProLiantDL380Gen10/>}/>
            <Route exact path="/HPE-ProLiant-DL180-Gen10" element={<HPEProLiantDL180Gen10/>}/>
            <Route exact path="/HP-Integrity-RX3600-Server" element={<HPIntegrityRX3600Server/>}/>
            <Route exact path="/HPE-ProLiant-DL20-Gen10-E-2224" element={<HPEProLiantDL20Gen10E2224/>}/>
            <Route exact path="/HPE-ProLiant-DL380-Gen10-4208-Silver" element={<HPESilver/>}/>
            <Route exact path="/HPE-ProLiant-DL3800-Gen10-4210-Server" element={<HPEGen104210/>}/>
            <Route exact path="/Dell" element={<Dell/>}/>
            <Route exact path="/Cisco-servers" element={<Ciscoservers/>}/>
            <Route exact path="/HPE-ProLiant-DL380-Gen10-4214R" element={<HPEGen104214R/>}/>
            <Route exact path="/Firewall" element={<Firewalls/>}/>
            <Route exact path="/TZ-Series-firewalls" element={<TzSeries/>}/>
            <Route exact path="/sonicwall-firewall" element={<Firewalls/>}/>
            <Route exact path="/Sophos-firewall" element={<Sophos/>}/>
            <Route exact path="/Sophos" element={<Sophosfirewall/>}/>
            <Route exact path="/Cisco-firewall" element={<Ciscofire/>}/>
            <Route exact path="/CiscoFirewalls" element={<Ciscofirewallls/>}/>
            <Route exact path="/Personal-Collaboration-Devices" element={<PersonalCollaboration/>}/>
            <Route exact path="/Webcams" element={<Webcams/>}/>
            <Route exact path="/Switches" element={<Switches/>}/>
            <Route exact path="/dlink-switches" element={<Switches/>}/>
            <Route exact path="/Cisco-switches" element={<Ciscoswitches/>}/>
            <Route exact path="/Ciscoswitches" element={<CiscoSwitch/>}/>
            <Route exact path="/About-Us" element={<About/>}/>
            <Route exact path="/PTZ-Camera" element={<PTZCamera/>}/>
            <Route exact path="/Contact-us" element={<Contact/>}/>
            <Route exact path="/Blog" element={<Blog/>}/>
            <Route exact path="/Surveillance-Security-Solution" element={<SurveillanceSecurity/>}/>
            <Route exact path="/New-Cordless-Wifi-Ip-Phone-Wp810" element={<NewCordless/>}/>
            <Route exact path="/Video-Conferencing-benefits" element={<VideoConferencingbenefits/>}/>
            <Route exact path="/Top-office-phone" element={<TopOfficePhone/>}/>
            <Route exact path="/GUV3050" element={<GUV3050/>}/>
            <Route exact path="/GUV3000" element={<GUV3000/>}/>
            <Route exact path="/GDS3702" element={<GDS3702/>}/>
            <Route exact path="/GDS3712" element={<GDS3712/>}/>
            <Route exact path="/GHP620" element={<GHP620/>}/>
            <Route exact path="/GHP610" element={<GHP610/>}/>
            <Route exact path="/GXV3480" element={<GXV3480/>}/>
            <Route exact path="/GXV3370" element={<GXV3370/>}/>
            <Route exact path="/GXV3350" element={<GXV3350/>}/>
            <Route exact path="/RealEstate" element={<RealEstate/>}/>
        </Routes>
        </>
    )
}
export default Routing;