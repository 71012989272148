import React from "react";
import { KeyboardDoubleArrowRight } from "@mui/icons-material";
import Header from "./Header";
import Footer from "./Footer";

const HPEGen104210 = () => {

    return (
        <>
            <Header />
          

            <section>
                <div className="container-fluid mt-3 ">
                    <div className="container text-center">
                        <div className="row">
                            <div>
                                <img className="w-50 " src={require("../images/HP-Integrity-RX6600.png")} alt="HP-Integrity-RX6600" />
                                <p className="fs-2 fw-bold">HPE ProLiant DL380 Gen10 4210 Server</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container-fluid mt-5 mb-3">
                    <div className="container  ">
                        <div className="row shadow rounded">
                            <div className="col-lg-6 ">
                                <div className="pt-3 ps-3">
                                    <p className="fs-5 fw-bold">Features</p>
                                    <div className="fs-6">
                                    <p><KeyboardDoubleArrowRight className="ar" />Item Height: 3.4 Inches</p>
                                    <p><KeyboardDoubleArrowRight className="ar" />Item Width: 17.5 Inches</p>
                                    <p><KeyboardDoubleArrowRight className="ar" />Item model number: P20174-B21</p>
                                    <p><KeyboardDoubleArrowRight className="ar" />Processor Brand: Intel</p>
                                    <p><KeyboardDoubleArrowRight className="ar" />Processor Type: Xeon</p>
                                    <p><KeyboardDoubleArrowRight className="ar" />Processor Speed: 2.2 GHz</p> 
                                    </div>
                               </div>
                            </div>
                            <div className="col-lg-6  text-center">
                                <div>
                                    <img className="w-75 pt-3  float-lg-center" src={require("../images/HPE-ProLiant-DL180-Gen10.jpg")} alt="HPE-ProLiant-DL180-Gen10" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default HPEGen104210;