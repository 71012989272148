import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const NetworkPOE = () => {

  return (
    <>
      <Header />
      <section>
      <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/header-bnr.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
          <div className="row text-center d-flex  justify-content-center align-items-center">
            <div className="col-lg-6 text-lg-start ">
              <div className="ps-lg-5 pb-lg-5 pt-lg-5">
                <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>Network POE Switches</p>

              </div>
            </div>
            <div className="col-lg-6   text-lg-center">
              <div className=" pt-lg-5 pb-lg-5">
                <p className="text-white">
                  <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}> / Grandstream / GWN7800
                  </span></span>
                </p>


              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid pt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-3 d-none d-sm-block">
                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>BRANDS</span>
                <div className="mb-1"></div>
                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                <div className="border border-white border-opacity-10 mb-2 "></div>
                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                  <NavLink exact to="/grandstream" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; Grandstream</p></NavLink>
                  <NavLink exact to="/Dinstar" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Dinstar</p></NavLink>
                  <NavLink exact to="/NEC" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; NEC</p></NavLink>
                  <NavLink exact to="/Alcatel" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Alcatel</p></NavLink>
                  <NavLink exact to="/YeaLink" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;YeaLink</p></NavLink>
                  <NavLink exact to="/Poly" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Poly</p></NavLink>
                  <NavLink exact to="/Cisco" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Cisco</p></NavLink>

                </p>

                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>SOLUTIONS</span>
                <div className="mb-1"></div>
                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                <div className="border border-white border-opacity-10 mb-2 "></div>
                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                  <NavLink exact to="/Enterprise-Manufacturing" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Enterprise-Manufacturing</p></NavLink>
                  <NavLink exact to="/Security&Surveillance" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Security & Surveillance</p></NavLink>
                  <NavLink exact to="/EducationHospitality" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}> Education & Hospitality</p></NavLink>
                  <NavLink exact to="/BusinessConference" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Business Conference</p></NavLink>

                </p>

                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">

                  <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/GWN7605">
                          <img src={require("../images/GWN7605.png")} style={{ width: "263px" }} alt="GWN7605" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>WiFi Access Point (GWN7605)</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream's powerful WiFi Access Points offer high performance networking, tremendous WiFi coverage range read more.. </p>
                        </NavLink>
                      </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/BusinessConference">
                          <img src={require("../images/Business-Conferencing.png")} style={{ width: "263px" }} alt="Business-Conferencing" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Business Conferencing</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream’s video conferencing solutions offer small and medium sized businesses flexibility read more..</p>
                        </NavLink>
                      </div>            </div>
                    <div className="carousel-item" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/wp810">
                          <img src={require("../images/wifi-cordless.png")} style={{ width: "263px" }} alt="wifi-cordless" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Wi-Fi IP Phone (WP 810)</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>WP810 is a portable Wi-Fi IP phone designed to suit a variety of enterprises  Read More..</p>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div>
                  <img src={require("../images/img/poe-switches-bnr.png")} className="w-100" alt="ip-voice-telephony-bnr" />
                </div>
                <div className="mt-3">
                  <p className="" style={{ color: "#333333", fontSize: '27px', fontFamily: "sans-serif", fontWeight: "600" }}>
                    Enterprise Layer 2+ Managed Network Switch
                  </p>
                </div>
                <div className="" style={{ fontSize: "15px", color: "#666666", fontFamily: "sans-serif" }}>
                  <p>
                    A powerful solution for enterprises and businesses that handle a large amount of data. The Grandstream Gigabit Network POE Switches (Power Over Ethernet) provide high-end data and power supply for smooth flow of network to the system. The GWN7800 series are Layer 2+ managed network switches that allow small-to-medium enterprises to build scalable, secure, high-performance, and smart business networks that are fully manageable. It supports advanced VLAN for flexible and sophisticated traffic segmentation, advanced QoS for automated detection & prioritization of latency-sensitive voice/video traffic, IGMP Snooping for network performance optimization, and comprehensive security capabilities against potential attacks. The PoE models provide smart dynamic PoE output to power IP phones, IP cameras, Wi-Fi access points and other PoE endpoints. The GWN7800 series can be managed in several ways, including the local network controller embedded in the GWN7800 series switch, any GWN7000 series router with an integrated local master, Grandstream’s free on-premise network management software (GWN Manager), as well as Grandstream’s cloud network management platform (GWN.Cloud). The GWN7800 series is the best value enterprise-grade managed network switch for small-to-medium businesses.
                  </p>
                  <p style={{ color: "#333333", fontSize: '14px', fontFamily: "sans-serif", fontWeight: "600" }}>
                    Network POE switches provide a scalable and sustainable business solution to build your communication and collaboration solutions. Offering smart solutions for unified communications for any organization.
                  </p>
                </div>
                <div className="row mt-5">
                  <p style={{ fontSize: "24px", fontFamily: "serif", fontWeight: "600", color: "#333333" }}>Features</p>
                  <ul className="ms-5" style={{ fontSize: "16px", fontWeight: "600", fontFamily: "sans-serif", color: "#666666" }}>
                    <li>Supports deployment in IPv6 and IPv4 networks.</li>
                    <li>8/16/24 Gigabit Ethernet ports and 2/4 Gigabit SFP ports</li>
                    <li>Device management via SNMP, RMON, CLI, HTTPS, TR069, GWN Manager, GWN.Cloud</li>
                    <li>Advanced QoS auto detects and prioritizes latencysensitive audio/video/RTP/VoIP/SIP packets</li>
                    <li>Smart power control to support dynamic PoE/PoE+ power allocation per port for the PoE models</li>
                    <li>Provides quaternary binding of IP, MAC, VLAN & port; ARP Inspection, IP Source Guard, DoS protection, port security & DHCP snooping</li>
                  </ul>
                </div>

                <div className="row mt-2 mb-5">
                  <div className="col-md-3">

                    <div className="d-flex btnHover justify-content-center align-items-center imgHover border p-1 border-dark ">
                      <img src={require("../images/GWN7801.png")} className="w-75 " alt="carrier-grade-ip-phone" />
                      <NavLink className="imgbtn text-center" exact to="/Network-POE-Switches"> <button className="btn border-none">See Product</button></NavLink>
                    </div>
                  </div>

                  <div className="col-lg-9">
                    <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>Grandstream GWN7801/GWN7801P</p>
                    <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                      The GWN7801 is a powerful Non-PoE switch that supports powerful business processing capabilities. It features modern capabilities of Gigabit ethernet port, Gigabit SFP Ports, Integrated Power Supply and Auxiliary Ports. It provides flexible management to control access policies. The GWN7801P is PoE powered switch to directly supply power to PoE-enabled devices.
                    </p>
                    <div className="border border-dark border-opacity-10 "></div>
                  </div>
                </div>

                <div className="row mt-2 mb-5">
                  <div className="col-md-3">

                    <div className="d-flex btnHover justify-content-center align-items-center imgHover border p-1 border-dark ">
                      <img src={require("../images/GWN7802.png")} className="w-75 " alt="carrier-grade-ip-phone" />
                      <NavLink className="imgbtn text-center" exact to="/Network-POE-Switches"> <button className="btn border-none">See Product</button></NavLink>
                    </div>
                  </div>

                  <div className="col-lg-9">
                    <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>Grandstream GWN7802/GWN7802P</p>
                    <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                    The GWN7802 is an easy-to-use Non-PoE switch with modern features that is useful in operating the device with ease and comfort. The features support advanced specifications for smooth connections between PoE-enabled devices. It consists of an Integrated Power Supply, Forwarding Mode, 16 Gigabit Ethernet Ports and a Switching Capability of up to 20 Gbps for fast processing and easy management. The GWN7802P is PoE powered switch to directly supply power to PoE-enabled devices.
                    </p>
                    <div className="border border-dark border-opacity-10 "></div>
                  </div>
                </div>

                <div className="row mt-2 mb-5">
                  <div className="col-md-3">

                    <div className="d-flex btnHover justify-content-center align-items-center imgHover border p-1 border-dark ">
                      <img src={require("../images/GWN7802.png")} className="w-75 " alt="carrier-grade-ip-phone" />
                      <NavLink className="imgbtn text-center" exact to="/Network-POE-Switches"> <button className="btn border-none">See Product</button></NavLink>
                    </div>
                  </div>

                  <div className="col-lg-9">
                    <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>Grandstream GWN7803/GWN7803P</p>
                    <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                    The GWN7803 is an advance easy-to-use Non-PoE switch with the latest features that makes this device easy to manage. The specifications of the device are customer friendly and updated with the latest security features to make the device secure. It consists of Integrated Power Supply, Forwarding Mode, 24 Gigabit Ethernet Ports, Auxiliary Ports, Max Total PoE Output Power up to 360W, PoE Standards IEEE 802.3af/at and Switching Capability up to 56Gbps for fast processing and easy management. The GWN7803P is PoE powered switch to directly supply power to PoE-enabled devices.
                    </p>
                    <div className="border border-dark border-opacity-10 "></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >



      <Footer />
    </>
  )
}
export default NetworkPOE;