import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "./EnterpriseManufacturing.css";
import { NavLink } from "react-router-dom";
const EnterpriseManufacturing = () => {

  return (
    <>
      <Header />


      <section>
        <div className="container-fluid p-0 m-0 enterprise-bnr">
          <div className="container">
            <div className="row text-center d-flex  justify-content-center align-items-center">
              <div className="col-lg-6 ">
                <div className=" pt-lg-5">
                  <p className="text-white pt-5" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>Enterprise & Manufacturing</p>
                </div>
              </div>
              <div className="col-lg-6   text-lg-end">
                <div className=" pt-lg-5">
                  <p className="text-white pt-lg-5">
                    <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}>  <NavLink exact to="/Solution" className="text-white">/ Solutions </NavLink>/ Enterprise & Manufacturing </span></span>
                  </p>



                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid bg-light">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-6">
              <div className="pt-lg-5">
                <p className="text-center " style={{ fontSize: "30px", fontFamily: "serif", color: "#333", fontWeight: "600" }}>
                  Unified Communications,Enterprise Solution
                </p>
                <div className="ps-lg-5" style={{ textAlign: "justify", fontSize: "14px", fontFamily: "sans-serif", color: "#666", lineHeight: '1.7' }}>
                  <p>
                    Unified communications (UC) is the integration of enterprise communication services across multiple mediums such as fixed line phones, mobiles and PC’s. Some of the features that can be extended by Unified Communication System are Voice (including IP Telephony), Presence Information, Instant Messaging, Mobility Features (including Single Number reach); Audio, Web & Video Conferencing, Desktop sharing and Data Sharing, Call Control and Speech Recognition with non-real time communication service such as Unified Messaging (integrated Voicemail, e-mail, SMS and Fax).

                    <p>
                      UC is not a single but, a set of products that provides a consistent unified user interface and user experience across multiple devices and media types and can encompass all forms of communications that are exchanged via a network to include other forms of communications and may be directed as one-to-one communications or broadcast communications from one to many. UC allows an individual to send a message on one medium and receive the same communication on another medium. One can receive a voicemail message and choose to access it through e-mail or a cell phone. If the sender is online according to the presence information and currently accepts calls, the response can be sent immediately through text chat or a video call. Otherwise, it can also be sent as a non-real-time message that can be accessed through a variety of other mediums.
                    </p>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5 text-start">
              <img className="w-75 pt-3" src={require("../images/UC-icon-final.png")} alt="UC-icon-final" />
            </div>
          </div>
        </div>
      </section>

      <section style={{ backgroundColor: "#E8E6E6" }}>
        <div className="container ">
          <div className="row pb-lg-5">
            <p className="pt-lg-5" style={{ fontSize: "30px", fontWeight: "600", fontFamily: "serif", color: "#186191" }}>Benefits of Unified Communications (UC)</p>
            <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666" }}>
              Let’s understand how UC can impact your business on a day to day basis.
            </p>
            <b style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#555555" }}>1. Improved Efficiency & Productivity
            </b><br />
            <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7" }}>
              Unifying communications makes it easier for people to do their job by saving time on simple work tasks. It has the features for incoming calls to be routed to office phone, mobile or a phone application on your computer. This reduces the possibility of missing that important phone call. Not only does a unified communication allow employees to work more efficiently but also from wherever they want to. People can work easily from remote without the need for any special set-up from their IT department.
            </p>

            <b style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#555555" }}>2. Reduced Operating Cost
            </b><br />
            <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7" }}>
              Unified communications make use of Voice over IP technology in order to integrate different communications platforms. This differs from traditional telephony which has historically relied on the rental of analogue phone lines from the service provider. With UC system in place that runs on IP, line rental costs are easily done away with, substantially reducing the recurring operating costs of phone system. A switch over to UC can reduce up to approx. 40% in real cost savings for an Enterprise.
            </p>

            <b style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#555555" }}>3. Better Customer Service
            </b><br />
            <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7" }}>
              Unified Communication facilitates a client or a partner to reach a person or a department at the shortest and quickest possible time, even if the person in not on desk or in office. It provides the feature for the call to be redirected from the fixed number device to mobile device. In case of inability to answer the call, ring groups can be set up to send the call to the next most suitable recipient(s). Ring groups set conditions for incoming calls.
              In case of a call transfer, short internal number dialing makes call transfer simple, especially if the intended recipient is in a different office or branch. A good unified communications system allows syncing of contacts from a central portal across the corporate network.


            </p>

            <b style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#555555" }}>4. Less administration
            </b><br />
            <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7" }}>
              With unified communications system in place, it’s easy to function with minimal number of people. There is a streamlined administration with single point of contact for all communications required for business needs.
            </p>

            <b style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#555555" }}>5. Enable a mobile and remote workforce
            </b><br />
            <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7" }}>
              The Business working system is changing rapidly. Remote and mobile working has become the working and popular norms within the organizations and also with employees. Unified communications offers the convenience of remote working.
            </p>

          </div>
        </div>
      </section>

      <section className="bg-light">
        <div className="container">
          <div className="row pb-lg-5">
            <div className="col-md-6 text-lg-end  pt-sm-5  pt-lg-5">
              <img className="w-75 pt-lg-5" src={require("../images/enterprise1.jpg")} alt="" />
            </div>
            <div className="col-md-5 pt-lg-5 text-start">
              <p className="pt-lg-5" style={{ fontSize: "15px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                The benefits of unified communications for Enterprises or Businesses are endless. From productivity, to cost, to customer service – the system you choose can give your business the advantage and your customers a better experience with your organization. Recognizing that the move to UC is a decision, every customer and user makes at their own pace, Cloud Infotech focuses on changing how businesses can deploy and consume communications technology by delivering on premise unified communications hardware solutions with all the advanced features and services required for seamless functioning of existing or new communications infrastructure. All under One Roof
                Check out the unified communications solutions & Services on offer from Cloud Infotech and take the correct decision of reducing your communication cost --- NOW.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/bg31.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
          <div className="container">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-lg-9">
                <div className="ps-2 p-lg-4" style={{lineHeight:"2"}}>
                  <p className="fs-2" style={{ color: "white", fontWeight: "700", fontFamily: "sans serif" }}>
                    Bright Solutions. Profitable Results.

                    <p style={{ fontSize: '18px', color: "white", fontWeight: "700", fontFamily: "sans serif" }}>
                      Experience you can trust, service you can count on.
                    </p></p>
                </div>
              </div>
              <div className="col-lg-3 mb-2">
                <div className="text-lg-center p-lg-5">
                  <a href="/Contact-us"><button style={{ fontSize: "14px", border: "none", outline: "none", padding: "10px 26px" }} className=" rounded-pill btn-lg text-black bg-white">Schedule a Demo</button></a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="pt-5 text-center">
            <img src={require("../images/office-sloutions1.png")} className="w-50" alt="office-sloutions1" />
          </div>
        </div>
      </section>



      <Footer />
    </>
  )
}
export default EnterpriseManufacturing;