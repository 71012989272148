import React from "react";
import Footer from "./Footer";
import Header from "./Header";
// import { NavLink } from "react-router-dom";

const SurveillanceSecurity = () => {

    return (
        <>
            <Header />
            <section>
                <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/header-bnr.png")})`, backgroundAttachment: "local", height: "25vh", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center', borderBottom: "2px solid white" }}>
                    <div className="container">
                        <div className="row d-flex  justify-content-center align-items-center">

                            <div className=" pt-lg-5 pb-lg-5">
                                <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>SURVEILLANCE SECURITY SOLUTIONS</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mt-5">
                                <img src={require("../images/SS.png")} className="w-100" alt="" />
                            </div>
                            <div className="col-lg-6 mt-5">
                                <p style={{ fontSize: "20px", fontFamily: 'serif', fontWeight: "600" }}>SURVEILLANCE SECURITY SOLUTIONS</p>
                                <p style={{ textAlign: "justify", fontSize: "14px",lineHeight:"1.7",fontFamily:"sans-serif", color: "#666666" }}>
                                    Surveillance is an essential aspect of human living. With increasing troubles and deteriorating law and order situation, it has become essential to get proper security solutions for your homes as well as properties. In today’s disturbed times when you hear news about criminals compromising the common man’s safety, it becomes necessary to look for some effective solutions. After all, you have invested a lot in building these precious buildings which you call your own. So, why should you allow any illegal entry into your premises, especially in your absence? This is the reason, you definitely need surveillance solutions, to keep yourself and your property safe and sound. Skyward understands this need very well and has brought some really effective security solutions for homes. Our main solutions revolve around Ip telephones and VoIP telephony services.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mt-4">
                    <div className="row">
                        <p style={{ fontSize: "24px", fontFamily: "serif", color: "#555555", fontWeight: "600" }}>What Are The Advantages Of IP Home Security Systems?</p>
                        <div style={{fontSize:"14px",fontFamily:"sans-serif",color:"#666666",lineHeight:"1.7"}}>
                            <p>Are you also planning to switching to IP based security systems but are not sure about whether it is the correct solution or not? Well, the doubts are logical and justified as well. After all, we all have been using normal security solutions since long but this concept of surveillance solutions connected to IP telephones is absolutely new. Well, foremost, these phone based surveillance equipments are designed to give you high resolution images as well as 100 percent accuracy. There is no point of haze pictures or video with eruptions. Thus, you can be rest assured of getting absolutely clear pictures of anyone trying to intrude into your privacy. The greater image details allows you to have perfect images which you can keep safe with yourself at any time of the year.
                            </p>
                            <p>Moreover, analog video signals often get weak when they have to travel which is not the case with IP based surveillance systems. These systems allow you to get access to digital images and retrieve them whenever the need arises. In addition, increased reliability and substantial savings contribute to the added advantages if using surveillance solutions.
                            </p>
                            <p>Skyward has made a niche for itself in the world of these surveillance systems. You name it and we have it. Moreover, since all our security solutions revolve around any telephones, they ought to give you hundred percent. Our increasing client base is a permanent proof of this fact.
                            </p>
                            <p>
                                Also when you use services for connecting to your home security, you need not get an additional audio or electric cable. You can also save your hard earned money here as you need not spend on extra cables just for clear connectivity. Thus, when on one side, these security solutions for homes can become too costly, time consuming, or involving too many cables, these wireless VoIP surveillance solutions offer cost-effective, flexible, and easy to install products.
                                In addition, as compared to normal security solutions, these based solutions have one more added benefit, added back up power. Thus, even when you do not have electricity at your home, these VoIP based solutions will work fine as phones do not need electricity connections to run.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

    



            <Footer />
        </>
    )
}
export default SurveillanceSecurity;