import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const NetworkSolution = () => {

    return (
        <>
            <Header />



            <section style={{ backgroundColor: "#090909" }}>
                <div className="container">
                    <div className="row text-center d-flex  justify-content-center align-items-center">
                        <div className="col-lg-6  ">
                            <div className="text-lg-start p-lg-2 ps-lg-5">
                                <p className="text-white " style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>NETWORK SOLUTION</p>
                            </div>
                        </div>
                        <div className="col-lg-6   text-lg-center">
                            <div className="p-lg-2">
                                <p className="text-white ">
                                    <span className="" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}><NavLink className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span> <NavLink exact to="/Grandstream" className="text-white">/ Grandstream </NavLink>/ Physical Security
                                    </span></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container-fluid pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 d-none d-sm-block">
                                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>BRANDS</span>
                                <div className="mb-1"></div>
                                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                                <div className="border border-white border-opacity-10 mb-2 "></div>
                                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                                    <NavLink exact to="/grandstream" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; Grandstream</p></NavLink>
                                    <NavLink exact to="/Dinstar" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Dinstar</p></NavLink>
                                    <NavLink exact to="/NEC" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; NEC</p></NavLink>
                                    <NavLink exact to="/Alcatel" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Alcatel</p></NavLink>
                                    <NavLink exact to="/YeaLink" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;YeaLink</p></NavLink>
                                    <NavLink exact to="/Poly" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Poly</p></NavLink>
                                    <NavLink exact to="/Cisco" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Cisco</p></NavLink>

                                </p>

                                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>SOLUTIONS</span>
                                <div className="mb-1"></div>
                                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                                <div className="border border-white border-opacity-10 mb-2 "></div>
                                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                                    <NavLink exact to="/Enterprise-Manufacturing" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Enterprise-Manufacturing</p></NavLink>
                                    <NavLink exact to="/Security&Surveillance" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Security & Surveillance</p></NavLink>
                                    <NavLink exact to="/EducationHospitality" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}> Education & Hospitality</p></NavLink>
                                    <NavLink exact to="/BusinessConference" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Business Conference</p></NavLink>

                                </p>

                                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">

                                    <div className="carousel-inner">
                                        <div className="carousel-item active" data-bs-interval="2500">
                                            <div>
                                                <NavLink exact to="/GWN7605">
                                                    <img src={require("../images/GWN7605.png")} style={{ width: "263px" }} alt="GWN7605" />
                                                    <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>WiFi Access Point (GWN7605)</p>
                                                    <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream's powerful WiFi Access Points offer high performance networking, tremendous WiFi coverage range read more.. </p>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="carousel-item" data-bs-interval="2500">
                                            <div>
                                                <NavLink exact to="/BusinessConference">
                                                    <img src={require("../images/Business-Conferencing.png")} style={{ width: "263px" }} alt="Business-Conferencing" />
                                                    <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Business Conferencing</p>
                                                    <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream’s video conferencing solutions offer small and medium sized businesses flexibility read more..</p>
                                                </NavLink>
                                            </div>            </div>
                                        <div className="carousel-item" data-bs-interval="2500">
                                            <div>
                                                <NavLink exact to="/wp810">
                                                    <img src={require("../images/wifi-cordless.png")} style={{ width: "263px" }} alt="wifi-cordless" />
                                                    <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Wi-Fi IP Phone (WP 810)</p>
                                                    <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>WP810 is a portable Wi-Fi IP phone designed to suit a variety of enterprises  Read More..</p>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div>
                                    <img src={require("../images/img/networking-solutions-bnr.jpg")} className="w-100" alt="networkvvvving-solutions-bnr" />
                                </div>

                                <div className="mt-5" style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                                    <p>
                                        Grandstream's GWN series of Networking Solutions offer powerful and secure networks with wide coverage range that feature industry-leading setup and management thanks to embedded provisioning and management controllers. This series offers both WiFi Access Points and Gigabit Routers in order to offer a complete wired and WiFi solution.
                                    </p>
                                </div>

                                <div className="row mb-5">
                                    <div className="col-md-3">
                                        <div className="d-flex btnHover h-100 justify-content-center align-items-center block border  p-2 border-dark ">
                                            <img src={require("../images/img/poe-switches-hm.png")} className="w-100 " alt="poe-switches-hm" />
                                            <NavLink className="imgbtn text-center" exact to="/Network-POE-Switches"> <button className="btn border-none">See Product</button></NavLink>
                                        </div>
                                    </div>
                                    <div className="col-lg-9">
                                        <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>Network POE Switches</p>
                                        <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                                            The GWN7800 series are Layer 2+ managed network switches that allow small-to-medium enterprises to build scalable, secure, high performance, and smart business networks that are fully manageable.
                                        </p>
                                        <div className="border border-dark border-opacity-10 "></div>
                                    </div>
                                </div>

                                <div className="row mt-5">
                                    <div className="col-md-3 ">
                                        <div className="d-flex btnHover h-100 justify-content-center align-items-center block border  p-2 border-dark ">
                                            <img src={require("../images/img/Gigabit-Routers.jpg")} className="w-100 " alt="Gigabit Routers" />
                                            <NavLink className="imgbtn text-center" exact to="/Gigabit-Routers"> <button className="btn border-none">See Product</button></NavLink>
                                        </div>
                                    </div>
                                    <div className="col-lg-9">
                                        <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>Gigabit Routers</p>
                                        <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                                            Ideal for the enterprise, small-to-medium business, retail, education, hospitality and medical markets, the GWN7000 supports comprehensive WiFi and VPN solutions that can be shared across one or many different physical locations.
                                        </p>
                                        <div className="border border-dark border-opacity-10 "></div>
                                    </div>
                                </div>

                                <div className="row mt-5">
                                    <div className="col-md-3">
                                        <div className="d-flex h-100 btnHover justify-content-center align-items-center block border  p-2 border-dark ">
                                            <img src={require("../images/img/Cloud-Management.jpg")} className="w-100" alt="Cloud-Management" />
                                            <NavLink className="imgbtn text-center" exact to="/cloud-management"> <button className="btn border-none">See Product</button></NavLink>
                                        </div>
                                    </div>
                                    <div className="col-lg-9">
                                        <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>Cloud Management</p>
                                        <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                                            GWN.Cloud is an enterprise-grade, management platform for Grandstream access points. Thanks to streamlined monitoring and maintenance, managing your network across multiple locations has never been easier.
                                        </p>
                                        <div className="border border-dark border-opacity-10 "></div>
                                    </div>
                                </div>

                                <div className="row mt-5">
                                    <div className="col-md-3">
                                        <div className="d-flex h-100 btnHover justify-content-center align-items-center block border border-dark ">
                                            <img src={require("../images/img/wifi-access-points.jpg")} className="w-100" alt="wifi-access-points" />
                                            <NavLink className="imgbtn text-center" exact to="/wifi-access-point"> <button className="btn border-none">See Product</button></NavLink>
                                        </div>
                                    </div>
                                    <div className="col-lg-9">
                                        <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>Wifi Access Point</p>
                                        <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                                            Grandstream's powerful WiFi Access Points offer high performance networking, tremendous WiFi coverage range, fast and easy provisioning/management thanks to a built-in controller, outstanding network throughput and support for a large number of clients per AP. Our APs can be paired with 3rd party routers, future Grandstream routers as well as existing WiFi networks, making them ideal for both new and existing WiFi deployments.
                                        </p>
                                        <div className="border border-dark border-opacity-10 "></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <Footer />
        </>
    )
}
export default NetworkSolution;