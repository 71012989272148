import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import "./FXSGateway.css";
const FxsGateway = () => {

    return (
        <>
            <Header />

            <section>
            <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/header-bnr.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}> 
                    <div className="container">
                        <div className="row text-center d-flex  justify-content-center align-items-center">
                            <div className="col-lg-6 text-lg-start">
                                <div className=" pt-lg-5 pb-lg-5">
                                    <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>FXS Gateway
                                    </p>

                                </div>
                            </div>
                            <div className="col-lg-6   text-lg-center">
                                <div className=" pt-lg-5 pb-lg5">
                                    <p className="text-white">
                                        <span className=""><NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}> <NavLink exact to="/Dinstar" className="text-white">/ Dinstar</NavLink>  / FXS Gateway </span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 d-none d-sm-block">
                                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>BRANDS</span>
                                <div className="mb-1"></div>
                                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                                <div className="border border-white border-opacity-10 mb-2 "></div>
                                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                                    <NavLink exact to="/grandstream" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; Grandstream</p></NavLink>
                                    <NavLink exact to="/Dinstar" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Dinstar</p></NavLink>
                                    <NavLink exact to="/NEC" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; NEC</p></NavLink>
                                    <NavLink exact to="/Alcatel" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Alcatel</p></NavLink>
                                    <NavLink exact to="/YeaLink" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;YeaLink</p></NavLink>
                                    <NavLink exact to="/Poly" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Poly</p></NavLink>
                                    <NavLink exact to="/Cisco" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Cisco</p></NavLink>                                                        </p>

                                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>SOLUTIONS</span>
                                <div className="mb-1"></div>
                                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                                <div className="border border-white border-opacity-10 mb-2 "></div>
                                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                                    <NavLink exact to="/Enterprise-Manufacturing" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Enterprise-Manufacturing</p></NavLink>
                                    <NavLink exact to="/Security&Surveillance" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Security & Surveillance</p></NavLink>
                                    <NavLink exact to="/EducationHospitality" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}> Education & Hospitality</p></NavLink>
                                    <NavLink exact to="/BusinessConference" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Business Conference</p></NavLink>

                                </p>

                                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">

                                    <div className="carousel-inner">
                                        <div className="carousel-item active" data-bs-interval="2500">
                                            <div>
                                                <NavLink exact to="/GWN7605">
                                                    <img src={require("../images/GWN7605.png")} style={{ width: "263px" }} alt="GWN7605" />
                                                    <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>WiFi Access Point (GWN7605)</p>
                                                    <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream's powerful WiFi Access Points offer high performance networking, tremendous WiFi coverage range read more.. </p>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="carousel-item" data-bs-interval="2500">
                                            <div>
                                                <NavLink exact to="/BusinessConference">
                                                    <img src={require("../images/Business-Conferencing.png")} style={{ width: "263px" }} alt="Business-Conferencing" />
                                                    <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Business Conferencing</p>
                                                    <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream’s video conferencing solutions offer small and medium sized businesses flexibility read more..</p>
                                                </NavLink>
                                            </div>            </div>
                                        <div className="carousel-item" data-bs-interval="2500">
                                            <div>
                                                <NavLink exact to="/wp810">
                                                    <img src={require("../images/wifi-cordless.png")} style={{ width: "263px" }} alt="wifi-cordless" />
                                                    <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Wi-Fi IP Phone (WP 810)</p>
                                                    <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>WP810 is a portable Wi-Fi IP phone designed to suit a variety of enterprises  Read More..</p>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">


                                <div className="mt-3" style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                                    <p style={{ fontSize: '34px', fontFamily: "serif", color: "#333333", fontWeight: "500" }}>FXS Gateway</p>
                                    <p>

                                        The Dinstar DAG series VoIP FXS analog gateways are cost-effective, reliable VoIP gateways that seamlessly connect your analog phones, fax machines and legacy PBX with IP PBX systems and IP Phone networks, without altering your existing telephony infrastructure. The DAG FXS series support from 1 to 112 FXS ports, always a proper size for your businesses. Simply deploy the DAG VoIP analog gateways and enjoy your VoIP connectivity.                                                               </p>
                                </div>

                                <div className="row gy-4 mb-4">
                                    <div className="col-md-4 h-100 ">
                                        <div className=" border  border-dark">

                                            <div className="  d-flex  justify-content-center align-items-center  ">
                                                <img className="w-100" src={require("../images/DAG1000-1-2S.png")} alt="" />
                                            </div>
                                            <div className="text-center ">
                                                <NavLink exact to="/DAG1000-1S/2s"><p style={{ fontSize: "18px", fontFamily: "serif" }}>DAG1000-1/2S</p> </NavLink>

                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-4 h-100 ">
                                        <div className=" border  border-dark">

                                            <div className="  d-flex  justify-content-center align-items-center  ">
                                                <img className="w-100" src={require("../images/DAG1000-4S.png")} alt="" />
                                            </div>
                                            <div className="text-center ">
                                                <NavLink exact to="/DAG1000-4S">  <p style={{ fontSize: "18px", fontFamily: "serif" }}>DAG1000-4S</p>
                                                </NavLink>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-4 h-100 ">
                                        <div className=" border  border-dark">

                                            <div className="  d-flex  justify-content-center align-items-center  ">
                                                <img className="w-100" src={require("../images/DAG1000-8S.png")} alt="DAG1000-8S" />
                                            </div>
                                            <div className="text-center ">
                                                <NavLink exact to="/DAG1000-8S"><p style={{ fontSize: "18px", fontFamily: "serif" }}>DAG1000-8S</p>
                                                </NavLink>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-4 h-100 ">
                                        <div className=" border  border-dark">

                                            <div className="  d-flex  justify-content-center align-items-center  ">
                                                <img className="w-100" src={require("../images/DAG2000-16S.png")} alt="" />
                                            </div>
                                            <div className="text-center ">
                                                <NavLink exact to="/DAG2000-16S"><p style={{ fontSize: "18px", fontFamily: "serif" }}>DAG2000-16S</p>
                                                </NavLink>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-4 h-100 ">
                                        <div className=" border  border-dark">

                                            <div className="  d-flex  justify-content-center align-items-center  ">
                                                <img className="w-100" src={require("../images/DAG2000-32S.png")} alt="" />
                                            </div>
                                            <div className="text-center ">
                                                <NavLink exact to="/DAG2000-24/32S"><p style={{ fontSize: "18px", fontFamily: "serif" }}>DAG2000-24/32S</p>
                                                </NavLink>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-4 h-100 ">
                                        <div className=" border  border-dark">

                                            <div className="  d-flex  justify-content-center align-items-center  ">
                                                <img className="w-100" src={require("../images/DAG2500-64-72S.png")} alt="" />
                                            </div>
                                            <div className="text-center ">
                                            <NavLink exact to="/DAG2500-64/72S"><p style={{ fontSize: "18px", fontFamily: "serif" }}>DAG2500-64/72S</p>
                                                </NavLink>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-4 h-100 ">
                                        <div className=" border  border-dark">

                                            <div className="  d-flex  justify-content-center align-items-center  ">
                                                <img className="w-100" src={require("../images/DAG3000-112S.png")} alt="" />
                                            </div>
                                            <div className="text-center ">
                                            <NavLink exact to="/DAG3000-112S"><p style={{ fontSize: "18px", fontFamily: "serif" }}>DAG3000-112S</p>
                                                </NavLink>
                                            </div>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Footer />
        </>
    )

}
export default FxsGateway;