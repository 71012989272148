import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const NewCordless = () => {

    return (
        <>
            <Header />
            <section>
                <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/header-bnr.png")})`, backgroundAttachment: "local", height: "25vh", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
                    <div className="container">
                        <div className="row d-flex  justify-content-center align-items-center">

                            <div className="text-center">
                                <p className="text-white" style={{ paddingTop: "7%", fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>New Cordless WiFi IP Phone WP810</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mt-5">
                                <img src={require("../images/wp810-bnr.png")} className="w-100" alt="" />
                            </div>
                            <div className="col-lg-6 mt-5">
                                <p style={{ fontSize: "24px", fontFamily: 'serif', fontWeight: "600" }}>New Cordless WiFi IP Phone WP810</p>

                                <p style={{ textAlign: "justify", fontSize: "14px", lineHeight: "1.7", fontFamily: "sans-serif", color: "#666666" }}>
                                    Grandstream, connecting the world since 2002 with award-winning unified communication solutions, today announced the release of the newest addition to their WP series of portable Wi-Fi IP Phones. The new WP810 is a powerful yet affordable Wi-Fi IP phone that offers seamless roaming and advanced features to suit a variety of vertical market applications, including enterprise, retail, logistics, healthcare and security.

                                    Grandstream WP810 Wi-Fi IP Phone is perfect for versatile IP communication and meandering all through any office with Wi-Fi coverage. It highlights double band 802.11ac Wi-Fi with sophisticated antenna design and advanced roaming support. It offers over 6-hour talk time on account of a rechargeable battery, completely clear HD voice with double MICs, and a configurable push to-talk button for moment communication. Because of a reasonable value point and concentrated provisioning, the WP810 offers critical cost-investment funds and versatility for large volume organization.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mt-4">
                    <div className="row">
                        <p style={{ fontSize: "24px", fontFamily: "serif", color: "#555555", fontWeight: "600" }}>The WP810 offers an affordable option and comes equipped with a combination of features, mobility and durability to suit all portable telephony and security needs of a variety of vertical markets. Key highlights features of the WP810 include:</p>
                        <div style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7", textAlign: "justify" }}>
                            <ul>
                                <li>
                                    2 SIP accounts, 2 lines
                                </li>
                                <li>
                                    1.8 inch (128x160) TFT color LCD
                                </li>
                                <li>
                                    Micro USB port and 3.5mm headset jack
                                </li>
                                <li>
                                    Rechargeable 1500mAh battery, 6-hour talk time, 120-hour standby
                                </li>
                                <li>
                                    Configurable button for push-to-talk, panic call and other functions
                                </li>
                                <li>
                                    Dual-band 802.11ac Wi-Fi with efficient antenna design and advanced roaming support
                                </li>
                                <li>
                                    Supports popular voice codecs including wide-band Opus, G.722, G.711, G.729A/B, iLBC, etc
                                </li>
                                <li>
                                    HD voice & dual MIC design with advanced Acoustic Echo Cancellation and Noise Shield Technology
                                </li>
                                <li>
                                    Supports WMM (Wi-Fi Multimedia) and smart QoS to ensure smooth roaming with the highest possible
                                    sustained quality of latency-sensitive voice/video communications against unpredictable network jitter
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </section>


            <Footer />
        </>
    )
}
export default NewCordless;