import React from "react";
import Footer from "./Footer";
import { NavLink } from "react-router-dom";
import "./IP-voice.css";
import Header from "./Header";

const Ipvoice = () => {


  return (
    <>
      <Header />
     
      <section>
        <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/header-bnr.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
          <div className="container">
            <div className="row text-center d-flex  justify-content-center align-items-center">
              <div className="col-lg-6 text-lg-start">
                <div className="pb-lg-5 pt-lg-5">
                  <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>IP Voice Telephony</p>

                </div>
              </div>
              <div className="col-lg-6   text-lg-end">
                <div className=" pt-lg-5 pb-lg-5">
                  <p className="text-white">
                    <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}>  / Ip Vioce Telephony </span></span>
                  </p>


                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section>
        <div className="container-fluid pt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-3 d-none d-sm-block">
                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>BRANDS</span>
                <div className="mb-1"></div>
                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                <div className="border border-white border-opacity-10 mb-2 "></div>
                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                  <NavLink exact to="/grandstream" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; Grandstream</p></NavLink>
                  <NavLink exact to="/Dinstar" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Dinstar</p></NavLink>
                  <NavLink exact to="/NEC" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; NEC</p></NavLink>
                  <NavLink exact to="/Alcatel" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Alcatel</p></NavLink>
                  <NavLink exact to="/YeaLink" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;YeaLink</p></NavLink>
                  <NavLink exact to="/Poly" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Poly</p></NavLink>
                  <NavLink exact to="/Cisco" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Cisco</p></NavLink>

                </p>

                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>SOLUTIONS</span>
                <div className="mb-1"></div>
                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                <div className="border border-white border-opacity-10 mb-2 "></div>
                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                  <NavLink exact to="/Enterprise-Manufacturing" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Enterprise-Manufacturing</p></NavLink>
                  <NavLink exact to="/Security&Surveillance" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Security & Surveillance</p></NavLink>
                  <NavLink exact to="/EducationHospitality" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}> Education & Hospitality</p></NavLink>
                  <NavLink exact to="/BusinessConference" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Business Conference</p></NavLink>

                </p>

                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">

                  <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/GWN7605">
                          <img src={require("../images/GWN7605.png")} style={{ width: "263px" }} alt="GWN7605" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>WiFi Access Point (GWN7605)</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream's powerful WiFi Access Points offer high performance networking, tremendous WiFi coverage range read more.. </p>
                        </NavLink>
                      </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/BusinessConference">
                          <img src={require("../images/Business-Conferencing.png")} style={{ width: "263px" }} alt="Business-Conferencing" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Business Conferencing</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream’s video conferencing solutions offer small and medium sized businesses flexibility read more..</p>
                        </NavLink>
                      </div>            </div>
                    <div className="carousel-item" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/wp810">
                          <img src={require("../images/wifi-cordless.png")} style={{ width: "263px" }} alt="wifi-cordless" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Wi-Fi IP Phone (WP 810)</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>WP810 is a portable Wi-Fi IP phone designed to suit a variety of enterprises  Read More..</p>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div>
                  <img src={require("../images/img/ip-voice-telephony-bnr.jpg")} className="w-100" alt="ip-voice-telephony-bnr" />
                </div>
                <div className="mt-3">
                  <p className="" style={{ color: "#333333", fontSize: '27px', fontFamily: "sans-serif", fontWeight: "600" }}>
                    GRANDSTREAM IP PHONES
                  </p>
                </div>
                <div className="" style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                  <p>
                    Grandstream VoIP Phones offer extraordinary usefulness and adaptability. All Grandstream IP Phones are completely SIP viable and have wide interoperability with most VoIP frameworks. Skyward is among the most notable Grandstream IP Phones seller and partner in Mumbai and Bengaluru . Profit best market value on Grandstream IP Phones through Skyward the approved seller, wholesaler, stockist, Certified Partner and vendor of Grandstream IP Telephony in Pune . Reach us to benefit best costs and markdown for Grandstream IP Phones.
                  </p>
                  <p>
                    Developing a powerful solution that is customized for every individual streamlines communication and workload efficiency. Grandstream makes state-of-the art telephony devices more accessible and easier to deploy than ever before. Take a look into our wide range of telephony endpoints and construct the perfect network with Grandstream, today.
                  </p>
                </div>

                <div className="row mb-5">
                  <div className="col-md-3">

                    <div className="d-flex btnHover justify-content-center align-items-center imgHover border p-1 border-dark ">
                      <img src={require("../images/carrier-grade-ip-phone.jpg")} className="w-75 " alt="carrier-grade-ip-phone" />
                      <NavLink className="imgbtn text-center" exact to="/carrier-grade-ip-phone"> <button className="btn border-none">See Product</button></NavLink>
                    </div>
                  </div>

                  <div className="col-lg-9">
                    <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>Carrier-Grade IP Phones</p>
                    <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                      The GRP2600 series are carrier-grade IP phones designed for mass deployment. This series of next-generation IP phones features a sleek new design, a reimagined user experience, unified firmware and powerful feature options. Designed for enterprises, service providers and other high-volume markets, the GRP series offers a powerful, easy-to-use and easy-to-deploy voice platform with next-gen features for high-end users.
                    </p>
                    <div className="border border-dark border-opacity-10 "></div>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-md-3">
                    <div className="d-flex  btnHover justify-content-center align-items-center imgHover border  p-2 border-dark ">
                      <img src={require("../images/High-end-ip-phones.jpg")} className="w-75 " alt="carrier-grade-ip-phone" />
                      <NavLink className="imgbtn text-center" exact to="/High-end-ip-phones"> <button className="btn border-none">See Product</button></NavLink>

                    </div>
                  </div>
                  <div className="col-lg-9">
                    <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>High End IP Phones</p>
                    <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                      Enable high-volume users to succeed with the ability to handle more calls and access to superior efficiency tools. Grandstream offers High End IP phones for workers who need access to advanced telephony features, multiple line support, and mobility options. The GXP2100 series is the perfect choice for receptionists, front desk workers and desk employees.
                    </p>
                    <div className="border border-dark border-opacity-10 "></div>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-md-3">
                    <div className="d-flex  btnHover justify-content-center align-items-center imgHover border  p-2 border-dark ">
                      <img src={require("../images/basic-phone.jpg")} className="w-75 " alt="carrier-grade-ip-phone" />
                      <NavLink className="imgbtn text-center" exact to="/Basic-IP-Phones"> <button className="btn border-none">See Product</button></NavLink>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>Basic IP Phones</p>
                    <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                      Intuitive IP phones put effective and simple communication access in the hands of users who need it. Our Basic IP Phones offer efficient, no-fuss communication for those who need a simple connection. Ideal for hotel rooms, information booths, and warehouse deployments.
                    </p>
                    <div className="border border-dark border-opacity-10 "></div>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-md-3">
                    <div className="d-flex btnHover justify-content-center align-items-center imgHover border  p-2 border-dark ">
                      <img src={require("../images/mid-range-ip-phone.jpg")} className="w-75 " alt="carrier-grade-ip-phone" />
                      <NavLink className="imgbtn text-center" exact to="/Mid-Range-IP-Phones"> <button className="btn border-none">See Product</button></NavLink>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>Mid-Range IP Phones</p>
                    <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                      The GXP1700 series of Mid-Range IP Phones was designed for growing businesses. These stylish phones have mid-range features and capacity that your business can grow with and depend with the competitive price that you need now. The GXP1700 series offers a great addition to any desktop thanks to its sleek, high-end design coupled with a variety of mid-range productivity tools that keep users efficient and productive.                    </p>
                    <div className="border border-dark border-opacity-10 "></div>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-md-3">
                    <div className="d-flex btnHover justify-content-center align-items-center imgHover border  p-2 border-dark ">
                      <img src={require("../images/softphone-app.jpg")} className="w-75 " alt="carrier-grade-ip-phone" />
                      <NavLink className="imgbtn text-center" exact to="/Software-App"> <button className="btn border-none">See Product</button></NavLink>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>Softphone App</p>
                    <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                      Grandstream Wave is a FREE softphone application that allows users to make and receive voice calls through up to 6 different business or residential SIP accounts on any Android™ device from anywhere in the world.
                    </p>
                    <div className="border border-dark border-opacity-10 "></div>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-md-3">
                    <div className="d-flex btnHover justify-content-center align-items-center imgHover border  p-2 border-dark ">
                      <img src={require("../images/extension-module.png")} className="w-75 " alt="carrier-grade-ip-phone" />
                      <NavLink className="imgbtn text-center" exact to="/extension-modules"> <button className="btn border-none">See Product</button></NavLink>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>Extension Modules</p>
                    <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                      High call-volume users require access to a clear and efficient way to easily route and control calls. Our GXP2200EXT empowers users with dual-colored speed dial/BLF keys to identify open lines and route calls effectively.
                    </p>
                    <div className="border border-dark border-opacity-10 "></div>
                  </div>
                </div>



                <div className="row mt-5">
                  <div className="col-md-3 d-none d-sm-block">
                    <div className="d-flex btnHover justify-content-center align-items-center imgHover border  p-2 border-dark ">
                      <img src={require("../images/dect-cordless.jpg")} className="w-75 " alt="carrier-grade-ip-phone" />
                      <NavLink className="imgbtn text-center" exact to="/DectCordless"> <button className="btn border-none">See Product</button></NavLink>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>DECT Cordless</p>
                    <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                      Mobilize a VoIP Network to create a productive and flexible communications environment. The DP750 and DP720 allows users to be free from their desks and move through any business, warehouse, retail store or residential home without restrictions.
                    </p>
                    <div className="border border-dark border-opacity-10 "></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >


      <Footer />
    </>
  )
}

export default Ipvoice;