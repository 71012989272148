import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { NavLink } from "react-router-dom";
const HT802 = () => {

    return (
        <>
            <Header />

            <section style={{ backgroundColor: "#090909" }}>
                <div className="container">
                    <div className="row text-center d-flex  justify-content-center align-items-center">
                        <div className="col-lg-6  ">
                            <div className="text-lg-start p-lg-2 ps-lg-5">
                                <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>HT802</p>
                            </div>
                        </div>
                        <div className="col-lg-6   text-lg-center">
                            <div className="p-lg-2">
                                <p className="text-white ">
                                    <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home /</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}>Grandstream / ATA / <b style={{ fontSize: "16px" }}>HT802</b>
                                    </span></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="container mt-5 ">
                        <div className="row">

                            <div className="col-lg-4 " >
                                <div id="carouselExampleFade" class="carousel slide " data-bs-ride="carousel">
                                    <div class="carousel-inner text-center border" style={{ height: "320px" }}>

                                        <div class="carousel-item active animated ">

                                            <img className=" w-100" src={require("../images/ht802-slide1.png")} alt="ht802-slide1" />
                                        </div>
                                        <div class="carousel-item ">

                                            <img className=" w-100" src={require("../images/ht802-slide2.png")} alt="ht802-slide2" />
                                        </div>
                                        <div class="carousel-item ">

                                            <img className=" w-100" src={require("../images/ht802-slide3.png")} alt="ht802-slide3" />
                                        </div>

                                        <div class="carousel-item ">

                                            <img className=" w-100" src={require("../images/ht802-slide4.png")} alt="ht802-slide4" />
                                        </div>
                                    </div>
                                    <button class="carousel-control-prev pe-2" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                                        <span class="carousel-control-next-icon p-4 bg-dark" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next ps-2" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                                        <span class="carousel-control-prev-icon p-4 bg-dark" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>


                            <div className="col-lg-8">
                                <div>
                                    <p className="" style={{ fontSize: "40px", fontFamily: "serif", fontWeight: "600" }} >
                                        HT802
                                    </p>
                                    <p style={{ fontSize: "30px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>
                                        Analog Telephone Adaptors
                                    </p>
                                    <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                                        Designed for users looking to connect their analog devices to a VoIP network at home or in the office. The HT812 is a powerful analog telephone adapter that is easily deployable and manageable. It comes equipped with 2 FXS ports and an integrated Gigabit NAT router.
                                    </p>
                                    <NavLink exact to="/Contact-us" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Request a Quote</button></NavLink>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mt-3">
                    <div className="row ps-3">
                        <div>
                            <p style={{ fontSize: "32px", fontFamily: "serif", color: "#333333", fontWeight: "500" }}>
                                Description

                                <div className="border border-primary" style={{ width: "55px" }}></div>
                                <div className=" mb-2 " style={{ borderBottom: "1px solid lightgray" }}></div>
                            </p>
                            <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                                The HT812 delivers powerful VoIP technology and routing capabilities to home and office environments, and allows users to successfully connect their analog devices to a manageable and powerful VoIP network. Built using Grandstream’s market-leading SIP ATA/gateway technology, with millions of units successfully deployed worldwide, this powerful ATA features exceptional voice quality in various applications and environments. The HT812 comes with 2 easy-to-use FXS ports, an integrated Gigabit NAT router, state-of-the-art encryption with a unique security certificate per unit, automated provisioning for volume deployment and device management and outstanding network performance.
                            </p>

                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container ">
                    <div className="row">
                        <div className="col-lg-6 ">
                            <p className="ps-3" style={{ fontSize: "30px", fontFamily: "serif", color: "#333333" }}>
                                Features
                            </p>
                            <ul style={{ fontSize: "16px", fontFamily: "sans-serif", lineHeight: "30px", textAlign: "justify" }}>
                                <li>
                                    Supports 2 SIP profiles through 2 FXS ports and a single 10/100Mbps port
                                </li>

                                <li>
                                    TLS and SRTP security encryption technology to protect calls and accounts
                                </li>
                                <li>
                                    Automated provisioning options include TR-069 and XML config files
                                </li>

                                <li>
                                    Supports 3-way voice conferencing
                                </li>
                                <li>
                                    Failover SIP server automatically switches to secondary server if main server loses connection
                                </li>
                                <li>Supports T.38 Fax for creating Fax-over-IP</li>
                                <li>Supports a wide range of caller ID formats</li>
                                <li>
                                    Use with Grandstream’s UCM series of IP PBXs for Zero Configuration provisioning
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 text-center">
                            <div>
                                <img className=" w-100 p-lg-5" src={require("../images/ht802-slide1.png")} alt="ht802-slide2" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section >
                <div className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="text-center p-5" style={{ backgroundColor: "#F5ECEC", }} >
                                    <p style={{ fontSize: "22px", fontFamily: "serif", fontWeight: "600" }}>
                                        Quick Enquiry
                                    </p>
                                    <p style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                                        For the product quotation please fill the given form our team will get back to you shortly after analysing your needs
                                    </p>

                                    <div className="pt-3">
                                        <NavLink className=" text-decoration-none fs-6" to="/Contact-us" >
                                            <span className="btn-round send-btn">SEND ENQUIRY</span>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 d-none d-sm-block">
                                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                                    <div className="carousel-indicators sliderbtn">

                                        <input style={{ width: "20px", height: "15px" }} type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active " aria-current="true" aria-label="Slide 1"></input>
                                        <input style={{ width: "20px", height: "15px" }} className=' ' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></input>
                                        <input style={{ width: "20px", height: "15px" }} className=' ' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></input>
                                        <input style={{ width: "20px", height: "15px" }} className=' ' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></input>
                                    </div>

                                    <div class="carousel-inner" style={{ height: "320px" }}>
                                        <div className="carousel-item active animated" data-bs-interval="1500">
                                            <div className="row">
                                                <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                                                    <img src={require("../images/GVC3210-slide.jpg")} className="w-100 bg-gradient" alt="" />
                                                </div>
                                                <div className="col-lg-6">
                                                    <div>
                                                        <p className="m-0 p-0">Full HD Conferencing</p>
                                                        <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>GVC3210 Video Conferencing </p>
                                                        <div className="border border-primary" style={{ width: "55px" }}></div>

                                                        <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}> The GVC3210 is an innovative video conferencing endpoint ideal for small to medium businesses who seek an easy-to-use yet potent video conferencing solution.  </p>

                                                        <NavLink exact to="/GVC3210" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item" data-bs-interval="1500">

                                            <div className="row">
                                                <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                                                    <img src={require("../images/GWN7605.png")} className="w-100" alt="" />
                                                </div>
                                                <div className="col-lg-6">
                                                    <div>
                                                        <p className="m-0 p-0">Networking Solutions</p>
                                                        <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>Wifi Access Point</p>
                                                        <div className="border border-primary" style={{ width: "55px" }}></div>

                                                        <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}> GWN7630 Wi-Fi access point for small to medium sized businesses, multiple floor offices, commercial locations and branch offices. </p>
                                                        <NavLink exact to="/wifi-access-point" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="carousel-item" data-bs-interval="1500">

                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <img src={require("../images/img/GXV3380-slide4.png")} className="w-100" alt="" />
                                                </div>
                                                <div className="col-lg-6">
                                                    <div>
                                                        <p className="m-0 p-0">Video Ip Phone</p>
                                                        <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>GXV 3380</p>
                                                        <div className="border border-primary" style={{ width: "55px" }}></div>

                                                        <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}> The GXV3380 is a powerful High-End Smart Video Phone for Android that combines a 16 line IP phone with a multi-platform video collaboration solution.   </p>
                                                        <NavLink exact to="/GXV3500" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item" data-bs-interval="1500">

                                            <div className="row">
                                                <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                                                    <img src={require("../images/img/UCM6200-slide3.png")} className="w-100" alt="" />
                                                </div>
                                                <div className="col-lg-6">
                                                    <div>
                                                        <p className="m-0 p-0"> UCM series IP PBXs</p>
                                                        <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>UCM6200 series IP PBX </p>
                                                        <div className="border border-primary" style={{ width: "55px" }}></div>

                                                        <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>  UCM6200 series IP PBX appliance combines enterprise-grade voice, video, data, and mobility features in an easy-to-manage solution. </p>
                                                        <NavLink exact to="/UCM6200-Series" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </section>


            <Footer />
        </>
    )
}
export default HT802;