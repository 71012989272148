import React from "react";
import { NavLink } from "react-router-dom";
import "./Dinstar.css";
import Footer from "./Footer";
import Header from "./Header";

const Dinstar = () => {
   return (
      <>
         <Header />

         <section>
            <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/header-bnr.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center', borderBottom: "2px solid white" }}>
               <div className="container">
                  <div className="row text-center d-flex  justify-content-center align-items-center">
                     <div className="col-lg-6 text-lg-start">
                        <div className="pb-lg-5 pt-lg-5">
                           <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>Dinstar</p>

                        </div>
                     </div>
                     <div className="col-lg-6   text-lg-center">
                        <div className=" pt-lg-5 pb-lg-5">
                           <p className="text-white">
                              <span className=""><NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home /</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}>  <NavLink exact to="/Dinstar" className="text-white">Dinstar</NavLink> </span></span>
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section>
            <div className="container-fluid  text-dark" style={{ backgroundColor: "#EEECEC" }}>
               <div className="container">
                  <div className="row">
                     <div className="col-lg-8 ">
                        <p className=" mt-3 fs-2 " style={{ color: "#186191", fontSize: '36px', fontWeight: "600", fontFamily: "serif" }}>Meet Dinstar</p>
                        <p className="mt-3" style={{ fontSize: "16px", fontFamily: "serif" }}>
                           Dinstar has the most complete VOIP gateway products, which covered GSM/CDMA/LTE Wireless Gateway, E1/T1 Trunk Gateway, FXS/FXO Analog Gateway, SIMBANK and SIMCLOUD system etc. Through our increasing innovation ability, excellent user experience, high performance-price ratio and tailored OEM/ODM service, Dinstar products and services have been widely deployed and recognized by telecom operators, system integrators, and enterprises around the world. With company culture of Honesty, Concentration, Innovation and Open-mind, our staffs gather and unify tightly as a team, aim to establish win-win relationships with our customers.
                        </p>
                        <NavLink exact to="/Contact-us"><button type="button" className=" mb-2 btn  me-md-3 " style={{ backgroundColor: "#186191", color: "white" }}>Get a Quote </button></NavLink>

                     </div>
                     <div className="col-lg-4  text-center">
                        <img className="w-100  mt-5" src={require("../images/dinstar-logo.png")} alt="dinstar-logo" />
                     </div>
                  </div>
               </div>
            </div>
         </section>


         <section>
            <div className="container-fluid">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-6 p-5">
                        <p className="" style={{ fontSize: "24px", fontFamily: "serif", color: "#333333" }}>
                           Walk into Dinstar
                           <div className="border border-primary mt-lg-2" style={{ width: "55px" }}></div>
                           <div className="border border-dark border-opacity-10 mb-3 "></div>
                        </p>
                        <p style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#666666" }}>
                           Dinstar offers the complete IP Communication products including SME VoIP PBX, Session Border Controller, GSM/WCDMA/LTE Wireless Gateway, E1/T1 Trunk Gateway, FXS/FXO Analog Gateway, SIMBank and SIMCloud system. Through our increasing innovation ability, excellent user experience, high performance-price ratio and tailored OEM/ODM service, Dinstar products and services have been widely deployed and recognized by telecom operators, system integrators, enterprises, SMBs and OEM partners around the world for our reliability, quality and innovation.
                        </p>
                     </div>
                     <div className="col-lg-6">
                        <div>
                           <img src={require("../images/img/dinstar-1.png")} className="w-100" alt="" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section>
            <div className="container-fluid mt-3" style={{ backgroundColor: "#186191" }}>
               <div className="container">
                  <div className="row g-2 p-3">
                     <div className="col-lg-7 pt-3">
                        <div className="text-white">
                           <p className=" mt-3 mb-3 fs-3 fw-bold" style={{ fontSize: "34px", fontFamily: "sans-serif" }}>Bright future with bright Brand</p>
                           <p style={{ fontSize: "18px", fontFamily: "sans-serif" }}>To know more about Dinstar Products & Solutions connect with our sales person!!</p>
                        </div>
                     </div>
                     <div className="col-lg-5 text-center pt-5">
                        <a href="/Contact-us"><button style={{ fontSize: "14px", border: "none", outline: "none", padding: "10px 26px" }} className=" rounded-pill btn-lg text-black bg-white">Contact Us</button></a>

                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section>
            <div className="container-fluid pt-5 pb-3">
               <div className="container">
                  <div className="row">
                     <div className="">
                        <p className=" fw-bold" style={{ fontSize: "32px", fontFamily: "serif" }}>Products and Solutions</p>
                        <p style={{ fontSize: "14px", fontFamily: "sans-serif" }}>Our Products & Solutions help your business to grow in the telecom sector.</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>


         <section>
            <div className="container-fluid">
               <div className="container">
                  <div className="row g-4">
                     <div className="col-lg-3 h-100">
                        <div className=" business-border ">
                           <div className="text-center">
                              <img className="w-100" src={require("../images/SME-IP-PBX.png")} alt="SME-IP-PBX" />
                           </div>
                           <p className="text-start ps-3" style={{ fontSize: '20px', color: "#555555", fontWeight: "600", fontFamily: "serif" }}>SME IP PBX</p>
                           <p className="text-start ps-3" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>UC series IP Phone systems integrate distinctive physic interfaces like</p>
                           <NavLink exact to="/SME-IP-PBX"><p className="text-start ps-3" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</p></NavLink>
                        </div>
                     </div>
                     <div className="col-lg-3 h-100">
                        <div className=" business-border ">
                           <div className="text-center">
                              <img className="w-100" src={require("../images/Digital-VoIP-Gateway.png")} alt="Digital-VoIP-Gateway" />
                           </div>
                           <p className="text-start ps-3" style={{ fontSize: '20px', color: "#555555", fontWeight: "600", fontFamily: "serif" }}>Digital VoIP Gateway</p>
                           <p className="text-start ps-3" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>MTG series are E1/T1 Digital VoIP Gateways that seamlessly connect VoIP</p>
                           <NavLink exact to="/Digital-VoIP-Gateway"><p className="text-start ps-3" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</p></NavLink>
                        </div>
                     </div>
                     <div className="col-lg-3 h-100">
                        <div className=" business-border ">
                           <div className="text-center">
                              <img className="w-100" src={require("../images/GSM-VoIP-Gateway.png")} alt="GSM-VoIP-Gateway" />
                           </div>
                           <p className="text-start ps-3" style={{ fontSize: '20px', color: "#555555", fontWeight: "600", fontFamily: "serif" }}>GSM VoIP Gateway</p>
                           <p className="text-start ps-3" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>Dinstar UC2000 series VoIP GSM/3G/4G Gateways offer complete choices</p>
                           <NavLink exact to="/GSM-VoIP-Gateway"><p className="text-start ps-3" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</p></NavLink>
                        </div>
                     </div>
                     <div className="col-lg-3 h-100">
                        <div className=" business-border ">
                           <div className="text-center">
                              <img className="w-100" src={require("../images/Analog-VoIP-Gateway.png")} alt="Analog-VoIP-Gateway" />
                           </div>
                           <p className="text-start ps-3" style={{ fontSize: '20px', color: "#555555", fontWeight: "600", fontFamily: "serif" }}>Analog VoIP Gateway</p>
                           <p className="text-start ps-3" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>Dinstar DAG series Analog VoIP gateway are cost-effective, reliable VoIP gateways</p>
                           <NavLink exact to="/Analog-VoIP-Gateway"><p className="text-start ps-3" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</p></NavLink>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section>
            <div className="container-fluid mt-4">
               <div className="container">
                  <div className="border border-dark "></div>
                  <div className="row mt-2">
                     <p className="text-center" style={{ fontSize: "32px", fontWeight: '600', fontFamily: 'serif', color: "#333" }}>DINSTAR VoIP Gateways Help You Migrate to VoIP Easily</p>
                     <div>
                        <img src={require("../images/img/dinstar-diagram.png")} className="w-100" alt="" />
                     </div>
                  </div>
               </div>
            </div>
         </section>


         <Footer />
      </>
   )
}
export default Dinstar;