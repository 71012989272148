import React from "react";
import Footer from "./Footer";
import Header from "./Header";
const VideoConferencingbenefits = () => {

    return (
        <>
            <Header />
            <section>
                <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/header-bnr.png")})`, backgroundAttachment: "local", height: "25vh", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center'}}>
                    <div className="container">
                        <div className="row d-flex  justify-content-center align-items-center">

                            <div className="text-center">
                                <p className="text-white" style={{ paddingTop:"7%",fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>Video Conferencing Benefits</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mt-5">
                                <img src={require("../images/video-conference-img.jpg")} className="w-100" alt="" />
                            </div>
                            <div className="col-lg-6 mt-5">
                                <p style={{ fontSize: "24px", fontFamily: 'serif', fontWeight: "600" }}>How Video Conferencing can improve the business communication system</p>

                                <p style={{ textAlign: "justify", fontSize: "14px", lineHeight: "1.7", fontFamily: "sans-serif", color: "#666666" }}>
                                    One of the greatest blessings of modern technology has been video conferencing. Similar in concept to an actual conference where people sit and talk, exchange and present ideas face-to-face, video conference also involves interaction between different people in-person. The similarity, however, ends here. While the conventional conference model cannot connect people stationed at different sites or places across the world, modern technology has enabled office colleagues and participants to get connected and have a full-fledged meeting between two or more people from anywhere on the planet.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mt-4">
                    <div className="row">
                        <p style={{ fontSize: "24px", fontFamily: "serif", color: "#555555", fontWeight: "600" }}>The Technical Aspect Behind Video Conferencing :</p>
                        <div style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7",textAlign:"justify" }}>
                            <ul>
                                <li>
                                    Two or more people from different sites or locations can conduct an actual conference by virtue of computer networks. People are able to hear each other via audio data and are able to see each other via video data.
                                </li>
                                <li>
                                    Point-to-point conferencing happens between two individuals use a computer-mounted video conferencing camera, speakers and microphone.
                                </li>
                                <li>
                                    Multipoint video conferencing enables more than two people to listen and speak to one another using the same technology.
                                </li>
                                <li>
                                    While the concept was an expensive affair a decade or so back, today, thanks to smartphones and mobiles, VoIP (Voice over the internet) and smarter computer devices – a video conference can easily be conducted now taking only a few minutes to connect all participants irrespective of their location.
                                </li>
                                <li>
                                    One of the most important aspects of corporate video conferencing services today is to focus on making the solution mobile-friendly. This means that corporates need to implement video conferencing solutions that are flexible and adaptable connecting easily the remotest of the worker because modern-day corporate culture is evolving rapidly to support remote working options.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <p style={{ fontSize: "24px", fontFamily: "serif", color: "#555555", fontWeight: "600" }}>Video Conferencing Benefits :</p >
                        <p style={{ fontSize: "18px", fontFamily: "serif", color: "#666666",textAlign:"justify" }}>Some of the reasons why an increasing number of corporates and business entities are embracing robust video conferencing elucidations are:-</p>
                        <div style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7" ,textAlign:"justify"}}>
                            <ul>
                                <li>
                                    Keeps employees connected and hence engaged. In the global world today, most corporates hire remote workers. As per a study, it was found that about 89% of these remote staff felt that video conference helps them to stay in touch with their colleagues and also finish work on time.
                                </li>
                                <li>
                                    And about 98% felt that through the use of the video conferencing services they are able to build and strengthen their relationships with their colleagues.
                                </li>
                                <li>
                                    It is the best option for vis-à-vis traveling. Video conferencing enables teams and their members to hold their business meetings at any time of the day. Hence the need to travel reduces a saving substantial amount of money for the organization.
                                </li>
                                <li>
                                    This aspect of technology has also served as a boon to people who need to work from outside the office, say for example people who work from home can stay in touch with their colleagues in office through the use of proper video conferencing equipment.
                                </li>
                                <li>
                                    Has removed the dependency on time. One of the biggest video conferencing benefits is that now people across nations can meet and discuss at any time of the day, any number of times in a day and even at short notices. This has opened the doors for smart working.
                                </li>
                                <li>It promotes effective communication. Since the video conferencing camera shows the person right in front, a lot can be gauged and fathomed through the body language and non-verbal communication of the speaker.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>


            <Footer />
        </>
    )
}
export default VideoConferencingbenefits;
