import React from "react";
import "./EducationHospitality.css";
import Footer from "./Footer";
import Header from "./Header";
import { NavLink } from "react-router-dom";
const EducationHospitality = () => {

   return (
      <>
         <Header />



         <section>
            <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/education-bnr-1.png")})`, backgroundAttachment: "local", height: "30vh", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center', borderBottom: "2px solid white" }}>
               <div className="container">
                  <div className="row text-center d-flex  justify-content-center align-items-center">
                     <div className="col-lg-6 text-lg-start">
                        <div className=" pt-lg-5 pb-lg-5">
                           <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>Enterprise & Manufacturing</p>
                        </div>
                     </div>
                     <div className="col-lg-6   text-lg-end">
                        <div className=" pt-lg-5 pb-lg-5">
                           <p className="text-white">
                              <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}>  <NavLink exact to="/Solution" className="text-white">/ Solutions </NavLink>/ Enterprise & Manufacturing </span></span>
                           </p>



                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section>
            <div className="container-fluid pb-lg-5 bg-light">

               <div className="row">
                  <div className="col-lg-1"></div>
                  <div className="col-lg-10 ps-lg-5">
                     <div className="pt-lg-5">
                        <p className="text-start ps-lg-5 " style={{ fontSize: "30px", fontFamily: "serif", color: "#333", fontWeight: "600" }}>
                           Solution for Hospitality Industry
                        </p>
                        <div className="ps-lg-5" style={{ textAlign: "justify", fontSize: "14px", fontFamily: "sans-serif", color: "#666", lineHeight: '1.7' }}>
                           <p>
                              The hospitality sector in India is booming at an unprecedented pace. To excel at providing professional services and top class guest experience, upgradation of existing communication infrastructure has become the most important factor. Intense competition in hotel industry drives hoteliers to pursue the high efficiency of communications and information management system. Advanced and customized communication solution not only facilitates hotel operations but also ensures high-quality guest experience and develops guest loyalty. In the hospitality space, several layers of communication can be drastically cut down to ensure better and more efficient internal as well as external communication. With an IP-based network infrastructure, hotels can deploy a flexible solution integrating data, voice, video, and convergence applications tailored to their needs. The converged network is a strategic asset for hotels to profitably improve guests’ overall experience. Hotels can add services and reduce costs by integrating existing communications, security, and building automation systems over one secure, flexible, and scalable network. Hotels can respond to guests in a much better way by offering automated check-in and checkout service, online caretaker service and help desk requests. It allows hotels to improve their scope of sales and marketing capabilities, enhance room management, upgrade front-office services, and develop better employee and human resources management.
                              It allows integrating with Hotel’s existing database management solutions, reservation & booking systems, and human resource management systems thereby enhancing its performance. Integration is certainly the way forward!
                              The full scope of the hospitality solution includes powerful IP-PBX as the server, FXS Gateway as the bridge between VoIP and hotel analog phones, and a wide range of compatible hotel phones for reception, rooms and back office.
                           </p>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-1"></div>
               </div>

               <div className="row">
                  <div className="col-lg-1"></div>
                  <div className="col-lg-10 ps-lg-5">
                     <div className="pt-lg-5">
                        <p className="text-start ps-lg-5 " style={{ fontSize: "30px", fontFamily: "serif", color: "#333", fontWeight: "600" }}>
                           Systems
                        </p>
                        <div className="ps-lg-5" style={{ textAlign: "justify", fontSize: "14px", fontFamily: "sans-serif", color: "#666", lineHeight: '1.7' }}>
                           <p>
                              <b>IP-PBX Server</b> <br />
                              An IP PBX can be referred to as clubbing of many services into one system: a business phone system, a unified communication system, or simply as a “PBX.” It’s essentially the way businesses make phone calls by using a centralized switching system rather than numerous independent phone lines. These services are offered basically to handle the traffic between each individual work station inside the offices and act as a gateway to the outside world. The features in IPPBX system allows hotels respond to customer inquiries and reservation without delay and in professional manner, helping the customers with good experience. The embedded system makes management and maintenance easy with intuitive Web interface, ensuring carrier-grade operation and reliability.
                           </p>
                        </div>
                     </div>
                     <div className="pt-lg-3">
                        <div className="ps-lg-5" style={{ textAlign: "justify", fontSize: "14px", fontFamily: "sans-serif", color: "#666", lineHeight: '1.7' }}>
                           <p>
                              <b>Hotel Phone</b> <br />
                              Reception desk, Guest Rooms, Lobby Area, Kitchen, Dining area, Room Service and Back office all require some kind of phone connectivity. IP Phones that are compatible with IP-PBX systems surely offers the smooth communication experience.
                           </p>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-1"></div>
               </div>
            </div>
         </section>

         <section style={{ backgroundColor: "#E8E6E6" }}>
            <div className="container ">
               <div className="row pb-lg-5">
                  <div className="col-lg-1"></div>
                  <div className="col-lg-10">
                     <p className="pt-lg-5" style={{ fontSize: "30px", fontWeight: "600", fontFamily: "serif", color: "#186191" }}>Benefits in Services</p>

                     <b style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#555555" }}>Front Desk
                     </b><br />
                     <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7" }}>
                        Reception desk is the front line of a hotel, where the reception staff welcomes the guests, helps in checks-ins and check outs of Guests, Does Room reservation, provides information and takes care of guest’s various other needs. A suitable front desk phone and hotel specific telephony features helps reception staff yields more with less effort. To achieve all these, a clear voice call and a reliable telephone system are crucial.
                     </p>

                     <b style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#555555" }}>Guest Rooms and Guest Services
                     </b><br />
                     <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7" }}>
                        In order to extend extra ordinary features like wake-up calls and other services, guest room phones have to functioning at its utmost. The built in Unified Communication software inside the IP-PBX, allows the guest room phones deliver superior guest experience with crystal clear voice call and other hospitality features like Wake-up call service, Intra-room calling, Voice Messages, SMS Notification, Detailed Phone Bills etc.
                     </p>

                     <b style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#555555" }}>Lobby
                     </b><br />
                     <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7" }}>

                        To extend easy communication access to guests, just placing service phones in lobby or other shared public areas does the trick. Guests simply need to lift the handset to contact the operator and get the desired service following the dialing directions. In this way, hotels could offer superior customer service to all guests.
                     </p>


                     <b style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#555555" }}>Back Office
                     </b><br />
                     <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7" }}>

                        IP communication system comes with array of business intelligence features to enhance the collaboration of front and back office and improve the customer experience.
                     </p>

                     <b style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#555555" }}>Improve efficiency and generate revenue
                     </b><br />
                     <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "1.7" }}>

                        The staff efficiency increases due to effective communications with advanced features like 3-way calling, intercom, mobility extension, and more. Hotels are also able to generate revenue by charging for telephone calls, conferencing, video conferencing, etc.
                     </p>
                  </div>
                  <div className="col-lg-1"></div>
               </div>
            </div>
         </section>

         <section className="bg-light">
            <div className="container">
               <div className="row pt-lg-5 pb-lg-5">
                  <div className="col-lg-1"></div>
                  <div className="col-lg-10">
                     <div className="text-start">
                        <p className="fw-bold" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                           Skyward Telecom Solutions from Skyward Telecom are devised after thorough understanding of every possible scenario, and based on research and industry standard applications to offer low cost powerful options for business continuity issues and to remove communication bottlenecks by providing constant access to employees. Skyward Telecom solutions have reliability and proven communication technology to enhance hotel IP Telephony solutions – making it easier to operate and ensuring seamless communication.
                        </p>
                     </div>

                  </div>

                  <div className="col-lg-1"></div>

               </div>
            </div>
         </section>

         <section>   
            <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/bg31.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
               <div className="container">
                  <div className="row d-flex justify-content-center align-items-center">
                     <div className="col-lg-9">
                        <div className="ps-2 p-lg-4" style={{lineHeight:"2"}}>
                           <p className="fs-2" style={{ color: "white", fontWeight: "700", fontFamily: "sans serif" }}>
                              Bright Solutions. Profitable Results.

                              <p style={{ fontSize: '18px', color: "white", fontWeight: "700", fontFamily: "sans serif" }}>
                                 Experience you can trust, service you can count on.
                              </p></p>
                        </div>
                     </div>
                     <div className="col-lg-3 mb-2">
                        <div className="text-lg-center p-lg-5">
                           <a href="/Contact-us"><button style={{ fontSize: "14px", border: "none", outline: "none", padding: "10px 26px" }} className=" rounded-pill btn-lg text-black bg-white">Schedule a Demo</button></a>

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section>
            <div className="container">
               <div className="pt-5 text-center">
                  <img src={require("../images/office-sloutions1.png")} className="w-50" alt="office-sloutions1" />
               </div>
            </div>
         </section>


         <Footer />
      </>
   )
}
export default EducationHospitality;