import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { NavLink } from "react-router-dom";
const HPEProLiantDL20Gen10E2224 = () => {

    return (
        <>
            <Header />

            <section style={{ backgroundColor: "#090909" }}>
                <div className="container">
                    <div className="row text-center d-flex  justify-content-center align-items-center">
                        <div className="col-lg-6  ">
                            <div className="text-lg-start p-lg-2 ps-lg-5">
                                <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>HPE ProLiant DL20 Gen10 E 2224 server</p>
                            </div>
                        </div>
                        <div className="col-lg-6   text-lg-center">
                            <div className="p-lg-2">
                                <p className="text-white ">
                                    <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home /</NavLink>  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/Servers" > Server /</NavLink><span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}><b style={{ fontSize: "16px" }}> HPE ProLiant DL20 Gen10 E 2224 server</b>
                                    </span></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container">
                    <div className="container mt-5 ">
                        <div className="row">

                            <div className="col-lg-4 " >
                                <div id="carouselExampleFade" class="carousel slide " data-bs-ride="carousel">
                                    <div class="carousel-inner text-center pt-5 border" style={{ height: "280px" }}>
                                        <div class="carousel-item active animated">

                                            <img className="w-75" src={require("../images/HPE ProLiant DL20 Gen10 E 2224 server-slide-1.jpeg")} alt="" />
                                        </div>
                                        <div class="carousel-item">

                                            <img className="w-75" src={require("../images/HPE ProLiant DL20 Gen10 E 2224 server-slide-1.jpeg")} alt="" />
                                        </div>

                                    </div>
                                    <button class="carousel-control-prev pe-2" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                                        <span class="carousel-control-next-icon p-4 bg-dark" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next ps-2" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                                        <span class="carousel-control-prev-icon p-4 bg-dark" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>


                            <div className="col-lg-6">
                                <div>
                                    <p className="" style={{ fontSize: "40px", fontFamily: "serif", fontWeight: "600" }} >
                                        HPE ProLiant DL20 Gen10 E 2224 server
                                    </p>

                                    <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                                        HPE ProLiant DL20 Gen10 Server The dense and compact HPE ProLiant DL20 Gen10 Server powered by Intel Xeon E Processor, Pentium and Core i3 processors provides a unique blend of enterprise class capabilities & value.
                                        It offers outstanding configuration flexibility to cater to a wide variety of business requirements at an affordable price point.
                                        Deploy this dense platform for diverse workloads in space constrained environments and maintain it with ease by automating the most essential server lifecycle management tasks with HPE iLO 5.
                                    </p>
                                    <NavLink exact to="/Contact-us" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Request a Quote</button></NavLink>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-lg-6 ">
                            <p className="ps-3" style={{ fontSize: "30px", fontFamily: "serif", color: "#333333" }}>
                                Features
                            </p>
                            <ul style={{ fontSize: "16px", fontFamily: "sans-serif", lineHeight: "30px", textAlign: "justify" }}>
                                <li>
                                    UEFI Secure Boot
                                </li>
                                <li>
                                    Secure Erase of NAND
                                </li>
                                <li>
                                    Silicon Root of Trust
                                </li>
                                <li>
                                    Intel Xeon E-2224 quad-core processor
                                </li>
                                <li>
                                    3.4 GHz base frequency, 4.6 GHz maximum turbo frequency
                                </li>

                            </ul>
                        </div>
                        <div className="col-lg-6 text-center">
                            <div>
                                <img src={require("../images/HPE-ProLiant-DL20-Gen10-E-2224.jpg")} className="w-100 " alt="HPE-ProLiant-DL20-Gen10-E-2224" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            <Footer />
        </>
    )
}
export default HPEProLiantDL20Gen10E2224;

