import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const Installations = () => {

   return (
      <>
         <Header />
         <section>
            <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/bnr.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
               <div className="container">
                  <div className="row text-center d-flex  justify-content-center align-items-center">
                     <div className="col-lg-6 text-lg-start ">
                        <div className="pb-lg-5 pt-lg-5">
                           <p className="text-white " style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>INSTALLATION</p>
                        </div>
                     </div>
                     <div className="col-lg-6   text-lg-end">
                        <div className=" pt-lg-5 pb-lg-5">
                           <p className="text-white">
                              <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}> / <NavLink exact to="/Installations" className="text-white">Services</NavLink> / Installation</span></span>
                           </p>


                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section>
            <div className="container-fluid" style={{ backgroundColor: "#f7f7f7" }}>
               <div className="container p-4">
                  <div className="row ">
                     <p className="fs-2" style={{ fontFamily: "Trirong,serif", fontWeight: "600", fontSize: "32px" }}>Installations of Any Products at Minimal cost With Skyward!</p>
                     <p style={{ fontFamily: "sans-serif", fontSize: "14px" }}>With Skyward, installations of Any Products gateway services becomes easy. Moreover, we make sure that with our expertise, your business enterprise does not face any issues with the installation. Our installations services have been designed especially to offer you the best telephony services with the assistance of really high speed internet connections. Moreover, our services remove the need for conventional phone services to charge up your communication scenario.</p>
                     <p style={{ fontFamily: "sans-serif", fontSize: "14px" }}>Any products over Internet Protocol is an increasingly used communication method in different countries. This service makes use of broadband connection instead of your normal phone line. Thus, you read it right! you can make those phone calls with the help of Internet and that too with high speed. Moreover, these calls are more reliable and more secure that that normal phone system. Thus, these Internet calls can also reduce your cost of these communication channels. So, you need not worry about spending crores on just setting up a proper communications channel. Moreover, this channel can help you to get in touch with your clients in different corners of the world.</p>
                     <p style={{ fontFamily: "sans-serif", fontSize: "14px" }}>Thus, every growing business, whether small or big needs this installation of any products at the earliest. Now, the question is who can help you in this task? Well, of course, Skyward.</p>
                  </div>
               </div>
            </div>
         </section>

         <section>
            <div className="container-fluid" style={{ backgroundColor: "#f7f7f7" }}>
               <div className="container">
                  <div className="row pb-5c">
                     <div className="col-lg-6 text-end">
                        <img src={require("../images/img/installations-service.png")} style={{ width: "60%" }} className=" " alt="" />
                     </div>
                     <div className="col-lg-6">
                        <b>
                           <p className="fs-2" style={{ fontFamily: "Trirong,serif", fontWeight: "600", fontSize: "32px" }}>Why Skyward Telecom?</p>
                        </b>
                        <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666" }}>The rise of any products gateway devices has lead to many different brands venturing into the business of delivering these services. You can find many companies ready to offer you installation of these any products services for your company. Then why should you select Skyward?</p>

                        <ul style={{ fontSize: "15px", fontFamily: "Kanit, sans-serif", color: "#666666", lineHeight: "30px" }}>
                           <li>Skyward believes in delivering only the best, no matter what kind of requirement you have.</li>
                           <li>We believe in the motto of delivering the best quality installation services for our clients.</li>
                           <li>Our long list of clients is a perfect proof of our reputation as a perfect installation firm for any services.</li>
                           <li>Whether it is consultation, configuration, or installation, we are always ready with our product-based solutions.</li>
                           <li>Our team will offer your solutions even after the installation is complete. Because we believe that once client, always a client!</li>
                        </ul>

                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section>
         <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/bg31.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
               <div className="container">
                  <div className="row">
                     <div className="col-lg-9">
                        <div className="ps-2 pt-4">
                           <p className="fs-2" style={{ color: "white", fontWeight: "700", fontFamily: "sans serif" }}>
                              Bright Solutions. Profitable Results.

                              <p style={{ fontSize: '18px', color: "white", fontWeight: "700", fontFamily: "sans serif" }}>
                                 Experience you can trust, service you can count on.
                              </p></p>
                        </div>
                     </div>
                     <div className="col-lg-3 text-start pt-4 pb-2">
                        <a href="/Contact-us"><button style={{ border: "none", outline: "none" }} className="ps-3 pe-3 pt-3 pb-3 rounded-pill btn-lg text-black bg-white">Get Free Installation</button></a>
                     </div>
                  </div>
               </div>
            </div>
         </section>



         <Footer />
      </>
   )
}
export default Installations;