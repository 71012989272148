import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { NavLink } from "react-router-dom";
const GSurf = () => {

    return (
        <>
            <Header />

            <section style={{ backgroundColor: "#090909" }}>
                <div className="container">
                    <div className="row text-center d-flex  justify-content-center align-items-center">
                        <div className="col-lg-6  ">
                            <div className="text-lg-start p-lg-2 ps-lg-5">
                                <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>GSurf Pro V2</p>


                            </div>
                        </div>
                        <div className="col-lg-6   text-lg-center">
                            <div className="p-lg-2">
                                <p className="text-white ">
                                    <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home / </NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}> <NavLink to="/Grandstream" className="text-white"> Grandstream </NavLink> / Physical Security / Video Management Software / <b style={{ fontSize: "16px" }}> GSurf Pro V2</b>
                                    </span></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

          
            <section>
                <div className="container">
                    <div className="container mt-5 ">
                        <div className="row">

                            <div className="col-lg-4 " >
                                <div id="carouselExampleFade" class="carousel slide " data-bs-ride="carousel">
                                    <div class="carousel-inner text-center border" style={{ height: "320px" }}>
                                        <div class="carousel-item active animated">

                                            <img className=" w-100" src={require("../images/GSurf-Pro-slide.png")} alt="GXV3500-right" />
                                        </div>
                                        <div class="carousel-item">

                                            <img className=" w-100" src={require("../images/GSurf-Pro-slide.png")} alt="GXV3500-slide1" />
                                        </div>

                                    </div>
                                    <button class="carousel-control-prev pe-2" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                                        <span class="carousel-control-next-icon p-4 bg-dark" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next ps-2" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                                        <span class="carousel-control-prev-icon p-4 bg-dark" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>


                            <div className="col-lg-8">
                                <div>
                                    <p className="" style={{ fontSize: "40px", fontFamily: "serif", fontWeight: "600" }} >
                                        GSurf Pro V2
                                    </p>
                                    <p style={{ fontSize: "30px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>
                                        Video Management Software
                                    </p>
                                    <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                                        Grandstream’s GSurf Pro V2 is free video management software (VMS) for PC-based video recording, indexing, searching and video storage management for up to 72 Grandstream cameras. GSurf Pro V2 supports remote viewing, controlling and recording from anywhere on the Internet or within a corporate network.
                                    </p>
                                    <NavLink exact to="/Contact-us" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Request a Quote</button></NavLink>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mt-3">
                    <div className="row ps-3">
                        <div>
                            <p style={{ fontSize: "32px", fontFamily: "serif", color: "#333333", fontWeight: "500" }}>
                                Description

                                <div className="border border-primary" style={{ width: "55px" }}></div>
                                <div className=" mb-2 " style={{ borderBottom: "1px solid lightgray" }}></div>
                            </p>
                            <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                                GSurf Pro V2 is free Video Management Software (VMS) offered by Grandstream. It offers PC-based video recording, indexing, searching and video storage management for up to 72 Grandstream cameras and encoder/decoders. GSurf Pro V2 provides a one-stop shop for controlling/managing devices, video recording and alarm events. Use GSurf_Pro V2 to monitor, search and record up to 72 cameras simultaneously, manage videos locally or remotely via the controlled server with Client/Server architecture. Powerful features and an intuitive interface make GSUrf Pro V2 a complete solution when combined with Grandstream IP surveillance products and 3rd party sensors.
                            </p>

                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 ">
                            <p className="ps-3" style={{ fontSize: "30px", fontFamily: "serif", color: "#333333" }}>
                                Features
                            </p>
                            <ul style={{ fontSize: "16px", fontFamily: "sans-serif", lineHeight: "25px", textAlign: "justify" }}>
                                <li>
                                    Client/Server architecture with e-Map support
                                </li>
                                <li>
                                    Transmit Server allows multi-point viewing same live video
                                </li>
                                <li>
                                    File Sharing Server allows video downloaded and replayed remotely
                                </li>
                                <li>
                                    Automatic patrol to pre-set positions
                                </li>
                                <li>
                                    Multi-language support (English, Chinese, Russian, French, Spanish, Portuguese)
                                </li>
                                <li>
                                    Multi-screen display support with manual switch or automatic patrol viewing

                                </li>
                                <li>
                                    Configurable dual streams for local and remote display to save bandwidth
                                </li>
                                <li>
                                    Scheduled or Alarm triggered primary stream recording for different cameras
                                </li>
                                <li>Supports composite (RCA) video output to TV, Motion Detection and Alarm Notification</li>
                                <li>
                                    Easy maintenance with import/export configure, user and log management
                                </li>
                                <li>Batch configure of Alarm DO/DI  </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 text-center">
                            <div>
                                <img src={require("../images/GSurf-Pro-slide.png")} className="w-100 p-lg-5  " alt="GSurf-Pro-slide" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-lg-4 mb-2">
                            <div className="text-center" style={{ backgroundColor: "#F5ECEC", padding: "40px" }} >
                                <p style={{ fontSize: "22px", fontFamily: "serif", fontWeight: "600" }}>
                                    Quick Enquiry
                                </p>
                                <p style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                                    For the product quotation please fill the given form our team will get back to you shortly after analysing your needs
                                </p>

                                <div className="pt-3">
                                    <NavLink className="text-white text-decoration-none fs-6" to="/Contact-us" >
                                        <span className="btn-round text-dark">SEND ENQUIRY</span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 d-none d-sm-block">
                            <div id="carouselExampleIndicators" className="carousel slide " data-bs-ride="carousel" style={{ height: "300px" }}>
                                <div className="carousel-indicators sliderbtn">
                                    <input style={{ width: "20px", height: "15px" }} type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active mb-2" aria-current="true" aria-label="Slide 1"></input>
                                    <input style={{ width: "20px", height: "15px" }} className='mb-2 ' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></input>
                                    <input style={{ width: "20px", height: "15px" }} className=' ' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></input>
                                    <input style={{ width: "20px", height: "15px" }} className=' ' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></input>
                                </div>

                                <div className="carousel-inner">
                                    <div className="carousel-item active animated" data-bs-interval="1500">
                                        <div className="row">
                                            <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                                                <img src={require("../images/img/GVC3210-slide.jpg")} className="w-100" alt="" />
                                            </div>
                                            <div className="col-lg-6">
                                                <div>
                                                    <p className="m-0 p-0">Full HD Conferencing</p>
                                                    <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>GVC3210 Video Conferencing </p>
                                                    <div className="border border-primary" style={{ width: "55px" }}></div>

                                                    <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}> The GVC3210 is an innovative video conferencing endpoint ideal for small to medium businesses who seek an easy-to-use yet potent video conferencing solution.  </p>

                                                    <NavLink exact to="/GVC3210" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item" data-bs-interval="1500">

                                        <div className="row">
                                            <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                                                <img src={require("../images/GWN7605.png")} className="w-100" alt="" />
                                            </div>
                                            <div className="col-lg-6">
                                                <div>
                                                    <p className="m-0 p-0">Networking Solutions</p>
                                                    <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>Wifi Access Point</p>
                                                    <div className="border border-primary" style={{ width: "55px" }}></div>

                                                    <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}> GWN7630 Wi-Fi access point for small to medium sized businesses, multiple floor offices, commercial locations and branch offices. </p>
                                                    <NavLink exact to="/wifi-access-point" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item" data-bs-interval="1500">

                                        <div className="row">
                                            <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                                                <img src={require("../images/img/GXV3380-slide4.png")} className="w-100" alt="" />
                                            </div>
                                            <div className="col-lg-6">
                                                <div>
                                                    <p className="m-0 p-0">Video Ip Phone</p>
                                                    <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>GXV 3380</p>
                                                    <div className="border border-primary" style={{ width: "55px" }}></div>

                                                    <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}> The GXV3380 is a powerful High-End Smart Video Phone for Android that combines a 16 line IP phone with a multi-platform video collaboration solution.   </p>
                                                    <NavLink exact to="/GXV3500" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item" data-bs-interval="1500">

                                        <div className="row">
                                            <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                                                <img src={require("../images/img/UCM6200-slide3.png")} className="w-100" alt="" />
                                            </div>
                                            <div className="col-lg-6">
                                                <div>
                                                    <p className="m-0 p-0"> UCM series IP PBXs</p>
                                                    <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>UCM6200 series IP PBX </p>
                                                    <div className="border border-primary" style={{ width: "55px" }}></div>

                                                    <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>  UCM6200 series IP PBX appliance combines enterprise-grade voice, video, data, and mobility features in an easy-to-manage solution. </p>
                                                    <NavLink exact to="/UCM6200-Series" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}
export default GSurf;