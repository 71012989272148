import React from "react";
import { NavLink } from "react-router-dom";
import "./Support.css";
import Footer from "./Footer";
import Header from "./Header";

const Support = () => {

   return (
      <>
         <Header />
         <section>
            <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/support-bnr-1.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
               <div className="container">
                  <div className="row text-center d-flex  justify-content-center align-items-center">
                     <div className="col-lg-6 text-lg-start">
                        <div className="pb-lg-5 pt-lg-5">
                           <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>SUPPORT</p>

                        </div>
                     </div>
                     <div className="col-lg-6   text-lg-end">
                        <div className=" pt-lg-5 pb-lg-5">
                           <p className="text-white">
                              <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}> / <NavLink exact to="/Support" className="text-white">Services</NavLink> / Support</span></span>
                           </p>


                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section>
            <div className="contianer-fluid p-5" style={{ backgroundColor: "#f7f7f7" }}>
               <div className="container">
                  <div className="row">
                     <p className="fs-2" style={{ fontSize: "32px", color: "#333333", fontWeight: "600", fontFamily: "serif" }}>Leave All Your Worries Regarding After Sales Support For Any Products <br /> Services To Skyward </p>
                     <img src={require("../images/img/Support-Maintenance-Bnr.png")} className="w-100" alt="" />
                     <div style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666" }}>
                        <p>Now that you have successfully availed the different services offered by Skyward and have started witnessing the different benefits of these services, comes the second stage of our package. The after sales support system designed by our firm makes sure that you do not face any kind of hassles after we have completed our services as promised.</p>
                        <p>Our services are very much in demand nowadays. After all, they have made a great impact on communication channels across different online platforms. You can now make elaborate phone calls to any corner of the world using your broadband connections only. Moreover, you do not require any extra software to make those phone calls which adds to your reduced costs required for making effective communications.</p>
                        <p>Well, even the perfectly designed as well as effectively assembled products can also face issues after some time. However, we at Skyward will make sure that you do not have to roam from pillar to pillar to get these issues resolved. In fact, we have a dedicated team of highly experienced professionals formed to cater to these after sales support requirements alone. Thus, you will not have to worry even a bit to contact us for these support issues.</p>
                        <p>Our technology is an uprising one which has made a niche for itself in the communications world. The said technology has undergone continuous upgradations and you can witness many elaborate changes in the channel. However, these upgradations may sometimes create issues in the services already installed if your set up is not compatible with them. Thus, your set up also requires continuous upgradations to meet these said changes. This is where Skyward comes into the picture. We will make sure that all the compatibility issues are resolved at the earliest. This is called after installation maintenance service.</p>
                     </div>

                  </div>

               </div>
            </div>
         </section>

         <section>
            <div className="contianer-fluid pb-5" style={{ backgroundColor: "#f7f7f7" }}>
               <div className="container">
                  <div className="row">
                     <div className="col-lg-6 text-center">
                        <img src={require("../images/support-1.png")} className="img-fluid w-50 " alt="" />
                     </div>
                     <div className="col-lg-6">
                        <p className="fs-2" style={{ fontFamily: "Trirong,serif", fontWeight: "600", fontSize: "32px" }}>Why Skyward Telecom?</p>

                        <div style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "30px" }}>

                           <p>Moreover, with our effective tools, we can also troubleshoot even the hardest of issues arising in your system. Delivering on time, we have been able to make an influence on all our customers, giving 100 percent performance whenever required. However, you need not worry about any kind of hidden costs as we will have a detailed session with you, explaining the need for actions to be taken if any.</p>
                           <p>Also, we have a three level support system to make sure that there is no lapse in getting you assistance of any kind. We also have a proper flowchart designed to make sure we can effectively identify, design the solution, and resolve your problems. So, contact Skyward at the earliest and get proper solutions at a reasonable cost.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>


         <section>
            <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/bg31.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
               <div className="container">
                  <div className="row">
                     <div className="col-lg-9">
                        <div className="ps-2 pt-4">
                           <p className="fs-2" style={{ color: "white", fontWeight: "700", fontFamily: "sans serif" }}>
                              Bright Solutions. Profitable Results.

                              <p style={{ fontSize: '18px', color: "white", fontWeight: "700", fontFamily: "sans serif" }}>
                                 Experience you can trust, service you can count on.
                              </p></p>
                        </div>
                     </div>
                     <div className="col-lg-3 text-start pt-5 pb-2">
                        <a href="/Contact-us"><button style={{ fontSize: "14px", border: "none", outline: "none", padding: "10px 26px" }} className=" rounded-pill btn-lg text-black bg-white">Get a Customer Support</button></a>
                     </div>
                  </div>
               </div>
            </div>
         </section>


         <Footer />
      </>
   )
}
export default Support;