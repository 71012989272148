import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const PhysicalSecurity = () => {

    return (
        <>
            <Header />

            <section>
                <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/header-bnr.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
                    <div className="container">
                        <div className="row text-center d-flex  justify-content-center align-items-center">
                            <div className="col-lg-6 text-lg-start">
                                <div className="pb-lg-5 pt-lg-5">
                                    <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>PHYSICAL SECURITY </p>

                                </div>
                            </div>
                            <div className="col-lg-6   text-lg-center">
                                <div className=" pt-lg-5 pb-lg-5">
                                    <p className="text-white">
                                        <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}>  <NavLink exact to="/Grandstream" className="text-white">/ Grandstream </NavLink>/ Physical Security
                                        </span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 d-none d-sm-block">
                                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>BRANDS</span>
                                <div className="mb-1"></div>
                                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                                <div className="border border-white border-opacity-10 mb-2 "></div>
                                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                                    <NavLink exact to="/grandstream" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; Grandstream</p></NavLink>
                                    <NavLink exact to="/Dinstar" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Dinstar</p></NavLink>
                                    <NavLink exact to="/NEC" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; NEC</p></NavLink>
                                    <NavLink exact to="/Alcatel" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Alcatel</p></NavLink>
                                    <NavLink exact to="/YeaLink" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;YeaLink</p></NavLink>
                                    <NavLink exact to="/Poly" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Poly</p></NavLink>
                                    <NavLink exact to="/Cisco" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Cisco</p></NavLink>

                                </p>

                                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>SOLUTIONS</span>
                                <div className="mb-1"></div>
                                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                                <div className="border border-white border-opacity-10 mb-2 "></div>
                                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                                    <NavLink exact to="/Enterprise-Manufacturing" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Enterprise-Manufacturing</p></NavLink>
                                    <NavLink exact to="/Security&Surveillance" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Security & Surveillance</p></NavLink>
                                    <NavLink exact to="/EducationHospitality" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}> Education & Hospitality</p></NavLink>
                                    <NavLink exact to="/BusinessConference" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Business Conference</p></NavLink>

                                </p>

                                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">

                                    <div className="carousel-inner">
                                        <div className="carousel-item active" data-bs-interval="2500">
                                            <div>
                                                <NavLink exact to="/GWN7605">
                                                    <img src={require("../images/GWN7605.png")} style={{ width: "263px" }} alt="GWN7605" />
                                                    <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>WiFi Access Point (GWN7605)</p>
                                                    <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream's powerful WiFi Access Points offer high performance networking, tremendous WiFi coverage range read more.. </p>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="carousel-item" data-bs-interval="2500">
                                            <div>
                                                <NavLink exact to="/BusinessConference">
                                                    <img src={require("../images/Business-Conferencing.png")} style={{ width: "263px" }} alt="Business-Conferencing" />
                                                    <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Business Conferencing</p>
                                                    <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream’s video conferencing solutions offer small and medium sized businesses flexibility read more..</p>
                                                </NavLink>
                                            </div>            </div>
                                        <div className="carousel-item" data-bs-interval="2500">
                                            <div>
                                                <NavLink exact to="/wp810">
                                                    <img src={require("../images/wifi-cordless.png")} style={{ width: "263px" }} alt="wifi-cordless" />
                                                    <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Wi-Fi IP Phone (WP 810)</p>
                                                    <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>WP810 is a portable Wi-Fi IP phone designed to suit a variety of enterprises  Read More..</p>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div>
                                    <img src={require("../images/img/facility-management-bnr.jpg")} className="w-100" alt="facility-management-bnr" />
                                </div>

                                <div className="mt-5" style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                                    <p>
                                        Security, protection and facility management all integrated into your communications network solution. Bring peace-of-mind and added control with Grandstreams IP Surveillance solutions. From HD IP Cameras, Network Video Recorders, IP Video Encoders, our own Video Management Software, to our Video Door System users can create the security environment they’re looking for.
                                    </p>
                                </div>

                                <div className="row mb-5">
                                    <div className="col-md-3 mt-3">
                                        <div className="d-flex btnHover h-100 justify-content-center align-items-center block border border-dark ">
                                            <img src={require("../images/img/control_stations_thumbnail.png")} className="w-100 " alt="control_stations_thumbnail" />
                                            <NavLink className="imgbtn text-center" exact to="/control-station"> <button className="btn border-none">See Product</button></NavLink>
                                        </div>
                                    </div>
                                    <div className="col-lg-9">
                                        <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>Control Stations</p>
                                        <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                                            The GSC series of HD Intercom and Facility Control Station provide dedicated devices to manage door access, video surveillance cameras, intercoms, building communications and more. Ideal for business, medical, residential, hospitality and retail facilities, they are designed to seamlessly integrate with a wide range of Grandstream products and third-party devices to create a centralized facility management solution.
                                        </p>
                                        <div className="border border-dark border-opacity-10 "></div>
                                    </div>
                                </div>

                                <div className="row mt-5">
                                    <div className="col-md-3 ">
                                        <div className="d-flex btnHover h-100 justify-content-center align-items-center block border  p-2 border-dark ">
                                            <img src={require("../images/img/Intercoms-Paging.png")} className="w-100 " alt="Intercoms-Paging" />
                                            <NavLink className="imgbtn text-center" exact to="/Intercoms-Paging"> <button className="btn border-none">See Product</button></NavLink>
                                        </div>
                                    </div>
                                    <div className="col-lg-9">
                                        <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>Intercoms & Paging</p>
                                        <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                                            The GSC3500 Series offer top-notch intercom and paging functionality ideal for office buildings, enterprises, schools, healthcare facilities, retail centers, residential settings, warehouses and other indoor environments. This series allows businesses to build powerful SIP intercom and paging solutions that expand communication and add security.
                                        </p>
                                        <div className="border border-dark border-opacity-10 "></div>
                                    </div>
                                </div>

                                <div className="row mt-5">
                                    <div className="col-md-3 ">
                                        <div className="d-flex btnHover h-100 justify-content-center align-items-center block border border-dark ">
                                            <img src={require("../images/img/ip-camera.png")} className="w-100" alt="ip-camera" />
                                            <NavLink className="imgbtn text-center" exact to="/HD-Ip-camera"> <button className="btn border-none">See Product</button></NavLink>
                                        </div>
                                    </div>
                                    <div className="col-lg-9">
                                        <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>HD IP Cameras</p>
                                        <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                                            Create and customize a security environment with Grandstream’s range of GXV3600 HD IP cameras. Easy to setup, deploy and manage, these cameras offer a proactive security system to keep a user’s facility secured and protected. The GXV3600 series of HD IP cameras feature full HD resolution and automatic voice and video calls in addition to email screenshots..
                                        </p>
                                        <div className="border border-dark border-opacity-10 "></div>
                                    </div>
                                </div>

                                <div className="row mt-5">
                                    <div className="col-md-3 ">
                                        <div className="d-flex btnHover h-100 justify-content-center align-items-center block border  p-2 border-dark ">
                                            <img src={require("../images/img/Facility-Access-Systems.png")} className="w-100" alt="Facility-Access-Systems" />
                                            <NavLink className="imgbtn text-center" exact to="/Facility Access Systems"> <button className="btn border-none">See Product</button></NavLink>
                                        </div>
                                    </div>
                                    <div className="col-lg-9">
                                        <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>Facility Access Systems</p>
                                        <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                                            The GDS series of Facility Access Systems tracks and manages access to any physical building, provides a dedicated audio and/or video intercom while also offering powerful integration with Grandstream full solution portfolio.
                                        </p>
                                        <div className="border border-dark border-opacity-10 "></div>
                                    </div>
                                </div>

                                <div className="row mt-5">
                                    <div className="col-md-3 ">
                                        <div className="d-flex btnHover h-100 justify-content-center align-items-center block border  p-2 border-dark ">
                                            <img src={require("../images/img/Decoders.png")} className="w-100" alt="Decoders" />
                                            <NavLink className="imgbtn text-center" exact to="/IP Video Encoders/Decoders"> <button className="btn border-none">See Product</button></NavLink>
                                        </div>
                                    </div>
                                    <div className="col-lg-9">
                                        <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>IP Video Encoders/Decoders</p>
                                        <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                                            The GXV3500 is an award-winning device that allows users to add IP cameras to an analog network, add analog cameras to an IP network and to create a public address system.
                                        </p>
                                        <div className="border border-dark border-opacity-10 "></div>
                                    </div>
                                </div>

                                <div className="row mt-5 mb-3">
                                    <div className="col-md-3 ">
                                        <div className="d-flex btnHover h-100 justify-content-center align-items-center block border  p-2 border-dark ">
                                            <img src={require("../images/img/software_0.png")} className="w-100" alt="software_0" />
                                            <NavLink className="imgbtn text-center" exact to="/video-management-software"> <button className="btn border-none">See Product</button></NavLink>
                                        </div>
                                    </div>
                                    <div className="col-lg-9">
                                        <p className="" style={{ fontSize: "18px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>Video Management Software</p>
                                        <p className="" style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                                            Grandstream’s GSurf Pro v2 is a free video management software that turns any computer or laptop into a video surveillance monitoring and recording solution. GSurf Pro v2 supports up to 72 Grandstream cameras and is a great option for centralized recording and monitoring of multiple physical locations.
                                        </p>
                                        <div className="border border-dark border-opacity-10 "></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Footer />
        </>
    )
}
export default PhysicalSecurity;