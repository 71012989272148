import React from "react";
import Footer from "./Footer";
import { NavLink } from "react-router-dom";
import Header from "./Header";


const GAC2500 = () => {

  return (
    <>
      <Header />


      <section style={{ backgroundColor: "#090909" }}>
        <div className="container">
          <div className="row text-center d-flex  justify-content-center align-items-center">
            <div className="col-lg-6  ">
              <div className="text-lg-start p-lg-2 ps-lg-5">
                <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>GAC2500</p>

              </div>
            </div>
            <div className="col-lg-6   text-lg-center">
              <div className="p-lg-2">
                <p className="text-white ">
                  <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home /</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}>Full HD Conferencing   / <b style={{ fontSize: "16px" }}>GAC2500</b>
                  </span></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container">
          <div className="container mt-5 ">
            <div className="row">

              <div className="col-lg-4 " >
                <div id="carouselExampleFade" class="carousel slide " data-bs-ride="carousel">
                  <div class="carousel-inner text-center border" style={{ height: "280px" }}>
                    <div class="carousel-item active animated">
                      <img className=" w-100" src={require("../images/GAC2500-slide2.jpg")} alt="" />
                    </div>

                    <div class="carousel-item">
                      <img className=" w-100" src={require("../images/GAC2500-1.png")} alt="" />
                    </div>
                    <div class="carousel-item active animated">
                      <img className=" w-100" src={require("../images/GAC2500-slide3.jpg")} alt="" />
                    </div>

                  </div>
                  <button class="carousel-control-prev pe-2" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                    <span class="carousel-control-next-icon p-4 bg-dark" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next ps-2" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                    <span class="carousel-control-prev-icon p-4 bg-dark" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>


              <div className="col-lg-6">
                <div>
                  <p className="" style={{ fontSize: "40px", fontFamily: "serif", fontWeight: "600" }} >
                    Grandstream GAC2500
                  </p>
                  <p style={{ fontSize: "30px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>
                    Voice Conferencing
                  </p>
                  <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                    An ideal conference solution that delivers a modern design paired with HD audio and a suite of productivity-focused features. The Android-based GAC2500 offers access to features and functionality not traditionally found in conference phones.                   </p>
                  <NavLink exact to="/Contact-us" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Request a Quote</button></NavLink>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-3">
          <div className="row ps-3">
            <div>
              <p style={{ fontSize: "32px", fontFamily: "serif", color: "#333333", fontWeight: "500" }}>
                Description

                <div className="border border-primary" style={{ width: "55px" }}></div>
                <div className=" mb-2 " style={{ borderBottom: "1px solid lightgray" }}></div>
              </p>
              <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                The GAC2500 is designed to transform your business conferencing experience through its immersive audio conferencing environment and suite of advanced features. Redefining audio conferencing, the GAC’s 6 lines, WiFi and Bluetooth compatibility, HD Audio, Gigabit network ports and Android operating system with touch screen enhances the way users will meet and work together. Easily connect any time, using the GAC’s 7-way conference bridge. the GAC supports a productive experience with the GAC2500’s call scheduler, AndroidTM operating system and Google Play Store access.
              </p>

            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 ">
              <p className="ps-3" style={{ fontSize: "30px", fontFamily: "serif", color: "#333333" }}>
                Features
              </p>
              <ul style={{ fontSize: "16px", fontFamily: "sans-serif", lineHeight: "30px", textAlign: "justify" }}>
                <li>
                  TLS and SRTP security encryption
                </li>
                <li>
                  Built-in 7-way conference bridge
                </li>
                <li>
                  Auto-sensing Gigabit port, built-in PoE support
                </li>
                <li>
                  Full HD audio support to maximize voice quality
                </li>
                <li>
                  6 lines, 6 SIP accounts, 7-way voice conferencing
                </li>
                <li>4.3" (800x480) capacitive touch screen for easy use</li>
                <li>Daisy-chain support to combine two GAC2500 together</li>
                <li>Bluetooth to support syncing of headsets and mobile devices</li>
                <li>
                  Built-in WiFi support offers mobility and network flexibility
                </li>
                <li>
                  Runs Android 4.4 and offers full access to the Google Play Store and all Android apps, such as Skype, Google Hangouts and more
                </li>
              </ul>
            </div>
            <div className="col-lg-6 text-center">
              <div>
                <img src={require("../images/gac2500_ryt.jpg")} className=" w-75 pb-lg-5  " alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="text-center" style={{ backgroundColor: "#F5ECEC", padding: "30px" }} >
                <p style={{ fontSize: "22px", fontFamily: "serif", fontWeight: "600" }}>
                  Quick Enquiry
                </p>
                <p style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                  For the product quotation please fill the given form our team will get back to you shortly after analysing your needs
                </p>

                <div className="pt-3">
                  <NavLink className=" text-decoration-none fs-6" to="/Contact-us" >
                    <span className="btn-round send-btn">SEND ENQUIRY</span>
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="col-lg-8 d-none d-sm-block">
              <div id="carouselExampleIndicators" className="carousel slide " data-bs-ride="carousel" style={{ height: "300px" }}>
                <div className="carousel-indicators sliderbtn">
                  <input style={{ width: "20px", height: "15px" }} type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></input>
                  <input style={{ width: "20px", height: "15px" }} className=' ' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></input>
                  <input style={{ width: "20px", height: "15px" }} className=' ' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></input>
                  <input style={{ width: "20px", height: "15px" }} className=' ' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></input>
                </div>

                <div className="carousel-inner">
                  <div className="carousel-item active animated" data-bs-interval="1500">
                    <div className="row">
                      <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                        <img src={require("../images/img/GVC3210-slide.jpg")} className="w-100" alt="" />
                      </div>
                      <div className="col-lg-6">
                        <div>
                          <p className="m-0 p-0">Full HD Conferencing</p>
                          <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>GVC3210 Video Conferencing </p>
                          <div className="border border-primary" style={{ width: "55px" }}></div>

                          <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}> The GVC3210 is an innovative video conferencing endpoint ideal for small to medium businesses who seek an easy-to-use yet potent video conferencing solution.  </p>

                          <NavLink exact to="/GVC3210" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item" data-bs-interval="1500">

                    <div className="row">
                      <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                        <img src={require("../images/GWN7605.png")} className="w-100" alt="" />
                      </div>
                      <div className="col-lg-6">
                        <div>
                          <p className="m-0 p-0">Networking Solutions</p>
                          <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>Wifi Access Point</p>
                          <div className="border border-primary" style={{ width: "55px" }}></div>

                          <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}> GWN7630 Wi-Fi access point for small to medium sized businesses, multiple floor offices, commercial locations and branch offices. </p>
                          <NavLink exact to="/wifi-access-point" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item" data-bs-interval="1500">

                    <div className="row">
                      <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                        <img src={require("../images/img/GXV3380-slide4.png")} className="w-100" alt="" />
                      </div>
                      <div className="col-lg-6">
                        <div>
                          <p className="m-0 p-0">Video Ip Phone</p>
                          <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>GXV 3380</p>
                          <div className="border border-primary" style={{ width: "55px" }}></div>

                          <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}> The GXV3380 is a powerful High-End Smart Video Phone for Android that combines a 16 line IP phone with a multi-platform video collaboration solution.   </p>
                          <NavLink exact to="/GXV3500" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item" data-bs-interval="1500">

                    <div className="row">
                      <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                        <img src={require("../images/img/UCM6200-slide3.png")} className="w-100" alt="" />
                      </div>
                      <div className="col-lg-6">
                        <div>
                          <p className="m-0 p-0"> UCM series IP PBXs</p>
                          <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>UCM6200 series IP PBX </p>
                          <div className="border border-primary" style={{ width: "55px" }}></div>

                          <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>  UCM6200 series IP PBX appliance combines enterprise-grade voice, video, data, and mobility features in an easy-to-manage solution. </p>
                          <NavLink exact to="/UCM6200-Series" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <Footer />
    </>
  )
}
export default GAC2500;