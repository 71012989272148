

import React from 'react';
// import { AttachEmail, PhoneAndroid, Menu, Height, Email, Subject } from '@mui/icons-material';
import "./Contact.css";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
// import { useForm } from "react-hook-form";
// import { Container } from '@mui/material';
// import { blue, red } from '@mui/material/colors';

const Contact = () => {


  function whatsapp() {
    var name = document.getElementById("name").value;

    var phone = document.getElementById("phone").value;

    var message = document.getElementById("message").value;

    var url = "https://wa.me/+919579713010?text="
      + "Name : " + name + "%0a"
     
      + "Phone : " + phone + "%0a"

      + "Message : " + message + "%0a"

    window.open(url, '_blank').focus();
  }











  return (
    <>


      <Header />
      <Helmet>



        <meta name="Skyward Telecom Pvt.Ltd" />
        <meta description="Skyward is a leading telecom services & solutions provider, targeting emerging market enterprises with a high range of  products portfolio." />
      </Helmet>




      <section>
        <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/header-bnr.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
          <div className="container">
            <div className="row text-center d-flex  justify-content-center align-items-center">
              <div className="col-lg-6 text-lg-start">
                <div className="">
                <p className='text-white' style={{ fontSize: "28px", fontFamily: "serif", color: "#666666" }}>
                  Let's Connect!
                </p>
                </div>
              </div>
              <div className="col-lg-6   text-lg-center">
                <div className=" ">
                  <p className="text-white" style={{paddingTop:"10%",paddingBottom:"10%"}}>
                    <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}>  / Contact </span></span>
                  </p>


                </div>
              </div>
            </div>
          </div>
        </div>
      </section>




      <section>

        <div className="container-fluid" style={{ backgroundColor: "#F7F7F7" }}>
          <div className="container ">

            <div className="row align-items-center">
              <div className="diss col-lg-7  mt-4 mb-4 ">
                <div>
                  <p className='' style={{ fontSize: "24px", fontWeight: "600", fontFamily: "Trirong,serif" }}>Interested in discussing ?</p>
                  <div className="" style={{ width: "55px", border: "1px solid #186191" }}></div>
                  <div className="border border-dark border-opacity-10 mb-2  "></div>
                  <p className="pt-3" style={{ textAlign: "justify", fontSize: "14px", fontFamily: "'open-sans',sans-serif" }}>Skyward Telecom is a leading telecom solutions provider and VoIP based company, targeting emerging market enterprises with a portfolio of products – PRI Cards, VoIP Gateways, GSM/CDMA Gateways, VoIP PBX, IP-Phones, Head-Phones etc.</p>
                </div>
                <div className='container pt-3 p-0'>

                  <form action="" onSubmit={whatsapp}>
                    <div className="row">
                      <div class="container overflow-hidden">
                        <div class="row gx-5">
                          <div class="col">
                            <label for="exampleFormControlInput1"><p style={{ fontSize: "14px", fontFamily: "'open-sans',sans-serif", fontWeight: "600" }}> Name * </p></label>
                            <input type="text" className="form-control  border border-dark p-2" id="name" placeholder="Enter Name" required  />
                          </div>
                          <div class="col">
                            <label for="exampleFormControlInput1"><p style={{ fontSize: "14px", fontFamily: "'open-sans',sans-serif", fontWeight: "600" }}> Email * </p></label>
                            <input type="Email" className="form-control border border-dark p-2" id="Email" placeholder="Enter Email" required />
                          </div>

                        </div>

                        <div class="row gx-5 mt-3 mb-3">
                          <div class="col">
                            <label for="exampleFormControlInput1"><p style={{ fontSize: "14px", fontFamily: "'open-sans',sans-serif", fontWeight: "600" }}> Subject * </p></label>
                            <input type="text" className="form-control  border border-dark p-2" id="Subject" placeholder="Enter Subject" required />
                          </div>
                          <div class="col">
                            <label for="exampleFormControlInput1"><p style={{ fontSize: "14px", fontFamily: "'open-sans',sans-serif", fontWeight: "600" }}>Phone</p></label>
                            <input type="phone" className="form-control  border border-dark p-2" id="phone" placeholder="Enter Phone" required />
                          </div>

                        </div>

                      </div>


                      <div>
                        <label for="form_name"><p style={{ fontSize: "14px", fontFamily: "'open-sans',sans-serif", fontWeight: "600" }}>Message</p></label>

                        <textarea className="form-control  border border-dark required" id="message" rows="3" placeholder="Enter Message"></textarea>

                      </div>



                      <div className='row mt-3'>
                        <div className='col'>
                          < button className="sendInquiry mt-2 p-2 me-2" style={{ backgroundColor: "#186191", color: "white", fontSize: "13px" }} type="submit"  >Send your message</button>
                          <button type="reset" className="sendInquiry-1 mt-2 " onClick="$('form').get(0).reset()">Reset</button>
                        </div>

                        {/* <div className='col text-start'>
                          <button type="reset" class="mt-2 btn btn-primary btn-sm" onClick="$('form').get(0).reset()">Reset</button>
                        </div> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-5">
                <div className='pl-5 ms-5'>
                  <p className='' style={{ fontSize: "32px", fontFamily: "Trirong,serif" }}>Skyward Telecom Pvt. Ltd.</p>
                  <p className='fs-3'> <span className=' text-white fw-bold' style={{ fontSize: "20px", fontFamily: "'open-sans',sans-serif", backgroundColor: "#186191", padding: "3px 7px" }}>Corporate Office : Kothrud, Pune</span></p>

                  <div style={{ fontSize: "14px", fontFamily: "'open-sans',sans-serif" }}>
                    <p className=''><b>Address &nbsp;:</b>&nbsp; Skyward Telecom Pvt.Ltd Office No 701, 7th Floor, Lotus Building, Opp Joshi Railway Museum, Kothrud, Pune 411038</p>
                    <p className=''><b>Phone Number &nbsp;:</b>&nbsp;+91 9579713010</p>
                    <p className=''><b>Email Id &nbsp;:</b>&nbsp;info@skywardtelecom.co.in</p>

                  </div>

                </div>

              </div>
            </div>

          </div>
        </div>

      </section >






      <section>
        <div className='container mt-5 mb-5'>
          <div className="row text-center">
            <div>
              <p className='mt-3 mb-3' style={{ fontSize: "28px", color: "#333333", fontWeight: "600", fontFamily: "Trirong,serif" }}>We are here to help you !</p >
              <p className='' style={{ fontSize: "24px", color: "#333333", fontWeight: "500", fontFamily: "Trirong,serif" }}>Reach out to us with your queries</p>
            </div>
          </div>

        </div>
      </section>


      <section>
        <div className='container '>
          <div className='row text-center'>
            <div className='col-lg-4'>
              <div className=''>

                <div className="iCon"><a href="tel:+91 9579713010">
                  <i style={{ color: "#186191", fontSize: "36px" }} className=" fa fa-phone fs-3 "></i>


                </a></div>

                <p className='' style={{ fontSize: "18px", fontWeight: "600", fontFamily: "Trirong,serif" }}>Call Us</p>
                <a className='' style={{ textDecoration: "none", color: "#808080", fontSize: "14px", fontWeight: "500", fontFamily: "Trirong,serif" }} href="tel:+91 9579713010">Phone: +91 9579713010</a>
              </div>

            </div>


            <div className='col-lg-4'>
              <div>

                <div className='iCon'><a href="https://goo.gl/maps/czap6SSdC2Jqh3556" aria-label="google map" rel="noopener" style={{ textDecoration: "none" }}>
                  <i style={{ color: "#186191", fontSize: "36px" }} className=" icon fs-3  fa fa-map-marker"></i>
                </a></div>
                <p className='mt-' style={{ fontSize: "18px", fontWeight: "600", fontFamily: "Trirong,serif" }}>Address</p>
                <p style={{ fontSize: "14px", fontWeight: "500", color: "#808080", fontFamily: "Trirong,serif" }}>
                  Office No 701, 7th Floor,
                  Lotus Building, Opp Joshi Railway
                  Museum, Kothrud, Pune 411038</p>


              </div>

            </div>

            <div className='col-lg-4' >
              <div >

                <div className='iCon'><a href="mailto:info@skywardtelecom.co.in" ><i style={{ color: "#186191", fontSize: "36px" }} className=" fa fa-envelope"></i></a></div>
                <p className='' style={{ fontSize: "18px", fontWeight: "600", fontFamily: "Trirong,serif" }}>Email</p>
                <p><a href="mailto:info@skywardtelecom.co.in" style={{ fontSize: "14px", fontWeight: "500", fontFamily: "Trirong,serif", color: "#808080", textDecoration: "none" }}>info@skywardtelecom.co.in</a></p>
              </div>

            </div>

          </div>

        </div>
      </section>

      <section>
        <div class="container-fluid p-0">
          <div class="gmap_canvas">
            <iframe width="100%" height="300" id="gmap_canvas" src="https://maps.google.com/maps?q=skyward%20telecom%20pvt%20ltdt=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" title="map" marginwidth="0">
            </iframe>
          </div>
        </div>
      </section>







      <Footer />
    </>
  )
}

export default Contact;