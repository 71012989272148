import { Facebook, WhatsApp } from "@mui/icons-material";
import React from "react";
import { NavLink } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
    return (
        <>
            <footer className="mt-1">
                <div className="footer-bg d-none d-sm-block">
                    <div className="row bg-white">
                        <div className="footer-box">
                            <div className="footer-block1">
                                <img src={require("../images/Grandstream.png")} alt="" />
                            </div>
                            <div className="footer-block1">
                                <img src={require("../images/NEC.png")} alt="" />
                            </div>
                            <div className="footer-block">
                                <img src={require("../images/Alcatel.png")} alt="" />
                            </div>
                            <div className="footer-block">
                                <img src={require("../images/Dinstar.png")} alt="" />
                            </div>
                            <div className="footer-block1">
                                <img src={require("../images/Yealink.png")} alt="" />
                            </div>
                            <div className="footer-block1">
                                <img src={require("../images/Poly.png")} alt="" />
                            </div>
                            <div className="footer-block1">
                                <img src={require("../images/Cisco.png")} alt="" />
                            </div>
                            <div className="footer-block2">
                                <img src={require("../images/Lifesize.png")} alt="" />
                            </div>
                            <div className="footer-block2">
                                <img src={require("../images/logi.png")} alt="" />
                            </div>
                            <div className="footer-block1">
                                <img src={require("../images/Avaya.png")} alt="" />
                            </div>
                            <div className="footer-block1">
                                <img src={require("../images/Honeywell.png")} alt="" />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="container-fluid text-white pt-3" style={{ backgroundColor: "#25272E" }}>
                    <div className="container">
                        <div className="row g-4 pt-lg-5 ps-lg-5">
                            <div className="col-lg-3">
                                <span className="" style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>SKYWARD TELECOM PVT.LTD </span>
                                <div className="mb-1"></div>
                                <div className="" style={{ width: "55px", border: "1px solid #186191" }}></div>
                                <div className="border border-white border-opacity-10 mb-2 "></div>
                                <div className="pt-2 pb-3">
                                    <p style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                                        SKYWARD is a leading telecom solutions provider and IP based company, targeting emerging market enterprises with a portfolio  of products – Gateways, GSM/CDMA Gateways, IP PBX,IP-Phones, Head-Phones etc.
                                    </p>

                                </div>
                                <NavLink className="text-white text-decoration-none fs-6" to="/About-Us" >
                                    <span className="btn-round">Read More..</span>
                                </NavLink>

                            </div>
                            <div className="col-lg-3">
                                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>CONTACT CENTER </span>
                                <div className="mb-1"></div>
                                <div className="" style={{ width: "55px", border: "1px solid #186191" }}></div>
                                <div className="border border-white border-opacity-10 mb-2 "></div>
                                <p className="pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                                    Skyward Telecom Pvt.Ltd<br />
                                    Office No 701, 7th Floor,<br />
                                    Lotus Building, Opp Joshi Railway<br />
                                    Museum, Kothrud, Pune 411038 <br />
                                    <br />
                                    <a href="mailto:info@skywardtelecom.co.in" style={{ color: "white" }}>info@skywardtelecom.co.in</a>
                                    <br />
                                    9579713010 | 02035000674
                                </p>

                            </div>
                            <div className="col-lg-3">
                                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>PRODUCT CATEGORIES</span>
                                <div className="mb-1"></div>
                                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                                <div className="border border-white border-opacity-10 mb-2 "></div>
                                <p className="pt-2 " style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                                    <NavLink exact to="/IP-PBX" style={{ textDecoration: "none" }}><p className="" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>IP PBXs</p></NavLink>
                                    <NavLink exact to="/Ip-phone" style={{ textDecoration: "none" }}><p className="" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>IP Phones</p></NavLink>
                                    <NavLink exact to="/Gateway-ATAs    " style={{ textDecoration: "none" }}><p className="" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Gateways & ATAs</p></NavLink>
                                    <NavLink exact to="/wifi-access-point" style={{ textDecoration: "none" }}><p className="" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Wifi Access Points</p></NavLink>
                                    <NavLink exact to="/BusinessConference" style={{ textDecoration: "none" }}><p className="" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Business Conferencing</p></NavLink>
                                </p>
                            </div>
                            <div className="col-lg-3">
                                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>USEFUL LINKS</span>
                                <div className="mb-1"></div>
                                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                                <div className="border border-white border-opacity-10 mb-2 "></div>
                                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                                    <NavLink exact to="/" style={{ textDecoration: "none" }}><p className="" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Home</p></NavLink>
                                    <NavLink exact to="/Installations" style={{ textDecoration: "none" }}><p className="" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Services</p></NavLink>
                                    <NavLink exact to="/solution" style={{ textDecoration: "none" }}><p className="" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Solution</p></NavLink>
                                    <NavLink exact to="/IP-PBX" style={{ textDecoration: "none" }}><p className="" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Products</p></NavLink>
                                    <NavLink exact to="/About-Us" style={{ textDecoration: "none" }}><p className="" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>About us</p></NavLink>
                                    <NavLink exact to="/Contact-us" style={{ textDecoration: "none" }}><p className="" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Contact Us</p></NavLink>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="container-fluid" style={{ backgroundColor: "#25272E" }}>
                   <div className="container">
                   <div className="row text-center ps-lg-5 pt-lg-5 pb-lg-4 pe-lg-4">
                        <div className="col-lg-4 ps-lg-2 text-center">
                            <p style={{ fontSize: '18px', fontWeight: "500", fontFamily: " Trirong, serif" }}></p>
                            <form action="" className="pt-lg-2">
                                <input className="ps-lg-3 " style={{ width: "300px", height: "40px" }} type="email" id="email" placeholder="Your Email" name="email" />
                                <span>
                                    <input type="submit" id="submit" value="Subscribe" style={{ backgroundColor: "#186191", color: "white", }} />
                                </span>
                            </form>
                        </div>
                        <div className="col-lg-4 text-center">
                            <p style={{ color: 'white', fontSize: '18px', fontWeight: "500", fontFamily: " Trirong, serif" }}>Call Us Now</p>
                            <NavLink className=" email1 text-decoration-none " style={{ color: "white" }} href="http://wa.me/9579713010"> +91-9579-713-010</NavLink> <br />
                            <NavLink href="#" className="" style={{ color: "#666" }}>+91-020-35000674</NavLink>
                        </div>
                        <div className="col-lg-4 text-lg-start">

                            <div className="social-icons text-center pe-lg-5">
                                <p style={{ color: "white", fontSize: '18px', fontWeight: "500", fontFamily: " Trirong, serif" }}>Connect With Us</p>
                                <NavLink
                                    NavLink href="http://wa.me/9579713010" aria-label="whatsapp" rel="noopener" target="_blank">
                                    <WhatsApp className="si " />
                                </NavLink>
                                <NavLink href="https://www.facebook.com/profile.php?id=100085546361027&mibextid=ZbWKwL" aria-label="facebook" rel="noopener" target="_blank"><Facebook className="si" /></NavLink>
                            </div>
                        </div>
                    </div>
                   </div>
                </div>

                <section>
                    <div className="container-fluid" style={{ backgroundColor: "#333333" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <p className="text-center pt-lg-3" style={{ fontSize: "13px", color: "#808080", fontFamily: "sans-serif" }}>
                                        © 2023 Skyward Telecom Pvt. Ltd.® All rights reserved.
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>


            </footer>
        </>
    )
}
export default Footer;