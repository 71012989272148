import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import "./Grandstream.css";
import Header from "./Header";

const Grandstream = () => {

    return (
        <>
            <Header />

            <section>
            <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/header-bnr.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
                    <div className="container">
                        <div className="row text-center d-flex  justify-content-center align-items-center">
                            <div className="col-lg-6 text-lg-start">
                                <div className="pb-lg-5 pt-lg-5">
                                    <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>GRANDSTREAM</p>

                                </div>
                            </div>
                            <div className="col-lg-6   text-lg-end">
                                <div className=" pt-lg-5 pb-lg-5">
                                    <p className="text-white">
                                        <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}>  <NavLink exact to="/Grandstream" className="text-white">/ Grandstream</NavLink> </span></span>
                                    </p>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid  text-dark" style={{ backgroundColor: "#EEECEC" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 ">
                                <p className=" mt-3 fs-2 " style={{ color: "#186191", fontSize: '36px', fontWeight: "600", fontFamily: "serif" }}>Meet Grandstream</p>
                                <p className="mt-3" style={{ fontSize: "16px", fontFamily: "serif" }}>
                                    Grandstream Networks has been manufacturing award-winning IP voice and video telephony,video conferencing and video surveillance products since 2002.
                                </p>
                                <NavLink exact to="/Contact-us"><button type="button" className=" mb-2 btn  me-md-3 " style={{ backgroundColor: "#186191", color: "white" }}>Get a Quote </button></NavLink>

                            </div>
                            <div className="col-lg-4">
                                <img className="w-75 mt-5" src={require("../images/Grandstream-logo.png")} alt="Grandstream-logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            <section>
                <div className="container-fluid pt-5 pb-3">
                    <div className="container">
                        <div className="row">
                            <div className="">
                                <p className=" fw-bold" style={{ fontSize: "32px", fontFamily: "serif" }}>Products and Solutions</p>
                                <p style={{ fontSize: "14px", fontFamily: "sans-serif" }}>Grandstream designs and manufactures a wide-range of award winning products and solutions</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row g-4">
                            <div className="col-lg-3 h-100">
                                <div className=" business-border ">
                                    <div className="text-center">
                                        <img className="w-50" src={require("../images/business-conference.jpg")} alt="business-conference" />
                                    </div>
                                    <p className="text-center" style={{ fontSize: '20px', color: "#555555", fontWeight: "600", fontFamily: "serif" }}>Business Conferencing</p>
                                    <p className="text-start ps-3" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>Take meetings and collaboration to the next level with video and audio conferencing solutions. </p>
                                    <NavLink exact to="/BusinessConference"><p className="text-center" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>See products →</p></NavLink>
                                </div>
                            </div>
                            <div className="col-lg-3 h-100">
                                <div className=" business-border ">
                                    <div className="text-center">
                                        <img className="w-50" src={require("../images/ip-voice-telphony.png")} alt="ip-voice-telphony" />
                                    </div>
                                    <p className="text-center" style={{ fontSize: '20px', color: "#555555", fontWeight: "600", fontFamily: "serif" }}>IP Voice Telephony</p>
                                    <p className="text-start ps-3" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>Grandstream VoIP Phones offer extraordinary usefulness and adaptability</p>
                                    <NavLink exact to="/ip-voice-telephony"><p className="text-center" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>See products →</p></NavLink>
                                </div>
                            </div>
                            <div className="col-lg-3 h-100">
                                <div className=" business-border ">
                                    <div className="text-center">
                                        <img className="w-75 img-full" src={require("../images/Network-Solutions.png")} alt="Network-Solutions" />
                                    </div>
                                    <p className="text-center" style={{ fontSize: '20px', color: "#555555", fontWeight: "600", fontFamily: "serif" }}>Networking Solutions</p>
                                    <p className="text-start ps-3" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>Grandstream's GWN series of Networking Solutions offer powerful and secure networks with wide coverage range.</p>
                                    <NavLink exact to="/Networksolution"><p className="text-center" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>See products →</p></NavLink>
                                </div>
                            </div>
                            <div className="col-lg-3 h-100">
                                <div className=" business-border ">
                                    <div className="text-center">
                                        <img className="w-50" src={require("../images/ip-voice-telphony.png")} alt="ip-voice-telphony" />
                                    </div>
                                    <p className="text-center" style={{ fontSize: '20px', color: "#555555", fontWeight: "600", fontFamily: "serif" }}>IP Voice Telephony</p>
                                    <p className="text-start ps-3" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>Grandstream VoIP Phones offer extraordinary usefulness and adaptability</p>
                                    <NavLink exact to="/ip-voice-telephony"><p className="text-center" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>See products →</p></NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5">
                    <div className="container">
                        <div className="row g-4">
                            <div className="col-lg-3 h-100">
                                <div className=" business-border ">
                                    <div className="text-center">
                                        <img className="w-50 " src={require("../images/gateway-atas.png")} alt="gateway-atas" />
                                    </div>
                                    <p className="text-center" style={{ fontSize: '20px', color: "#555555", fontWeight: "600", fontFamily: "serif" }}>Gateways & ATAs</p>
                                    <p className="text-start ps-3" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>Protect your analog communication investment and create a high-quality and manageable IP telephony hybrid solution. </p>
                                    <NavLink exact to="/Gateway-ATAs"><p className="text-center" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>See products →</p></NavLink>
                                </div>
                            </div>
                            <div className="col-lg-3 h-100">
                                <div className=" business-border ">
                                    <div className="text-center">
                                        <img className="w-50" src={require("../images/ip-voice-telphony.png")} alt="ip-voice-telphony" />
                                    </div>
                                    <p className="text-center" style={{ fontSize: '20px', color: "#555555", fontWeight: "600", fontFamily: "serif" }}>IP PBXs</p>
                                    <p className="text-start ps-3" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>A powerful yet easy to manage voice, video, data, and mobility communications platform has never been more achievable.</p>
                                    <NavLink exact to="/ip-voice-telephony"><p className="text-center" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>See products →</p></NavLink>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container-fluid mt-5" style={{ backgroundColor: "#186191" }}>
                    <div className="container">
                        <div className="row g-2 p-3">
                            <div className="col-lg-7 pt-3">
                                <div className="text-white">
                                    <p className=" mt-3 mb-3 fs-3 fw-bold" style={{ fontSize: "34px", fontFamily: "sans-serif" }}>Grandstream is connecting the World</p>
                                    <p style={{ fontSize: "18px", fontFamily: "sans-serif" }}>Grandstream Networks has been manufacturing award-winning IP voice and video telephony</p>
                                </div>
                            </div>
                            <div className="col-lg-5 text-center pt-5">
                                <a href="/Contact-us"><button style={{ fontSize: "14px", border: "none", outline: "none", padding: "10px 26px" }} className=" rounded-pill btn-lg text-black bg-white">Contact Us</button></a>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Grandstream;