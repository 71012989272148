import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";


const PersonalCollaboration=()=>{

    return(
        <>
        <Header/>
        <section>
        <div className="">
          <div >
          <img src={require("../images/brand.jpg")} className=" w-100" alt="" />

          </div>
      
           
           
 
        </div>
            <div className="container">
                <div className="row">
                    {/* <img src={require("../images/brand.jpg")} className="img-fluid" alt="" /> */}
                    <p className="fs-1 text-center"><b>Personal Collaboration Devices</b></p>
                </div>
                <div className="row bg-success bg-opacity-10">
                <p>The GUV series of Personal Collaboration Solutions provides a variety of powerful audio and video solutions that enhance remote communication and collaboration. They are designed to provide a powerful HD collaboration experience with all day comfort for remote workers, students, teachers, healthcare professionals, contact centers and more. The GUV series offers peace-of-mind by being compatible with most major third-party platforms, apps and softphones as well as computers, laptops and IP Phones - providing a powerful yet easy-to-deploy solution.</p>                 

                </div>

                <div className="row mt-5 border border-dark">
                    <div className="col-lg-3">
                        <img src={require("../images/gs-headset.png")} className="img-fluid" alt="" />

                    </div>
                    <div className="col-lg-9">
                        <p className="fs-3 mt-5">
                        Headsets
                        </p>
                    <p>
                     Designed for remote workers, contact centers and other busy environments, the GUV3000 and GUV3005 are HD USB Headsets that pair with laptops, computers, IP phones and other devices to offer high-quality sound.
                     </p> 
                     <NavLink exact to="/Grandstream-Headsets"><button type="button" class="btn btn-info">See Products</button></NavLink>

                    </div>
                </div>

                <div className="row mt-5 mb-5 border border-dark">
                    <div className="col-lg-3">
                        <img src={require("../images/webcams.png")} className="img-fluid" alt="" />

                    </div>
                    <div className="col-lg-9">
                        <p className="fs-3">Webcams</p>
                        <p>
                     Webcams in the GUV series include a Full HD USB camera that enables high-quality audio and video collaboration through laptops, computers and more. The series is compatible with all major third-party communication platforms, apps and softphones as well as Grandstream’s IPVideoTalk Meetings and Wave app.
                     </p> 
                     <NavLink exact to="/Webcams"><button type="button" class="btn btn-info">See Products</button></NavLink>

                    </div>
                </div>
                
            </div>
        </section>



        
          <Footer/>
        </>
    )
}
export default PersonalCollaboration;