import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import "./Basic-Iphone.css";
import Header from "./Header";

const BasicIphone = () => {

  return (
    <>
      <Header />

      <section>
        <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/header-bnr.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center'}}>
          <div className="container">
            <div className="row text-center d-flex  justify-content-center align-items-center">
              <div className="col-lg-6 text-lg-start">
                <div className=" pt-lg-5 pb-lg-5">
                  <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>High End IP Phones</p>

                </div>
              </div>
              <div className="col-lg-6   text-lg-center">
                <div className=" pt-lg-5 pb-lg-5">
                  <p className="text-white ">
                    <span className=""><NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}> <NavLink exact to="/Grandstream" className="text-white">/ Grandstream</NavLink> / IP Voice Telephony / High End IP Phones </span></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid pt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-3 d-none d-sm-block">
                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>BRANDS</span>
                <div className="mb-1"></div>
                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                <div className="border border-white border-opacity-10 mb-2 "></div>
                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                  <NavLink exact to="/grandstream" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; Grandstream</p></NavLink>
                  <NavLink exact to="/Dinstar" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Dinstar</p></NavLink>
                  <NavLink exact to="/NEC" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; NEC</p></NavLink>
                  <NavLink exact to="/Alcatel" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Alcatel</p></NavLink>
                  <NavLink exact to="/YeaLink" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;YeaLink</p></NavLink>
                  <NavLink exact to="/Poly" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Poly</p></NavLink>
                  <NavLink exact to="/Cisco" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Cisco</p></NavLink>

                </p>

                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>SOLUTIONS</span>
                <div className="mb-1"></div>
                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                <div className="border border-white border-opacity-10 mb-2 "></div>
                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                  <NavLink exact to="/Enterprise-Manufacturing" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Enterprise-Manufacturing</p></NavLink>
                  <NavLink exact to="/Security&Surveillance" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Security & Surveillance</p></NavLink>
                  <NavLink exact to="/EducationHospitality" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}> Education & Hospitality</p></NavLink>
                  <NavLink exact to="/BusinessConference" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Business Conference</p></NavLink>

                </p>

                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">

                  <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/GWN7605">
                          <img src={require("../images/GWN7605.png")} style={{ width: "263px" }} alt="GWN7605" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>WiFi Access Point (GWN7605)</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream's powerful WiFi Access Points offer high performance networking, tremendous WiFi coverage range read more.. </p>
                        </NavLink>
                      </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/BusinessConference">
                          <img src={require("../images/Business-Conferencing.png")} style={{ width: "263px" }} alt="Business-Conferencing" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Business Conferencing</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream’s video conferencing solutions offer small and medium sized businesses flexibility read more..</p>
                        </NavLink>
                      </div>            </div>
                    <div className="carousel-item" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/wp810">
                          <img src={require("../images/wifi-cordless.png")} style={{ width: "263px" }} alt="wifi-cordless" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Wi-Fi IP Phone (WP 810)</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>WP810 is a portable Wi-Fi IP phone designed to suit a variety of enterprises  Read More..</p>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div>
                  <img src={require("../images/basic-ip-phone-bnr.jpg")} className="w-100" alt="basic-ip-phone-bnr" />
                </div>

                <div className="mt-3" style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                  <p>
                    Simple, intuitive, and effective. Our GXP1600 series of Basic IP phones deliver interactive communications to a desktop for the user who needs access to VoIP but does not require advanced features.
                  </p>
                </div>

                <div className="row gy-4 mb-4">
                  <div className="col-md-4 h-100 ">
                    <div className=" border border-dark">
                      <div className=" d-flex  justify-content-center align-items-center  ">
                        <img className="w-100" src={require("../images/GXP1630.png")} alt="GXP1630" />

                      </div>
                      <div className=" text-center">
                        <p style={{ fontSize: "18px", fontFamily: "serif" }}>GXP1630</p>
                        <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000" }}>2 lines with up to 1 SIP accounts</p>
                        <NavLink exact to="/GXP1630" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</NavLink>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4  h-100">
                    <div className="border border-dark">
                      <div className="d-flex  justify-content-center align-items-center  ">
                        <img className="w-100" src={require("../images/GXP1628.png")} alt="GXP1628" />
                      </div>
                      <div className="text-center">
                        <p style={{ fontSize: "18px", fontFamily: "serif" }}>GXP1628</p>
                        <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000" }}>2 lines with up to 2 SIP accounts</p>
                        <NavLink exact to="/GXP1628" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</NavLink>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 h-100">
                    <div className=" border border-dark">
                      <div className="d-flex  justify-content-center align-items-center  ">
                        <img className="w-100" src={require("../images/GXP1620.png")} alt="GXP1620" />
                      </div>
                      <div className="text-center">
                        <p style={{ fontSize: "18px", fontFamily: "serif" }}>GXP1620/GXP1625</p>
                        <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000" }}>2 lines with up to 2 SIP accounts</p>
                        <NavLink exact to="/GXP1620/GXP1625" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</NavLink>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 h-100">
                    <div className=" border border-dark">
                      <div className="d-flex  justify-content-center align-items-center  ">
                        <img className="w-100" src={require("../images/GXP1610.png")} alt="GXP1610" />
                      </div>
                      <div className="text-center">
                        <p style={{ fontSize: "18px", fontFamily: "serif" }}>GXP1610/GXP1615</p>
                        <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000" }}>2 lines with up to 1 SIP accounts</p>
                        <NavLink exact to="/GXP1610/GXP1615" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>





      <Footer />
    </>
  )
}
export default BasicIphone;