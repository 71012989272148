import React, { useState } from "react";
import Header from "./Header";
import { NavLink } from "react-router-dom";
import "./Alcatel.css";
import Footer from "./Footer";
const Alcatel = () => {

  const [display, setDisplay] = useState("key_Featured");

  const [btn1, setBtn1] = useState("newStyle");

  const [btn2, setBtn2] = useState("btnStyle");

  const [btn3, setBtn3] = useState("newwStyle");

  const [btn4, setBtn4] = useState("newwwStyle");


  const changleDisplay = () => {

    setDisplay("key_Featured")
    setBtn1("newStyle")
    setBtn2("btnStyle")
    setBtn3("newwStyle")


  }

  const changleDisplay1 = () => {

    setDisplay("Benefits")
    setBtn2("newStyle");
    setBtn3("newwStyle")

  }

  const changleDisplay2 = () => {

    setDisplay("features")
    setBtn2("newStyle");
    setBtn3("newwStyle")

  }

  const changleDisplay3 = () => {

    setDisplay("effects")
    setBtn2("newStyle");
    setBtn3("newwStyle")
    setBtn4("newwwStyle")

  }


  return (
    <>
      <Header />


      <section style={{ backgroundColor: "#090909" }}>
        <div className="container">
          <div className="row text-center d-flex  justify-content-center align-items-center">
            <div className="col-lg-6  ">
              <div className="text-lg-start p-lg-2 ps-lg-5">
                <p className="text-white " style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>Alcatel</p>


              </div>
            </div>
            <div className="col-lg-6   text-lg-center">
              <div className="p-lg-2">
                <p className="text-white ">
                  <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home /</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}>Brand / Alcatel
                  </span></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section>
        <div className="container-fluid pt-5 mb-3">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>BRANDS</span>
                <div className="mb-1"></div>
                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                <div className="border border-white border-opacity-10 mb-2 "></div>
                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                  <NavLink exact to="/grandstream" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; Grandstream</p></NavLink>
                  <NavLink exact to="/Dinstar" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Dinstar</p></NavLink>
                  <NavLink exact to="/NEC" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; NEC</p></NavLink>
                  <NavLink exact to="/Alcatel" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Alcatel</p></NavLink>
                  <NavLink exact to="/YeaLink" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;YeaLink</p></NavLink>
                  <NavLink exact to="/Poly" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Poly</p></NavLink>
                  <NavLink exact to="/Cisco" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Cisco</p></NavLink>
                </p>

                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>SOLUTIONS</span>
                <div className="mb-1"></div>
                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                <div className="border border-white border-opacity-10 mb-2 "></div>
                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                  <NavLink exact to="/Enterprise-Manufacturing" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Enterprise-Manufacturing</p></NavLink>
                  <NavLink exact to="/Security&Surveillance" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Security & Surveillance</p></NavLink>
                  <NavLink exact to="/EducationHospitality" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}> Education & Hospitality</p></NavLink>
                  <NavLink exact to="/BusinessConference" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Business Conference</p></NavLink>

                </p>

                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">

                  <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/GWN7605">
                          <img src={require("../images/GWN7605.png")} style={{ width: "263px" }} alt="GWN7605" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>WiFi Access Point (GWN7605)</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream's powerful WiFi Access Points offer high performance networking, tremendous WiFi coverage range read more.. </p>
                        </NavLink>
                      </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/BusinessConference">
                          <img src={require("../images/Business-Conferencing.png")} style={{ width: "263px" }} alt="Business-Conferencing" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Business Conferencing</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream’s video conferencing solutions offer small and medium sized businesses flexibility read more..</p>
                        </NavLink>
                      </div>            </div>
                    <div className="carousel-item" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/wp810">
                          <img src={require("../images/wifi-cordless.png")} style={{ width: "263px" }} alt="wifi-cordless" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Wi-Fi IP Phone (WP 810)</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>WP810 is a portable Wi-Fi IP phone designed to suit a variety of enterprises  Read More..</p>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">

                <div className="mt-3">
                  <p className="" style={{ color: "#333333", fontSize: '27px', fontFamily: "sans-serif", fontWeight: "600" }}>
                    Meet ALCATEL
                  </p>
                </div>

                <div className="" style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                  <p>
                    Alcatel Lucent Enterprise is a leading provider of enterprise communications solutions and services, from the office to the cloud. Building on our established heritage of innovation and entrepreneurial spirit, we operate globally with 2700+ employees in 100+ countries worldwide, with headquarters near Paris, France. With communications, networking and cloud solutions for business of all sizes, our team of technology experts, service professionals, and 2900+ partners serves more than 830,000 customers worldwide, tailoring and adapting our solutions and services to local requirements. Skyward is a Value Added Distributor (VAD) of Alcatel-Lucent Enterprise.                  </p>

                </div>



                <div className="ps-3">
                  <div className="row">
                    <div className="col-lg-6 p-0 d-flex " >
                      <button style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "600" }} className={btn4} onClick={changleDisplay3}>VOICE</button>
                      <button style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "600" }} className={btn1} onClick={changleDisplay}>DATA</button>
                      <button style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "600" }} className={btn2} onClick={changleDisplay1}>PHONE</button>
                      <button style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "600" }} className={btn3} onClick={changleDisplay2}>COLLABORATION</button>

                    </div>
                  </div>
                  <div className="row border ">
                    <div>
                      {


                        display === 'effects' ?
                          <div>
                            <div className="row m-3">

                              <div className="col-md-4 h-100">
                                <div className=" border border-dark">
                                  <div className="  d-flex  justify-content-center align-items-center  ">
                                    <img className="w-100" src={require("../images/ALCATEL-LUCENT-OMNIPCX-OFFICE.jpg")} alt="Cloud Virtual Server" />

                                  </div>
                                  <div className="text-center">
                                    <p style={{ fontSize: "18px", fontFamily: "serif", fontWeight: "600" }}>ALCATEL-LUCENT OMNIPCX OFFICE</p>
                                    <p className="p-2" style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000", textAlign: "justify" }}>Alcatel-Lucent OmniPCX Office Communication Server is an “e-communication server”, a new “all-in-one” concept combining proven telephony functions with data management.</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 h-100">
                                <div className=" border border-dark">
                                  <div className="  d-flex  justify-content-center align-items-center  ">
                                    <img className="w-100" src={require("../images/ALCATEL-LUCENT-OMNIPCX.jpg")} alt="Power-Virtual-Server" />
                                  </div>
                                  <div className="text-center">
                                    <p style={{ fontSize: "18px", fontFamily: "serif", fontWeight: "600" }}>ALCATEL-LUCENT OMNIPCX</p>
                                    <p className="p-2" style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000", textAlign: "justify" }}>OMNIPCX ENTERPRISE COMMUN-ICATION SERVER (OXE) is primarily a SIP based IP PBX as well as Hybrid EPABX System. Employees who primarily work on company premises .</p>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4 h-100">
                                <div className=" border border-dark">
                                  <div className="  d-flex  justify-content-center align-items-center  ">
                                    <img className="w-100" src={require("../images/ALCATEL-LUCENT-OXO-CONNECT.jpg")} alt="Enterprise-Server" />

                                  </div>
                                  <div className="text-center">
                                    <p style={{ fontSize: "18px", fontFamily: "serif", fontWeight: "600" }}>ALCATEL-LUCENT OXO CONNECT</p>
                                    <p className="p-2" style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000", textAlign: "justify" }}>The SL2100 is highly cost effective because there is more built-in. With VoIP capabilities and a complete Unified Communications (UC) client available right from your web browser. </p>
                                  </div>

                                </div>
                              </div>
                            </div>

                          </div>


                          : display === "key_Featured" ?
                            <div className="m-2 ">
                              <div className="container">
                                <div className="row gy-4 mb-4">
                                  <div className="col-md-4 h-100 ">
                                    <div className="border border-dark">
                                      <div className="d-flex  justify-content-center align-items-center  ">
                                        <img className="w-100" src={require("../images/ALCATEL-LUCENT-OMNIACCESS-AP1101.jpg")} alt="HP-Integrity-RX3600-Server" />
                                      </div>
                                      <div className="text-center">
                                        <p style={{ fontSize: "18px", fontFamily: "serif", fontWeight: "600" }}>ALCATEL-LUCENT OMNIACCESS AP1101</p>
                                        <p className="p-2" style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000", textAlign: "justify" }}>Multifunctional Alcatel-Lucent Omni-Access® AP1101 is an entry level access point (AP) for medium density and small business deployments.</p>
                                      </div>
                                    </div>
                                  </div>


                                  <div className="col-md-4 h-100 ">
                                    <div className="border border-dark">
                                      <div className="d-flex  justify-content-center align-items-center  ">
                                        <img className="w-100" src={require("../images/ALCATEL-LUCENT-OMNIACCESS-STELLAR-AP1201H.jpg")} alt="HP-Integrity-RX3600-Server" />
                                      </div>
                                      <div className="text-center">
                                        <p style={{ fontSize: "18px", fontFamily: "serif", fontWeight: "600" }}>ALCATEL-LUCENT OMNIACCESS STELLAR AP1201H</p>
                                        <p className="p-2" style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000", textAlign: "justify" }}>Multi-functional Alcatel-Lucent Omni-Access® Stellar AP1201H access point is a highly versatile.</p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 h-100 ">
                                    <div className="border border-dark">
                                      <div className="d-flex  justify-content-center align-items-center  ">
                                        <img className="w-100" src={require("../images/ALCATEL-LUCENT-OMNIACCESS-6450.jpg")} alt="HP-Integrity-RX3600-Server" />
                                      </div>
                                      <div className="text-center">
                                        <p style={{ fontSize: "18px", fontFamily: "serif", fontWeight: "600" }}>ALCATEL-LUCENT OMNISWITCH 6450</p>
                                        <p className="p-2" style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000", textAlign: "justify" }}>The Alcatel-Lucent OmniSwitch® 6450 Stackable Fast Ethernet and Gigabit Ethernet LAN value switch family offers versatile, 24/48-port fixed configuration switches with 10 GigE uplinks</p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 h-100 ">
                                    <div className="border border-dark">
                                      <div className="d-flex  justify-content-center align-items-center  ">
                                        <img className="w-100" src={require("../images/ALCATEL-LUCENT-OMNIACCESS-2220.jpg")} alt="HP-Integrity-RX3600-Server" />
                                      </div>
                                      <div className="text-center">
                                        <p style={{ fontSize: "18px", fontFamily: "serif", fontWeight: "600" }}>ALCATEL-LUCENT OMNISWITCH 2220</p>
                                        <p className="p-2" style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000", textAlign: "justify" }}>The Alcatel-Lucent OmniSwitch® 2220 Gigabit WebSmart family of switches provides a simple, secure, and smart business network at affordable prices.</p>
                                      </div>
                                    </div>
                                  </div>



                                </div>

                              </div>
                            </div>
                            : display === "Benefits" ?
                              <div>
                                <div className="row m-3">
                                  <div className="col-lg-4 h-100 text-center ">
                                    <div className=" border">
                                      <img src={require("../images/ALCATEL-LUCENT-8018.jpg")} className="w-100 mt-2" alt="" />
                                      <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }} >ALCATEL-LUCENT 8018</p>
                                      <p className="p-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>Industries such as hospitality and healthcare are looking for cost-effective ways to offer an exceptional in-room communication experience to guests and patients.</p>
                                    </div>

                                  </div>

                                  <div className="col-lg-4 text-center ">
                                    <div className=" border">
                                      <img src={require("../images/ALCATEL-LUCENT-IP-DESKTOP.jpg")} className="w-100 mt-2" alt="" />
                                      <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }} >ALCATEL-LUCENT IP DESKTOP</p>
                                      <p className="p-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>Alcatel-Lucent IP Desktop Softphone is an application installed on a user’s desktop (PC or Mac), tablet or smartphone.</p>
                                    </div>

                                  </div>

                                  <div className="col-lg-4 text-center ">
                                    <div className=" border">
                                      <img src={require("../images/ALCATEL-LUCENT-8028-8038-8068.jpg")} className="w-100 mt-2" alt="" />
                                      <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }} >ALCATEL-LUCENT 8028-8038-8068T</p>
                                      <p className="p-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>Alcatel-Lucent 8068, 8038 and 8028 Premium DeskPhones offer a rich IP communications experience and a great conversations comfort.</p>
                                    </div>

                                  </div>
                                </div>
                              </div>
                              : display === 'features' ?

                                <div>
                                  <div className="row">
                                    <div className="col-lg-4 text-center">
                                      <div className="m-3 border">
                                        <img src={require("../images/ALCATEL-LUCENT-OMNITOUCH.jpg")} className="img-fluid" alt="" />
                                        <p className="" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "serif" }}>ALCATEL-LUCENT OMNITOUCH</p>
                                        <p className="ps-2 pe-2" style={{ fontSize: '14px', fontFamily: "sans-serif", textAlign: "justify" }}>Alcatel-Lucent OmniTouch™ Contact Center Standard Edition is the ideal solution for companies with contact centers that are mainly driven by voice interactions.</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>



                                : <div>
                                  somethings went wrong
                                </div>
                      }
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <Footer />
    </>
  )
}
export default Alcatel;