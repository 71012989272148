import React from "react";
import { NavLink } from "react-router-dom";
import "./Consultation.css";
import Footer from "./Footer";
import Header from "./Header";

const Consultation = () => {

   return (
      <>
         <Header />
         <section>
            <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/bnr.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
               <div className="container">
                  <div className="row text-center d-flex  justify-content-center align-items-center">
                     <div className="col-lg-6 text-lg-start">
                        <div className="pb-lg-5 pt-lg-5">
                           <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>CONSULTATION</p>

                        </div>
                     </div>
                     <div className="col-lg-6   text-lg-end">
                        <div className=" pt-lg-5 pb-lg-5">
                           <p className="text-white">
                              <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}> / <NavLink exact to="/Consultation" className="text-white">Services</NavLink> / Consultation</span></span>
                           </p>


                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section>
            <div className="contianer-fluid pt-5" style={{ backgroundColor: "#f7f7f7" }}>
               <div className="container">
                  <div className="row">
                     <div className="col-lg-12">
                        <p className="fs-2" style={{ fontSize: "36px", color: "#333333", fontFamily: "serif" }}><b>Skyward Offers Unflinching Support For All Your Product Based Needs !</b> </p>
                        <div style={{ fontFamily: "sans-serif", fontSize: "14px" }}>
                           <p>Voice IP services are the new talk of the town nowadays. The reason being the ample of advantages it holds over normal telephony services. Thus, more and more enterprises and businesses are transferring to the VoIP gateway services in India and even worldwide. Infact, voip gateway devices are being most sought after in the business industries.</p>
                           <p>We all are aware of the fact that a phone system basically can convert your voice call into minor data packets. The receiver on the other hand receives these data packets over the Internet. Moreover, the receiver then can decode these data packets into your voice. Now, how is it useful for your business? Well, any business owner may be confused about the advantages of gateway device for their enterprise and network. However, you need not worry as Skyward is here to provide you the proper consultation over the usage of these devices.</p>
                           <p>Using the consultation services of Skyward, you can get a clear picture about the different alternatives available for gateway devices. Our team of experienced professionals can put their expertise to your usage and have a detailed session with you regarding the increasing presence of gateway services in India. After all, communication plays an imperative role in increasing revenues for any business, be it marketing or catering to the users’ needs. Moreover, organizations can easily avail the benefits of significant cost savings in their communicating channels through phone systems.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section>
            <div className="container-fluid pb-5 pt-3 p-0 m-0" style={{ backgroundColor: "#f7f7f7" }}>
               <div className="container">
                  <div className="row ">
                     <div className="col-lg-6 text-end">
                        <img src={require("../images/img/Consultation-bnr.jpg")} className="w-75" alt="" />
                     </div>
                     <div className="col-lg-6">
                        <div style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", lineHeight: "30px" }}>
                           <p>Are you aware of the fact that where a normal landline phone system can cost $50 per phone line for every month? But on the other hand, VoIP plans can be availed for even less than $20 per line. Interesting, isn’t it? Well, we at skyward have many more benefits listed for VoIP for you. Just give us a call and we will be ready to serve you at the earliest.</p>
                           <p>When we talk of traditional phone services, the businesses and enterprises may have to bear massive initial costs. These initial costs include PBX costs. However, the VoIP gateway devices make the PBX costs go away since you need a broadband connection to power the phone service. Thus, we would appreciate if you could take out some time from your busy schedule and contact skyward at the earliest for our consultation services.</p>
                           <p>We have been into this business since long and hold the reputation for being the best when it comes to consultation for VoIP gateway services in India. So, be it consultation, installation, or the after sales support, we are at your service at every stage. So, come to us and get a perfect alternative for your phone system at the earliest!</p>
                           <p><b>If you're interested in learning more about our consultation services, feel free to contact us as soon as possible.</b></p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>


         <section>
         <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/bg31.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
               <div className="container">
                  <div className="row">
                     <div className="col-lg-8">
                        <div className="ps-2 pt-4">
                           <p className="fs-2" style={{ color: "white", fontWeight: "700", fontFamily: "sans serif" }}>
                              Bright Solutions. Profitable Results.

                              <p style={{ fontSize: '18px', color: "white", fontWeight: "700", fontFamily: "sans serif" }}>
                                 Experience you can trust, service you can count on.
                              </p></p>
                        </div>
                     </div>
                     <div className="col-lg-4 text-start pt-5 pb-2">
                        <a href="/Contact-us"><button style={{ fontSize: "14px", border: "none", outline: "none", padding: "10px 26px" }} className=" rounded-pill btn-lg text-black bg-white">Get a Best Consultation Service</button></a>
                     </div>
                  </div>
               </div>
            </div>
         </section>





         <Footer />
      </>
   )
}
export default Consultation;