import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { NavLink } from "react-router-dom";
const SoftphoneApp = () => {

  return (
    <>
      <Header />

      <section>
      <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/header-bnr.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
          <div className="container">
            <div className="row text-center  d-flex  justify-content-center align-items-center">
              <div className="col-lg-6 text-lg-start">
                <div className="pb-lg-5 pt-lg-5">
                  <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>Softphone-app</p>

                </div>
              </div>
              <div className="col-lg-6 text-lg-center">
                <div className=" pb-lg-5  pt-lg-5">
                  <p className="text-white">
                    <span className=""><NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}> <NavLink exact to="/Grandstream" className="text-white">/ Grandstream</NavLink> / IP Voice Telephony / Softphone-app </span></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid pt-5 pb-4">
          <div className="container">
            <div className="row">
              <div className="col-md-3 d-none d-sm-block">
                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>BRANDS</span>
                <div className="mb-1"></div>
                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                <div className="border border-white border-opacity-10 mb-2 "></div>
                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                  <NavLink exact to="/grandstream" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; Grandstream</p></NavLink>
                  <NavLink exact to="/Dinstar" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Dinstar</p></NavLink>
                  <NavLink exact to="/NEC" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; NEC</p></NavLink>
                  <NavLink exact to="/Alcatel" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Alcatel</p></NavLink>
                  <NavLink exact to="/YeaLink" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;YeaLink</p></NavLink>
                  <NavLink exact to="/Poly" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Poly</p></NavLink>
                  <NavLink exact to="/Cisco" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Cisco</p></NavLink>

                </p>

                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>SOLUTIONS</span>
                <div className="mb-1"></div>
                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                <div className="border border-white border-opacity-10 mb-2 "></div>
                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                  <NavLink exact to="/Enterprise-Manufacturing" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Enterprise-Manufacturing</p></NavLink>
                  <NavLink exact to="/Security&Surveillance" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Security & Surveillance</p></NavLink>
                  <NavLink exact to="/EducationHospitality" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}> Education & Hospitality</p></NavLink>
                  <NavLink exact to="/BusinessConference" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Business Conference</p></NavLink>

                </p>

                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">

                  <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/GWN7605">
                          <img src={require("../images/GWN7605.png")} style={{ width: "263px" }} alt="GWN7605" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>WiFi Access Point (GWN7605)</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream's powerful WiFi Access Points offer high performance networking, tremendous WiFi coverage range read more.. </p>
                        </NavLink>
                      </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/BusinessConference">
                          <img src={require("../images/Business-Conferencing.png")} style={{ width: "263px" }} alt="Business-Conferencing" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Business Conferencing</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream’s video conferencing solutions offer small and medium sized businesses flexibility read more..</p>
                        </NavLink>
                      </div>            </div>
                    <div className="carousel-item" data-bs-interval="2500">
                      <div>
                        <NavLink exact to="/wp810">
                          <img src={require("../images/wifi-cordless.png")} style={{ width: "263px" }} alt="wifi-cordless" />
                          <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Wi-Fi IP Phone (WP 810)</p>
                          <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>WP810 is a portable Wi-Fi IP phone designed to suit a variety of enterprises  Read More..</p>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div>
                  <img src={require("../images/Softphone-App-bnr.jpg")} className="w-100" alt="Softphone-App-bnr" />
                </div>

                <div className="mt-3" style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                  <p>
                    Expand your communications solution by linking your desk phone to your mobile device to keep in touch with your business and residential accounts while working remotely or traveling. Grandstream Wave is a free softphone that revolutionizes a user’s workflow. It integrates with up to 6 SIP accounts and supports essential call control features such as 6-way voice conferencing, 24 virtual BLF keys, 2-way video calls, and so much more. The Grandstream Wave is also capable of integrating with your IP PBXs (such as Grandstream’s UCM6200 series or UCM6510 series of IP PBX) for even more powerful network utilization.
                  </p>
                </div>

                <div className="row gy-4 mb-4">
                  <div className="col-lg-5 p-3 text-center " >
                    <div id="carouselExampleFade" class="carousel slide " data-bs-ride="carousel">
                      <div class="carousel-inner text-center">

                        <div class="carousel-item active animated">

                          <img className=" w-100" src={require("../images/grandstream-wave1.jpg")} alt="grandstream-wave1" />
                        </div>
                        <div class="carousel-item" >

                          <img className=" w-100" src={require("../images/grandstream-wave2.jpg")} alt="grandstream-wave2" />
                        </div>
                        <div class="carousel-item ">

                          <img className=" w-100" src={require("../images/grandstream-wave3.jpg")} alt="grandstream-wave3" />
                        </div>

                      </div>
                      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon p-4 bg-dark" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                        <span class="carousel-control-next-icon p-4 bg-dark" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                  <div className="col-md-7 pt-5 ">
                    <p style={{fontSize:"18px",fontFamily:"sans-serif",color:"#666666"}}>
                      Enable users to move freely and continue to receive calls from any business or residential SIP account. The Grandstream Wave is a free softphone application that allows users to connect to their SIP accounts from anywhere in the world. Utilize on any AndroidTM or iOS device to increase mobility and stay connected to essential communications.
                    </p>
                  </div>

                </div>
               
                <div className="row p-3" style={{backgroundColor:"#F7F7F7"}}>
                  <p style={{fontSize:"24px",color:"#186191",fontFamily:"serif",fontWeight:"600"}}>Features</p>
                  <ul style={{listStyleType:"none"}}>
                    <li>
                      <i className="fa-solid fa-check"></i> Supports G.711µ/a, G.722 (HD-audio), G.726-32, G.729, GSM, iLBC, Opus and Speex codecs
                    </li>
                    <li>
                    <i className="fa-solid fa-check"></i> Security features include SIP over TLS and 128/256-bit SRTP
                    </li>
                    <li>
                    <i className="fa-solid fa-check"></i> Up to 6-way audio conference, and 24 virtual BLF keys
                    </li>
                    <li>
                    <i className="fa-solid fa-check"></i> Supports 6 SIP accounts
                    </li>
                    <li>
                    <i className="fa-solid fa-check"></i> Native integration with mobile devices including contact, call history and ringtones
                    </li>
                    <li>
                    <i className="fa-solid fa-check"></i> Enterprise features including IP PBX integration, BLF, call transfer/pickup, call recording with UCM, LDAP
                    </li>
                    <li>
                    <i className="fa-solid fa-check"></i> Automatic call forwarding based on time and location rules
                    </li>
                    <li>
                    <i className="fa-solid fa-check"></i> Full integration with Grandstream’s UCM-series of IP PBXs, including creation of QR code for automatic provisioning
                    </li>
                    <li>
                    <i className="fa-solid fa-check"></i> Voice channel switching between Bluetooth, earphone, speakerphone & 3.5mm headset
                    </li>
                    <li>
                    <i className="fa-solid fa-check"></i> Supports 2-way video calls based on SIP & H.264 video codec
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}
export default SoftphoneApp;