import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import "./Solution.css";

const Solution = () => {
    return (
        <>
            <Header />



            <section>
                <div className="contianer-fluid" style={{ backgroundColor: "rgb(24,97,145)" }}>
                    <div className="container">
                        <div className="row pt-3 text-center">
                          
                                <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white  text-decoration-none" to="/" > Home</NavLink> <span  className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}> / Solutions</span></span>
                            
                        </div>
                        <div className="row pt-4 pb-4">
                            <div className="col-lg-1"></div>
                            <div className="col-lg-10 text-center">
                                <p className="fs-1 text-white" style={{ fontWeight: "500", fontSize: "32px", fontFamily: "Trirong, serif" }}>Our Solutions</p>
                                <p className="text-white" style={{ fontFamily: "'Open Sans', sans-serif", fontSize: "14px" }}>Your VoIP calls will never be the same with HD Voice calling from the leaders in VoIP. With HD Voice you can experience crisp, clear high definition audio superior to normal calling. For more check out the Blogs in TAB and HD capable handsets below.</p>
                            </div>
                            <div className="col-lg-1"></div>
                        </div>
                    </div>
                </div>
            </section>



            <section>
                <div className="container-fluid mt-5">
                    <div className="container">
                        <div className="row">
                            <p className="fs-3"><img src={require("../images/enterprise.png")} style={{ backgroundColor: "#186191" }} className="img-fluid" alt="" /> &nbsp; <span className="fs-2" style={{ fontWeight: "600",fontFamily:"Trirong,serif",color:"#333" }}>Corporate & industrial</span></p>
                            <p style={{ textAlign: "justify",fontSize:"14px",color:"#555555" }}><b>It’s time to boost your internal as well as external communication with an approach of Unified Communications that Skyward India offer.</b> </p>
                            <p style={{ textAlign: "justify",fontSize:"14px",fontFamily:"sans-serif",color:'#666666' }}>Businesses of any sort and of any scale are observing the increase in development with Skyward India Solutions for combined communication. The accounted factors for such are a legacy of flexibility, efficiency, increased productivity, and scalability. They are resulted in the Responsiveness that sets apart a venture.</p>
                            <NavLink exact to="/Enterprise-Manufacturing" ><button type="button" className="btn bmw  rounded-pill" style={{ fontSize: "11px", fontFamily: "Open Sans', sans-serif" }} >Read more</button></NavLink>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid mt-5">
                    <div className="container">
                        <div className="row">
                            <p className="fs-3" style={{ textAlign: "justify" }}><img src={require("../images/sme.png")} style={{ backgroundColor: "#186191" }} className="img-fluid" alt="" /> &nbsp;<span className="fs-3" style={{ fontWeight: "600",fontFamily:"Trirong,serif",color:"#333" }}> Small & Medium-Sized Enterprises</span></p>
                            <p style={{ textAlign: "justify",fontSize:"14px",color:"#555555" }}><b>Small & Medium-Sized Enterprises can impact your day-to-day in a positive way, and make your life safer and easier! Affordable and Friendly for you!</b></p>
                            <p style={{ textAlign: "justify",fontSize:"14px",fontFamily:"sans-serif",color:'#666666' }}>Imagine the very best in comfort, security, and design and energy efficiency for tomorrow’s smart home, today. With Skyward Smart home solutions it is easy to access Intercom facility with option of Outward Calling along with customized billing format.</p>
                            <NavLink exact to="/Security&Surveillance"><button type="button" className="btn bmw rounded-pill" style={{ fontSize: "11px", fontFamily: "Open Sans', sans-serif" }} >Read more</button></NavLink>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid mt-5">
                    <div className="container">
                        <div className="row">
                            <p className="fs-3" style={{ textAlign: "justify" }}><img src={require("../images/education.png")} style={{ backgroundColor: "#186191" }} className="img-fluid" alt="" />&nbsp;  <span className="fs-3" style={{ fontWeight: "600",fontFamily:"Trirong,serif",color:"#333" }}> Education & Hospitality</span></p>
                            <p style={{ textAlign: "justify",fontSize:"14px",color:"#555555" }}><b>
                                Skyward is getting better the future of the upcoming generation one school at a moment.</b></p>
                            <p style={{ textAlign: "justify",fontSize:"14px",fontFamily:"sans-serif",color:'#666666' }}>The specifications mentioned below are not just create internal communication amid teachers more competent, perfectly available and simple to use, but also have the aptitude to create a better barrier of safety surveillance for school buildings, all at a reasonable price.</p>
                            <NavLink exact to="/Education-Hospitality"><button type="button" className="btn bmw rounded-pill" style={{ fontSize: "11px", fontFamily: "Open Sans', sans-serif" }} >Read more</button></NavLink>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid mt-5">
                    <div className="container">
                        <div className="row">
                            <p className="fs-3" style={{ textAlign: "justify" }}><img src={require("../images/Office.png")} style={{ backgroundColor: "#186191" }} className="img-fluid" alt="" />&nbsp; <span className="fs-3"  style={{ fontWeight: "600",fontFamily:"Trirong,serif",color:"#333" }}>Offices Solutions</span></p>
                            <p style={{ textAlign: "justify",fontSize:"14px",color:"#555555" }}><b>
                                Office Solutions makes an impact on our communities.</b></p>
                            <p style={{ textAlign: "justify",fontSize:"14px",fontFamily:"sans-serif",color:'#666666' }}>Office solutions continue to be the most vital assets to this SME industry globally. The most efficient way to achieve productivity at is to have safe and secure intercom using wifi routers.</p>
                            <NavLink exact to="/BusinessConference"><button type="button" className="btn bmw rounded-pill" style={{ fontSize: "11px", fontFamily: "Open Sans', sans-serif" }} >Read more</button></NavLink>
                        </div>
                    </div>
                </div>
            </section>


            <Footer />
        </>
    )

    
}

export default Solution;
