import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { NavLink } from "react-router-dom";
const MTG3000 = () => {

  return (
    <>
      <Header />


      <section style={{ backgroundColor: "#090909" }}>
        <div className="container">
          <div className="row text-center d-flex  justify-content-center align-items-center">
            <div className="col-lg-6  ">
              <div className="text-lg-start p-lg-2 ps-lg-5">
                <p className="text-white " style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>MTG 3000</p>
              </div>
            </div>
            <div className="col-lg-6   text-lg-center">
              <div className="p-lg-2">
                <p className="text-white ">
                  <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home /</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}>Dinstar / Digital VoIP Gateway / <b style={{ fontSize: "16px" }}>MTG 3000</b>
                  </span></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="container mt-5 ">
            <div className="row">

              <div className="col-lg-4 " >
                <div id="carouselExampleFade" class="carousel slide " data-bs-ride="carousel">
                  <div class="carousel-inner text-center border" style={{ height: "280px" }}>
                    <div class="carousel-item active animated">
                      <img className=" w-100" src={require("../images/mtg2000-slide.png")} alt="mtg2000-slide" />
                    </div>
                    <div class="carousel-item">

                      <img className=" w-100" src={require("../images/mtg2000-slide2.png")} alt="mtg2000-slide2" />
                    </div>

                    <div class="carousel-item">

                      <img className=" w-100" src={require("../images/mtg2000-slide3.png")} alt="mtg2000-slide3" />
                    </div>

                  </div>
                  <button class="carousel-control-prev pe-2" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                    <span class="carousel-control-next-icon p-4 bg-dark" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next ps-2" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                    <span class="carousel-control-prev-icon p-4 bg-dark" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>


              <div className="col-lg-8">
                <div>
                  <p className="" style={{ fontSize: "40px", fontFamily: "serif", fontWeight: "600" }} >
                    MTG 3000
                  </p>
                  <p style={{ fontSize: "30px", fontFamily: "serif", color: "#333333", fontWeight: "600" }}>
                    High Density Digital VoIP Gateway
                  </p>
                  <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                    MTG3000 is a carrier-grade Digital VoIP gateway, scalable from 16 to 63 ports E1/T1 with STM-1 interface. It provides carrier-grade VoIP and FoIP services, as well as value-added functions such as modem and voice recognition. With highly maintainable, manageable and operable features, it offers a high performance, reliable communication network for users.
                  </p>
                  <NavLink exact to="/Contact-us" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Request a Quote</button></NavLink>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-3">
          <div className="row ps-3">
            <div>
              <p style={{ fontSize: "32px", fontFamily: "serif", color: "#333333", fontWeight: "500" }}>
                Description

                <div className="border border-primary" style={{ width: "55px" }}></div>
                <div className=" mb-2 " style={{ borderBottom: "1px solid lightgray" }}></div>
              </p>
              <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                MTG3000 supports a wide-range of signaling protocols, realizing the interconnection between SIP and traditional signals like ISDN PRI / SS7, utilizing efficiency of trunking resources while ensuring voice quality. With multiple voice codes, secure signal encryption and smart voice recognition technology, MTG3000 is ideal for a variety of applications of services providers and telecom operators.
              </p>

            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 ">
              <p className="ps-3" style={{ fontSize: "30px", fontFamily: "serif", color: "#333333" }}>
                Features
              </p>
              <ul style={{ fontSize: "16px", fontFamily: "sans-serif", lineHeight: "30px", textAlign: "justify" }}>
                <li>
                  16 to 63 ports E1/T1 in 2U chassis, STM-1 interface
                </li>
                <li>
                  Up to 1890 simultaneous calls
                </li>
                <li>
                  Redundancy Dual MCU units
                </li>
                <li>
                  Dual Power Supplies
                </li>
                <li>
                  Flexible routing
                </li>
                <li>
                  Multiple SIP trunks
                </li>
                <li>
                  Fully compatible with mainstream VoIP platforms

                </li>

              </ul>
            </div>
            <div className="col-lg-6 text-center">
              <div>
                <img className=" w-75" src={require("../images/mtg3000-right.png")} alt="mtg3000-right" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-lg-5">
          <div className="row">
            <div className="col-lg-4" >
              <div className="text-center" style={{ backgroundColor: "#F5ECEC", padding: "40px" }} >
                <p style={{ fontSize: "22px", fontFamily: "serif", fontWeight: "600" }}>
                  Quick Enquiry
                </p>
                <p style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                  For the product quotation please fill the given form our team will get back to you shortly after analysing your needs
                </p>

                <div className="pt-3">
                  <NavLink className=" text-decoration-none fs-6" to="/Contact-us" >
                    <span className="btn-round send-btn">SEND ENQUIRY</span>
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="col-lg-8" >
              <div id="carouselExampleIndicators" className="carousel slide " data-bs-ride="carousel" style={{ height: "300px" }}>
                <div className="carousel-indicators sliderbtn">
                  <input style={{ width: "20px", height: "15px" }} type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></input>
                  <input style={{ width: "20px", height: "15px" }} className='' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></input>
                  <input style={{ width: "20px", height: "15px" }} className=' ' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></input>
                  <input style={{ width: "20px", height: "15px" }} className=' ' type="radio" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></input>
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active animated" data-bs-interval="1100">
                    <div className="row">
                      <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                        <img src={require("../images/SME-IP-PBX.png")} className="w-100" alt="" />
                      </div>
                      <div className="col-lg-6">
                        <div>
                          <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>SME IP PBX </p>
                          <div className="border border-primary" style={{ width: "55px" }}></div>

                          <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>  Dinstar UC series IP Phone systems integrate different physic interfaces like LTE/GSM, FXS, FXO, E1, Wi-Fi into one box, offer rich functionality of IP PBX.   </p>

                          <NavLink exact to="/SME-IP-PBX" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="carousel-item" data-bs-interval="1200">

                    <div className="row">
                      <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                        <img src={require("../images/DAG1000-4S-1.png")} className="w-100" alt="" />
                      </div>
                      <div className="col-lg-6">
                        <div>
                          <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>Analog VoIP Gateway</p>
                          <div className="border border-primary" style={{ width: "55px" }}></div>

                          <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}> Dinstar DAG series Analog VoIP gateway are cost-effective, reliable VoIP gateways that seamlessly connect your analog phones, fax machines and legacy PBX with IP PBX systems and IP Phone networks, leveraging investment of your existing telephony infrastructure and simplifying your VoIP migration.  </p>
                          <NavLink exact to="/Analog-VoIP-Gateway" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item" data-bs-interval="1300">

                    <div className="row">
                      <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                        <img src={require("../images/GSM-VoIP-Gateway.png")} className="w-100" alt="" />
                      </div>
                      <div className="col-lg-6">
                        <div>
                          <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>GSM /3G/4G/VoIP Gateway</p>
                          <div className="border border-primary" style={{ width: "55px" }}></div>

                          <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}> Dinstar UC2000 series VoIP GSM/3G/4G Gateways offer complete choices scalable from 1 to 32 channels, implement the state-of-the-art functionality in market as always, and enable the smooth transit between mobile and VoIP networks.     </p>
                          <NavLink exact to="/GSM-VoIP-Gateway" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item" data-bs-interval="1400">

                    <div className="row">
                      <div className="col-lg-4 text-center d-flex justify-content-center align-items-center">
                        <img src={require("../images/Digital-VoIP-Gateway.png")} className="w-100" alt="" />
                      </div>
                      <div className="col-lg-6">
                        <div>
                          <p className="m-0 p-0" style={{ fontSize: "32px", fontFamily: "serif", fontWeight: "600" }}>Digital VoIP Gateway </p>
                          <div className="border border-primary" style={{ width: "55px" }}></div>

                          <p className="mt-4" style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>  MTG series are E1/T1 Digital VoIP Gateways that seamlessly connect VoIP and the legacy PSTN networks (legacy PBX or E1/T1 service providers) , scalable up to 63 E1 or T1 lines.   </p>
                          <NavLink exact to="/Digital-VoIP-Gateway" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Read More..</button></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}
export default MTG3000;