import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { NavLink } from "react-router-dom";
const HPEProLiantDL380Gen10 = () => {

    return (
        <>
            <Header />

            <section style={{ backgroundColor: "#090909" }}>
                <div className="container">
                    <div className="row text-center d-flex  justify-content-center align-items-center">
                        <div className="col-lg-6  ">
                            <div className="text-lg-start p-lg-2 ps-lg-5">
                                <p className="text-white " style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>HPE ProLiant DL380 Gen10 Server</p>
                            </div>
                        </div>
                        <div className="col-lg-6   text-lg-center">
                            <div className="p-lg-2">
                                <p className="text-white ">
                                    <span className=""  >  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home /</NavLink>  <NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/Servers" > Server /</NavLink><span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}> <b style={{ fontSize: "16px" }}>HPE ProLiant DL380 Gen10 Server</b>
                                    </span></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section>
                <div className="container">
                    <div className="container mt-5 ">
                        <div className="row">

                            <div className="col-lg-4 " >
                                <div id="carouselExampleFade" class="carousel slide " data-bs-ride="carousel">
                                    <div class="carousel-inner text-center pt-5 border" style={{ height: "280px" }}>
                                        <div class="carousel-item active animated">

                                            <img className="w-100" src={require("../images/HPE-ProLiant-DL380-Gen10.png")} alt="" />
                                        </div>
                                        <div class="carousel-item">

                                            <img className="w-100 mt-5" src={require("../images/HPE ProLiant DL380 Gen10 Server-slide-2.jpg")} alt="" />
                                        </div>

                                    </div>
                                    <button class="carousel-control-prev pe-2" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                                        <span class="carousel-control-next-icon p-4 bg-dark" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next ps-2" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                                        <span class="carousel-control-prev-icon p-4 bg-dark" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>


                            <div className="col-lg-6">
                                <div>
                                    <p className="" style={{ fontSize: "40px", fontFamily: "serif", fontWeight: "600" }} >
                                        HPE ProLiant DL380 Gen10 Server
                                    </p>

                                    <p style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#666666", textAlign: "justify" }}>
                                        The HP Integrity rx4640 Server is a powerful entry-class workhorse for demanding application and database workloads. This next-generation Integrity server features a new architecture that delivers significantly expanded computing capabilities along with a high return on your IT investment.
                                    </p>
                                    <NavLink exact to="/Contact-us" className="ps-3"> <button type="button" style={{ fontSize: "14px", fontFamily: "open sans" - 'sans-serif', padding: "10px , 20px", border: "1px solid black" }} className="btn bgmi  rounded-pill .me-20 mb-5" >Request a Quote</button></NavLink>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-lg-6 ">
                            <p className="ps-3" style={{ fontSize: "30px", fontFamily: "serif", color: "#333333" }}>
                                Features
                            </p>
                            <ul style={{ fontSize: "16px", fontFamily: "sans-serif", lineHeight: "30px", textAlign: "justify" }}>
                                <li>
                                    Form Factor: 2U
                                </li>
                                <li>
                                    Processor Name: Intel
                                </li>
                                <li>
                                    Memory Slots: 24 DIMM slots
                                </li>
                                <li>
                                    Processor Speed: 3.9 GHz, maximum depending on processor
                                </li>
                                <li>
                                    Standard Memory: 3.0 TB (24 X 128 GB) LRDIMM; 6.0 TB (12 X 512 GB) Persistent Memory
                                </li>
                                <li>
                                    Maximum Memory: 3.0 TB with 128 GB DDR4, depending on processor model; 6.0 TB with HPE 512GB 2666 Persistent Memory Kit, depending on processor model
                                </li>
                                <li>
                                    Processor Family: Intel Xeon Scalable 8100/8200 series Intel Xeon Scalable 6100/6200 series Intel Xeon Scalable 5100/5200 series Intel Xeon Scalable 4100/4200 series Intel Xeon Scalable 3100/3200 series
                                </li>

                            </ul>
                        </div>
                        <div className="col-lg-6 text-center">
                            <div>
                                <img src={require("../images/HPE-ProLiant-DL380-Gen10.png")} className="w-100 p-lg-5  " alt="HP-Integrity-RX4640-1" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}
export default HPEProLiantDL380Gen10;