import React from "react";
import Header from "./Header";
import Footer from "./Footer";
const Sophosfirewall = () => {

  return (
    <>
      <Header />

      <section>
        <div className="">
          <div >
            <img src={require("../images/Firewall.png")} className=" w-100" alt="Firewall" />

          </div>




        </div>
        <div className="container">
          <div className="row justify-content-center">
            {/* <img src={require("../images/Firewall.png")} className="img-fluid w-50" alt="" /> */}
            <b><p className="fs-2 text-center">Sophos Firewall</p></b>
          </div>
          <div className="row">
            <div className="bg-success text-dark bg-opacity-10">
              <p className="fd-3">Xstream Protection</p>
              <p>Sophos Firewall’s Xstream architecture protects your network from the latest threats while accelerating your important SaaS, SD-WAN, and cloud application traffic.</p>
              <p><b>TLS 1.3 Decryption</b>
                Remove an enormous blind spot with intelligent TLS inspection that’s fast and effective, supporting the latest standards with extensive exceptions and point-and-click policy tools to make your job easy.</p>
              <p><b>Deep Packet Inspection</b>
                Stop the latest ransomware and breaches with high-performance streaming deep packet inspection, including next-gen IPS, web protection, and app control, as well as deep learning and sandboxing powered by SophosLabs Intelix.</p>
              <p><b>Application Acceleration</b>
                Accelerate your SaaS, SD-WAN, and cloud traffic such as VoIP, video, and other trusted applications automatically or via your own policies, putting them on the Xstream FastPath to optimize performance.
              </p>
            </div>
          </div>
        </div>

        <div className="container mt-5 border border-dark shadow p-3 mb-5">
          <div className="row">

            <div className="col-lg-4">
              <img src={require("../images/Sophos-firewall.png")} className="img-fluid" alt="" />

            </div>

            <div className="col-lg-8">
              <b>  <p className="fs-4">Features</p></b>

              <p>Expose Hidden Risks</p>
              <p>Stop Unknown Threats</p>
              <p>Isolate Infected Systems</p>
              <p>Multiple machine learning models</p>
              <p>Supports all modern cypher suites</p>

            </div>

          </div>
        </div>
      </section>


      <Footer />
    </>
  )
}

export default Sophosfirewall;