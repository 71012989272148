import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const HotelPhone = () => {

    return (
        <>
            <Header />

            <section>
            <div className='container-fluid ' style={{ backgroundImage: `url(${require("../images/img/header-bnr.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}> 
                    <div className="container">
                        <div className="row text-center d-flex  justify-content-center align-items-center">
                            <div className="col-lg-6 text-lg-start">
                                <div className="pb-lg-5 pt-lg-5">
                                    <p className="text-white" style={{ fontSize: "21px", fontFamily: "serif", fontWeight: '600' }}>GSM/3G/4G VoIP Gateway</p>

                                </div>
                            </div>
                            <div className="col-lg-6   text-lg-center">
                                <div className=" pt-lg-5 pb-lg-5">
                                    <p className="text-white">
                                        <span className=""><NavLink style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }} className=" text-white pt-5  text-decoration-none" to="/" > Home</NavLink> <span className="text-white" style={{ fontSize: "12px", fontFamily: "Open Sans', sans-serif" }}> <NavLink exact to="/Dinstar" className="text-white">/ Dinstar</NavLink> / GSM/3G/4G VoIP Gateway </span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 d-none d-sm-block">
                                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>BRANDS</span>
                                <div className="mb-1"></div>
                                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                                <div className="border border-white border-opacity-10 mb-2 "></div>
                                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                                    <NavLink exact to="/grandstream" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; Grandstream</p></NavLink>
                                    <NavLink exact to="/Dinstar" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Dinstar</p></NavLink>
                                    <NavLink exact to="/NEC" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp; NEC</p></NavLink>
                                    <NavLink exact to="/Alcatel" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Alcatel</p></NavLink>
                                    <NavLink exact to="/YeaLink" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;YeaLink</p></NavLink>
                                    <NavLink exact to="/Poly" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Poly</p></NavLink>
                                    <NavLink exact to="/Cisco" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}><i class="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;Cisco</p></NavLink>

                                </p>

                                <span style={{ fontFamily: "Open Sans', sans-serif", fontSize: '14px' }}>SOLUTIONS</span>
                                <div className="mb-1"></div>
                                <div className=" " style={{ width: "55px", border: "1px solid #186191" }}></div>
                                <div className="border border-white border-opacity-10 mb-2 "></div>
                                <p className=" pt-2" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif" }}>
                                    <NavLink exact to="/Enterprise-Manufacturing" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Enterprise-Manufacturing</p></NavLink>
                                    <NavLink exact to="/Security&Surveillance" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Security & Surveillance</p></NavLink>
                                    <NavLink exact to="/EducationHospitality" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}> Education & Hospitality</p></NavLink>
                                    <NavLink exact to="/BusinessConference" style={{ textDecoration: "none" }}><p className="pb-1" style={{ color: "#666", fontSize: '14px', fontFamily: "'Open Sans', sans-serif", borderBottom: '1px dashed #404040' }}>Business Conference</p></NavLink>

                                </p>

                                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">

                                    <div className="carousel-inner">
                                        <div className="carousel-item active" data-bs-interval="2500">
                                            <div>
                                                <NavLink exact to="/GWN7605">
                                                    <img src={require("../images/GWN7605.png")} style={{ width: "263px" }} alt="GWN7605" />
                                                    <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>WiFi Access Point (GWN7605)</p>
                                                    <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream's powerful WiFi Access Points offer high performance networking, tremendous WiFi coverage range read more.. </p>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="carousel-item" data-bs-interval="2500">
                                            <div>
                                                <NavLink exact to="/BusinessConference">
                                                    <img src={require("../images/Business-Conferencing.png")} style={{ width: "263px" }} alt="Business-Conferencing" />
                                                    <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Business Conferencing</p>
                                                    <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>Grandstream’s video conferencing solutions offer small and medium sized businesses flexibility read more..</p>
                                                </NavLink>
                                            </div>            </div>
                                        <div className="carousel-item" data-bs-interval="2500">
                                            <div>
                                                <NavLink exact to="/wp810">
                                                    <img src={require("../images/wifi-cordless.png")} style={{ width: "263px" }} alt="wifi-cordless" />
                                                    <p className="fs-4" style={{ fontFamily: "serif", color: "#333333" }}>Wi-Fi IP Phone (WP 810)</p>
                                                    <p style={{ fontSize: '14px', fontFamily: "sans-serif" }}>WP810 is a portable Wi-Fi IP phone designed to suit a variety of enterprises  Read More..</p>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div>
                                    <img src={require("../images/img/hotel-phones-banner.webp")} className="w-100" alt="" />
                                </div>


                                <div className="mt-3">
                                    <p className="" style={{ color: "#333333", fontSize: '27px', fontFamily: "sans-serif", fontWeight: "600" }}>
                                        Compact Hotel Phones - GHP Series
                                    </p>
                                </div>
                                <div className="" style={{ fontSize: "14px", color: "#666666", fontFamily: "sans-serif" }}>
                                    <p>
                                        The GHP Series of hotel phones feature easy-to-use IP phones for any hotel room that can be programmed and customized based on the needs of hotels and their guests. The GHP Series is supported by Grandstream’s Device Management System (GDMS), which provides a centralized interface to configure, provision, manage and monitor deployments of Grandstream endpoints. GHP series IP phones can be installed on desktop or wall-mounted and contain Wi-Fi adjacent models equipped with integrated dual-band Wi-Fi to support wireless deployments. Thanks to a sleek and compact design, the series is the ideal fit for not only hotels but also apartments, dormitories, hospitals, retail and much more.
                                    </p>
                                    <p>
                                        Grandstream Hotel IP phones deliver high-quality audio features for clear audio every time. Its latest design and security features make this a best-in-class IP phone for hotels and similar organizations to be used for communication.
                                    </p>
                                </div>
                                <div className="row gy-4 mb-4">
                                    <div className="col-md-4 h-100 ">
                                        <div className="border border-dark">
                                            <div className="d-flex  justify-content-center align-items-center  ">
                                                <img className="w-100" src={require("../images/GHP620&GHP621.png")} alt="GHP620&GHP621" />
                                            </div>
                                            <div className="text-center">
                                                <p style={{ fontSize: "18px", fontFamily: "serif" }}>GHP620(W) & GHP621(W)</p>
                                                <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000" }}>3-way audio conferencing for easy conference calls</p>
                                                <NavLink exact to="/GHP620" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4  h-100">
                                        <div className="border border-dark">
                                            <div className="d-flex  justify-content-center align-items-center  ">
                                                <img className="w-100" src={require("../images/GHP610&GHP611.png")} alt="GHP610&GHP611" />
                                            </div>
                                            <div className="text-center">
                                                <p style={{ fontSize: "18px", fontFamily: "serif" }}> GHP610(W) & GHP611(W)</p>
                                                <p style={{ fontSize: "12px", fontFamily: "sans-serif", color: "#000000" }}>3-way audio conferencing for easy conference calls</p>
                                                <NavLink exact to="/GHP610" style={{fontFamily:"sans-serif",color:"#186191",fontSize:"14px"}}>Read More →</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div >
            </section >

            
            <Footer />
        </>
    )
}
export default HotelPhone;
