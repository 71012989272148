import React from "react";
import "./Patners.css";

const Patners =()=>{

    return(
        <>
          

        <div className="partner">
           
              <div className="partner-border"></div>

                <div className="partner-wrapper">
                   <div className="partner-slider">
                      <div className="partner-track">

                        <div className="partner-block">
                            <img src={require("../images/Hyundai.jpg")} className="img-fluid" alt="patners"/>
                        </div>
                        <div className="partner-block">
                            <img src={require("../images/Govind-Milk.png")} className="img-fluid" alt="patners"/>
                        </div>
                        <div className="partner-block">
                            <img src={require("../images/Rayat-Shikshan-Sanstha.png")} className="img-fluid" alt="patners"/>
                        </div>
                        <div className="partner-block">
                            <img src={require("../images/Yashwantrao.png")} className="img-fluid" alt="patners"/>
                        </div>
                        <div className="partner-block">
                            <img src={require("../images/Kedareshwar.png")} className="img-fluid" alt="patners"/>
                        </div>
                        <div className="partner-block">
                            <img src={require("../images/Oyo.png")} className="img-fluid" alt="patners"/>
                        </div>
                        <div className="partner-block">
                            <img  src={require("../images/Bvg.png")} className="img-fluid" alt="patners"/>
                        </div>
                        <div className="partner-block">
                            <img src={require("../images/Webplate.png")} className="img-fluid" alt="patners"/>
                        </div>
                        <div className="partner-block">
                            <img src={require("../images/Haosen.png")} className="img-fluid" alt="patners"/>
                        </div>
                        <div className="partner-block">
                            <img src={require("../images/Ebixcash.png")} className="img-fluid" alt="patners"/>
                        </div>

                    </div>
                   </div>
              </div>
            
        </div>
        </>
    )
}
export default Patners;