import React from "react";
import Footer from "./Footer";
import { KeyboardDoubleArrowRight } from "@mui/icons-material";
import Header from "./Header";



const GXV3611IRHD =()=>{

    return(
        <>
        <Header/>
          <div className="GXV3611IR_HD">
          <div class="p-3">
             <img src="https://skywardtelecom.co.in//static/media/brand.jpg" className="img-fluid" alt="brand" />
              <h1>GXV3611IR HD</h1>
          </div>
          <div class="clearfix">
             <p>
              <img src="https://skywardtelecom.co.in//static/media/GXV3611IR_HD-2.png" class="col-md-6 float-md-end mb-3 ms-md-3" alt="..."/>
                <h2>HD IP Cameras</h2>
                An innovative surveillance solution to not only monitor and secure a location but have an audio conversation gives users unprecedented control over their facilities. The GXV3611 IR_HD is an indoor camera with infrared capabilities that is perfect for indoor areas where low light may be a concern and 2-way communications is a must. Banks, retail stores and residential entry points all are potential deployments for the GXV3611 IR_HD.
               </p>
              </div>
              <div class=" p-6 mb-2 bg-body-tertiary">
             <p>
             <h5>Description</h5>
             The GXV3611IR_HD is an easily deployable solution for advanced security that users can rely on for elevated monitoring and facility control. Perfect for banks, hotels, retail offices and building entrances, its high-definition 2.88mm lens and infrared capabilities make it a go to solution for users looking for added flexibility. The GXV3611IR_HD supports auto-exposure/auto-white-balance for exceptional performance in all lighting conditions, and 2-way audio provides an ideal solution for facility management.
              The GXV3611IR_HD can be managed with GSURF Pro (Grandstream’s FREE Video Management Software that allows simultaneous control of up to 72 cameras) along with other ONVIF-complaint video management systems.
             </p>
         </div>
         <div className="wp825-box">
                 <div className="wp825-block">
                     <img src="https://skywardtelecom.co.in//static/media/GXV3611IR_HD-1.png" class=" float-md-start" alt="..." className="wp825-img"/>
                         </div>
                 <div className="wp825-block ">
                <h5>Features</h5>
                <h6><KeyboardDoubleArrowRight className="ar"/>Integrated Power-over-Ethernet (802.3af)</h6>
                <h6><KeyboardDoubleArrowRight className="ar"/>Advanced multi-streaming rate real-time H.264, Motion JPEG</h6>
                <h6><KeyboardDoubleArrowRight className="ar"/>Includes built-in speaker, microphone and SD card slot for local storage</h6>
                <h6><KeyboardDoubleArrowRight className="ar"/>1 megapixel Progressive Scan CMOS image sensor, 720p resolution, 2.8mm lens</h6>
                <h6><KeyboardDoubleArrowRight className="ar"/>Supports Motion Detection and can send notifications when security events occur to IP Video Phones, IP Phones, screenshot to email, and more</h6>
                </div>
                </div>
          </div>
          <Footer/>
        </>
    )
}
export default GXV3611IRHD;